import state from './app.state';
import getters from './app.getters';
import mutations from './app.mutations';
import actions from './app.actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

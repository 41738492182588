





































import Modal from "@/components/Modal.vue";
import {Banner as BannerApi, Applications, Menu} from "@/services/SOLO";
import {BanAttributes, Banner, Slide} from "@/models";
import {translations} from '@/mixins'
import {mapGetters} from 'vuex'

import {
  ValidationProvider,
} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

interface CreateAppForm {
  application_type?: string
  label?: string
  default_menu_id?: number | null
  hosts?: string
  slider_id?: number | null
  'menu_only'?: any
  'payment-gateway'?: string | any
  'allowed_order_types'?: string | any
  'allowed_payments_pickup'?: string | any
  'allowed_payments_deliver'?: string | any
}

@Component({
  components: {
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getConceptSettings: "account/getConceptSettings",
      getUser: 'account/getUser',
      getUserClient: 'account/getUserClient'

    }),

  },
  mixins: [translations],
})
export default class WebAddressSetModal extends Vue {
  $notify: any;
  getConceptSettings!: any
  getUserClient!: any
  getUser!: any
  $refs!: {
    file: any;
  }
  isLoading: Boolean = false;
  menus: any = []
  selectedMenu: any = '';
  form: CreateAppForm = {
    application_type: "social-ordering",
    label: "",
    default_menu_id: null,
    hosts: "",
    slider_id: null,
    menu_only:  0,
    'payment-gateway': '',
    'allowed_order_types': 'deliver',
    'allowed_payments_pickup': 'cash',
    'allowed_payments_deliver': window.location.host === 'online.foodics.com' ? 'card':'cash',
  }
  webAddress: any = ''
  @Prop() open!: Boolean
  @Prop() defaultWebAddress!: any

  get isOpen(): Boolean {
    return this.open;
  }
  set isOpen(newVal: Boolean) {
    if (!newVal) {
    }
  }

  constructor() {
    super();
  }

  mounted() {
    this.getMenus();
  }

  copyWebaddress() {
    if(this.getClientUserLabel == 'Solo Portal') {
      navigator.clipboard.writeText('https://' + this.defaultWebAddress + '.ordernosh.com'); // copy the value to the clipboard

    } else {
      navigator.clipboard.writeText('https://' + this.defaultWebAddress + '.foodics.online'); // copy the value to the clipboard

    }
  }

  async getMenus() {
    await Menu.all().then((response: any) => {
      this.menus = response.data.data;
    });
    this.selectedMenu = this.menus[0]
    this.form.default_menu_id = this.selectedMenu.id

    console.log("select sel", this.selectedMenu);
  }


  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }
  nextTo() {
       this.$emit("modal:close", false);

  }
}

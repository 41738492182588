import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';
import account from './account'
import menu from './menu'
import app from './app'
import messaging from './messaging'
import page from './page'
import button from './button'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  // key: process.env.VUE_APP_HOSTNAME === 'foodics.getsolo.io' ? 'FOODICS' : 'SOLO',
  key: 'SOLO',
  storage: localStorage,
});

export default new Vuex.Store({
  modules: {
    account,
    menu,
    app,
    messaging,
    page,
    button,
  },
  plugins: [vuexPersist.plugin]
});






















import Modal from "@/components/Modal.vue";
import {Banner as BannerApi} from "@/services/SOLO";
import {BanAttributes, Banner, Slide} from "@/models";
import {translations} from '@/mixins'
import {
  ValidationProvider,
} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
  },
  mixins: [translations],
})
export default class WebAddressSetModal extends Vue {
  $notify: any;
  $refs!: {
    file: any;
  }
  @Prop() open!: Boolean


  get isOpen(): Boolean {
    return this.open;
  }
  set isOpen(newVal: Boolean) {
    if (!newVal) {
    }
  }

  constructor() {
    super();
  }

  closeLogo(e: any) {
    this.$emit("modal:close", e);
  }

}

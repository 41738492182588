import BaseService from '../base.service'
import store from '@/store'
export default class PageService extends BaseService {
  url!: string
  constructor() {
    super();
    this.baseURL = this.baseURL + 'cms/';
  }

  all(page = 1, size = 10) {
    this.url = this.baseURL + 'pages';
    return super.get(this.url, {
      page: page,
      size: size,
    });
  }

  find(id: any, lang: string = 'ar-sa') {
    this.url = this.baseURL + `pages/${id}`;
    // this.headers['Accept-Language'] = lang;

    return super.get(this.url);
  }

  add(payload: {}) {
    let url = this.baseURL + 'pages';

    return super.post(url, payload);
  }

  update(payload: {}, id: any) {
    let url = this.baseURL + `pages/${id}`;

    return super.patch(url, payload);
  }

  search(label: string) {
    this.url = this.baseURL + `pages?filter[label]=${label}`;  
    return super.get(this.url);
  }

  deletePage(id: any) {

    this.url = this.baseURL + `pages/${id}`

    return super.remove(this.url, {})  }
}




































































































import { Category, Settings } from '@/services/SOLO'
import {Select, Option, Menu, Form} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Category as CategoryModel, CustomField, Menus as MenuModel } from "@/models"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import { BForm } from 'bootstrap-vue'

interface itemName {
  [x: string]: string
}

interface modifierGroupItem {
  name: itemName
  'display-order': number | null  
  code: string
  minimum: string
  maximum: string  
  'image-uri': string
  price: number
  enabled: number
  
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [eventHandler]      
})
export default class CreateCustomFieldModal extends Vue {  
  parent: any
  name: itemName = {}
  enabled: boolean = true
  arrayLang: Array<string> = ["en-us", "ar-sa"]
  item: modifierGroupItem = {
    name: {},
    'display-order': null,
    code: '',
    minimum: '',
    maximum: '',
    'image-uri': '',
    price: 0,
    enabled: 1
  }

  waiting!: Function
  restore!: Function
  $notify: any
  $refs!: {
    form: BForm
  }
  
  constructor() {
    super()  
    this.parent = this.$parent?.$parent?.$parent?.$parent?.$parent?.$parent
    for(let i in this.arrayLang) {
      this.name[this.arrayLang[i]] = ''
    }
  }

  mounted() {
    
  }

  @Watch('enabled', { immediate: true, deep: true })
  isEnabled(newVal: boolean) {    
    this.item.enabled = newVal ? 1 : 0
  }
  
  saveForm(e: HTMLFormElement, reset: any) {
    const defaultText = e.submitter.innerHTML
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Saving modifier')        
    Settings.createModifiers(this.parent.parent.row.id, this.itemData)
      .then((response: any) => {                
        this.$emit('get:modifiers',this.arrayLang[0])
        this.restore(e, defaultText)
        
        this.item = {
          name: {},
          'display-order': null,
          code: '',
          minimum: '',
          maximum: '',
          'image-uri': '',
          price: 0,
          enabled: 1
        }

        for(let i in this.arrayLang) {
          this.name[this.arrayLang[i]] = ''
        }

        this.$notify({
          title: "DATA SAVED",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Modifier successfully created",
          type: "success",
          icon: "fas fa-check",
        })
        
        reset()

      })
      .catch((err: any) => {
        this.restore(e, defaultText)
        console.error(err)
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }

  get itemData(): modifierGroupItem {
    this.item.name = this.name
    return this.item
  }
}































































import moment from "moment";
import { Table, TableColumn, Button } from "element-ui";
import { Component, Vue } from "vue-property-decorator";
import { notificationAlerts, translations } from "@/mixins";

import { Promotion } from "@/services/SOLO";
import  Translations  from '../components/Translations.vue'

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  mixins: [translations, notificationAlerts]
})
export default class SegmentsCustomerList extends Vue {
  public translate!: Function;
  color: string = "white";
  successNotification!: Function;

  customers: any = null;
  rows: number = 0;
  perPage: number = 50;
  currentPage: number = 1;

  standardDateFormat(value: any) {
    if (!value) return "---";
    return moment(value).format("DD/MM/YYYY");
  }

  created() {
    this.getSegmentCustomers();
  }
  formatDate(date: string) {
    return moment(date).locale('en-us').format('DD/MM/YYYY HH:mm');
  }

  onViewCustomer(row: any) {

  }

  paginate(page: number) {
    this.currentPage = page;
    this.getSegmentCustomers();
  }

  getSegmentCustomers(): void {
    Promotion.getSegmentCustomers(this.$route?.params?.id, this.currentPage)
      .then((res) => {
        this.customers = res.data.data;
        this.rows = this.customers.total;
        this.perPage = this.customers.per_page;
        this.currentPage = this.customers.current_page;
      });
  }
}

export default {
  user: null,
  isFoodicsPay: false,
  userRole: [],
  isUserAllowed: false,
  concept: {},
  language: {},
  integration: {},
  conceptSettings: null,
  subscription: null,
  applications: {},
  dashboard: 5,
  paymentId: null,
  client: null
}


























































import { Component, PropSync, Mixins, Watch } from "vue-property-decorator";
import { Loyalty as LoyaltyService } from "@/services/SOLO";
import Translations from "@/mixins/translations";

@Component
export default class LoyaltyCollection extends Mixins(Translations) {
  loading: boolean = false;

  form: any = {
    mobile: "",
    points: 0
  };

  async addPoints() {
    try {
      this.loading = true;

      if (!this.form.mobile || !this.form.points) {
        return;
      }

      await LoyaltyService.collectPointsByMobile(this.form.mobile, this.form.points)

      this.$notify({
        title: `LOYALTY POINTS COLLECTION`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Points was added successfully!`,
        type: "success"
      } as any);
    } catch (e) {
      console.log(e);

      if (e.response) {
        this.$notify({
          title: `POINTS COLLECTION ERROR`,
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: (e as any).response.data.error[0].detail,
          type: 'danger',
        } as any);
      }

    } finally {
      this.loading = false;
    }
  }
}

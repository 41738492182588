



















import { translations } from '@/mixins';
import Translations from './components/Translations.vue';
import { Component, Vue } from "vue-property-decorator";
import { mixins } from 'vue-class-component';

import PromotionsHeader from './components/PromotionsHeader'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'

@Component({
  components: {
    Translations,
    PromotionsHeader,
    RouteBreadCrumb,
  },
  mixins: [translations],
})
export default class Promotions extends Vue {
  created() {
    
  }
}















































































import { eventHandler,translations } from "@/mixins"
import { Select, Option } from "element-ui"
import { Menu as MenuApi } from '@/services/SOLO'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import Multiselect from 'vue-multiselect'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
    Multiselect
  },
  mixins: [eventHandler, translations],
})
export default class AvailableComponentItems extends Vue {
  id: string = ''
  newComponentItems: Array<string> = []
  formatSelected: Array<string> = []
  @Prop() componentId!: string
  @Prop() availableItemIsLoading!: Boolean
  @Prop() selectedComponentItems!: Array<Object>
  @Prop() availableComponentItems!: Array<Object>
  options: any = []
  public translate!: Function
  $notify: any

  @Watch('componentId', {immediate: true, deep: true})
  onComponentIdchange(newVal: string) {    
    this.id = newVal
  }

  @Watch('selectedComponentItems', {immediate: true, deep: true})
  onSelectedComponentsChange(newVal: any) {
    this.formatSelected = newVal.map((item: any) => {
      return item.id
    }) 
    
    this.options = this.availableComponentItems.filter((item: any) => { 
      return !this.formatSelected.includes(item.id.toString())
    }).map((item: any) => {
      return {name: item.attributes.name, id: item.id}
    })
  }

  mounted() {
    this.newComponentItems = []
  }

  async linkItems(e: any) {
    let target = e.target
    let defaultText = target.innerHTML
    target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Saving component'  
    target.disabled = true       
    try {
      const payload = { items: this.newComponentItems.map((item: any) => {return item.id}) }
      await MenuApi.linkComponentItems(payload, this.id)
        .then((response: any) => {
          this.$emit('item:linked', this.id)
          this.$notify({
            title: this.translate("ADDED SUCCESSFULLY!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: this.translate("Component item has been added successfully"),
            type: "success",
            icon: "fas fa-check"
          })
        })
        .catch((err: any) => {
          console.log(err.response);
          this.$notify({
            title: this.translate("SYSTEM ERROR!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: err.response.data.error.detail,
            type: "danger",
            icon: "fas fa-bomb",
          });
      });
    } catch (error) {
      console.error('Error in Linking Items', error)
    } finally {
      this.newComponentItems = []
      target.innerHTML = defaultText
      target.disabled = true
    }
  }
}





































































































































































































































































































































































import {
  Form,
  Alert,
  Input,
  Row,
  Col,
  InputNumber,
  FormItem,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
  Table,
  TableColumn,
  Dialog
} from 'element-ui'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Category, Integrations, Location} from '@/services/SOLO'
import {translations, eventHandler} from '@/mixins';
import Translations from '../../../Translations.vue';
import { 
  mapGetters, 
  mapMutations 
} from 'vuex'
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate"
import Multiselect from 'vue-multiselect'
@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    Translations,
    ValidationProvider,
    Multiselect
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getUser: 'account/getUser',
      getMenus: 'menu/getMenus',
      getConceptSettings: 'account/getConceptSettings'
    }),
  },
  methods: {
    ...mapMutations({
        setIsRefresh: 'page/setIsRefresh'
    })
  },
  filters: {
    formTitle(str: any) {
      return str.split('-').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    fieldTitle(str: any) {
      return str.split('_').map((item: any) => {
        if(item == 'api') { //
          return 'API' //
        }
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    capitalizeFirstLetter(str: any) {
      if (typeof str !== 'string') return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  mixins: [translations, eventHandler],
})

export default class IntegrationAvailable extends Vue {
  @Prop() currentProviders?: Array<Object>
  disabled: boolean = false
  translate!: Function;
  getConceptSettings: any
  getLocale!: any
  fieldTitle!: any
  capitalizeFirstLetter!: any
  items: any = []
  isOpen: boolean = false
  categories: Array<Object> = []
  formElements: any = {
    options: {
      address_types: {
        home: '',
        company: '',
        school: ''
      },
      config: {
        categories: '',
        fields: {
          items: '',
          modifiers: '',
          modifierGroups: '',
          location: '',
        },
        sources: {
          Web: '',
          iOS: '',
          Android: '',
        }
      },
      concept_id: '',
      extra_items_deliver: '',
      language: '',
      license_code: '',
      menu_id: '',
      menu_template_id: '',
      order_mode_deliver: '',
      order_mode_pickup: '',
      password_salt: '',
      pay_sub_type_card_on_delivery: '',
      pay_sub_type_cash: '',
      pay_type_card_on_delivery: '',
      pay_type_cash: '',
      request_code: '',
      service_charge: '',
      tender_id_card: '',
      tender_id_card_on_delivery: '',
      tender_id_cash: '',
      wsdl_url: '',
      loyalty_type: '',
    }
  }
  $notify: any
  providerId: any = ''
  formPayload: any = {}
  payloadForm: any = {}
  installForm: any = {
    options: {
      address_types: {
        home: '',
        school: '',
        company: ''
      },
      config: {
        categories: [],
        fields: {
          items: '',
          modifiers: '',
          modifierGroups: '',
          location: ''
        },
        sources: {
          Web: '',
          iOS: '',
          Android: ''
        }
      }
    }
  }
  // itemFields = [
  //   {text: 'Name', value: 'name'},
  //   {text: 'Price', value: 'price'},
  //   {text: 'Minimum', value: 'minimum'},
  //   {text: 'Maximum', value: 'maximum'},
  //   {text: 'Enabled', value: 'enabled'},
  // ]
  // modifiers = [
  //   {text: 'Name', value: 'name'},
  //   {text: 'Price', value: 'price'},
  //   {text: 'Minimum', value: 'minimum'},
  //   {text: 'Maximum', value: 'maximum'},
  //   {text: 'Enabled', value: 'enabled'},
  // ]
  // locations = [
  //   {text: 'Lat', value: 'lat'},
  //   {text: 'Lng', value: 'lng'},
  //   {text: 'Opening Hours', value: 'opening_hours'},
  // ]
  // modifierGroups = [
  //   {text: 'Name', value: 'name'},
  //   {text: 'Minimum', value: 'minimum'},
  //   {text: 'Maximum', value: 'maximum'},
  // ]
  itemFields = ['name','price','minimum','maximum','enabled']
  modifiers = ['name','price','minimum','maximum','enabled']
  locations = ['lat','lng','opening_hours']
  modifierGroups = ['name','minimum','maximum']

  getMenuIdForCategories: any = ''
  search: any = ''
  branches: any = []
  locationsArr: any = []
  parentBranches: any = []
  branchArr: any = []
  loadedBranchesArr: any = []
  newBranches: any = []
  finalBranches: any = []
  files: any = {}
  newBranchArr: any = []
  tAgree: boolean = false
  hasTAgree: boolean = false
  foodicsOnlinePaymentFlag: any
  yallowError: boolean = false
  waiting!: Function
  public getUser!: any
  getMenus!: any;
  checkSDM: any = '';
  catMenu: any = []
  selectedAvailableIntegration: any = null
  setIsRefresh!: Function
  isValidNumber: boolean = true

  @Watch('currentProviders', {deep: true})
  onCurrentProvidersChanged(newVal: any) {
    this.items = newVal
  }

  @Watch('catMenu', {deep: true})
  onCurrentCategories(newVal: any) {

    let len = newVal.length;
    let i = 0;
    var arr = [];
    for(i=0;i<len;i++) {
      arr.push(newVal[i].attributes.code)
    }
    this.installForm.options.config.categories = arr;
  }


  @Watch('isOpen', {deep: true})
  onIsOpenChanged(newVal: boolean) {
    if (newVal) { // true
      this.branches = []
      this.formPayload = {}
      this.disabled = true

      if (this.formElements.label == 'foodics-online' && this.formElements.type == 'payment') {
        this.formPayload.contact_name = this.getUser.attributes.name
        this.formPayload.email = this.getUser.attributes.email
        this.formPayload.mobile = this.getUser.attributes.mobile
      }
    }
  }

  @Watch('formPayload.mobile', { deep: true, immediate: false })
  onChangeMobileNumber(newVal: any) {
    if (this.selectedAvailableIntegration.label === 'whatsapp' && newVal !== undefined && newVal !== '') {
      const { 'mobile-format' : pattern } = this.getConceptSettings.attributes
      const newRegexPattern = new RegExp(pattern)
      this.isValidNumber = newRegexPattern.test(newVal)
    } else {
      this.isValidNumber = true
    }
  }

  mounted() {
    this.getIntegrationLocation()
    console.log("menuss", this.getMenus);
  }

  getCustomPlaceHolder(field : any): any {
    let tempArr: any[] = []
    const items: any = {
      "tap" : {
        fields: [
          {
            name: "token",
            placeholder: "sk_live_xxxxx"
          },
          {
            name: "web_public_key",
            placeholder: "pk_live_xxxxx"
          }
        ],
      }
    }
    
    if (items[this.selectedAvailableIntegration.label]) {
      tempArr = items[this.selectedAvailableIntegration.label].fields.map((item : any) => {
        if (item.name === field) {
          return item.placeholder
        }
      })

      return tempArr.filter((val: any) => {
        return val !== undefined;
      })[0];
    }
  }

   customLabel (option: any) {
      return option.attributes.name +  ' - ' + option.attributes.code
    }
   customLabel1 (option: any) {

      const str2 = option.charAt(0).toUpperCase() + option.slice(1);

      return str2;
    }

    fieldTitleF(str: any) {
      return str.split('_').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    }

  beforeClose(done: any) {
    this.isOpen = false
    done()
  }

  closeModal() {
    this.formElements = {}
    this.branches = []
    this.isOpen = false
  }

  termAgree(name: any, trms: any, flag: any) {
    this.tAgree = trms;
    this.hasTAgree = true;
    this.foodicsOnlinePaymentFlag = flag;
    return name === 'I agree to the <a href=\"https://skylinedynamics.com\">Terms and Conditions</a>' ? 'I agree to the Terms and Conditions' : ''
  }

  yallowValidator(data: any) {
    this.yallowError = data;
  }

  addRow() {
    this.branches.push({
      id: '',
      edited: true,
      name: '',
    })
  }

  getIntegrationLocation() {
    Location.getAllLocations().then((response: any) => {
      this.parentBranches = response.data.data
      this.branches = this.parentBranches
    });
  }

  handleDelete(index: any, data: any, branches: any) {
    branches.splice(index, 1)
    Location.removeLocation(data.id).then((response: any) => {
    })
  }

  handleEditRow(index: any, data: any, branches: any) {
    branches[index].edited = true
  }

  handleSaveRow(index: any, data: any, branches: any) {

    this.branchArr[index] = `"${data.name}":"${data.id}"`
    this.formPayload.branches = `{${this.branchArr}}`

    branches[index].edited = false
  }

  getMenuID(e: any) {
    this.getMenuIdForCategories = e;
    this.getSdmCategories(this.getMenuIdForCategories)

  }

  getProviderItem(data: any) {
    console.log("check integ", data);

    if(data.attributes.label == 'sdm') {
      this.checkSDM = data.attributes.label;
    }
    this.selectedAvailableIntegration = data.attributes
    if (data.attributes.label == 'yallow') {
      this.yallowError = true
    } else {
      this.yallowError = false
    }
    this.isOpen = true
    this.providerId = data.id
    this.formElements = data.attributes
    this.locationsArr = data.attributes.available_options.branches ? JSON.parse(data.attributes.available_options.branches) : []
    // if (this.formElements.type === 'pos') {
    //   // this.formElements.options.address_types = JSON.parse(this.formElements.options.address_types)
    //   // this.formElements.options.config = JSON.parse(this.formElements.options.config)
    //   this.getSdmCategories(this.getMenuIdForCategories)
    // }
  }



  redirectToInstallUrl(url: string) {
    window.location.href = process.env.VUE_APP_URL + '/' + url;
  }

  async getSdmCategories(menuID: any) {
    let staticMenuId = menuID;
    await Category.index(staticMenuId).then((response: any) => {
      this.categories = response.data.data
      console.log("check cat sdm", this.categories);
    })
  }

  checkImgSource(data: any) {
    try {
      let checkLogo = require(`@/assets/images/integrations/providers/${data}.png`)
      if (checkLogo) {
        return checkLogo
      }
    } catch (e) {
    }
  }

  buildFormData(formData: FormData, data: any, parentKey: string = '') {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  handleFile(event: any, key: string) {
    this.files[key] = event.target.files[0];
  }

  async install(e: any) {
    let optionPayload: any = {}
    let payload: any = []
    let set: any = []

    if (Object.keys(this.formPayload).length === 0 && this.formElements.available_options.length != 0) {
      this.$notify({
        title: "Required Fields",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Please fill in the fields!",
        type: "warning",
        icon: "fas fa-bomb",
      });
      return false;
    }

    if(window.location.host === 'foodics.getsolo.io' || window.location.host === 'online.foodics.com') {
      if(!this.tAgree && this.hasTAgree) {
            this.$notify({
              title: "Terms and Conditions",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "You must agree with the terms and conditions first!",
              type: "warning",
              icon: "fas fa-bomb",
            });
        return false;
      }

      if(this.foodicsOnlinePaymentFlag == 'has_terms_agreed') {
          if(!this.formPayload.contact_name || !this.formPayload.email || !this.formPayload.mobile ) {
                this.$notify({
                  title: "Online Payment",
                  verticalAlign: "bottom",
                  horizontalAlign: "left",
                  message: "Please fill in the fields!",
                  type: "warning",
                  icon: "fas fa-bomb",
                });
            return false;
          }
      }
    }

    if(this.yallowError) {
      this.$notify({
        title: "Yallow Error",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Please fill in the fields!",
        type: "warning",
        icon: "fas fa-bomb",
      });
      return false;
    }


    for (let key in this.formPayload) {
      set = {
        'key': key,
        'value': this.formPayload[key]
      }
      payload.push(set)
    }

    if(this.checkSDM == 'sdm') {
      for (let key in this.installForm.options) {
        set = {
          'key': key,
          'value': typeof (this.installForm.options[key]) === 'object' ? JSON.stringify(this.installForm.options[key]) : this.installForm.options[key]
        }
        payload.push(set)
      }
    }

    optionPayload['type'] = this.formElements.type
    optionPayload['provider'] = this.formElements.label
    optionPayload['options'] = payload
    this.payloadForm = optionPayload

    if (this.files.length != 0) {
      this.payloadForm.files = this.files;
      const formData = new FormData();
      this.buildFormData(formData, this.payloadForm);
      this.payloadForm = formData;
    }

    const defaultText = e.target.innerHTML
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Installing')
    await Integrations.store(this.formElements.label, this.payloadForm)
        .then((response) => {
          this.isOpen = false
          this.$notify({
            title: "Integration Provider",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Integration provider has been successfully installed.",
            type: "success",
            icon: "fas fa-check",
          });
          this.setIsRefresh(1)
          this.$emit("getIntegrations");
        })
        .catch((err) => {
          this.isOpen = false
          if(err.response.data.error[0]?.detail) {
            this.$notify({
              title: "Integration Provider",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data.messages[Object.keys(err.response.data.messages)[0]][0] || err.response.data.error[0]?.detail,
              type: "danger",
              icon: "fas fa-bomb",
            });
          }
          
        })
  }
}

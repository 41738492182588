























































import { Component, Prop, Vue } from "vue-property-decorator";
import { Promotion } from "@/services/SOLO";
import Criteria from "./Criteria";
import { v4 as uuidv4 } from "uuid";
import { Form, FormItem } from "element-ui";
import Translations from "./Translations.vue";
import { translations } from "@/mixins";

@Component({
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    Criteria
  },
  mixins: [translations]
})
export default class CriteriaFields extends Vue {
  @Prop() action!: string;
  @Prop() segment!: any;

  criteriaList: Array<any> = [];
  isLoading: boolean = false;
  created() {
    this.getCriteria();
  }

  getCriteria() {
    Promotion.fetchSegmentsCriterias().then(res => {
      this.criteriaList = res.data.data;
      if (!this.segment.criteria.length) {
        this.addCriteria();
      }
    });
  }

  addCriteria() {
    this.segment.criteria.push({
      id: uuidv4(),
      provider: this.criteriaList[0].provider,
      condition: this.criteriaList[0].conditions[0],
      value: null,
      html_type: this.criteriaList[0].html_type,
      choices: this.criteriaList[0].choices
    });
  }

  deleteCriteria(id: number) {
    if (this.segment.criteria.length > 1) {
      let index = this.getIndexById(id);
      this.segment.criteria.splice(index, 1);
    }
  }

  getIndexById(id: number) {
    let criteria = this.segment.criteria.find(
      (criterion: any) => criterion.id === id
    );
    return this.segment.criteria.indexOf(criteria);
  }

  updateCriterionValue(val: any, criterion: any) {
    if (val) {
      criterion.value = val;
    }
  }
}

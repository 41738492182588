export default {
    page_allowed: [],
    page_disabled: [],
    money_hash: null,
    solo_loyalty: null,
    partoo_gmb: null,
    whats_app: null,
    is_refresh: 1,
    is_whatsapp_verified: false,
    is_whatsapp_onboarded: false
}





































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { eventHandler, translations } from '@/mixins';
import { Menu as MenuApi } from '@/services/SOLO';
import { Button, Table, TableColumn } from 'element-ui';
import menuRoles from '@/directives/menuRoles';
import {AuditLog as AuditLogApi} from "@/services/SOLO"
import {mapGetters} from 'vuex';
import EditModgroupItem from '@/views/Admin/pages/Menus/pages/itemPages/modifiercomponents/EditModgroupItem';
import LoadingPanel from "@/components/LoadingPanel"
import draggable from "vuedraggable"
import EnableDisableModifierModal from './EnableDisableModifierModal';
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    EditModgroupItem,
    LoadingPanel,
    draggable,
    EnableDisableModifierModal
  },
  directives: {
    menuRoles
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      userRole: 'account/getUserRole',
      getUserClient: 'account/getUserClient',
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [eventHandler, translations],
})
export default class ModgroupModifiers extends Vue {
  getLocale!: any;
  public items: Array<any> = [];
  public itemsSecondary: Array<any> = [];
  loading: boolean = false
  color: string = 'white';
  view: string = 'grid';
  private arrayLang: Array<string> = [];
  getLanguages!: Function;
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  tab: number = 1;
  translate!: Function;
  pageLoaded: boolean = false;
  isLoaded: boolean = false;
  list: any = [];
  getConceptSettings!: Function;
  loadingConcept: Boolean = true;
  userRole!: any;
  isEnableDisableOpen: Boolean = false;
  itemId: any = 0;
  getUserClient!: any;
  showGripIconItem: any = null;
  confirm!: Function;
  $notify: any;
  uploadImgItemId: any = 0;
  indexImgItemId: any = 0;
  $refs!: {
    file: any;
  };
  created() {
    if (this.$route.params.tab) {
      this.tab = Number(this.$route.params.tab);
    }
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    /* @ts-ignore */
    if (this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
    console.log('arrayLang', this.arrayLang);
  }

  mounted() {
    for (let i in this.arrayLang) this.getModifierItem(this.arrayLang[i]);
  }

  onHoverCard(ind: any){
    this.showGripIconItem = ind
  }

  uploadImage(itemId: any, index: any) {
    this.uploadImgItemId = itemId;
    this.indexImgItemId = index;
    /* @ts-ignore */
    document.getElementById('upload').click();
  }

  filesUploadChange() {
    console.log('filesUploadChange', this.$route.params);
    let formData = new FormData();
    formData.append('image', this.$refs.file.files[0]);
    MenuApi.updateModifierItem(formData, this.$route.params.modifierId, this.uploadImgItemId)
    .then((response: any) => {
      this.items[this.indexImgItemId].attributes['image-uri'] = response.data.data.attributes['image-uri'];
      this.$notify({
        title: "UPDATES SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Image have been upload successfully",
        type: "success",
        icon: "fas fa-check",
      });
    })
    .catch((err: any) => {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: err.response.data.error.detail,
        type: "danger",
        icon: "fas fa-bomb",
      });
    });
  }

  sortItems() {
    const promiseArr: any = [];
    let dynamicIndex = ((this.currentPage - 1) * this.perPage) + 1;
    this.items.forEach((item: any) => {
      let form = new FormData();
      form.append(`display-order`, dynamicIndex.toString());
      form.append(`enabled`, item.attributes.enabled.toString());
      MenuApi.updateModifierItem(form, this.$route.params.modifierId, item.id);
      dynamicIndex += 1;
    });
  }

  public get isFoodics() {
    return this.getUserClient?.attributes?.label === 'Foodics Online';
  }

  async viewAudit() {
    if (! this.pageLoaded) {
      this.loading = true
      await AuditLogApi.all('modifier-groups', parseInt(this.$route.params.modifierId), this.currentPage, this.perPage)
        .then((response: any) => {
          this.total = response.data.meta.pagination.total
          // this.currentPage = response.data.meta.pagination.current_page
          this.perPage = response.data.meta.pagination.per_page
          this.isLoaded = true

          this.list = response.data.data
          this.loading = false
          this.pageLoaded = true
        })
    }
  }

  findAndUpdateItem(itemId: string, action: string, value: Boolean = true) {
    switch (action) {
      case 'enableDisable':
        this.items.forEach(element => {
          if (element.id == itemId) {
            element.attributes.enabled = (value == true ? 1 : 0)
          }
        });
        break;
      case 'remove':
        this.items.forEach((element: any, index: any) => {
          if (element.id == itemId) {
            this.items.splice(index, 1)
          }
        });
        break;
      case 'includeExclude':
        this.items.forEach((element: any, index: any) => {
          if (element.id == itemId) {
            element.attributes['is-excluded'] = !value
          }
        });
        break;
      case 'enableNonAdminModItem':
        this.items.forEach(element => {
          if (element.id == itemId) {
            element.attributes['is-enabled-for-employee'] = true;
          }
        });
        break;
    }
  }

  private enableDisable(id: string, enabled: any) {
    let payload = {
      enabled: enabled
    }
    MenuApi.updateModifierItem(payload, this.$route.params.modifierId, id)
      .then((response: any) => {
        // this.getModifierItem()
        this.findAndUpdateItem(id, 'enableDisable', payload.enabled)
      })
  }

  private enableNonAdminModItem(id: string) {
    let payload = {
      'schedule-disabled-hours': '__enabled__',
    }
    MenuApi.updateModifierItem(payload, this.$route.params.modifierId, id)
      .then((response: any) => {
        // this.getModifierItem()
        this.findAndUpdateItem(id, 'enableNonAdminModItem', true)
      })
  }

  enableDisableModal(open: any, itemId: string) {
    this.isEnableDisableOpen = !!open;
    this.itemId = itemId;
    
    if (!open) {
      this.items.forEach((element: any) => {
        if (element.id == itemId) {
          element.attributes['is-enabled-for-employee'] = open;
        }
      });
    }
  }

  closeModal(close: any) {
    this.isEnableDisableOpen = !!close;
  }

  private exclude(id: string, isExclude: Boolean) {
    let payload: Object = {
      'modifier-id': id
    }
    if(!isExclude) {
      MenuApi.exludeModifierItem(payload, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, this.$route.params.modifierId)
        .then((response: any) => {
          // this.getModifierItem()
          this.findAndUpdateItem(id, 'includeExclude', isExclude)
        })
    }else {
      MenuApi.includeModifierItem(payload, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, this.$route.params.modifierId, id)
        .then((response: any) => {
          // this.getModifierItem()
          this.findAndUpdateItem(id, 'includeExclude', isExclude)
        })
    }
  }

  get deleteMsgBoxText(): String {
    return this.translate('Are you sure you want to delete this Item?')
  }


  private remove(id: string) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if (value) {

          MenuApi.removeModifierItem(id)
            .then((response: any) => {
              // this.getModifierItem()
              this.findAndUpdateItem(id, 'remove');
            });
        }
      })
  }

  async getModifierItem(lang: string = 'en-us', page: any = 1) {
    await MenuApi.getModifierItem(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, this.$route.params.modifierId, lang, page)
      .then((response: any) => {
        console.log(`response ${lang}: `, response);
        /* @ts-ignore */
        if (this.getConceptSettings.attributes['primary-language']) {
          if (this.getLocale.replace("_", "-").toLowerCase() == lang) {
            this.items = response.data.data;
          }
        }
        /* @ts-ignore */
        if (this.getConceptSettings.attributes['secondary-language']) {
          if (this.getLocale.replace("_", "-").toLowerCase() == lang) {
            this.itemsSecondary = response.data.data;
          }
        }
        this.total = response.data.meta.pagination.total;
        this.currentPage = response.data.meta.pagination.current_page;
        this.perPage = response.data.meta.pagination.per_page;
        this.loadingConcept = false;
      });
  }

  pageChange(data: any) {
    for (let i in this.arrayLang) this.getModifierItem(this.arrayLang[i], data);
  }

  formatDate(date: string) {
    return moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .local()
      .format("MMM D YYYY hh:mm A");
  }

  onChangeView(view: string) {
    this.view = view;
  }

  getNameSecondary(row: any) {
    const find: any = this.itemsSecondary.find((item: any) => Number(item.id) === Number(row.id));
    return find && find.attributes.name;
  }
}

import moment from "moment";
import "moment/locale/pt-br";
import BaseService from '../base.service'
import store from '@/store'
export default class OrderService extends BaseService{

    constructor(){
        super();
    }


    all(page = 1, size = 10 ,filters: any = {}) {
        let filter = ''
        let now = moment.utc()

        // console.log('filters: ', filters);

        if (filters.today) {
            if (!filters.from) {
                filter += `&filter[from]=${now.format("YYYY-MM-DD")}`
                delete filters.from
            }

            if (!filters.to) {
                filter += `&filter[to]=${now.format("YYYY-MM-DD")}`
                delete filters.to
            }
        }
        // filter += 'order=1185863'
        // else {
        //     filter += Object.keys(filters)
        //         .filter(k => { return k != 'today' })
        //         .map(k => { return `filter[${encodeURIComponent(k)}]` + '=' + encodeURIComponent(filters[k])})
        //         .join('&')

        // }
        filter += '&'
        filter += Object.keys(filters)
                .filter(k => { return k != 'today' })
                .map(k => { return `filter[${encodeURIComponent(k)}]` + '=' + encodeURIComponent(filters[k])})
                .join('&')

        // console.log('filter: ', filter);

        let url = this.baseURL + `orders?page=${page}&size=${size}${filter}&include=items`;

        return super.get(url);
    }

    orderstatuses() {
        this.conceptId = store.getters['account/activeConcept'].id
        let url = this.baseURL + 'concepts/' + this.conceptId + '/statuses';
        return super.get(url);
    }
    
    activeStatusList() {
        const concept = store.getters['account/activeConcept'].key;
        let url = this.baseURL + 'concepts/' + concept + '/statuses';
        return super.get(url);
    }

    orderStatuses() {
        let url = this.baseURL + 'orders/statuses';
        return super.get(url);
    }


    updateorderstatuses(payload: Object = {}, orderstatusID: string | number) {
        this.conceptId = store.getters['account/activeConcept'].id
        let url = this.baseURL + `concepts/` + this.conceptId + `/statuses/${orderstatusID}`

        return super.patch(url, payload);
    }

    validate(params: object = {}) {
        let url = this.baseURL + 'orders/validate'

        return super.post(url, params);
    }

    store(params: {}) {

        let url = this.baseURL + 'orders';

        return super.post(url, params);
    }

    coupon(coupon: string){
        let url = this.baseURL + 'coupons?coupon-code=' + coupon + '&include=promotion' ;
        return super.get(url);
    }

    applycoupon(params: {}){
        let url = this.baseURL + 'orders?include=promotion';

        return super.post(url, params);
    }

    hyperpay(params: {})
    {
        let url = this.baseURL + 'hyperpay/checkout';

        return super.post(url, params);
    }

    hyperpayStatus(params: {})
    {
        let url = this.baseURL + 'hyperpay/get-payment-status';

        return super.post(url, params);
    }

    getOrderStatusSync(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/sync';

        return super.get(url)
    }

    find(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '?include=items';

        return super.get(url)
    }

    statuses(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/statuses?filter[visible]=1';

        return super.get(url)
    }

    updatePromisedTime(orderId: string, payload: any) {
        const url = this.baseURL + 'orders/' + orderId;

        return super.patch(url, payload);
    }

    feedback(params: {})
    {
        let url = this.baseURL + 'feedback'
        let headers = {
            "Content-Type": "form-data"
        }
        return super.post(url, params, headers)
    }

    postedFeedback(orderId: string)
    {
        let url = this.baseURL + 'orders/'+ orderId +'/ratings/topics'

        return super.get(url)
    }

    topics()
    {
        let url = this.baseURL + '/topics'

        return super.get(url)
    }

    findItems(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/items';

        return super.get(url)
    }

    findResource(orderId: string,itemOrderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/items/' + itemOrderId + '?include=items';

        return super.get(url)
    }

    statusList() {
        let url = this.baseURL + `orders/statuses`
        return super.get(url)
    }

    findStatuses(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/statuses';

        return super.get(url)
    }

    findDriver(orderId: string){
        let url = this.baseURL + 'orders/' + orderId + '/employees?filter[function]=driver';

        return super.get(url)
    }

    updateStatus(payload: Object = {}, orderId: string) {

        let url = this.baseURL + `orders/${orderId}/statuses`

        return super.post(url, payload)
    }

    updatePaymentMethod(payload: Object = {}, orderId: string | number) {

        let url = this.baseURL + `orders/${orderId}/payments`

        return super.post(url, payload)
    }



    updateDate(payload: Object = {}, orderID: string | number) {

        let url = this.baseURL + `orders/${orderID}`

        return super.patch(url, payload);
    }


    cancel(orderId: string, params: any) {

        let url = this.baseURL + 'orders/' + orderId + '/cancellation';

        return super.patch(url, params);
    }

    exportOrder(orderId: string) {
        let url = this.baseURL + `orders/${orderId}/exports`

        return super.post(url, {});
    }

    exportAllOrder(filters: any = {}) {
        let filter = ''
        let now = moment()


        if (filters.today) {
            if (!filters.from) {
                filter += `&filter[from]=${now.format("YYYY-MM-DD")}`
                delete filters.from
            }

            if (!filters.to) {
                filter += `&filter[to]=${now.format("YYYY-MM-DD")}`
                delete filters.to
            }
        }

        filter += '&'
        filter += Object.keys(filters)
                .filter(k => { return k != 'today' })
                .map(k => { return `filter[${encodeURIComponent(k)}]` + '=' + encodeURIComponent(filters[k])})
                .join('&')

                console.log('filter: ', filter);

                console.log('filters sa sa: ', filters);

        let url = this.baseURL + `orders/exports?${filter}`

        return super.post(url, {});
    }

    exportERP(date: string = '') {
        let url = this.baseURL + `reports/daily-sales/export?date=${date}`

        return super.get(url, {});
    }

    orderPayment(orderId: string) {
        let url = this.baseURL + `orders/${orderId}/payments`

        return super.get(url)
    }

    orderDetails(orderId: string) {
        let url = this.baseURL + `orders/${orderId}?include=items,modifiers,customerAddress,payfortHooks`

        return super.get(url)
    }

    getCustomer(orderId: string) {
        let url = this.baseURL + `customers/${orderId}`

        return super.get(url)
    }

    getCustomerAddresses(customerId: string) {
        let url = this.baseURL + `customers/${customerId}/addresses`

        return super.get(url)
    }

    getDeliveryLogs(orderID: any) {
      let log = this.baseURL1 + `delivery-logs?filter[order-id]=${orderID}`;
  
      return super.get(log);
    }

    getPaymentLogs(orderID: any) {
      let log = this.baseURL1 + `payments?filter[order-id]=${orderID}`;
  
      return super.get(log);
    }
}

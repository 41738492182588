
























































































































































































































































import {translations} from '@/mixins'
import {mapGetters} from 'vuex'
import {Select, Option} from "element-ui";

import {
ValidationProvider,
} from "vee-validate";
import {Component, Prop, Vue, Watch, Emit,} from "vue-property-decorator";
import { Applications } from "@/services/SOLO";

@Component({
components: {
ValidationProvider,
[Select.name]: Select,
[Option.name]: Option,
},
computed: {
...mapGetters({
  getConceptSettings: "account/getConceptSettings",
  getUser: 'account/getUser',
  getUserClient: 'account/getUserClient'

}),

},
mixins: [translations],
})
export default class WebAddressSetModal extends Vue {
$notify: any;
getConceptSettings!: any
getUserClient!: any
termsFlag: Boolean = false
getUser!: any
$refs!: {
file: any;
}
term_agree: Boolean = false
isLoading: Boolean = false;
deliveryCharge: any = "";
deliveryChargeID: any = "";

@Prop() open!: Boolean
@Prop() deliveryCharges!: any

get isOpen(): Boolean {
return this.open;
}
set isOpen(newVal: Boolean) {
if (!newVal) {
}
}

constructor() {
super();
}

@Watch('term_agree', {deep: true, immediate: true})
  onChangeCheckTerms(data: any) {
    if(data) {
          if(data) {
            this.termsFlag = false;
          }
    }
  }

mounted() {
}

returnValueCharge(e: any) {
this.deliveryChargeID = e.id;
return e.value;
}



logout() {
     // this.logoutUser()
    setTimeout(() => {
      location.reload()
      localStorage.clear()
    }, 2000)
    // this.$router.go(0)
  }
async submitTerms() {
  if(!this.term_agree) {
    this.termsFlag = true;
    return;
  }
  this.isLoading = true;
  var payload = {
    "term-key": "solo-toc-20230205",
    "is-agreed": this.term_agree
    }
    await Applications.saveTerms(payload)
        .then((response: any) => {
          console.log("check response post terms", response.data);
          this.termsFlag = false;
          this.isLoading = false;
          this.$emit("modal:close", false);

        })
}




}





























































import { Pagination } from "element-ui";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Customer } from "@/services/SOLO";
import { translations } from "@/mixins";
import Translations from "./Translations.vue";

@Component({
  components: {
    [Pagination.name]: Pagination
  },
  mixins: [translations]
})
export default class CouponGroupCollapse extends Vue {
  @Prop() cardClass!: string;
  @Prop() hideResetButton!: boolean;
  @Prop() isDigitalCoupon!: boolean;
  public translate!: Function;
  name: string = "";

  mounted () {
    this.name = ""
  }

  get getSearchPlaceholder () : any {
    if (this.isDigitalCoupon === true || this.isDigitalCoupon === undefined || this.isDigitalCoupon === null) {
      return 'Enter name'
    }

    if (this.isDigitalCoupon === false) {
      return 'Enter coupon code'
    }
  }

  get getSearchInputLabel () : any {
    if (this.isDigitalCoupon === true || this.isDigitalCoupon === undefined || this.isDigitalCoupon === null) {
      return 'Name'
    }

    if (this.isDigitalCoupon === false) {
      return 'Coupon Code'
    }
  }

  pageChange(data: any) {
    this.$emit("getCoupons", data);
  }

  searchFeedback(name: string) {
    this.$emit("searchCoupons", {name: name});
  }

  resetForm(data: any) {
    this.name = "";
    this.$emit("reset", data);
  }
}

import BaseService from '../base.service'

export default class ApplicationsService extends BaseService{
  url!: string
  constructor(){
    super();
  }

  getDeliveryCharge() {
      this.url = this.baseURL + 'foodics-f5/charges'
      return super.get(this.url)
  }

  getEmployeeDetails(empId: any) {
    this.url = this.baseURL + `employees/${empId}/summary`
      return super.get(this.url)
  }

  getIntegrationID() {
    this.url = this.baseURL + 'integrations?filter[provider]=foodics-f5'
    return super.get(this.url)
  }

  saveDeliveryCharge(payload: Object = {}, intergation_id: any) {
    this.url = this.baseURL + `integrations/${intergation_id}`
    return super.post(this.url, payload)
  }

  saveTerms(payload: Object = {}) {
    this.url = this.baseURL + `user-terms`
    return super.post(this.url, payload)
  }

  getAllApplications() {
      this.url = this.baseURL + 'applications'
      return super.get(this.url)
  }

  getApplication(id: number) {
      this.url = this.baseURL + `applications/${id}?include=popup`
      return super.get(this.url)
  }

  createApplication(payload: Object = {}) {
      this.url = this.baseURL + 'applications/'
      return super.post(this.url, payload)
  }

  updateApplication(id: number, payload: Object = {}) {
      this.url = this.baseURL + 'applications/'+ id
      return super.post(this.url, payload)
  }

  updateApplicationPutMethod(id: number, payload: Object = {}) {//for MR issue
    this.url = this.baseURL + 'applications/'+ id
    return super.put(this.url, payload)
}


  updateApplicationTheme(id: number, payload: Object = {}) {
      this.url = this.baseURL + 'applications/'+ id +'/themes'
      return super.put(this.url, payload) //SOLO-2227
  }

  getClient(url: string) {
      this.url = this.baseURL + 'clients?filter[url]='+ url
      return super.get(this.url)
  }

  validateHost(url: string) {
      this.url = this.baseURL + 'application-validation/'+ url
      return super.get(this.url)
  }

  getTranslations(id: Number | String, key: string) {
    this.url = this.baseURL + `applications/${id}/translations` + (key ? `?filter[key]=${key}` : '')
    return super.get(this.url)
  }

  updateTranslation(id: Number | String, payload: []) {
    this.url = this.baseURL + `applications/${id}/translations`
    return super.post(this.url, payload)
  }

}

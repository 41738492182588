





















import Translations from "./Translations.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import VueQrious from "vue-qrious";
import { translations } from "@/mixins";


@Component({
  components: {
    Translations,
    VueQrious,
  },
  mixins: [translations],
})
export default class DigitalCouponQrCode extends Vue {
  
  translate!: Function;

  mounted() {
    console.log("DigitalCouponQrCode");
  }
}

















import moment from 'moment'
import { Feedback as FeedbackModel } from "@/models"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue'
import {Component, Vue} from "vue-property-decorator"
import FeedbackCollapse from "./FeedbackCollapse.vue"
import FeedbackList from "./FeedbackList.vue"
import { Feedback } from '@/services/SOLO'

@Component({
  components: {
    RouteBreadCrumb,
    FeedbackList,
    FeedbackCollapse
  }
})
export default class FeedbackItem extends Vue {
  feedbacks: Array<FeedbackModel> = []
  feedbackMeta: any = {}
  targetKeyword: string = ''
  isLoaded: boolean = false
  page: number = 1
  loading: boolean = false
  concept: any = ''
  location: any = ''

  mounted() {
    this.getFeedbacks(this.page);
  }

  async getFeedbacks(page: number) {
    this.loading = true
    await Feedback.all(page)
      .then((response: any) => {
        this.feedbacks = response.data
        this.feedbackMeta = response.data.meta.pagination

        let inc = response.data.included;
        let temp_concept = inc.find((e: any) => e.type == 'concept');

        this.location = inc.find((e: any) => e.type == 'location');

        this.concept = temp_concept.attributes.label;

        this.loading = false
      })
  }

  async searchFeedbacks(name: string, email: string, telephone: string) {
    this.loading = true
    await Feedback.search(name, email, telephone).then((response: any) => {
      this.feedbacks = response.data.data;
      console.log(this.feedbacks); 
      this.loading = false
    });

  }





}

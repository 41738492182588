
















import {translations} from '@/mixins'
import {
ValidationProvider,
} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";
@Component({
components: {
},
mixins: [translations],
})
export default class BannerSetModal extends Vue {
$notify: any;

@Prop() open!: Boolean
@Prop() flag_sync!: Boolean
@Prop() wizard!: Boolean
@Prop() doneSetup!: Boolean


get isOpen(): Boolean {
return this.open;
}
set isOpen(newVal: Boolean) {
if (!newVal) {
}
}

constructor() {
super();
}


closeModalSetup(e: any) {
   setTimeout(() => {
        this.$emit("modal:close", e);
    }, 2000);
}


}





































































import {Settings} from '@/services/SOLO'
import {Component, Vue, Prop} from "vue-property-decorator"

interface modifierGroupName {
  [x: string]: string
}

interface modifierGroupIcon {
  [x: string]: any
}

interface modifierGroupIconName {
  [x: string]: string
}

interface modifierGroup {
  name: modifierGroupName
  icon: modifierGroupIcon
}

@Component

export default class EditAllergenItem extends Vue {
  @Prop() allergenId: Number | any
  open: boolean = false
  isFileActive: boolean = false
  name: modifierGroupName = {}
  icon: modifierGroupIcon = {}
  iconName: modifierGroupIconName = {}
  arrayLang: Array<string> = ["ar", "en"];
  $notify: any
  $refs!: {
    fileIcon: any
    form: any
    formValidator: any
    inputName: any
  }
  modifierGroup: modifierGroup = {
    name: {},
    icon: {}
  }

  constructor() {
    super()
    for (let i in this.arrayLang) {
      this.name[this.arrayLang[i]] = ''
      this.iconName[this.arrayLang[i]] = ''
    }
  }

  openModal() {
    this.open = true;
    this.loadLang(this.arrayLang)
  }
  loadLang(language: string[]) {
    for (let lang in language ) {
      this.editAllergen(this.allergenId, this.arrayLang[lang])
    }
  }

  editAllergen(id: Number, lang: string) {
    Settings.getAllergen(id, lang).then((response: any) => {
      this.iconName[lang] = response.data.data.attributes.icon
      this.name[lang] = response.data.data.attributes.name
      }).catch((err: any) => {})
  }

  // delete single record and its index
  async deleteAllergenItem() {
    let id = this.allergenId
    await Settings.deleteAllergenRecord(id)
    .then((response: any) => {
      this.$emit('deleteAllergen', id);
    })
  }

  filesUpload(event: any, lang: string) {
    this.iconName[lang] = event.target.files[0].name
    this.icon[lang] = event.target.files[0]
    this.isFileActive = true
  }

  get modifierGroupData(): modifierGroup {
    this.modifierGroup.icon = this.iconName
    this.modifierGroup.name = this.name
    return this.modifierGroup
  }

  isFormData(name: any, icon: any) {
    const allergenFormData = new FormData()

    allergenFormData.append('_method', 'PATCH')

    Object.entries(icon).map((value: any | Blob) => {
      allergenFormData.append(`icon[${value[0]}]`, value[1])
    })

    Object.entries(name).map((value: any) => {
      allergenFormData.append(`name[${value[0]}]`, value[1])
    })

    return allergenFormData
  }

  async update(e: any) {

    const payload = this.isFileActive ? this.isFormData(this.name, this.icon) : this.modifierGroupData;

    await Settings.updateAllergen(this.allergenId, payload).then((response: any) => {
      this.$emit('updateItemRecord', response.data.data)
      this.open = false

    }).catch((err: any) => {
      this.open = false

    })
  }

  closeMe() {
    this.open = false
  }

  resetForm() {
    this.iconName = {}
    this.name = {}
    this.icon = {}
    return true
  }


}

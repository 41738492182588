























































































































































































































































import VSwatches from 'vue-swatches'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Applications} from '@/services/SOLO'
import {translations} from '@/mixins'
import { Chrome } from 'vue-color';
import {mapGetters} from "vuex";


interface AppTheme {
  primary_color?: any
  primary_text_color?: any
  secondary_color?: any
  secondary_text_color?: any
  button1_color?: any
  button1_text_color?: any
  button2_color?: any
  button2_text_color?: any
  background_color?: any
}

interface HomeSCreen {
  home_screen_design: any
}

@Component({
  components: {
    VSwatches,
    colorPicker: Chrome
  },
  mixins: [translations],
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
})
export default class Theme extends Vue {
  public getLocale!: Function;
  @Prop() theme!: any
  saving: boolean = false
  $notify: any
  selecta: any = ''
  form: AppTheme = {}
  translate!: Function
  type: any = ''
  home_screen_option: any = [
    {key: 'Default', value: 'default'},
    {key: 'Option 1', value: 'option-1'},
    {key: 'Option 2', value: 'option-2'},
    {key: 'Option 3', value: 'option-3'},
    {key: 'Option 4', value: 'option-4'}

  ]
  s_prim_color = ''
  s_sec_color = ''
  f_button_color1 = ''
  f_button_color2 = ''
  p_text_color = ''
  s_text_color = ''
  b1_text_color = ''
  b2_text_color = ''
  k_back_color = ''
  primary_color_show: boolean = false
  secondary_color_show: boolean = false
  button1_color_show: boolean = false
  button2_color_show: boolean = false
  primary_text_color_show: boolean = false
  secondary_text_color_show: boolean = false
  button1_text_color_show: boolean = false
  button2_text_color_show: boolean = false
  kiosk_background_color_show: boolean = false
  formHomeSCreen: HomeSCreen = {
    home_screen_design: '',
  }

  @Watch('theme') onThemeChanged(val: any) {

    let data = val.attributes
    this.processApplicantDetails(data)

    this.formHomeSCreen.home_screen_design = this.formHomeSCreen.home_screen_design ?? 'default'
    this.selecta = this.formHomeSCreen.home_screen_design ?? 'default'
    this.type = val.attributes['application-type']
    this.form = val.attributes.theme ? val.attributes.theme : {}
    this.f_button_color1 = this.form.button1_color ? this.form.button1_color : '#ffffff'
    this.f_button_color2 = this.form.button2_color ? this.form.button2_color : '#ffffff'
    this.p_text_color = this.form.primary_text_color ? this.form.primary_text_color: '#ffffff'
    this.s_text_color = this.form.secondary_text_color ? this.form.secondary_text_color: '#ffffff'
    this.b1_text_color = this.form.button1_text_color ? this.form.button1_text_color: '#ffffff'
    this.b2_text_color = this.form.button2_text_color ?  this.form.button2_text_color: '#ffffff'
    this.k_back_color = this.form.background_color ?  this.form.background_color: '#ffffff'

    if (Object.keys(this.form).length === 0) {
      this.s_prim_color = this.form.primary_color ? this.form.primary_color: '#ffffff'
      this.s_sec_color = this.form.secondary_color ? this.form.secondary_color: '#ffffff'
    }
    if (this.type === 'social-ordering') {
      this.s_prim_color = this.form.primary_color ? this.form.primary_color: '#ffffff'
      this.s_sec_color = this.form.secondary_color ? this.form.secondary_color: '#ffffff'
    } else {
      this.s_prim_color = this.form.primary_color ? this.form.primary_color: '#ffffff'
      this.s_sec_color = this.form.secondary_color ? this.form.secondary_color: '#ffffff'
    }
  }

  processApplicantDetails(data: any) {
    this.formHomeSCreen.home_screen_design = data['home_screen_design']
  }


    selectedThme(v: any) {
      this.selecta = v;
      this.formHomeSCreen.home_screen_design = v;
      console.log("selected app", this.selecta, this.formHomeSCreen.home_screen_design);

    }

    setColor(color: any) {
        this.s_prim_color = color.hex
    }

    setColor1(color: any) {
        this.s_sec_color = color.hex
    }
    setColor2(color: any) {
        this.f_button_color1 = color.hex
    }
    setColor3(color: any) {
        this.f_button_color2 = color.hex
    }
    setColor4(color: any) {
        this.p_text_color = color.hex
    }
    setColor5(color: any) {
        this.s_text_color = color.hex
    }
    setColor6(color: any) {
        this.b1_text_color = color.hex
    }
    setColor7(color: any) {
        this.b2_text_color = color.hex
    }
    setColor8(color: any) {
        this.k_back_color = color.hex
    }

    toggle() {
      this.primary_color_show = !this.primary_color_show;
    }
    toggle1() {
      this.secondary_color_show = !this.secondary_color_show;
    }
    toggle2() {
      this.button1_color_show = !this.button1_color_show;
    }
    toggle3() {
      this.button2_color_show = !this.button2_color_show;
    }
    toggle4() {
      this.primary_text_color_show = !this.primary_text_color_show;
    }
    toggle5() {
      this.secondary_text_color_show = !this.secondary_text_color_show;
    }
    toggle6() {
      this.button1_text_color_show = !this.button1_text_color_show;
    }
    toggle7() {
      this.button2_text_color_show = !this.button2_text_color_show;
    }
    toggle8() {
      this.kiosk_background_color_show = !this.kiosk_background_color_show;
    }

    
  updateHomeScreen() {
    this.saving = true
    Applications.updateApplicationPutMethod(Number(this.$route.params.id), this.formHomeSCreen)
        .then((response: any) => {
          this.saving = false
        })
  }

  updateTheme() {
    this.saving = true
    this.updateHomeScreen();
    this.form.primary_color = this.s_prim_color;
    this.form.secondary_color = this.s_sec_color;
    this.form.button1_color = this.f_button_color1;
    this.form.button1_text_color = this.b1_text_color;
    this.form.button2_color = this.f_button_color2;
    this.form.button2_text_color = this.b2_text_color;
    this.form.primary_text_color = this.p_text_color;
    this.form.secondary_text_color = this.s_text_color;
    this.form.background_color = this.k_back_color;
    console.log('this.form: ', this.form);
    Applications.updateApplicationTheme(Number(this.$route.params.id), this.form)
        .then((response: any) => {
          this.$notify({
            title: "THEMES UPDATED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Record has been updated successfully",
            type: "success",
            icon: "fas fa-check"
          })
          this.saving = false
          // console.log(response.data.data.attributes.theme)
        }).catch((err: any) => {
      let errMsg: any = []
      for (let error in err.response.data.errors) {
        errMsg = err.response.data.errors[error]
      }
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: errMsg[0],
        type: "danger",
        icon: "fas fa-bomb",
      });
      this.saving = false
    })
  }

}



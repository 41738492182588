


































































import { Table, TableColumn, Button } from "element-ui"
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import {AuditLog as AuditLogApi} from "@/services/SOLO"
import moment from "moment"
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("D/MM/YYYY hh:mm:ss");
    }      
  },
  mixins: [translations],
})
export default class CategoryAuditLogsApp extends Vue {
  public translate!: Function
  @Prop() active!: any
  @Prop() id!: any
  list: any = []
  loading: boolean = false
  pageLoaded: boolean = false
  color: string = "white"
  isLoaded: boolean = false
  total: number = 0
  currentPage: number = 1
  perPage: number = 10


  @Watch("id", {deep: true, immediate: true})
  onChangeAppId(data: any) {
      if(data) {
         this.viewAudit();
      }
  }

  async viewAudit() {
    if (! this.pageLoaded) {
      this.loading = true
      await AuditLogApi.all('applications', parseInt(this.id), this.currentPage, this.perPage)
        .then((response: any) => {
          this.total = response.data.meta.pagination.total
          // this.currentPage = response.data.meta.pagination.current_page
          this.perPage = response.data.meta.pagination.per_page
          this.isLoaded = true

          this.list = response.data.data
          console.log("app logs", this.list);
          this.loading = false
          this.pageLoaded = true
        })
    }
  }

  pageChange(data: any) {
    this.currentPage = data;
    this.pageLoaded = false
    this.viewAudit();
  }

  mounted() {
  }
}

import BaseService from '../base.service'

let locationFacilitiesService = class LocationFacilitiesService extends BaseService {
    url!: string
    constructor() {
        super()
    }

    all(locationId: string) {
        let url = this.baseURL + `locations/${locationId}/facilities`
        return super.get(url)
    }

    store(locationId: string, facilityId: string) {
        let url = this.baseURL + `locations/${locationId}/facilities`
        return super.post(url, {
          'facility-id': facilityId
        })
    }

    destroy(locationId: string, facilityId: string) {
        let url = this.baseURL + `locations/${locationId}/facilities/${facilityId}`
        return super.remove(url, {})
    }
};

export default locationFacilitiesService

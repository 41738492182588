

















// npm package
import { Component, Vue } from "vue-property-decorator";

// our package
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';

@Component({
  components: {
    RouteBreadCrumb,
  },
})
export default class Messaging extends Vue {
  public btnLinks: any = [
    {
      btnName: 'General',
      btnRouteName: 'reports_general',
      btnPath: '/reports/general',
    },
    {
      btnName: 'Orders',
      btnRouteName: 'reports_orders',
      btnPath: '/reports/orders',
    },
    {
      btnName: 'Locations',
      btnRouteName: 'reports_locations',
      btnPath: '/reports/locations',
    },
    {
      btnName: 'Customers',
      btnRouteName: 'reports_customers',
      btnPath: '/reports/customers',
    },
    {
      btnName: 'Products',
      btnRouteName: 'reports_products',
      btnPath: '/reports/products',
    },
  ];

  redirectTo(name: string) {
    this.$router.push({ name });
  }
}

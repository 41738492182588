import BaseService from '../base.service'
export default class PaymentService extends BaseService {

  constructor() {
    super();
  }

  updatePaymentMethod(payload: {}, orderId: string | number) {

    let url = this.baseURL + `orders/${orderId}`
    
    return super.patch(url, payload)
  } 
}

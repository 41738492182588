










































import { Component, Mixins, Watch } from "vue-property-decorator";
import Translations from "@/mixins/translations";
import {Loyalty as LoyaltyApi} from '@/services/SOLO';

@Component
export default class SoloLoyalty extends Mixins(Translations) {
  showCreateModal: boolean = false;
  $notify: any;
  name: string = '';
  mobile: string = '';
  type: string = '';
  from: string = '';
  to: string = '';
  order: string = '';
  searched: Boolean = false;

  onCreate() {
    this.showCreateModal = true;
  }

  onUpdateModalActivator(val: boolean) {
    this.showCreateModal = val;
  }

  updateModal(value: boolean) {
    this.showCreateModal = value;
  }

  updateExportParams(name: string, mobile: string, type: string, from: string, to: string, order: string, searched: Boolean) {
    this.name = name;
    this.mobile = mobile;
    this.type = type;
    this.from = from;
    this.to = to;
    this.order = order;
    this.searched = searched;
  }
  created() {
    this.$root.$refs.SoloLoyalty = this;
  }

  exportAllLoyalty(name: string, mobile: string, type: string, from: string, to: string, order: string, searched: Boolean) {
    this.$notify({
      title: "PLEASE WAIT!",
      horizontalAlign: "right",
      message: 'Please wait, the file will be downloaded in few seconds.',
      type: "success",
      icon: "fas fa-spinner fa-spin",
    })
    LoyaltyApi.exportAllLoyalty(this.name, this.mobile, this.type, this.from, this.to, this.order, this.searched)
      .then((response: any) => {
        window.open(response.data.data.attributes['csv-uri'], '_blank');
      })
      .catch((err: any) => {
        const message = JSON.parse(err?.response?.data?.messages)?.errors?.status[0]
          || JSON.parse(err?.response?.data?.messages)?.message
          || 'Something went wrong, please try again!';
        // console.log('err:' , JSON.parse(err?.response?.data?.messages));
        // this.description = this.orderStatusCode;
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message,
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}

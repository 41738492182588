


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import * as Ably from 'ably';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Select, Button, Option} from 'element-ui'
import DriversModal from './components/DriversModal.vue'
import ExportAllOrderNoti from './components/exportOrderAllNoti.vue'

import LogisticsModal from './components/LogisticsModal.vue'
import OrderStatus from './components/OrderStatus.vue'
import buttonAssignLogistics from './components/buttonAssignLogistics.vue'
import buttonAssignDriver from './components/buttonAssignDriver.vue'
import { Order as OrderApi, Integrations, Order, Location as LocationApi, Employee } from "@/services/SOLO";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {ValidationObserver, configure} from 'vee-validate'
import {Component, Prop, Vue, Watch, Emit, Ref} from "vue-property-decorator";
import {Driver, DriverAttributes, Location, Logistics} from '@/models'
import moment from "moment";
import "moment/locale/pt-br";
import {eventHandler} from '@/mixins'
import {mapGetters} from 'vuex';
import sound from '../../../../../public/sound/default_notif.wav'
import {BButton} from 'bootstrap-vue';
import {translations} from '@/mixins'
import Translations from './components/Translations.vue'
import { truncate } from '@/util/truncate';

interface Filter {
  order: string,
  from: string,
  to: string,
  customer: string,
  email: string,
  mobile: string,
  location: any,
  status: string,
  statusGroup?: string,
  today: Boolean,
  type: string,
  'exclude-statuses'?: string,
}

@Component({
  components: {
    RouteBreadCrumb,
    buttonAssignDriver,
    DriversModal,
    ExportAllOrderNoti,
    buttonAssignLogistics,
    LogisticsModal,
    OrderStatus,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    flatPicker
  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      userRole: 'account/getUserRole',
      user: 'account/getUser',
    })
  },
  filters: {
    datetime(value: any, format: string = "YYYY-MM-DD HH:mm") {
      return moment.utc(value).locale("en-us").fromNow();
    },
    circleColor(finishedTime: any, promisedTime: any) {
      let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
      let end = moment.utc(promisedTime);
      let diff = moment.duration(start.diff(end)).asMinutes();
      if (diff > 2) {
        console.log('text-danger');
        return 'text-danger'
      } else if (diff > 0) {
        console.log('text-warning');
        return 'text-warning';
      } else {
        console.log('text-success');
        return 'text-success';
      }
    },
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY HH:mm");
    }
  },
  mixins: [eventHandler, translations]
})
export default class Orders extends Vue {
  activeConcept: any
  userRole: any
  user: any
  canPlayNotificationSound: Boolean = false;
  driverOpen: Boolean = false
  exportOrderNotiOpen: Boolean = false
  logisticsOpen: Boolean = false
  page: number = 1;
  size: number = 20;
  source: string = "Source"
  posTooltip: string = ""
  paymentMethod: string = "Payment"
  type: string = "Type"
  orders: any = [];
  driverData: Object = {}
  logisticData: Object = {}
  logistics: Array<Logistics> = []
  statuses: Array<Object> = []
  storeLocations: Array<Object> = []
  isLoading: Boolean = false
  visible: Boolean = false
  filter: Filter = {
    order: '',
    from: '',
    to: '',
    customer: '',
    email: '',
    mobile: '',
    location: [],
    status: '',
    statusGroup: 'all',
    today: true,
    type: ''
  }
  flag: Boolean = true;
  rows: number = 0
  perPage: number = 50
  currentPage: number = 1

  confirm!: Function
  broadcasted!: Function
  $notify: any
  notif!: Function
  public translate!: Function
  $refs!: {
    sound: HTMLAudioElement,
    playNewOrderAudio: any
  }
  isVisible: boolean = false;
  locationIds: string = '';
  employeeLocations: any = [];
  isPlay: boolean = false;
  isMobile: boolean = false;
  webNotif: any;
  ablyUnsubscribe: any;
  trunc: Function = truncate;

  @Watch("canPlayNotificationSound", { immediate: true, deep: true })
  onCanPlaySoundChanged(val: any) {
    if (val) {
      if (!this.isPlay) {
        let audio = document.getElementsByTagName("audio")[0];
        audio.play().then(() => {
        }).catch(() => {
        });
        this.isPlay = true;
      }
    }
  }

  private get isMobileDevice(): Boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  mounted() {
    if (!('Notification' in window)) {
      console.error('This browser does not support desktop notification');
    } else if (Notification.permission === 'default' || Notification.permission === 'denied') {
      Notification.requestPermission();
    }

    window.addEventListener('resize', this.screensizeHandler);

    if (this.isMobileDevice) {
      this.isMobile = true;
      this.confirm(
        this.$bvModal,
        'Do you want to play a sound when an order is received?',
        {
          title: 'Attention!',
          okTitle: 'YES',
          cancelTitle: 'NO',
        },
      ).then(async (value: boolean) => (this.canPlayNotificationSound = value));
    }

    this.getStoreLocations()
      .then(() => {
        this.getLogisticPartners();
        this.pushedNotification();
        return this.getOrderStatuses();
      })
      .then(() => (this.getOrders()));
  }

  destroyed() {
    if (this.webNotif) {
      this.webNotif.close();
      this.webNotif = null;
    }
    if (this.ablyUnsubscribe) {
      this.ablyUnsubscribe.unsubscribe();
      this.ablyUnsubscribe = null;
    }
    window.removeEventListener('resize', this.screensizeHandler);
  }

  screensizeHandler() {
    if (this.isMobileDevice) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.$forceUpdate();
  }

  disabled(check: any) {
    if(check === true) {
      return 'pointer-events: none';
    }
    return 'pointer-events: auto';
  }

  getEmployeeLocations() {
    if (!this.employeeLocations.length) {
      return Employee.find(this.user.id)
        .then((response) => {
          if (response.data.included) {
            this.employeeLocations = response.data.included;
          }
        });
    }
    return Promise.resolve();
  }

  getFirstName(customerName: string) {
    return customerName ? customerName.split(' ')[0]: 'N/A';
  }

  closeOrder() {
    this.flag = true;
    this.visible = true;
  }

  openOrder() {
    this.flag = true;
  }

  circleTooltip(finishedTime: any, promisedTime: any) {
    let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
    let end = moment.utc(promisedTime);
    let diff = moment.duration(start.diff(end)).asMinutes();
    if (diff > 2) {
      return 'Past the promised time'
    } else if (diff > 0) {
      return 'Slightly past the promised time';
    } else {
      if (!finishedTime) {
        return 'Not finished';
      } else {
        return 'Finished on time';
      }
    }
  }

  posColor(code: number, posResponse: any) {
    if (code && posResponse) {
      this.posTooltip = "Order successfully sent to POS";
      return 'success'
    } else if (!code && posResponse) {
      this.posTooltip = "Order failed in POS";
      return 'danger'
    } else {
      this.posTooltip = "";
      return 'secondary'
    }
  }

  tooltip(code: number, posResponse: any) {
    if (code && posResponse) {
      return 'Order successfully sent to POS'
    } else if (!code && posResponse) {
      return 'Order failed in POS'
    } else {
      return ''
    }
  }

  paginate(page: number) {
    this.page = page
    this.getOrders()
  }

  resetForm(reset: any) {
    this.filter.order = ''
    this.filter.from = ''
    this.filter.to = ''
    this.filter.customer = ''
    this.filter.email = ''
    this.filter.mobile = ''
    this.filter.location = []
    this.filter.status = ''
    this.filter.today = true
    this.filter.type = ''
  }

  pushedNotification() {
    this.ablyUnsubscribe = this.broadcasted(this.activeConcept.id);
    this.ablyUnsubscribe.subscribe((payload: any) => {
      console.log('broadcasting orders: ', payload);
      switch (payload.name) {
        case 'Solo\\Order':
          const exclude = 'initiated,waiting-for-payment';
          const paymentMethod = 'cash,card-on-pickup,card-on-delivery';
          if (!exclude.includes(payload?.data?.order?.status) || paymentMethod.includes(payload?.data?.order?.['payment-method'])) {
            if (payload?.data?.order?.['payment-method'] !== 'cash') {
              if (payload?.data?.order?.['payment-method'] === 'card-on-pickup' || payload?.data?.order?.['payment-method'] === 'card-on-delivery') {
                this.findOrder(payload.data.order.id);
              } else {
                Order.orderPayment(payload?.data?.order?.id).then((response: any) => {
                  const payment = response?.data?.data[0];
                  if (payment?.attributes?.['payment-reference-number']) {
                    this.findOrder(payload.data.order.id);
                  }
                });
              }
            } else {
              this.findOrder(payload.data.order.id);
            }
          }
          break;
        case 'Solo\\Order\\Status':
          this.findOrder(payload.data.order.id);
          break;
      }
    });
  }

  DriverModalOpenState(open: Boolean = true) {
    this.driverOpen = open
  }

  ExportOrderAllNotiModalOpenState(open: Boolean = true) {
    this.exportOrderNotiOpen = open
  }

  LogisticsModalOpenState(open: Boolean = true) {
    this.logisticsOpen = open
  }

  showDriver(data: Object) {
    this.driverData = data
    this.DriverModalOpenState(true)
  }

  showLogistics(data: Object) {
    this.logisticData = data
    this.LogisticsModalOpenState(true)
  }

  getOrders() {
    const filter = { ...this.filter };
    let tmpFilterLocation: any = [];
    this.orders = []
    this.isLoading = true

    if (!filter.location.length && this.userRole === 'supervisor') {
      filter.location = this.storeLocations.map((s: any) => s.id).join();
    } else {
      tmpFilterLocation = [...filter.location];
      filter.location = filter.location.join();
    }

    const exclude = 'initiated,waiting-for-payment';
    if (filter.statusGroup === 'unpaid') {
      const statuses = this.statuses
        .filter((status: any) => !exclude.includes(status.attributes.code))
        .map((status: any) => status.attributes.code)
        .join();
      delete filter.statusGroup;
      filter['exclude-statuses'] = statuses;
    } else if (filter.statusGroup === 'paid') {
      const statuses = this.statuses
        .filter((status: any) => exclude.includes(status.attributes.code))
        .map((status: any) => status.attributes.code)
        .join();
      delete filter.statusGroup;
      filter['exclude-statuses'] = statuses;
    } else if (filter.statusGroup === 'all') {
      delete filter['exclude-statuses'];
      delete filter.statusGroup;
    }
    OrderApi.all(this.page, this.size, filter).then((response) => {
      this.filter.location = [...tmpFilterLocation];
      this.isLoading = false
      this.orders = response.data.data;
      this.rows = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.currentPage = response.data.meta.pagination.current_page
      this.getOrderStatuses()
    });
    this.isVisible = false
  }

  getStoreLocations() {
    return LocationApi.fetchLocations()
      .then((response: any) => (this.storeLocations = response.data.data));
  }

  playNotificationSound() {
    let audio = document.getElementsByTagName("audio")[0];
    if (this.canPlayNotificationSound) {
      audio.play().then(() => {
      }).catch(() => {
      });
    }
  }

  async findOrder(orderId: string) {
    await OrderApi.find(orderId)
      .then((response: any) => (this.updateOrders(response.data.data)));
  }

  async refreshStatus(data: any) {
    await OrderApi.getOrderStatusSync(data)
      .then((response: any) => {
        let item = response.data.data
        console.log(item)
        this.orders.some((v: any, i: any) => {
          if (item.id === v.id) {
            this.orders[i].attributes['current-status'].description = item.attributes.code
            return true
          }
        })
      })
  }

  notify(title: string, message: string, order: any) {
    if (!this.isMobile) {
      this.webNotif = new Notification(title, {
        body: message,
      });
      this.webNotif.onclick = () => {
        this.webNotif = null;
        this.$router.push({ name: 'order-details', params: { id: order.id } });
      };
    } else {
      let config: any = { body: message };
      navigator.serviceWorker.register('/sw.js');
      navigator.serviceWorker.ready.then(function (registration) {
        config.data = order.id;
        registration.showNotification(title, config);
      });
    }
  }

  showNotification(title: string, message: string, order: any) {
    if (('Notification' in window)) {
      if (Notification.permission === 'granted') {
        this.notify(title, message, order);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            this.notify(title, message, order);
          }
        });
      }
    }
  }

  updateOrder(data: any) {
    const { id, attributes } = data;
    const sr = this.translate('SAR');
    const old = !!this.orders.find((ord: any) => Number(ord.id) === Number(id));
    const title = 'New order';
    const message = `New order #${id} for ${sr} ${attributes.total} at ${attributes['location-name']}.`;
    const findOrderIdx = this.orders.findIndex((ord: any) => Number(ord.id) === Number(id));

    console.log('old: ', old);
    // Play sound and notification
    if (!old) {
      this.playNotificationSound();
      this.showNotification(title, message, data);
    }

    if (findOrderIdx > -1) {
      this.orders[findOrderIdx] = data;
      this.$forceUpdate();
      // this.orders.splice(findOrderIdx, 1);
    } else {
      this.orders.unshift(data);
    }
  }

  updateOrders(data: any) {
    const isAdmin = this.userRole.includes('administrator');
    // GET employee locations
    // if already exist do not call API for performance
    this.getEmployeeLocations()
      .then(() => {
        const found = this.employeeLocations
          .find((loc: any) => Number(loc.id) === Number(data.attributes['location-id']));
        if (isAdmin || found) {
          this.updateOrder(data);
        }
      });
  }

  updateOrderIndex(data: any) {
    this.orders[data.index] = data.data;
  }

  iconSrc(src: string) {
    return `/img/order icons/${src.toLowerCase()}.png`;
  }

  getLogisticPartners() {
    Integrations.logisticsPartners('food-logistics')
      .then(response => (this.logistics = response.data.data));
  }

  getOrderStatuses() {
    return OrderApi.statusList()
      .then((response: any) => (this.statuses = response.data.data));
  }

  exportOrder(orderId: string) {
    if (true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be downloaded in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportOrder(orderId)
        .then(response => {
          window.open(response.data.data.attributes['csv-uri'], '_blank');
        })
    }
  }

  exportAllOrder() {
    const filter = { ...this.filter };
    delete filter.statusGroup;

    if (true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be exported in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportAllOrder(filter)
        .then(response => {
          // window.open(response.data.data.attributes['csv-uri'], '_blank');
          this.ExportOrderAllNotiModalOpenState();
        })
    }
  }

  NewExportOrderAllNoti(open: any) {
    this.exportOrderNotiOpen = open
  }

  closeExportOrderAllNotiSet(close: any) {
    this.exportOrderNotiOpen = close
  }

  filterActiveDrivers(drivers: any) {
    // console.log('filterActiveDrivers: ', drivers);
    // console.log('filterActiveDrivers - filtered: ', drivers && drivers.filter((driver: any) => (driver.attributes.status === 'active')));
    return drivers && drivers.filter((driver: any) => (driver.attributes.status === 'active'));
  }

  onStatusGroupClick(type: string) {
    this.filter.statusGroup = type;
    this.getOrders();
  }
}

import BaseService from '../base.service'
import store from '@/store'

let DashboarService = class dashboarService extends BaseService {
  url!: string
  constructor(){
    super()
  }

  dailySummary() {
    this.url = this.baseURL + 'reports/daily-summary'
    return super.get(this.url)
  }

  deliveryAreas() {
    this.url = this.baseURL + 'reports/delivery-areas'
    return super.get(this.url)
  }

  dailySales() {
    this.url = this.baseURL + `reports/daily-sales?days=30`
    return super.get(this.url)
  }

  latestOrders() {
    this.url = this.baseURL + `orders?page=1&size=10`
    return super.get(this.url)
  }

  getRegistrationBySource() {
    this.url = this.baseURL + `reports/registrations-by-source`;
    return super.get(this.url);
  }

  getOrderByStatuses(type: string) {
    this.url = this.baseURL + `reports/orders-by-statuses?type=${type}`;
    return super.get(this.url);
  }

  getTopPerformingLocations(payload: any) {
    this.url = this.baseURL + `reports/top-performing-locations`;
    return super.get(this.url, payload);
  }

  getTopPerformingProducts(payload: any) {
    this.url = this.baseURL + `reports/top-performing-products`;
    return super.get(this.url, payload);
  }
}

export default DashboarService







import { Order as OrderApi, Integrations, Location } from "@/services/SOLO";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/pt-br";
@Component
export default class buttonAssignDriver extends Vue {
  @Prop() index!: number
  @Prop() logistic!: Array<Object>
  @Prop() deliveryLogistic!: string
  @Prop() orderId!: string

  initAssign() {
    let data = {
      index: this.index,
      'logistic': this.deliveryLogistic,
      'orderId': this.orderId
    }
    this.$emit('show:logistics', data)
  }
}


































import { Vue, Component } from "vue-property-decorator";
import { Table, TableColumn, Button } from "element-ui";
import { mapGetters } from "vuex"

import { translations } from "@/mixins";
import {
  WhatsApp as WhatsAppService
} from "@/services/SOLO";

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  mixins: [translations],
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getLocale: 'app/getLocale',
    }),
  },
})

export default class ManageMessages extends Vue {
  getConceptSettings: any;
  whatsAppMessages: Array<any> = [];
  getLocale: any;

  created() {
    this.getWhatsAppMessages();
  }

  openAction = (index: number, action: string, data: any) => {
    this.$emit('toggleCustomizeMessage', { index, action, data });
  }

  get locale() {
    if (this.getLocale === 'en_US') {
      return 'en-us';
    }
    return 'ar-sa';
  }

  get tableData() {
    return this.whatsAppMessages?.map((msg) => { 
      return {
        message: msg.attributes.title[this.locale] 
      }
    })
  }

  async getWhatsAppMessages() {
    try {
      const { id } = this.getConceptSettings;
      const { data } = await WhatsAppService.getMessages(id);
      if (data?.data) {
        this.whatsAppMessages = data.data;
        let orderStatusMsg = {
          'is-order-status': true,
          attributes: {
            title: {
              'ar-sa': 'رسالة تحديث حالة الطلب',
              'en-us': 'Order Status Update Message'
            },
            body: {
              'ar-sa': '',
              'en-us': ''
            },
            'default-id': null,
            'display-order': 999,
            'button-model': null,
            'buttons': [],
            'is-active-editable': false,
            'is-active': false,
            'default-buttons': []
          }
        }
        this.whatsAppMessages.push(orderStatusMsg);
      }
			
    } catch (err) { 
      if (err instanceof Error) {
        console.log(err.message);
      }

      console.log('Unexpected error', err);
    }
  }
}

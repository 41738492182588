





















import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import store from '@/store'

@Component({
  components: { Modal }
})
export default class WelcomeModal extends Vue {
  notify: boolean = false
  success: boolean | null = null
  closeModal() {
    this.notify = false
    if(this.success) {
      // document.body.classList.remove('bg-solo')
      this.$router.push({name: 'dashboard'})
    }
  }

  getResellerName() {
    return store?.getters?.['account/getUserClient']?.attributes?.label;
  }
}



































































































































































































































import { translations } from "@/mixins";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { mapGetters, mapMutations } from 'vuex'

import {
  WhatsApp as WhatsAppService
} from "@/services/SOLO";


import {
  Form,
  Alert,
  Input,
  Row,
  Col,
  InputNumber,
  FormItem,
  Select,
  Option,
  Collapse,
  CollapseItem,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
  Table,
  TableColumn,
  Dialog
} from "element-ui";

import Step1 from "./components/Step1.vue";
import Step2 from "./components/Step2.vue";
import Step3 from "./components/Step3.vue";

@Component({
  components: {
    Step1,
    Step2,
    Step3,
    [Col.name]: Col,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getLocale: 'app/getLocale'
    }),
  },
  methods: {
    ...mapMutations({
      setIsWhatsAppOnBoarded: 'page/setIsWhatsAppOnBoarded',
    })
  },
  mixins: [translations]
})
export default class OnBoarding extends Vue {
  translate!: Function;
  /** Vuex Getters */
  getConceptSettings!: any
  getLocale: any

  whatsAppAccountDetails!: any 
  loaded: boolean = false

  /** Setter for Account On Boarded */
  setIsWhatsAppOnBoarded! : Function
  
  /** Conditional Check for every step */
  accountIsVerified: boolean = false
  isStep2Done: boolean = false
  isSubmittedRequestForActivation: boolean = false

  /** Custom Messages For Static Whats App Simulation */
  simulationMessages: Array<any> = []
  messageNo: number = 1;

  created() {
    this.checkAccountIfVerified()
  }

  get accountDetails() {
    return this.whatsAppAccountDetails
  }

  set accountDetails (data: any) {
    this.whatsAppAccountDetails = data
  }

  get getSimulationMessages() {
    return this.simulationMessages
  }

  set getSimulationMessages (data: any) {
    this.simulationMessages = data
  }

  async checkAccountIfVerified() {
    try {
      const { id } = this.getConceptSettings
      const { data } = await WhatsAppService.checkAccountIfVerified(id)
      /** Check Account here */
      if (data.data[0] !== undefined) {
        this.accountDetails = data.data[0]
        this.accountIsVerified = data.data[0].attributes['is-verified']
      }
      this.loaded = true
    } catch (error) {
      if (error.response) {
      // Error with response from the server
        const { error: err } = error.response.data
        console.log("GET THE ERROR", err);
      }
    }
  }

  submittedRequestForAccountActivation(isSubmitted: boolean) {
    /** if user submitted account request, it means the user has an account already regardles if verified or not */
    this.isSubmittedRequestForActivation = isSubmitted
  }

  checkStep2IfDone(val: boolean) {
    // true if done, false if not
    this.isStep2Done = val
  }

  async updateWhatsAppAccount() {
    try {
      this.loaded = false
      const { id: conceptId } = this.getConceptSettings
      const payload = { 'is-onboarded': true }
      const { id: vonageId } = this.accountDetails
      const res = await WhatsAppService.updateWhatsAppAccount(conceptId, vonageId ,payload)
      /** Redirect to dashboard after api call success */
      if (res.data && res.data.data.attributes['is-onboarded']) {
        this.setIsWhatsAppOnBoarded(true)
        this.$emit('to-dashboard', true)
        this.loaded = true
      }
    } catch (error) {
      if (error.response) {
        // Error with response from the server
        const { error: err } = error.response.data
        console.log("GET THE ERROR", err);
      }
    }
  }

  onChangeMessages(messages: any) {
    this.getSimulationMessages = messages.map((message: any) => {
      if (message.attributes['display-order'] === 1) {
        return {
          ...message,
          attributes: {
            ...message['attributes'],
            incomingMsg: 'Hello.'
          }
        }
      }

      if (message.attributes['display-order'] === 2) {
        return {
          ...message,
          attributes: {
            ...message['attributes'],
            incomingMsg: 'Dine In.'
          }
        }
      }

      if (message.attributes['display-order'] === 3) {
        return {
          ...message,
          attributes: {
            ...message['attributes'],
            incomingMsg: 'Branch 1.'
          }
        }
      }

      if (message.attributes['display-order'] === 5) {
        return {
          ...message,
          attributes: {
            ...message['attributes'],
            incomingMsg: 'Done'
          }
        }
      }

      if (message.attributes['display-order'] === 6) {
        return {
          ...message,
          attributes: {
            ...message['attributes'],
            incomingMsg: 'Sure! Thank you.'
          }
        }
      }

      if (message.attributes['display-order'] === 7) {
        return {
          ...message,
          attributes: {
            ...message['attributes'],
            incomingMsg: 'It was great and convenient.'
          }
        }
      }

      if (message.type === 'whatsapp-messages-order-status') {
        const received = [{ 'en-us' : 'The restaurant has received your order.', 'ar-sa' : 'The restaurant has received your order.' }]
        const prepared = [{ 'en-us' : 'Your order is prepared and ready for Collection.', 'ar-sa' : 'Your order is prepared and ready for Collection.' }]
        const collected = [{ 'en-us' : 'Your order has been collected', 'ar-sa' : 'Your order has been collected' }]
        const orderStatusesMsg = [...received, ...prepared, ...collected]
        return {
          ...message,
          attributes: {
            messages: orderStatusesMsg
          }
        }
      }

      return message
    })
  }

  onChangeMessageOrder(messageNo: any) {
    this.messageNo = messageNo
  }

  onChangeOrderOptions(options: any) {
    this.getSimulationMessages = this.getSimulationMessages.map((message : any) => {
      return message.attributes['button-model'] === 'order_type' ? {
          ...message,
          attributes: {
            ...message['attributes'],
            simulationOption: options.map((option : any) => option.label)
          }
        } : message
    })
  }

  onChangeBranchOptions(options: any) {
    this.getSimulationMessages = this.getSimulationMessages.map((message : any) => {
      return message.attributes['button-model'] === 'location' ? {
          ...message,
          attributes: {
            ...message['attributes'],
            simulationOption: options.map((option : any) => option.label)
          }
        } : message
    })
  }
}

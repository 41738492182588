





























































































































































































import { Component, ProvideReactive, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator"
import { eventHandler, translations } from '@/mixins'
import { mapGetters } from 'vuex'
import {Select, Option, Menu, Form} from 'element-ui'
import Translations from '../../Translations.vue'
import { Settings, Category as CategoryApi } from '@/services/SOLO'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"

interface modifierGroupName {
  [x: string]: string
}

interface modifierGroup {
  name: modifierGroupName
  'display-order': string
  label: string
  minimum: string
  maximum: string
  type: string
  code: string
  enabled: number
  'image-uri': string
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',

    }),
  },
  mixins: [translations, eventHandler]
})
export default class CreateCustomFieldModal extends Vue {
  public translate!: Function
  waiting!: Function
  restore!: Function
  $notify: any
  getLocale!: any
  getConceptSettings!: any
  private arrayLang: Array<string> = []
  name: modifierGroupName = {}
  enabled: boolean = false
  modifierGroup: modifierGroup = {
    name: {},
    'display-order': '1',
    label: '',
    minimum: '',
    maximum: '',
    type: '',
    code: '',
    enabled: 1,
    'image-uri': ''
  }
  types: Array<Object> = [
    {
      value: '',
      text: 'Select Type'
    },
    {
      value: 'options',
      text: 'options'
    },
    {
      value: 'sizes',
      text: 'sizes'
    },
    {
      value: 'fries',
      text: 'fries'
    },
    {
      value: 'drinks',
      text: 'drinks'
    }
  ]
  overrideMsg: String = ''
  private imageType: number = 0
  private imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    },
    {
      value: 2,
      text: "Stock Images",
    },
  ]
  searchStock: any = ''
  isLoading: Boolean = false
  stockImages: any = []
  $refs!: {
    file: any
  }

  @Watch('modifierGroup.type', {immediate: true, deep: true})
  isModifierGroupTypeChanged(newVal: any) {
    if (newVal == 'sizes') {
      this.overrideMsg = 'Warning: Size modifiers override the product price.';
    } else {
      this.overrideMsg = '';
    }
  }

  @Watch("imageType", {deep: true, immediate: true})
  onChangeImageType(data: any) {
    if(data === 2) {
      this.getStockImages();
    }
  }

  created() {
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    /* @ts-ignore */
    if (this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
  }

  checkSize(text: any) {
    if(this.getLocale === 'en_US') {
      if(text === 'The image field size must be less than 2000KB') {
        return 'The image file size must be less than 2MB'
      }
    }
    if(text === 'The image field size must be less than 2000KB') {
      return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }
  }

  selectStockImage(stockImage: any) {
    this.modifierGroup['image-uri'] = stockImage;
  }
  searchStockImages(e: any) {
    this.isLoading = true;
      CategoryApi.searchStockImgs(this.searchStock)
      .then((response) => {
        this.stockImages = response.data.data;
        this.isLoading = false;
        console.log("search stock images", this.stockImages);
      })
  }
  getStockImages() {
    CategoryApi.getStockImgs()
      .then((response) => {
        this.stockImages = response.data.data;
        console.log("stock images", this.stockImages);
      })
  }

  // get modifierGroupData(): modifierGroup {
  //   this.modifierGroup.name = this.name;
  //   return this.modifierGroup;
  // }

  createForm(e: any) {
    const defaultText = e.submitter.innerHTML
    this.waiting(e, `<i class="fas fa-spinner fa-spin"></i> ${this.translate('Creating...')}`)
    let form = new FormData();
    for (let i in this.arrayLang) {
      form.append(`name[${this.arrayLang[i]}]`, this.name[this.arrayLang[i]]);
    }
    form.append(`display-order`, this.modifierGroup['display-order']);
    form.append(`label`, this.modifierGroup.label);
    form.append(`code`, this.modifierGroup.code);
    form.append(`type`, this.modifierGroup.type);
    form.append(`minimum`, this.modifierGroup.minimum);
    form.append(`maximum`, this.modifierGroup.maximum);
    form.append(`enabled`, this.modifierGroup.enabled ? '1' : '0');
    if (this.imageType != 1) {
        form.append(`image-uri`, this.modifierGroup['image-uri']);
    } else {
        form.append(`image`, this.$refs.file.files[0]);
    }
    Settings.createGlobalModifierGroups(form)
      .then(async(response: any) => {
        await this.addModGroup(response.data.data.id);
        this.restore(e, defaultText);
        /* @ts-ignore */
        this.$router.push({ name: 'itemModifier', params: { itemId: this.$route.params.itemId, tab: 1 } });
      })
      .catch((err: any) => {
        console.log('err: ', err);
        this.restore(e, defaultText);
        this.$notify({
          title: this.translate('SYSTEM ERROR!'),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate('Something went wrong, please try again!'),
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }

  async addModGroup(modGroupId: any) {
    await Settings.addItemModifiers({ 'modifier-group': modGroupId }, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId)
      .then((response: any) => {
        this.$notify({
          title: this.translate("DATA SAVED"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Modifier has been added!"),
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        this.$notify({
          title: this.translate("SYSTEM ERROR!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Something went wrong, please try again!"),
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}









import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class TabHeader extends Vue {
  @Prop() title!: any;
}

import BaseService from '../base.service'
import store from '@/store'
import app from '@/store/app';
export default class PageService extends BaseService {
  url!: string
  constructor() {
    super();
  }

  removePopup(popupId: any) {
    let url = this.baseURL + `popups/${popupId}`
    return super.remove(url, {})
  }

  all() {
    let url = this.baseURL + 'popups';

    return super.get(url);
  }

  getApplications() {
    return super.get(this.baseURL + 'applications');
  }

  create(payload: {}) {
    let url = this.baseURL + 'popups';

    return super.post(url, payload);
  }

  find(popupId: Number | String, lang: string) {
    this.headers['Accept-Language'] = lang
    let url = this.baseURL + `popups/${popupId}`;

    return super.get(url, {}, this.headers)
  }

  update(payload: {}, popupId: Number | String) {
    let url = this.baseURL + `popups/${popupId}`;

    return super.post(url, payload);
  }
}

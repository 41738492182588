











































































































































































































































import moment from "moment";
import { translations } from "@/mixins";
import Translations from "../components/Translations.vue";
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Promotion, Coupon } from "@/services/SOLO";
import CouponGroupsCreateModal from '@/views/Admin/pages/Promotions/components/CouponGroupsCreateModal';
import CouponGroupCollapse from "../components/CouponGroupCollapse.vue";
import CouponGroupListView from "../components/CouponGroupListView.vue";

@Component({
  components: {
    CouponGroupsCreateModal,
    Translations,
		CouponGroupListView,
    CouponGroupCollapse
  },
  mixins: [translations]
})
export default class CouponGroupsList extends Vue {
  hasList: boolean = true;
  couponGroups: Array<any> = [];
  openCreate: boolean = false
  filter: any = {
    name: '',
  }
	currentView: string = 'grid'

  created() {
    this.loadCouponsGroup();
  }

  onNew() {
    this.openCreate = true;
  }

  handleClose(value: any) {
    this.openCreate = value;
  }

  applyFilter(data: any) {
    this.filter.name = data.name;
    this.loadCouponsGroup();
  }

  reset() {
    this.filter.name = '';
    this.loadCouponsGroup();
  }

  loadCouponsGroup() {
    Coupon.allCouponsGroup(1, this.filter.name).then(response => {
      this.couponGroups = response.data.data;
      console.log('allDigitalCoupons: ', this.couponGroups);
    });
  }

  onEditGroup(coupon: any) {
    this.$router.push({
      path: `/coupon-groups/coupon-list/${coupon.id}`,
    });
  }

  onDeleteGroup(coupon: any) {
    const r = confirm(`Are you sure you want to delete this coupon "${coupon.attributes.name}"?`);
    if (r) {
      Coupon.deleteCouponsGroup(coupon.id)
      .then(() => this.loadCouponsGroup());
    }
  }

  onChangeView(view: string) {
		this.currentView = view
	}

  formatDate(date: Date) {
    console.log(moment.locale());
    return moment
      .utc(date)
      .locale("en-us")
      .local()
      .format("ll");
  }
}

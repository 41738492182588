import store from '@/store'

export default function subscription({ to, from, next, router }) {  
  // let isLoggedIn = store.getters['account/isLoggedIn']
  // let getConceptSettings = store.getters['account/getConceptSettings']
  // let getUserRole = store.getters['account/getUserRole']
  // let subscription = store.getters['account/getSubscription']
  // let paymentId = store.getters['account/getSubscriptionPaymentId']  
  
  // if(isLoggedIn && getConceptSettings && getUserRole.includes('administrator')) {    
  //     if(getConceptSettings.attributes['billing-type'] === 'stripe' && !getConceptSettings.attributes['is-free-package'] && subscription === null) {            
  //         if(!paymentId) {                
  //             return next('/customer-subscription')
  //         } else {
  //             return next(`/payment/${paymentId}`)
  //         }
  //     } else if(getConceptSettings.attributes['billing-type'] === 'stripe' && !getConceptSettings.attributes['is-free-package'] && subscription) {
  //         if(subscription.attributes.status === 'past_due') {
  //             return next('/customer-subscription')
  //         } else if(subscription.attributes.status === 'incomplete' && !paymentId) {
  //             return next('/customer-subscription')
  //         } else if (subscription.attributes.status === 'incomplete' && paymentId) {
  //             return next(`/payment/${paymentId}`)
  //         }
  //     }
  // }
}

























import TimeEventModal from "./TimeEventModal.vue";
import TimeEventCards from "./TimeEventCards";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Menu } from '@/services/SOLO';
import { TimeEvent } from '@/models';
import moment from 'moment';
import 'moment/locale/pt-br';
import EventTimes from '@/models/EventTimes';
import {Cms as CmsApi} from "@/services/SOLO";
import { time } from 'd3';


@Component({
  components: {
    TimeEventModal,
    TimeEventCards,
  },
  mixins: [eventHandler, translations]
})
export default class ItemTimedEvents extends Vue {
  open: Boolean = false;
  tempEventTimes: Array<EventTimes> = [];
  eventTimes: Array<EventTimes> = [
    { day: 0, "from-time": "05:00 AM", "to-time": "05:00 PM" },
    { day: 1, "from-time": "05:00 AM", "to-time": "05:00 PM" },
    { day: 2, "from-time": "05:00 AM", "to-time": "05:00 PM" },
    { day: 3, "from-time": "05:00 AM", "to-time": "05:00 PM" },
    { day: 4, "from-time": "05:00 AM", "to-time": "05:00 PM" },
    { day: 5, "from-time": "05:00 AM", "to-time": "05:00 PM" },
    { day: 6, "from-time": "05:00 AM", "to-time": "05:00 PM" },
  ];

  timeEvent: TimeEvent = {
    id: moment().unix(),
    saved: false,
    attributes: {
      label: "",
      "from-date": moment().format("YYYY-MM-DD"),
      "to-date": moment().format("YYYY-MM-DD"),
      value: "",
      "event-times": this.eventTimes,
      type: "",
    },
  };

  timeEvents: Array<TimeEvent> = [];
  
  translate!: Function
  mounted() {
    this.getEvents();
  }

  setDefaultEvent() {
    this.timeEvent = {
      id: moment().unix(),
      saved: false,
      attributes: {
        label: "",
        "from-date": moment().format("YYYY-MM-DD"),
        "to-date": moment().format("YYYY-MM-DD"),
        value: "",
        "event-times": this.eventTimes,
        type: "",
      },
    };
  }

  modalOpenState(open: Boolean = true, setDefault: Boolean = true) {
    this.open = open;
    if (setDefault) {
      this.setDefaultEvent();
    }
  }

  formatTimes(data: TimeEvent) {
    this.tempEventTimes = [];
    data.attributes["event-times"].map((time) => {
      this.tempEventTimes.push({
        day: time.day,
        "from-time": this.formatTime(time["from-time"]),
        "to-time": this.formatTime(time["to-time"]),
      });
    });

    return this.tempEventTimes;
  }

  formatTime(time: String) {
    if (!time) {
      return "";
    }

    let formattedTime = moment(`${time}`, "hh:mm A").utc();
    return formattedTime.isValid() ? formattedTime.format("HH:mm") : "";
  }

  formatDefaultTimes(data: TimeEvent) {
    this.tempEventTimes = [];
    let fromDate = moment(data.attributes["from-date"].toString()).format(
      "YYYY-MM-DD"
    );
    let toDate = moment(data.attributes["to-date"].toString()).format(
      "YYYY-MM-DD"
    );
    data.attributes["event-times"].map((time) => {
      this.tempEventTimes.push({
        day: time.day,
        "from-time": time["from-time"] ? moment
          .utc(`${fromDate} ${time["from-time"]}`)
          .local()
          .format("hh:mm A") : '',
        "to-time": time["to-time"] ? moment
          .utc(`${toDate} ${time["to-time"]}`)
          .local()
          .format("hh:mm A") : '',
      });
    });

    return this.tempEventTimes;
  }

  get setData() {
    return {
      "hours": this.formatTimes(this.timeEvent),
      "from": this.timeEvent.attributes["from-date"],
      label: this.timeEvent.attributes["label"],
      "to": this.timeEvent.attributes["to-date"],
      type: this.timeEvent.attributes["type"],
      value: this.timeEvent.attributes["value"],
      "is-active": true,
    };
  }

  showItemEvent(item: TimeEvent) {
    console.log(item);
    this.timeEvent = {
      id: item.id,
      attributes: {
        "event-times": this.formatDefaultTimes(item),
        "from-date": item.attributes["from-date"],
        label: item.attributes["label"],
        "to-date": item.attributes["to-date"],
        type: item.attributes["type"],
        value: item.attributes["value"],
      },
      saved: true,
    };
    console.log(this.timeEvent);
    this.modalOpenState(true, false);
  }

  getEvents() {
    CmsApi.getTimedEvents(
      this.$route.params.slide_id,
    ).then((response: any) => {
      this.timeEvents = response.data.data;
    });
  }

  saveEvent(event: TimeEvent) {
    CmsApi.storeTimedEvents(
      event,
      this.setData,
      this.$route.params.slide_id,
    )
      .then((response: any) => {
        if (!event.saved) {
          this.setDefaultEvent();
        }
        this.getEvents();
        this.open = false;
      })
      .catch((err: any) => {
        console.error(err);
      });
  }


  deleteEvent(event: any) {
    console.log("timeEvents=>", event);

    CmsApi.deleteTimedEvents(
      event,
      this.$route.params.slide_id,
    )
      .then((response: any) => {
        if (!event.saved) {
          this.setDefaultEvent();
        }
        this.getEvents();
        this.open = false;
      })
      .catch((err: any) => {
        console.error(err);
      });

  }
}

export default {
  getHistory (state: { history: Array<any> }) {
      return state.history;
  },

  getPending (state: { pending: Array<any> }) {
    return state.pending;
  },

  getDeviceCount (state: { deviceCount: Array<any> }) {
    return state.deviceCount;
  },

  getSegments (state: { segments: Array<any> }) {
    return state.segments;
  },
}

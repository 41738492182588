
































import moment from "moment";
import { translations } from "@/mixins";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { Table, TableColumn, Button } from "element-ui";
import { Loyalty as LoyaltyModel } from "@/models";

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  mixins: [translations]
})
export default class CustomerLoyalties extends Vue {
  @Prop() loyalties!: Array<LoyaltyModel>;

  tempData: any = [];

  formatDate(date: string) {
    return moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .local()
      .format("MMM D YYYY hh:mm A");
  }
}

import BaseService from '../base.service'

let topicService = class TopicService extends BaseService {

  constructor() {
    super();
  }

  getTopics(lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    const url = `${this.baseURL}topics`;
    return super.get(url, {}, this.headers);
  }

  addTopic(payload: any) {
    const url = `${this.baseURL}topics`;
    return super.post(url, payload);
  }

  deleteTopic(id: string) {
    const url = `${this.baseURL}topics/${id}`;
    return super.remove(url, {});
  }

  updateTopic(id: string, payload: any) {
    const url = `${this.baseURL}topics/${id}`;
    return super.put(url, payload);
  }
}

export default topicService

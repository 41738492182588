import state from './messaging.state';
import getters from './messaging.getters';
import mutations from './messaging.mutations';
import actions from './messaging.actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}













































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import { Option, Select } from "element-ui";
import { translations } from "@/mixins";

@Component({
  components: {
    vSelect,
    DatePicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [translations]
})
export default class CriteriaInput extends Vue {
  @Prop() htmlType!: string;
  @Prop() id!: number;
  @Prop() condition!: any;
  @Prop() choices!: Array<any>;
  @Prop() value!: any;
  @Prop() menus!: Array<any>;

  selectedValue: any = null;
  choicesLists: any = [];

  @Watch("choices", { immediate: true, deep: true })
  choicesHandler(val: any) {
    if (val) {
      this.selectedValue = []
      this.mapChoices()
    }
  }

  @Watch("condition", { immediate: true, deep: true })
  conditionHandler(val: any) {
    if (!this.value && this.htmlType === "date" && val === "between") {
      this.value = {
        between: []
      };
    }
    if (!this.value && this.htmlType === "date" && val === "since") {
      this.value = {
        since: {
          value: null,
          unit: "y"
        }
      };
    }
  }
  
  @Watch("htmlType", { immediate: true, deep: true })
  htmlTypeHandler(val: any) {
    if (!this.value && val === "date" && this.condition === "since") {
      this.value = {
        since: {
          value: 1,
          unit: "y"
        }
      };
    }
  }

  @Watch("menus", { immediate: true, deep: true })
  menusHandler(val: any) {
    if (val.length && this.value) {
      this.selectedValue = this.value
    }
  }

  selectItem(val:any) {
    this.$emit("update-value", val);
  }

  mapChoices() {
    let choicesLists = this.choices.map((choice: any) => {
      return {
        value: choice.id,
        text: choice.text
      };
    });
    
    this.choicesLists = choicesLists
  }
}

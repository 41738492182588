
















































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins'
import Translations from './Translations.vue'

@Component({
mixins: [translations],
})
export default class OrderDetails extends Vue {
  public translate!: Function
}


















































import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator"
import { mapGetters } from "vuex"
import { Menu as MenuApi } from '@/services/SOLO'
import ComboItemModal from './ComboItemModal'
import { translations } from "@/mixins"

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui"
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ComboItemModal
  },

  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [translations],
})
export default class SelectedComponentItems extends Vue {

  @Prop() componentId!: string
  @Prop() items!: Array<Object>;
  @Prop() selectedItemIsLoading!: Boolean
  public translate!: Function

  open: Boolean = false
  updateData: Object = {}
  isUpdate: Boolean = false
  getLocale!: String

  get getAppLocale(): any {
    if (this.getLocale === 'en_US') {
      return 'en-us'
    }
    if (this.getLocale === 'ar_SA') {
      return 'ar-sa'
    }
    if (this.getLocale === 'fr') {
      return 'fr'
    }
  }

  updateComboItem(data: any) {
    this.isUpdate = true
    this.updateData = data
    this.open = true   
  }

  modalCloseState(open: Boolean = false) {    
    this.open = open
    this.isUpdate = false
    if(!open) {
      this.updateData = {}
    }
  }

  updateComponent(comboModId: any, itemModId: any, price: any) {
    this.items.forEach((item: any) => {
      item.relationships.sizes.forEach((size: any) => {
        if (size.combo_modifier_id == comboModId && size.item_modifier_id == itemModId) {
          size.price = price
        }
      })
    })
  }
  
  getSize(sizes: any, comModId: string) {
    // let filtered = sizes.filter((size: any) => {      
    //   return size.item_modifier
    // })
    // filtered = sizes.filter((size: any) => {
    //   if (Object.keys(size.item_modifier.name).length > 0) {
    //     return size.item_modifier.name.toLowerCase() === strSize
    //   }
    // })
    
    // if(!filtered.length) {
    //   return 0
    // }

    // return Number(filtered[0].price)

    let filtered = sizes.filter((size: any) => {
      if (size.combo_modifier_id == comModId) {
        return size.price
      }
    })

    if(!filtered.length) {
      return 0
    }

    return Number(filtered[0].price)
    
  }

  async removeComboComponent(e: any, componentItemId: string) {
    let target = e.target
    let defaultText = target.innerHTML
    target.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'  
    target.disabled = true
    try {
      await MenuApi.removeComboComponent(this.componentId, componentItemId)
      this.$emit('item:unlinked', this.componentId)
    } catch (error) {
      console.error('Error in Removing Combo Component', error)
    } finally {
      target.innerHTML = defaultText
      target.disabled = false
    }
  }
}






















































import { mapGetters } from 'vuex';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Button, Table, TableColumn } from 'element-ui';
import TabHeader from '@/views/Admin/pages/Messaging/components/TabHeader';
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import moment from "moment";
import {Messaging} from "@/services/SOLO"

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    TabHeader,
  },
  computed: {
    ...mapGetters({
      pending: 'messaging/getPending',
    })
  },
mixins: [translations],
})
export default class TabPending extends Vue {
  public translate!: Function
  pending: any;

  formatDate(date: string) {
    return moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .local()
      .format("MMM D YYYY hh:mm A");
  }

  checkIfInvalid(e: any) {
    return e !== 'Invalid date' ? e : '';
  }

  deleteMessage(id: number) {
    Messaging.deletePushNotification(id).then((response: any) => {
      const findOrderIdx = this.pending.findIndex((item: any) => item.id === id);
      this.pending.splice(findOrderIdx, 1);
    });
  }

}

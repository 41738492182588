import BaseService from '../base.service'

export default class LoyaltyService extends BaseService {
  url!: string
  constructor() {
    super();
  }


  search(name: string, mobile: string, type: string, from: string, to: string, order: string) {
    this.url = this.baseURL + `loyalty-points?filter[customer]=${name}&filter[mobile]=${mobile}&filter[type]=${type}&filter[from]=${from}&filter[to]=${to}&filter[order]=${order}`;
    return super.get(this.url);
  }


  all(page = 1) {
    let url = this.baseURL + 'loyalty-points';

    return super.get(url, {
      page: page
    });
  }

  getTiers(params: any = {}) {
    this.url = this.baseURL + 'loyalty/tiers';

    return super.get(this.url, params)
  }

  createTier(data: any) {
    this.url = this.baseURL + 'loyalty/tiers';

    return super.post(this.url, data);
  }

  updateTier(tierId: string, data: any) {
    this.url = this.baseURL + `loyalty/tiers/${tierId}`;
    return super.put(this.url, data);
  }

  collectPointsByMobile(mobile: string, points: number) {
    this.url = this.baseURL + 'loyalty/points/collection';

    return super.post(this.url, {
      mobile,
      points,
    });
  }

  exportTierCustomers(tierId: string) {
    this.url = this.baseURL + `loyalty/tiers/${tierId}/customers/export`;

    return super.post(this.url, {});
  }

  exportAllLoyalty(name: string, mobile: string, type: string, from: string, to: string, order: string, searched: Boolean) {
    let url = this.baseURL + 'loyalty/transactions/export?';

    if (searched) {
      url += `filter[name]=${name}&filter[mobile]=${mobile}&filter[type]=${type}&filter[from]=${from}&filter[to]=${to}&filter[order]=${order}`;
    } else {
      url += 'page=1';
    }

    return super.post(url, {});
  }

  getMerchants(params: any = {}) {
    this.url = this.baseURL + 'loyalty/available-brands';

    return super.get(this.url, params)
  }

  enableMerchantBranding(data: any) {
    this.url = this.baseURL + `loyalty/merchant`;
    return super.post(this.url, data);
  }

  getBrands() {
    this.url = this.baseURL + `loyalty/brands`;
    return super.get(this.url);
  }

  updateBrands(data: any) {
    this.url = this.baseURL + `loyalty/brands`;
    return super.post(this.url, data);
  }
}

import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { mapGetters } from 'vuex';
import translations from '@/locales/translations.json'

interface TlInterface {
  [x: string]: any
}

@Component({
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',
    })
  }
})
export default class Translations extends Vue {
  public getConceptSettings: any
  public getLocale: any
  public translationsData: TlInterface = {}
  constructor() {
    super()
    this.translationsData = translations
  }

  private getText(key: string) {
    if(this.translationsData[this.getLocale]) {
      if(this.translationsData[this.getLocale][key]) {
        return this.translationsData[this.getLocale][key]
      }
    }    
    return key
  }
  public translate(key: string, strReplace: any = {}) {
    let text = this.getText(key)    
    Object.keys(strReplace).forEach(str => {
      text = text.replace(str, strReplace[str])
    })  
    return !!text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
  }

  public translateNumber(num: any): number {
    if(!num) {
        num = 0;
    }

    if(this.getLocale === 'en_US') {
      return num.toLocaleString('en');  
    } else if(this.getLocale === 'ar_SA') {
      return num.toLocaleString('ar');  
    } else {
      return num.toLocaleString('fr');  
    }  
  }

  private getLanguages() {
    return this.getConceptSettings.attributes.languages
  }

  private getLang(lang: string) {
    if(this.getConceptSettings.attributes.languages.length > 1) {
      return `[${lang}]`
    }    
    return `[${this.getConceptSettings.attributes.languages[0]}]`
  }
}

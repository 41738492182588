




































































































































































































































































































































































































































































































































































































































































































































import moment from "moment";
import { mapGetters } from "vuex";
import { Select, Option, Form, FormItem } from "element-ui";
import { translations, eventHandler } from "@/mixins";
import StoreTimes from "../components/StoreTimes.vue";
// import StoreTimes from "@/views/Admin/pages/Locations/components/StoreTimes.vue";
import Translations from "../components/Translations.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import {
  Subscription as SubscriptionApi,
  Menu,
  Category,
  Promotion,
  Item,
  Location,
  Integrations
} from "@/services/SOLO";
import { mixins } from "vue-class-component";
// import BaseInput from "@/components/Inputs/BaseInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    Translations,
    StoreTimes,
    // BaseInput,
    ValidationProvider
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("D/MM/YYYY");
    }
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getUser: "account/getUser",
      getLocale: "app/getLocale",
      getSubscription: "account/getSubscription"
    })
  },
  mixins: [translations, eventHandler]
})
export default class PromotionsCreate extends Vue {

  private selected: number = 1;
  private scopeSelected: number = 1;
  
  hasIntegration: boolean = false;
  getLocale!: any;
  isSubmitting: boolean = false;
  isShow: boolean = false;
  totalCheckOrder: any;
  totalCheckCustomer: any;
  totalCheckType: any;
  $notify: any;
  loading: boolean = false;

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  redemption_customer: any;
  redemption_global: any;

  item: any = {
    menu: null,
    category: null,
    itemId: null
  };

  eligibilityItems: any = [];
  
  qualifyingItems: any = [];
  showQualifyingItems: boolean = false;

  minimumCartAmount: any = '0';

  posDiscount: string = "";

  app: any = {
    web: {
      value: "web",
      model: true
    },
    app: {
      value: "app",
      model: true
    },
    kiosk: {
      value: "kiosk",
      model: true
    },
    all: {
      value: "all",
      model: true
    }
  }

  orderTypes: any = {
    delivery: {
      value: "deliver",
      model: true
    },
    eatIn: {
      value: "eat-in",
      model: true
    },
    toGo: {
      value: "to-go",
      model: true
    },
    pickup: {
      value: "pickup",
      model: true
    },
    curbside: {
      value: "curbside",
      model: true
    }
  };

  accountTypes: any = {
    full: {
      value: "full",
      model: true
    },
    guest: {
      value: "guest",
      model: true
    }
  };

  accountStatus: any = {
    new: {
      value: "new",
      model: true
    },
    returning: {
      value: "returning",
      model: true
    }
  };

  openingHours: any = [
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      selected: false
    }
  ];

  dataForm: any = {
    type: "discount",
    name: {
      "en-us": "",
      "ar-sa": ""
    },
    duration: 1,
    "item-id": null,
    "item-code": null,
    "item-quantity": 0,
    "item-price": 0,
    "discount-type": "fixed",
    "discount-code": null,
    "discount-value": null,
    "discount-max": '',
    "subscription-amount": 0,
    "redemption-limit-global": 100000,
    "redemption-limit-customer": 100,
    "eligibility-rules": null,
    qualification: "order",
    "availability-rules": null,
    "cart-rules": null,
    "segment-id": null,
  };
  menus: any = [];
  categories: any = [];
  posDiscounts: any = [];
  items: any = [];
  menuId: string | number = "";
  categoryId: string | number = "";
  matchDay = [
    {
      idx: 0,
      day: "sun"
    },
    {
      idx: 1,
      day: "mon"
    },
    {
      idx: 2,
      day: "tue"
    },
    {
      idx: 3,
      day: "wed"
    },
    {
      idx: 4,
      day: "thu"
    },
    {
      idx: 5,
      day: "fri"
    },
    {
      idx: 6,
      day: "sat"
    }
  ];
  public enable247: boolean = true;
  public advancedSettings: boolean = false;
  public getConceptSettings!: any;

  countCheckedApps: number = 0;

  locations: any = [];
  segments: any = [];
  selectedLocations: any = [];

  selectedSegment: any = null
  discountAttributes: any = null
  onChangeCategory: boolean = false;

  @Watch('app.web', { deep: true })
  onChangesWeb(newVal: any) {
    this.checkAllApps()
  }

  @Watch('app.kiosk', { deep: true })
  onChangesKiosk(newVal: any) {
    this.checkAllApps()
  }

  @Watch('app.app', { deep: true })
  onChangesApp(newVal: any) {
    this.checkAllApps()
  }

  @Watch("orderTypes", { deep: true })
  onChangeOrderTypes(newVal: any) {
    let totalCheck = 0;

    if(newVal.curbside.model === false) {
       totalCheck = totalCheck + 1;
       console.log(newVal.curbside.value);
      if(totalCheck === 5) {
        this.$notify({
          title: `ORDER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Order Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })       
      }
    }
    if(newVal.delivery.model === false) {
       totalCheck = totalCheck + 1;
      if(totalCheck === 5) {
        this.$notify({
          title: `ORDER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Order Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })       
      }
    }
    if(newVal.eatIn.model === false) {
       totalCheck = totalCheck + 1;
      if(totalCheck === 5) {
          this.$notify({
            title: `ORDER TYPE ERROR`,
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Please check at least one Order Type!',
            type: 'danger',
            icon: 'fas fa-bomb',
          })
       }
    }
    if(newVal.pickup.model === false) {
       totalCheck = totalCheck + 1;
       if(totalCheck === 5) {
        this.$notify({
          title: `ORDER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Order Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })       
      }
    }
    if(newVal.toGo.model === false) {
       totalCheck = totalCheck + 1;
      if(totalCheck === 5) {
        this.$notify({
          title: `ORDER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Order Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })       
      }
    } 

    this.totalCheckOrder = totalCheck;
  
  }

  @Watch("accountTypes", { deep: true })
  onChangeAccountTypes(newVal: any) {
    let totalCheck = 0;

    if(newVal.full.model === false) {
       totalCheck = totalCheck + 1;
      if(totalCheck === 2) {
        this.$notify({
          title: `CUSTOMER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Customer Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })       
      }
    }
    if(newVal.guest.model === false) {
       totalCheck = totalCheck + 1;
      if(totalCheck === 2) {
        this.$notify({
          title: `CUSTOMER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Customer Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })       
      }
    }

    this.totalCheckCustomer = totalCheck;
  
  }


  @Watch("accountStatus", { deep: true })
  onChangeAccountStatus(newVal: any) {
    let totalCheck = 0;

    if(newVal.new.model === false) {
       totalCheck = totalCheck + 1;
      if(totalCheck === 2) {
        this.$notify({
          title: `TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })       
      }
    }
    if(newVal.returning.model === false) {
       totalCheck = totalCheck + 1;
      if(totalCheck === 2) {
        this.$notify({
          title: `TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })      
      }
    }

    this.totalCheckType = totalCheck;
  
  }

  @Watch("item.itemId", { deep: true })
  onItemId(newVal: boolean) {
    this.dataForm['item-id'] = newVal;
  }

  @Watch("advancedSettings", { deep: true })
  onChangeAdvancedSettings(newVal: boolean) {
    if (!newVal) {
      this.dataForm['redemption-limit-global'] = 100000;
      this.dataForm['redemption-limit-customer'] = 100;
      this.dataForm['cart-rules'] = null;
      this.dataForm['availability-rules'] = null;
      this.dataForm['eligibility-rules'] = null;
    } else {
      this.dataForm['redemption-limit-global'] = this.redemption_global;
      this.dataForm['redemption-limit-customer'] = this.redemption_customer;
      this.dataForm['cart-rules'] = {
        items: {},
        categories: {},
        menu: {},
        total: '',
        // "payment_method": '',
        "order-type": '',
      };
      this.dataForm['availability-rules'] = {
        schedule: {
          mon: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          tue: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          wed: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          thu: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          fri: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          sat: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          sun: {
            from: "0000",
            to: "2359",
            nextDay: false
          }
        },
      };
      this.dataForm['eligibility-rules'] = {
        "account-type": '',
        type: ''
      };
    }
  }

  created() {
    this.checkIntegrationIfExists()
    this.getBranchLocations()
    this.getSegments()
    this.getPOSDiscounts();
    this.getMenus();
    if (this.$route.params.id) {
      Promotion.getPromotion(this.$route.params.id).then(async (response) => {
        const data = response.data.data;
        this.menuId = data.attributes['cart-rules']?.menu ? Object.keys(response.data.data.attributes['cart-rules']?.menu)[0] : 0;

        this.redemption_customer = data.attributes['redemption-limit-customer'];
        this.redemption_global = data.attributes['redemption-limit-global'];

        if (
          data.attributes
          && data.attributes.type === 'item'
        ) {
          Item.getItem(data.attributes['item-id'])
            .then((response) => {
              const dt = response.data.data;
              dt.id = Number(dt.id);
              this.items.push(dt);
              this.item.itemId = data.attributes['item-id'];
              this.dataForm['item-id'] = data.attributes['item-id'];
              this.dataForm['item-code'] = data.attributes['item-code'];
              this.dataForm['item-quantity'] = data.attributes['item-quantity'];
              this.dataForm['item-price'] = data.attributes['item-price'];
            });

            this.item.menu = response.data.included[0].attributes.menu_id;
            this.getCategoriesByMenu(this.item.menu);
            this.item.category = response.data.included[1].attributes['category-id'];

        }

        if (
          data.attributes
          && (data.attributes['cart-rules']
          // || data.attributes['eligibility-rules']
          || data.attributes['availability-rules'])
        ) {
          this.advancedSettings = true;
        }

        if (
          data.attributes
          && data.attributes['cart-rules']
          && data.attributes['cart-rules'].categories
        ) {
          const catPromiseArray: any = [];
          const itmPromiseArray: any = [];
          const { menu } = data.attributes['cart-rules'];
          const { categories } = data.attributes['cart-rules'];
          const { items } = data.attributes['cart-rules'];

          for (const id in menu) {
            await this.getCategoriesByMenu(id);
          }

          for (const id in items) {
            itmPromiseArray.push(!id.includes('null') ? Item.getItem(id) : items[id]);
          }

          if (itmPromiseArray.length) {
            Promise.all(itmPromiseArray)
              .then((res: any) => {
                res.forEach((item: any) => {
                  if (item.hasOwnProperty('quantity')) {
                    this.eligibilityItems.push({
                      menu: item.menuId + '',
                      categories: item.categoryId + '',
                      item: null,
                      quantity: item.quantity.substring(4),
                    });
                  } else {
                    const links = item.data.data.links.self;
                    const menuId = links.split('/menus/')[1].split('/')[0];
                    const categoryId = links.split('/categories/')[1].split('/')[0];
                    this.items.push(item.data.data);
                    this.eligibilityItems.push({
                      menu: menuId,
                      categories: categoryId,
                      item: item.data.data.id,
                      quantity: (items[item.data.data.id].quantity).slice(4),
                    });
                  }
                  this.isShow = true;
                });
              });
          }
        }

        if (data.attributes && data.attributes['cart-rules']) {
          if (data.attributes['cart-rules'].location) { 
            let locationId = data.attributes['cart-rules'].location.split(":")
            if (locationId.length > 1) {
              this.selectedLocations = locationId[1].split(",")
            } 
          }
        }

        if (data.attributes && data.attributes['cart-rules']) {
          const total = data.attributes['cart-rules']['total'];
          const orderType = data.attributes['cart-rules']['order-type'];
          // const paymentMethod = data.attributes['cart-rules']['payment_method'];
          let s = JSON.stringify(total);
          let p = JSON.parse(s);

          this.minimumCartAmount = p.slice(4); //get total only

          if (orderType) {
            this.orderTypes.delivery.model = orderType.includes('deliver');
            this.orderTypes.eatIn.model = orderType.includes('eat-in');
            this.orderTypes.toGo.model = orderType.includes('to-go');
            this.orderTypes.pickup.model = orderType.includes('pickup');
            this.orderTypes.curbside.model = orderType.includes('curbside');
          } else {
            this.orderTypes.delivery.model = false;
            this.orderTypes.eatIn.model = false;
            this.orderTypes.toGo.model = false;
            this.orderTypes.pickup.model = false;
            this.orderTypes.curbside.model = false;
          }
        }
        
        if (data.attributes && data.attributes['eligibility-rules']) {
          const account_type = data.attributes['eligibility-rules']['account-type'];
          const type = data.attributes['eligibility-rules']['type'];
          const app = data.attributes['eligibility-rules']['app'];
          if (app) {
            if (app === "in:") {
              this.appsChecker(false)
            }
            if (app.includes('all')) {
              this.appsChecker(true)
            }
            if (!app.includes('all') && app !== "in:") {
              this.app.web.model = app.includes('web');
              this.app.app.model = app.includes('app');
              this.app.kiosk.model = app.includes('kiosk');
              this.app.all.model = app.includes('all');
              if (!app.includes('web') && !app.includes('app') && !app.includes('kiosk')) {
                this.appsChecker(true)
              }
            }
          } else {
            this.appsChecker(true)
          }

          if (account_type) {
            this.accountTypes.full.model = account_type.includes('full');
            this.accountTypes.guest.model = account_type.includes('guest');
          } else {
            this.accountTypes.full.model = false;
            this.accountTypes.guest.model = false;
          }

          if (type) {
            this.accountStatus.new.model = type.includes('new');
            this.accountStatus.returning.model = type.includes('returning');
          } else {
            this.accountStatus.new.model = false;
            this.accountStatus.returning.model = false;
          }
        }

        let is247Count = 0;
        if (data?.attributes?.['availability-rules']) {
          this.openingHours = this.openingHours.map((d: any, idx: number) => {
            const found: any = this.matchDay.find((m: any) => m.idx === idx);
            const day = data.attributes['availability-rules'].schedule[found.day];
            const { from, to, available } = day
            const outputFrom = [from.slice(0, 2), ':', from.slice(2)].join('');
            const outputTo = [to.slice(0, 2), ':', to.slice(2)].join('');
            const open = moment(moment().format('YYYY-MM-DD') + ' ' + outputFrom).format('hh:mm A');
            const closed = moment(moment().format('YYYY-MM-DD') + ' ' + outputTo).format('hh:mm A');

            if (open === '12:00 AM' && closed === '11:59 PM') {
              is247Count = is247Count + 1;
            }
            return { open, closed, day: found.day, selected: available };
          });
        }

        if(data.attributes['availability-rules']) {
          this.enable247 = false;
        }

        if (data.attributes && data.attributes.items) {
          const { items } = data.attributes
          const itemsPromiseArr: any = []

          items.forEach(async (val : any) => {
            await this.getCategoriesByMenu(val.menu);
          });

          items.forEach(async (val : any) => {
            await itemsPromiseArr.push(Item.getItem(val.item));
          });

          if (itemsPromiseArr.length) {
            Promise.all(itemsPromiseArr)
              .then((i: any) => {
                i.forEach((itm: any) => {
                  const links = itm.data.data.links.self;
                  const menuId = links.split('/menus/')[1].split('/')[0];
                  const categoryId = links.split('/categories/')[1].split('/')[0];
                  this.items.push(itm.data.data);
                  this.qualifyingItems.push({
                    menu: +menuId,
                    category: +categoryId,
                    item: +itm.data.data.id,
                  });
                });
              });
          }
        } else {
          Promotion.getPromotionQualifyingItems(this.$route.params.id).then(res => {
            const { data: items } = res
            const itemsPromiseArr: any = []

            items.forEach(async (val : any) => {
              await this.getCategoriesByMenu(val['menu-id']);
            });

            items.forEach(async (val : any) => {
              await itemsPromiseArr.push(Item.getItem(val['item-id']));
            });

            if (itemsPromiseArr.length) {
              Promise.all(itemsPromiseArr)
                .then((i: any) => {
                  i.forEach((itm: any) => {
                    const links = itm.data.data.links.self;
                    const menuId = links.split('/menus/')[1].split('/')[0];
                    const categoryId = links.split('/categories/')[1].split('/')[0];
                    this.items.push(itm.data.data);
                    this.qualifyingItems.push({
                      menu: +menuId,
                      category: +categoryId,
                      item: +itm.data.data.id,
                    });
                  });
                });
            }
          }).catch(err => {
            console.log('err in getPromotionQualifyingItems', err);
          })
        }

        this.dataForm = {
          type: data.attributes.type,
          name: {
            'en-us': data.attributes.name,
          },
          duration: 1,
          "discount-type": data.attributes['discount-type'],
          "discount-code": data.attributes['discount-code'],
          "discount-value": data.attributes['discount-value'],
          "discount-max": data.attributes['discount-max'],
          "subscription-amount": data.attributes['subscription-amount'],
          "redemption-limit-global": data.attributes['redemption-limit-global'],
          "redemption-limit-customer": data.attributes['redemption-limit-customer'],
          "eligibility-rules": data.attributes['eligibility-rules'],
          "qualification": data.attributes['qualification'] === 'order' ? 'order' : 'item',
          "availability-rules": data.attributes['availability-rules'],
          "cart-rules": data.attributes['cart-rules'],
          "item-price": data.attributes['item-price'],
          "item-quantity": data.attributes['item-quantity'],
          "segment-id": data.attributes['segment-id']
        };
        setTimeout(() => {
          this.dataForm['redemption-limit-global'] = data.attributes['redemption-limit-global'];
          this.dataForm['redemption-limit-customer'] = data.attributes['redemption-limit-customer'];
        }, 200)
      });
    }
  }

  appsChecker (value: boolean) {
    for (const [key] of Object.entries(this.app)) {
      this.app[key].model = value
    }
  }

  onInputAllApps(value: any) {
    this.appsChecker(value)
  }

  checkAllApps() {
    this.countCheckedApps = 0
    for (const [key] of Object.entries(this.app)) {
      if (key !== 'all') {
        if (this.app[key].model) {
          this.countCheckedApps++
        }
      }
    }
    this.countCheckedApps === 3 && this.appsChecker(true)
  }

  async refreshPromotionsList(){
    try {
      this.loading = true
      const response = await Promotion.refreshPOSDiscounts();
      this.posDiscounts = response.data.data;
    } catch (error) {
      console.log('Error in refreshing promotion discount dropdown', error)
    } finally {
      this.loading = false
    }
  }

  public get defaultPos(): string {
    return this.getConceptSettings?.attributes?.['default-pos'];
  }

  async getBranchLocations(){
    try {
      const lang = this.getLocale
      const response = await Location.fetchLocations(lang)
      this.locations = response.data.data;
    } catch (error) {
      console.error('error', error)
    }
  }

  async getSegments(){
    try {
      const lang = this.getLocale
      const response = await Promotion.fetchSegments(lang)
      this.segments = response?.data?.data?.data;
    } catch (error) {
      console.error('error', error)
    }
  }

  async getMenus() {
    const response = await Menu.all();
    this.menus = response.data.data;
  }

  async getCategoriesByMenu(menuId: string | number) {
    const response = await Category.all(menuId, 1);
    this.categories = [...this.categories, ...response.data.data];
  }

  async getItemsByCategory(
    menuId: string | number,
    categoryId: string | number
  ) {
    const response = await Category.items(menuId, categoryId, 1);
    console.log('Response: getItemsByCategory-> ', response);
    this.onChangeCategory = true
    this.items = response.data.data;
  }

  async getPOSDiscounts() {
    const response = await Promotion.getPOSDiscounts();
    this.posDiscounts = response.data.data;
    console.log('this.posDiscounts: ', this.posDiscounts);
  }

  onMenuChange(id: string | number) {
    console.log("check menu id", id);
    this.menuId = id;
    // empty categories and items list
    this.categories = [];
    this.items = [];
    // set null to category and item
    this.item.itemId = null;
    this.dataForm['item-id'] = null;
    this.item.category = null;
    this.getCategoriesByMenu(id);
  }

  onMenuQualificationChange(id: string|number, index: number) {
    if (this.$route.params.id) {
      let currentItem = this.qualifyingItems[index]
      const { item, category } = currentItem
      if (category && item) {
        this.$notify({
          title: 'Category and Item',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: "Category and Item is required to change, else it won't save.",
          type: 'warning',
          icon: 'fas fa-exclamation-triangle',
        })
      }
    }
    this.menuId = id;
    this.getCategoriesByMenu(id);
  }

  onCategoryQualificationChange(id: string|number) {
    this.getItemsByCategory(this.menuId, id);
  }

  onMenuEligibilityChange(id: string|number) {
    this.menuId = id;
    this.getCategoriesByMenu(id);
  }

  onCategoryChange(id: string|number) {
    this.categoryId = id;
    console.log("check category", id);
    // empty items list
    this.items = [];
    // set null to items
    this.item.itemId = null;
    this.dataForm['item-id'] = null;
    this.getItemsByCategory(this.menuId, id);
  }

  onCategoryEligibilityChange(id: string|number) {
    this.getItemsByCategory(this.menuId, id);
  }

  savePromo() {
    this.isSubmitting = true
    if(this.totalCheckOrder === 5) {
        this.$notify({
          title: `ORDER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Order Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })
      this.isSubmitting = false
      return false;
    }

    if (this.dataForm['type'] === 'discount') {
      if (this.hasIntegration && this.dataForm['discount-code'] === null) {
        this.$notify({
          title: `Discount is Required`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please select a discount',
          type: 'danger',
          icon: 'fas fa-bomb',
        })
        this.isSubmitting = false
        return false
      }
    }

    if(this.totalCheckCustomer === 2) {
        this.$notify({
          title: `CUSTOMER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Customer Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })      
        this.isSubmitting = false
        return false;
    }

    if(this.totalCheckType === 2) {
        this.$notify({
          title: `TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })      
        this.isSubmitting = false
        return false;
    }

    if (this.dataForm["item-id"]) {
      const item = this.items.find(
        (i: any) => i.id === this.dataForm["item-id"]
      );
      console.log("item: ", item);
      console.log("this.items: ", this.items);
      console.log(`this.dataForm['item-id']: `, this.dataForm["item-id"]);
      if (item) {
        this.dataForm["item-code"] = item.attributes.code;
      }
    }

    if (this.qualifyingItems.length) {
      this.dataForm["items"] = this.qualifyingItems
    }

    // Get order types
    let arr = [];
    for (let x in this.orderTypes) {
      if (this.orderTypes[x].model) {
        arr.push(this.orderTypes[x].value);
      }
    }
    if (this.dataForm["cart-rules"]) {

      let n = 'in:';
      let l = n + arr; //add prefix
      let convertToArray = l.split(" ");

      this.dataForm["cart-rules"]["order-type"] = convertToArray.length
      ? convertToArray.join()
      : "";

    }

    arr = [];

    // Get payment methods
    // for (let x in this.paymentMethods) {
    //   if (this.paymentMethods[x].model) {
    //     arr.push(this.paymentMethods[x].value);
    //   }
    // }
    // if (this.dataForm["cart-rules"]) {
    //   this.dataForm["cart-rules"]["payment_method"] = arr.length
    //   ? arr.join()
    //   : "";
    // }

    arr = [];

    // Get account types
    for (let x in this.accountTypes) {
      if (this.accountTypes[x].model) {
        arr.push(this.accountTypes[x].value);
      }
    }
    if (this.dataForm["eligibility-rules"]){
      let n = 'in:';
      let l = n + arr; //add prefix
      let convertToArray = l.split(" ");

      this.dataForm["eligibility-rules"]["account-type"] = convertToArray.length
      ? convertToArray.join()
      : "";
    }

    arr = [];

    // Get account status ....
    for (let x in this.accountStatus) {
      if (this.accountStatus[x].model) {
        arr.push(this.accountStatus[x].value);
      }
    }
    if (this.dataForm["eligibility-rules"]) {
      let n = 'in:';
      let l = n + arr; //add prefix
      let convertToArray = l.split(" ");

      this.dataForm["eligibility-rules"]["type"] = convertToArray.length
      ? convertToArray.join()
      : "";
    }

    arr = [];

    // Get Selected App
    let arrApp = []

    if (this.app.all.model) {
      arrApp.push('all');
    } else {
      for (let x in this.app) {
        if (this.app[x].model) {
          arrApp.push(this.app[x].value);
        }
      }
    }

    if (this.dataForm["eligibility-rules"]) {
      let n = 'in:';
      let l = n + arrApp; // add prefix
      let convertToArray = l.split(" ");

      this.dataForm["eligibility-rules"]["app"] = convertToArray.length
      ? convertToArray.join()
      : "";
    }

    const schedule: any = {};
    let isValidateCount = 0;
    this.openingHours.forEach((d: any, idx: number) => {
      const found: any = this.matchDay.find((m: any) => m.idx === idx);
      if (d.open && d.closed) {
        schedule[found.day] = {
          from: this.enable247 ? '00:00' : d.open.split(':').join(''),
          to: this.enable247 ? '23:59' : d.closed.split(':').join(''),
          nextDay: false,
          available: d.selected,
        };
      } else {
        isValidateCount = isValidateCount + 1;
      }

      console.log('d.open: ', d.open);
      console.log('d.closed: ', d.closed);
      console.log('schedule[found.day]: ', schedule[found.day]);
    });

    if (isValidateCount) {
      alert('Schedule dates should be filled in!');
      return;
    }

    if (this.dataForm["availability-rules"]) {
      this.dataForm["availability-rules"].schedule = schedule;
    }

    if(this.enable247 === true) {
        this.dataForm["availability-rules"] = null;
    }

    const cartRules: any = {
      items: {},
      categories: {},
      menu: {}
    };

    this.eligibilityItems.forEach((cartItem: any, ind: any) => {
      const { item, categories, menu, quantity } = cartItem

      cartRules.menu[menu] = {
        quantity: `gte:${quantity}`
      }

      cartRules.categories[categories] = {
        quantity: `gte:${quantity}`
      }

      if (item) {
        cartRules.items[item] = { quantity: `gte:${quantity}` }
      } else {
        cartRules.items[`${item}-${ind}`] = { quantity: `gte:${quantity}`, categoryId: categories, menuId: menu}
      }
    })

    if (this.selectedLocations.length === 0 && this.advancedSettings) {
      this.isSubmitting = false
      return this.$notify({
        title: `Location Error`,
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: 'Please select location/s.',
        type: 'danger',
        icon: 'fas fa-bomb',
      })
    }

    if (this.dataForm["cart-rules"]) {
      this.dataForm["cart-rules"].items = cartRules.items;
      this.dataForm["cart-rules"].categories = cartRules.categories;
      this.dataForm["cart-rules"].menu = cartRules.menu;
      this.dataForm["cart-rules"].total = 'gte:' + this.minimumCartAmount.toString();
      this.dataForm["cart-rules"].location = this.selectedLocations.length ? `in:${this.selectedLocations.join(",")}` : null;
    }

    console.log("openingHours: ", this.openingHours);
    console.log("DataForm: ", this.dataForm);
    console.log("cartRules: ", cartRules);
    if (this.dataForm.type === 'delivery') {
      this.dataForm['discount-type'] = 'percentage';
      this.dataForm['discount-value'] = 100;
    }

    this.dataForm['redemption-limit-global'] = Number(this.dataForm['redemption-limit-global']);
    this.dataForm['redemption-limit-customer'] = Number(this.dataForm['redemption-limit-customer']);

    let promise: any = null;
    const { id } = this.$route.params;
    console.log('PARAMS: Promo Before Submit', this.dataForm);
    if (id) {
      promise = Promotion.updatePromotion(id, this.dataForm);
    } else {
      promise = Promotion.savePromotion(this.dataForm);
    }

    promise
      .then(() => {
        this.$notify({
          title: id ? 'UPDATE PROMOTION' : 'ADD PROMOTION',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: `Promotion successfully ${id ? 'updated' : 'added'}`,
          type: "success",
          icon: "fas fa-check"
        });
        this.$router.push({ name: "PromotionsList" });
      })
      .catch((err: any) => {
        console.log('err:', err.response.data.error)
        let errMsg: any = ''
        err.response.data.error.forEach((e: any) => {
          errMsg += e.detail + '\n';
        });
        console.log('errMose: ', errMsg);
        this.$notify({
          title: `${id ? 'UPDATE' : 'ADD'} PROMOTION ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: errMsg,
          type: 'danger',
          icon: 'fas fa-bomb',
        })
      }).finally(() => {
        this.isSubmitting = false
      });
  }
  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  onAddEligibilityItem() {
    this.isShow = !false;
    this.eligibilityItems.push({
      menu: null,
      categories: null,
      item: null,
      quantity: null,
    });
  }

  onDeleteEligibilityItem(index: number) {
    this.eligibilityItems.splice(index, 1);
  }

  cancelPromo() {
    this.$router.push({ name: "PromotionsList" });
  }

  onDeleteQualificationItem(index: number) {
    if (this.qualifyingItems.length === 1) {
      this.$notify({
        title: 'Unable to remove an item.',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: "Promotion must have atleast 1 item.",
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    } else {
      this.qualifyingItems.splice(index, 1);
    }
  }

  onAddQualifyingItem() {
    this.qualifyingItems.push({
      menu: null,
      category: null,
      item: null
    });
  }

  onChangeQualification(val : any){
    this.showQualifyingItems = val === 'item' ? true : false
  }
  getOptionLabel (nameObj: any): any {
    if (this.getLocale === 'en_US') {
      return nameObj['en']
    }

    if (this.getLocale === 'ar_SA' && nameObj['ar']) {
      return nameObj['ar']
    }

    return nameObj['en']
  }

  onChangeDiscountCode(e : any) {
    if (e) {
      const onChangeIteration = new Promise((resolve, reject) => {
        for (const [key, value] of Object.entries(this.posDiscounts)) {
          if (this.posDiscounts[key].id == e) {
            resolve(value)
          }
        }
      })

      onChangeIteration.then((val : any ) => {
        this.dataForm['discount-value'] = val['discount-amount'] ? val['discount-amount'] : 0
        this.dataForm['discount-type'] = val['discount-type'] ? val['discount-type'] : 'fixed'
        this.dataForm['discount-max'] = val['maximum-discount-amount'] ? val['maximum-discount-amount'] : 0
        this.minimumCartAmount = val['minimum-order-amount'] ? val['minimum-order-amount'] : 0
      })
    }
  }

  async checkIntegrationIfExists() {
    try {
      const { data: integration } = await Integrations.logisticsPartners('pos')
      this.hasIntegration = integration.data.length > 0 ? true : false
      console.log('Check if Integration Exists in the Response', this.hasIntegration);
    } catch (error) {
      console.error('Error Occured', error)
    }
  }
}

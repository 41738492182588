import BaseService from '../base.service'

let configService = class ConfigService extends BaseService {

    constructor() {
        super();                        
    }
    
    getApplicationsByKey(key: string)
    {
        console.log(this.baseURL)
        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Solo-App': key
        }

        let url = this.baseURL + '/applications?include=concept'                
        return super.get(url)
    }

    getApplications()
    {
        let url = this.baseURL + '/applications?include=concept&filter[url]='+ this.activeDomain                
        return super.get(url)
    }

    getConcept()
    {
        let url = this.baseURL + '/clients?filter[url]='+ this.activeDomain      
        return super.get(url)
    }        

};

export default configService
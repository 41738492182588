import BaseService from '../base.service'
import store from '@/store'

export default class SettingsConceptsService extends BaseService{
  url!: string
  constructor(){
    super();        
  }
  
  all(lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = `${this.baseURL}concepts`
    return super.get(this.url)
  }

  get(lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    const concept = store.getters['account/activeConcept'].key
    this.url = `${this.baseURL}concepts/${concept}`
    return super.get(this.url)
  }

  update(id: string, payload: any = {}, lang: string = 'en-us') {    
    this.headers['Accept-Language'] = lang
    const concept = store.getters['account/activeConcept'].key
    this.url = `${this.baseURL}concepts/${id}`
    payload.append('_method', 'patch')
    return this.post(this.url, payload)
  }
}
import BaseService from '../base.service'
import store from '@/store'
export default class GainsightService extends BaseService {
  url!: string
  constructor() {
    super();
    // this.baseURL = this.baseURL + 'menus/';
  }

  details() {
    this.url = this.baseURL + `gainsight-details`;
    return super.get(this.url);
  }
  
}

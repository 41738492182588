






































































































































































































































import {
  Form,
  Alert,
  Input,
  InputNumber,
  FormItem,
  Select,
  Option,
  Switch,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Button,
  Upload
} from 'element-ui'
import {Component, Vue} from "vue-property-decorator"
import {SubscriptionMeals} from '@/services/SOLO'
import {mapGetters, mapMutations} from 'vuex'

@Component({
  components: {
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Switch.name]: Switch,
    [Input.name]: Input,
    [Button.name]: Button,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings'
    })
  }
})
export default class SubscriptionSettings extends Vue {
  isActive: any // this is a flag to identify if a current has a subscription settings configured
  $notify: any
  getConceptSettings!: Array<typeof mapGetters>
  currentConcept: any
  saving: boolean = false
  delivery_slots: any = []
  form: any = {
    delivery_time_slot_utc: [],
    default_delivery_slots_per_hour: 2,
    delivery_slot_per_hour: 2,
    delivery_charge: 5,
    get_meals_in_one_go: true,
    allow_meals_in_one_go: true,
    allow_extra_meal: true,
    allow_customize_week: true,
    allow_multiple_servings: true,
    allow_customize_addons: true,
    allow_add_servings: true,
    allow_drinks_snacks_meal: true,
    allow_add_weeks: true,
    allow_pause_subscription: true,
    enable_subscription: true
  }

  mounted() {
    this.currentConcept = this.getConceptSettings
    this.getDeliverySlot()
    this.getSubscriptionSetting()
  }

  getDeliverySlot() {
    SubscriptionMeals.getDeliverySlots()
        .then((response: any) => {
          this.delivery_slots = response.data.data
        })
  }

  getSubscriptionSetting() {
    SubscriptionMeals.getSubscriptionSetting(this.currentConcept.id)
        .then((response: any) => {

          let res = response.data.data.attributes

          this.form.delivery_time_slot_utc = res['delivery-time-slot-utc'] ?? []
          this.form.default_delivery_slots_per_hour = res['default-delivery-slots-per-hour']
          this.form.delivery_slot_per_hour = res['delivery-slot-per-hour']
          this.form.delivery_charge = res['delivery-charge']
          this.form.get_meals_in_one_go = Boolean(Number(res['get-meals-in-one-go']))
          this.form.allow_meals_in_one_go = Boolean(Number(res['allow-meal-i-on-go']))
          this.form.allow_extra_meal = Boolean(Number(res['allow-extra-meal']))
          this.form.allow_customize_week = Boolean(Number(res['allow-customize-week']))
          this.form.allow_multiple_servings = Boolean(Number(res['allow-multiple-servings']))
          this.form.allow_customize_addons = Boolean(Number(res['allow-customize-addons']))
          this.form.allow_add_servings = Boolean(Number(res['allow-add-servings']))
          this.form.allow_drinks_snacks_meal = Boolean(Number(res['allow-drinks-snacks-meal']))
          this.form.allow_add_weeks = Boolean(Number(res['allow-add-weeks']))
          this.form.allow_pause_subscription = Boolean(Number(res['allow-pause-subscription']))
          this.form.enable_subscription = Boolean(Number(res['enable-subscription']))

        })
    .catch((err: any) => {
      let empty = this.currentConcept.attributes.label + ' Concept has no subscription settings configured.'
      let unauthorized = err.response.data
      this.$notify({
          title: "Dashboard Warning",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: err.response.status === 401 ? unauthorized : empty,
          // message: err.response.data.error.detail,
          type: "warning",
          icon: "fas fa-bomb",
        })
      this.isActive = true
    })
  }

  createOrUpdate() {
    this.saving = true
    if (this.isActive) {

      SubscriptionMeals.createSubscriptionSetting(this.form)
        .then((response: any) => {
          this.saving = false
          // this.form = response.data.data.attributes
          this.$notify({
            title: this.currentConcept.attributes.label + " Subscription Settings Created.",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "You have successfully configured your subscription settings.",
            type: "success",
            icon: "fas fa-check",
          });
          this.isActive = false
        })
    } else {
      SubscriptionMeals.updateSubscriptionSetting(this.currentConcept.id, this.form)
        .then((response: any) => {
          this.saving = false
          this.$notify({
            title: "SUBSCRIPTION SETTINGS UPDATED",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "You have successfully updated your subscription settings.",
            type: "success",
            icon: "fas fa-check",
          });
        })
        .catch((err: any) => {
          this.$notify({
            title: "System Error",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: err.response.data.error.detail,
            type: "danger",
            icon: "fas fa-bomb",
          })
        })
    }
  }
}

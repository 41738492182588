







































import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

import { translations } from "@/mixins";
import ManageMessages from "./components/ManageMessages.vue";
import WhatsAppAccount from "./components/MyWhatsAppAccount.vue";
import DashboardCustomizeMessage from "./components/DashboardCustomizeMessage.vue";
import { OrderStatus as OrderStatusModel } from "@/models";
import {
  Order as OrderApi,
  WhatsApp as WhatsAppService
} from "@/services/SOLO";

@Component({
  components: {
    ManageMessages,
    WhatsAppAccount,
    DashboardCustomizeMessage,
  },
  mixins: [translations],
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
})
export default class Dashboard extends Vue {
  isCustomizeMessage: boolean = false;
  customizeId!: Number;
  action!: String;
  tabIndex: Number = 0;
  messageData: Array<any> = [];
  orderStatuses: Array <OrderStatusModel> = [];
  getConceptSettings: any;
  vonageData: any = {}; 

  mounted() {
    this.getOrderStatuses();
    this.geVonageData();
  }
  
  toggleCustomizeMessage({ index, action, data = []}: {index: number, action: string, data: any}) {
    this.customizeId = index;
    this.action = action;
    this.messageData = data;
    this.isCustomizeMessage = !this.isCustomizeMessage;
  }

  getOrderStatuses() {
    OrderApi.orderstatuses().then((response: any) => {
      this.orderStatuses = response.data.data;
    });
  }

  get vonageAcc() {
    return this.vonageData;
  }

  async geVonageData() {
    try {
      const { id } = this.getConceptSettings;
      const { data } = await WhatsAppService.checkAccountIfVerified(id);
      this.vonageData = data.data[0];

    } catch (err) { 
      if (err instanceof Error) {
        console.log(err.message);
      }

      console.log('Unexpected error', err);
    }
  }
}




































































































































































import moment from "moment";
import { notificationAlerts, translations } from "@/mixins";
import Translations from "../components/Translations.vue";
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Promotion, Coupon } from "@/services/SOLO";
import { Table, TableColumn } from 'element-ui';
import CouponGroupsCouponListTab from "./CouponGroupsCouponListTab";

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Translations,
    CouponGroupsCouponListTab,
  },
  mixins: [translations, notificationAlerts]
})
export default class CouponGroupsEditList extends Vue {
  hasList: boolean = true;
  coupons: Array<any> = [];
  promotions: Array<any> = [];
  tab: number = 1;
  successNotification!: Function;

  created() {
    // if (this.$route.query.digital) {
    //   this.allDigitalCoupons();
    // } else {
    //   this.getCouponsbyCouponGroupId();
    // }
  }

  // onAddPromotion() {
  //   this.$router.push({
  //     name: "PromotionsCreate"
  //   });
  // }
  allDigitalCoupons() {
    Coupon.allDigitalCoupons().then(response => {
      this.coupons = response.data.data;
      this.promotions = response.data.included;
    });
  }

  getCouponsbyCouponGroupId() {
    const id = this.$route.params.id;
    Coupon.getCouponsbyCouponGroupId(id).then(response => {
      this.coupons = response.data.data;
      this.promotions = response.data.included;
    });
  }

  onEdit(coupon: any) {
    console.log('onEdit', coupon);
    if (this.$route.query.digital) {
      this.$router.push({ path: `/digital-coupons/edit/${coupon.id}?digital=true` });
    } else {
      this.$router.push({ path: `/coupon-groups/coupon-edit/${this.$route.params.id}/coupon/${coupon.id}` });
    }
  }

  onDelete(coupon: any) {
    if (this.$route.query.digital) {
    //  Delete Digital Coupons
      Coupon.deleteDigitalCoupon(coupon.id)
        .then(() => {
          const idx = this.coupons.findIndex((coup: any) => coup.id === coupon.id);
          this.coupons.splice(idx, 1);
          this.successNotification('COUPONS DELETED!', 'Coupon successfully deleted!');
        });
    } else {
      Coupon.deleteCoupon(this.$route.params.id, coupon.id)
        .then(() => {
          const idx = this.coupons.findIndex((coup: any) => coup.id === coupon.id);
          this.coupons.splice(idx, 1);
          this.successNotification('COUPONS DELETED!', 'Coupon successfully deleted!');
        });
    }
  }

  filterCouponsGroup(tabType: string) {
    return this.coupons.filter((c: any) => {
      if (tabType === c.attributes.status) {
        return c;
      } else if (tabType === c.attributes.status) {
        return c;
      } else if (tabType === c.attributes.status) {
        return c;
      } else if (this.$route.query.digital) {
        return c;
      }
    });
  }

  formatDate(date: Date) {
    return moment
      .utc(date)
      .locale("en-us")
      .local()
      .format("lll");
  }

  getPromotion(coupon: any) {
    return this.promotions.find((promotion: any) => {
      return Number(promotion.id) === Number(coupon.relationships.promotion.data.id);
    });
  }
}

import '@/polyfills'
import Notifications from '@/components/NotificationPlugin'
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from '@/components/SidebarPlugin'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/assets/sass/argon.scss'
import '@/assets/css/nucleo/css/nucleo.css'
import { extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import Translations from './translations'
locale.use(lang)

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  })
})
export default {
  install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    Vue.use(Notifications)
    Vue.use(BootstrapVue)
    Vue.use(IconsPlugin)
    Vue.use(Translations)
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty']
      }
    })
  }
}

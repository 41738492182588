















































import {translations} from '@/mixins'
import {mapGetters} from 'vuex'
import {Select, Option} from "element-ui";

import {
  ValidationProvider,
} from "vee-validate";
import {Component, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapGetters({
      getConceptSettings: "account/getConceptSettings",
      getUser: 'account/getUser',
      getUserClient: 'account/getUserClient'

    }),

  },
  mixins: [translations],
})
export default class WebAddressSetModal extends Vue {
  $notify: any;
  getConceptSettings!: any
  getUserClient!: any
  getUser!: any
  $refs!: {
    file: any;
  }
  isLoading: Boolean = false;
  deliveryCharge: any = "";
  deliveryChargeID: any = "";

  @Prop() open!: Boolean
  @Prop() deliveryCharges!: any

  get isOpen(): Boolean {
    return this.open;
  }
  set isOpen(newVal: Boolean) {
    if (!newVal) {
    }
  }

  constructor() {
    super();
  }

  mounted() {
  }

  returnValueCharge(e: any) {
    this.deliveryChargeID = e.id;
    return e.value;
  }



  nextToLogo() {
       this.$emit("modal:close", false);
       this.$emit("modal:openLogoCharge", this.deliveryChargeID);
  }


  

}

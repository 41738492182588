




















































import {Component, Vue, Prop} from "vue-property-decorator"
import { Customer } from '@/services/SOLO'
import moment from 'moment'
import { Table, TableColumn, Button } from "element-ui"
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
mixins: [translations],
})
export default class Events extends Vue {
  @Prop() logs!: any;
  @Prop() loading!: any
   mounted() {
  }


  filterTag(value: any, row: any) {
      return row.attributes['level'] === value;
   }
  capitalizeFirstLetter(str: any) {
    if (typeof str !== 'string') return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

}

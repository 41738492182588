






















import { Settings, SettingsOrderStatuses } from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { OrderStatus } from "@/models";
import { Pagination } from "@/interfaces/SOLO";
import { translations } from '@/mixins';
import Translations from '../Translations.vue';

interface CustomFieldAttributes {
  name: String;
  type: Boolean;
}

interface CustomField {
  id: String;
  attributes: CustomFieldAttributes;
}

@Component({
  components: {
    OrderStatusesTable: () =>
      import("./components/order-statuses/OrderStatusesTable"),
    LoadingPanel: () => import("@/components/LoadingPanel"),
    BasePagination: () => import("@/components/BasePagination"),
    Translations,
  },
  mixins: [translations],
})
export default class Globals extends Vue {
  translate!: Function;
  itemLimit: number = 100;
  currentPage: number = 1;
  loading: boolean = false;
  orderStatuses: Array<OrderStatus> = [];
  $notify: any;

  mounted() {
    this.getStatuses();
  }

  async getStatuses(showLoading: boolean = true) {
    if (showLoading) {
      this.loading = true;
    }

    try {
      const response = await SettingsOrderStatuses.all();
      this.orderStatuses = response.data.data;
      console.log(this.orderStatuses);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb"
      });
    }
  }
}







import { Order as OrderApi, Integrations, Location } from "@/services/SOLO";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/pt-br";
@Component
export default class buttonAssignDriver extends Vue {
  @Prop() index!: number
  @Prop() orderId!: string
  @Prop() locationId!: string
  @Prop() driverId!: string
  @Prop() driverName!: string
  @Prop() isDisabled!: boolean
  @Prop() deliveryLogistic!: any

  loading: boolean = false

  mounted() {
    console.log('isDisabled: ', this.driverName);
  }

  initAssign() {
    this.getLocationDriver()
  }

  getLocationDriver() {
    let data1 = {
      index: this.index,
      'logistic': this.deliveryLogistic,
      'orderId': this.orderId
    }
    this.loading = true
    Location.fetchDrivers(this.locationId)
      .then(response => {
        console.log(this.driverId)
        const data = {
          drivers: response.data.data, orderId: this.orderId, driverId: this.driverId, index: this.index
        };

        if (data.drivers) {
          data.drivers = data.drivers.filter((driver: any) => (driver.attributes.status === 'active'));
        }

        this.$emit('show:assign', data, data1, this.driverName);
        this.loading = false
      })
  }
}

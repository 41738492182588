


























































































































































































import {translations} from '@/mixins'
import {Popup as PopupApi, Banner as BannerApi} from "@/services/SOLO"
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {mapGetters} from "vuex"
import {Select, Option} from "element-ui"
import {ValidationProvider} from "vee-validate"

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
  mixins: [translations]
})
export default class CreatePopup extends Vue {
  buttonDisableMenu: any = false;
  buttonDisable: any = false
  getLocale!: any
  getConceptSettings!: Function
  // langChecked: Array<string> = []
  arrayLang: Array<string> = []
  imageType: any = []
  imageTypes: Array<Object> = [
    {value: 0, text: "Upload IMG",},
    {value: 1, text: "Image URL",},
  ]
  buttonType: string = ''
  buttonTypes: Array<Object> = [
    {value: "product", text: "Product"},
    {value: "category", text: "Category"},
    {value: "url", text: "URL"},
  ]
  buttonMenu: string = ''
  buttonMenus: any = []
  buttonItem: string = ''
  buttonItems: any = []
  label: string = ''
  imageUri: any = {}
  button: any = {}
  buttonUri: string = ''
  idle_seconds: any = ''
  $notify: any
  fileValue: any = {}
  // $refs!: {
  //   file: any;
  // }
  saving: Boolean = false

  mounted() {
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language'])
    /* @ts-ignore */
    this.imageType[this.getConceptSettings.attributes['primary-language']] = 0
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['secondary-language'])
    /* @ts-ignore */
    this.imageType[this.getConceptSettings.attributes['secondary-language']] = 0

    this.getMenus()
  }

  // updateLangChecked(lang: any, e: any) {
  //   if (!e) {
  //     const index = this.langChecked.indexOf(lang)
  //     if (index > -1) {
  //       this.langChecked.splice(index, 1)
  //     }
  //   } else {
  //     this.langChecked.push(lang)
  //   }
  // }

  getMenus() {
    BannerApi.menus().then((response: any) => {
      this.buttonMenus = response.data.data;
    });
  }

  updateButtonType(e: any) {

    if (e == 'product') {
      this.buttonDisableMenu = false;
      this.buttonDisable = true;
      if (this.buttonMenu) {
        this.buttonDisable = false;
        BannerApi.linkValueSearch(this.buttonMenu, 'items', 1)
        .then((response) => (this.buttonItems = response.data.data))
      }
    }
    if (e == 'category') {
      BannerApi.linkValueSearch(this.buttonMenu, 'categories', 1)
        .then((response) => (this.buttonItems = response.data.data))
    }
  }

  changeButtonMenu(type: any) {
    this.updateButtonType(type)
  }

  async createPopup() {
    this.saving = true
    let formData = new FormData()

    formData.append(
      `popup[label]`,
      this.label || ''
    )
    formData.append(`popup[idle-seconds]`, this.idle_seconds || '')

    formData.append(
      `popup[action-menu-id]`, this.buttonMenu
    )
    
    for (let i in this.arrayLang) {
      if (this.imageUri[this.arrayLang[i]] || this.fileValue[this.arrayLang[i]]) {
        if (typeof this.imageUri[this.arrayLang[i]] == 'string') {
          formData.append(
            `popup[image-uri][${this.arrayLang[i]}]`,
            this.imageUri[this.arrayLang[i]]
          )
        } else {
          formData.append(
            `popup[image-uri][${this.arrayLang[i]}]`,
            this.fileValue[this.arrayLang[i]]
          )
        }
      }

      if (this.button[this.arrayLang[i]]) {
        formData.append(
          `popup[button-label][${this.arrayLang[i]}]`,
          this.button[this.arrayLang[i]] || ''
        )
      }
    }

    // if (this.button) {
    //   formData.append(
    //     `popup[button-label]`,
    //     this.button || ''
    //   )
    // }
    if (this.buttonType) {
      formData.append(
        `popup[action-type]`,
        this.buttonType || ''
      )
    }
    if (this.buttonItem) {
      formData.append(
        `popup[action-value]`,
        this.buttonItem || ''
      )
    }

    if (this.buttonUri) {
      formData.append(
        `popup[url]`,
        this.buttonUri
      )
    }

    await PopupApi.create(formData)
      .then((response) => {
        this.saving = false
        this.showNotifyStatus(true)
        this.$router.back()
      })
      .catch((err) => {
        this.saving = false
        this.showNotifyStatus(false)
      })
  }

  showNotifyStatus(status: Boolean) {
    if (status) {
      this.$notify({
        title: "POPUP SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Popup created successfully.",
        type: "success",
        icon: "fas fa-check",
      });
    } else {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  checkSize(text: any) {
    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }
  }

  cancelCreate() {
    this.$router.go(-1);
  }
}

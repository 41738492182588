





























import { Component, Prop, Vue } from "vue-property-decorator";
import { translations } from "@/mixins";

@Component({
  mixins: [translations]
})
export default class CouponGroupListview extends Vue {
  @Prop() couponGroups!: Array<any>;

  onEditGroup(coupon: any) {
    this.$emit('on-edit-coupon-group', coupon)
  }

  onDeleteGroup(coupon: any) {
    this.$emit('on-delete-coupon-group', coupon)
  }
}




































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
mixins: [translations]
})
export default class CustomerDetails extends Vue {
  @Prop() isLoaded!: Boolean
  @Prop() order!: Object
  @Prop() customerAddress!: any
  public translate!: Function
  @Watch('customerAddress', {immediate: true, deep: true})
  onChange() {
    console.log('customerAddress', this.customerAddress)
  }

  getFirstName(customerName: string) {
    return customerName ? customerName: 'N/A';
  }
}

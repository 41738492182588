





























































































import {Customers} from "@/models";
import {Pagination} from "element-ui";
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {Customer} from '@/services/SOLO'
import { translations } from '@/mixins'
import Translations from './Translations.vue'

@Component({
  components: {
    [Pagination.name]: Pagination,
  },
mixins: [translations],
})
export default class CustomerCollapse extends Vue {
  @Prop() customerMeta!: Array<Customers>;
  public translate!: Function
  filterDate: string = "";
  name: string = "";
  email: string = "";
  mobile: string = "";
  nextPage: number = 0;
  hasMore: boolean = false;
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  totalPage: number = 0;
  isLoaded: boolean = false;
  customers: Array<any> = [];

  @Watch("customerMeta", { deep: true })
  oncustomerMetaChanged(newVal: any) {
    this.isLoaded = true;
    this.total = newVal.total;
    this.currentPage = newVal.current_page;
    this.perPage = newVal.per_page;
  }
  // filteredcustomers() {
  //   this.$emit("findcustomers", this.search);
  // }
  pageChange(data: any) {
    this.$emit("getCustomers", data);
  }

  searchFeedback(name: string, email: string, mobile: string, date: string) {
    this.$emit("searchCustomers", name, email, mobile, date);
  }

  resetForm(data: any) {
      this.name = "";
      this.email = "";
      this.mobile = "";
      this.$emit("getCustomers", data);
  }

}

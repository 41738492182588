


















































































import Modal from "@/components/Modal.vue";
import {PageAttributes} from "@/models";
import {Page as PageApi} from "@/services/SOLO";
import {translations} from '@/mixins'
import {Select, Option} from "element-ui";
import {
  ValidationProvider,
} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";
interface objKey {
  [x: string]: string;
}
@Component({
  components: {
    ValidationProvider,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [translations],
})
export default class EditPageModal extends Vue {
  page: PageAttributes = {
    label: '',
    url: '',
    title: {},
    type: ''
  }
  pageTitle: objKey = {}
  translate!: any
  $notify: any;
  arrayLang: Array<string> = [];
  Types: any = [
    {text: 'Page', value: 'page'},
    {text: 'Link', value: 'link'}
  ]
  @Prop() open!: Boolean
  @Prop() editId!: Boolean
  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("openEditPage", newVal);
  }

  set curreEditId(newVal: any) {
    this.editId = newVal;
  }

  @Watch("editId", { immediate: true, deep: true })
  currEditId(val: any) {
    if (val) {
      this.editId = val;
      this.findPage(val);
    }
  }

  constructor() {
    super();
  }

  mounted() {
            /* @ts-ignore */
    if(this.getConceptSettings.attributes['primary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    }

    /* @ts-ignore */
    if(this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
  }

  async findPage(id: any) {
    await PageApi.find(id)
      .then((response: any) => {
        this.page = {
          label: response.data.data.attributes.label,
          url: response.data.data.attributes.url,
          title: response.data.data.attributes.title,
          type: response.data.data.attributes.type
        }
      });
  }

  close() {
    this.$emit("closeEditPage", false);
  }

  get pageData(): any {
        for (let i in this.arrayLang) {
          this.pageTitle[this.arrayLang[i]] = this.page.title[this.arrayLang[i]]
        }  
        return {
          title: this.pageTitle,
          label: this.page.label,
          type: this.page["type"],
          url: this.page["url"]
        };
    }

  updatePage(e: any, reset: any) {
    let defaultText = e.target.innerHTML;
    e.target.innerHTML =
        '<i class="fas fa-spinner fa-spin"></i> Updating Page';
    e.target.disabled = true;
    PageApi.update(this.pageData, this.editId)
      .then((response) => {
        this.$notify({
          title: "PAGE UPDATED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Page has been updated successfully",
          type: "success",
          icon: "fas fa-check",
        });
        this.page.label = "";
        this.page.url = "";
        e.target.innerHTML = defaultText;
        e.target.disabled = false;
        this.$emit("updatePage", response.data.data);
        this.$emit("openEditPage", false);
        reset();
      })
      .catch((err) => {
        e.target.innerHTML = defaultText;
        e.target.disabled = false;
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}

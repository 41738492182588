












































import moment from 'moment'
import { Customers } from "@/models";
import { Table, TableColumn, Button } from "element-ui"
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
mixins: [translations],
})
export default class CustomerList extends Vue {
  @Prop() list!: Array<Customers>
  @Prop() loading: boolean = false
  public translate!: Function
  color: string = 'white'
  currentPage: number = 1
  customer: Customers = {
    id: 0,
    attributes: {
      code: '',
      'first-name': '',
      'last-name': '',
      email: '',
      mobile: '',
      status: '',
      'account-type': '',
      provider: 0,
      'last-order-id': 0,
      'last-order-type': '',
      'last-order-location': 0,
      'last-order-location-name': '',
      pincode: 0,
    }
  }

  @Watch('loading', {deep: true})
  onLoadingChanged(newVal: any) {
    this.loading = newVal
  }
  formatDate(date: string) {
    return moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .local()
      .format("MMM D YYYY hh:mm A");
  }


}

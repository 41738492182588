



































































































































































































































import moment from 'moment'
import KiosksFormModal from "./KioskFormModal.vue";
import CustomerUpdateModal from './CustomerUpdateModal'
import Events from "./Events.vue"
import CustomerAddress from './CustomerAddress'
import CustomerOrders from './CustomerOrders'
import CustomerLoyalties from './CustomerLoyalties'
import {Table, TableColumn, Button, Form, FormItem, Select, Option} from 'element-ui'
import {Component, Prop, Watch, Vue} from "vue-property-decorator"
import { Customer } from '@/services/SOLO'
import { translations } from '@/mixins'
import { Loyalty as LoyaltyService,  Device as DeviceService, Location as LocationService, } from '@/services/SOLO'
import { Loyalty as LoyaltyModel } from "@/models"
import { Device as DeviceModel, Location as LocationModel } from "@/models";


import {mapGetters} from "vuex";
  import { Connect as ConnectService } from "@/services/SOLO";

interface CustomerDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string,
  'last-order-id'? : number
}

@Component({
  components: {
    CustomerAddress,
    CustomerOrders,
    CustomerUpdateModal,
    CustomerLoyalties,
    Events,
    KiosksFormModal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  filters: {
      standardDateFormat(value: any)  {
        if (! value) return '---';
        return moment(value).format('DD/MM/YYYY');
      }
  },
  mixins: [translations],
})
export default class CustomerSingle extends Vue {
  loading: any = false
  openEdit: boolean = false
  devices: Array<DeviceModel> = [];
  locations: Array<LocationModel> = [];
  id: any
  tab: number = 1
  singleCustomer: CustomerDetails = {}
  customerAddress: any = {}
  customerOrders: any = {}
  loyalties: Array<LoyaltyModel> = []
  page: number = 1
  public translate!: Function
  customerLoyalty: any = {}
  logs: any = []
  dvces: any = []
  locPage: any = 1
  $refs!: {
      kiosksFormModal: KiosksFormModal;
    };
  item: any = []
  mounted() {
    let customerId = this.$route.params.id
    this.getSingleCustomer(customerId)
    this.getCustomerAddress(customerId)
    this.getCustomerOrders(customerId)
    this.getCustomerLoyalty(customerId)
    this.getLogs();
    this.getDevices(true);
    this.getLocations(this.locPage);
    this.getCustomerLoyaltyPoints(customerId)
  }
  onModalClose() {
      // do not perform on first load of KiosksFormModal component
      if (this.$refs.kiosksFormModal) {
        // this.$refs.kiosksFormModal.model = { ...kioskDefaultValue };
      }
    }
  async getDevices(showLoading: boolean = true) {
      if (showLoading) {
        this.loading = true;
  
      }
  
      try {
        const response = await DeviceService.all();
        this.devices = response.data.data;
        console.log("devices", response.data);
        this.loading = false;
  
      } catch (error) {
        this.loading = false;
        // this.systemErrorNotification();
      }
    }
  
    async getLocations(page: any) {
      let lang = 'en-us'
  
      try {
        const response = await LocationService.fetchLocationsActive(lang, page);
  
        if (response?.data?.data && Array.isArray(response?.data?.data)) {
              this.locations = [...this.locations, ...response?.data?.data];
              this.locations = this.locations.sort((a: any, b: any) => (a.attributes.name > b.attributes.name) ? 1 : -1)
  
            }
            if (
                response.data.meta.pagination.current_page <
                response.data.meta.pagination.total_pages
            ) {
              this.locPage = response.data.meta.pagination.current_page + 1;
  
              this.getLocations(response.data.meta.pagination.current_page + 1);
            }
            this.$forceUpdate();
  
      } catch (error) {
        // this.systemErrorNotification();
      }
    }

    private editItem(idd: any) {
      let temp = this.devices;
      this.item = temp.find(item => item.id === idd.toString());
      if(this.item) {
        console.log('check kiosk', this.item);
        this.$refs.kiosksFormModal.model = {
        id: this.item.id.toString(),
        "location-id": this.item.attributes["location-id"]?.toString(),
        "customer-id": this.item.attributes["customer-id"]?.toString(),
        label: this.item.attributes.label,
        uuid: this.item.attributes['device-uuid'],
        "ip-address": this.item.attributes["ip-address"],
        "terminal-id": this.item.attributes["terminal-id"],
        "is-settings-password-protected": this.item.attributes['is-settings-password-protected'],
        "payment-gateway": this.item.attributes["payment-gateway"],
        "type": this.item.attributes["type"],
        "port": this.item.attributes["port"],
        "printer-settings": {
          'printer-manufacturer': this.item.attributes["printer-settings"]?.["printer-manufacturer"],
          'receipt-mode': this.item.attributes["printer-settings"]?.["receipt-mode"],
          'ip-address': this.item.attributes["printer-settings"]?.["ip-address"],
          'printer-name': this.item.attributes["printer-settings"]?.["printer-name"],
        },
        'payment-settings': {
          'terminal-poiid': this.item.attributes["payment-settings"]?.["terminal-poiid"],
          'key-version': this.item.attributes["payment-settings"]?.["key-version"],
          'key-identification': this.item.attributes["payment-settings"]?.["key-identification"],
          'key-passphrase': this.item.attributes["payment-settings"]?.["key-passphrase"],
        }
      };
      this.$refs.kiosksFormModal.open = true;
      }
      
    }
  handleEdit() {
    this.openEdit = true
  }

  handleClose(value: any) {
    this.openEdit = value
  }

  async getLogs() {
    this.loading = true;
    await Customer.getCustomerLogs(this.$route.params.id)
      .then((response: any) => {

         let e = response.data.data;

         this.logs = e.sort((a: any, b: any) => {
            return new Date(b.attributes['created-at']).getTime() - new Date(a.attributes['created-at']).getTime();
         });
         this.loading = false;
        console.log("check logs", response.data.data);
      })
  }

  async getCustomerLoyalty(customerId: any) {
    try {
      const response = await Customer.getCustomerLoyalty(customerId)
      this.customerLoyalty = response.data.data.attributes
    } catch (error) {
      console.error('Error in getting Customer Loyalty', error)
    }
  }

  async getCustomerLoyaltyPoints(customerId: any) {
    try {
      const response = await Customer.getCustomerLoyaltyPoints(customerId)
      this.loyalties = response.data.data
    } catch (error) {
      console.error('Error in getting Customer Loyalty Points', error)
    }
  }

  async getSingleCustomer(id: any) {
    try {
      const response = await Customer.find(id)
      this.singleCustomer = response.data.data.attributes
      // const { mobile } = this.singleCustomer
      // if (mobile) {
      //   const getLoyaltiesCustomer = await LoyaltyService.search('', mobile + '' , '', '', '', '')
      //   this.loyalties = getLoyaltiesCustomer.data.data
      // } else {
      //   this.loyalties = []
      // }
    } catch (error) {
      console.error('Error in getting Customer', error)
    }
  }

  async getCustomerOrders(id: any) {
    try {
      const response = await Customer.getOrders(id)
      this.customerOrders = response.data.data
    } catch (error) {
      console.error('Error in getting Customer Orders', error)  
    }
  }

  async getCustomerAddress(id: any) {
    try {
      const response = await Customer.getAddresses(id)
      this.customerAddress = response.data.data
    } catch (error) {
      console.error('Error in getting Customer Address', error)
    }
  }

  get dropDownText(): any {
     if (this.tab === 1) {
       return this.translate('Addresses')
     } else {
       return this.translate('Orders')
     }                   
  }
}

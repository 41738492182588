















































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  methods: {
    ...mapMutations({
      setLocale: 'app/setLocale',
    }),
  },
})
export default class LangSwitcher extends Vue {
  private setLocale!: Function
  private getLocale!: string;
  public lang: string = 'en_US';

  created(): void {
    this.lang = this.getLocale ? this.getLocale : this.lang;
  }

  public onSwitchLang(lang: string) {
    this.lang = lang;
    this.setLocale(lang);
    window.location.reload();
  }
}

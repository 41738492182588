
































































































































































































import flatPicker from "vue-flatpickr-component";
import { Component, PropSync, Mixins, Watch } from "vue-property-decorator";
import { Table, TableColumn, Button, Select, Option } from "element-ui";
import {
  Connect as ConnectService,
  Loyalty as LoyaltyService,
  Location as LocationSerivce,
} from "@/services/SOLO";
import OrderDetailsModal from "./OrderDetailsModal.vue";
import Translations from "@/mixins/translations";

@Component<ConnectOrders>({
  components: {
    OrderDetailsModal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker,
    // TierSingleModal
  },
})
export default class ConnectOrders extends Mixins(Translations) {
  @PropSync("showCreateModal", {
    type: Boolean,
    required: true,
    default: false,
  })
  syncedShowCreateModal!: boolean;
  color: string = 'white'

  visible: boolean = false;
  flag: boolean = true;
  loading: boolean = false;
  tiers: any[] = [];
  connections: any[] = [];
  orders: any[] = [];
  exporting: any = {};
  locations: any[] = [];

  showUpdateModal: boolean = false;
  selectedTier: any = null;
  selectedConnectionId: any = null;

  orderDetails: any = null;

  filter: any = {
    today: null,
    branch: null,
    connectionId: null,
  };

  async mounted() {
    this.getConnections();
    this.getLocations();
  }

  async getConnections() {
    try {
      this.loading = true;
      let { data } = await ConnectService.getConnections();
      this.connections = data.data;
      if (this.connections.length) {
        this.filter.connectionId = data.data[0].id;
        this.getOrders();
      }
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  async getOrders() {
    this.loading = true;

    console.log(this.filter.connectionId);

    let params: any = {};

    if (this.filter.branch) {
      params.branch_id = this.filter.branch;
    }

    if (this.filter.connectionId) {
      let { data } = await ConnectService.getOrdersByConnection(
        this.filter.connectionId,
        params
      );

      this.orders = data.data;
    }

    this.loading = false;
  }

  viewAggregatorOrder(row: any) {
    this.orderDetails = row.aggregator_order;
    this.syncedShowCreateModal = true;
  }

  viewConnectOrder(row: any) {
    this.orderDetails = row.connect_order;
    this.syncedShowCreateModal = true;
  }

  viewOrderResponse(row: any) {
    this.orderDetails = row.order_response;
    this.syncedShowCreateModal = true;
  }

  handleRowClick(row: any) {
    this.selectedTier = row;
    this.syncedShowCreateModal = true;
  }

  updateTier(tier: any) {
    this.selectedTier = tier;
  }

  addTier(tier: any) {
    this.tiers.push(tier);
  }

  async exportCustomers(row: any) {
    // this.exporting[row.id] = true;
    this.$set(this.exporting, row.id, true);

    try {
      let { data } = await LoyaltyService.exportTierCustomers(row.id);

      window.open(data.data.attributes["csv-uri"], "_blank");
    } catch (e) {
      console.log(e);
    } finally {
      this.$set(this.exporting, row.id, false);
      // this.exporting[row.id] = false;
    }
  }

  showFilters() {
    this.flag = false;
    this.visible = true;
  }

  hideFilters() {
    this.flag = true;
    this.visible = true;
  }

  applyFilters() {
    this.getOrders();
  }

  resetFilters() {
    this.filter = {
      today: null,
      branch: null,
      connectionId: null,
    };
    this.getOrders()
  }

  connectionLabel(connection: any): string {
    return `${connection.attributes.aggregator.name} - ${connection.attributes.menu.label}`;
  }

  async getLocations() {
    let { data } = await LocationSerivce.getAllLocations();
    this.locations = data.data.map((item: any) => {
      return {
        value: item.id,
        text: item.attributes.name,
      };
    });

    console.log(this.locations);
  }

  disabled(check: any) {
    if (check === true) {
      return "pointer-events: none";
    }
    return "pointer-events: auto";
  }

  @Watch("syncedShowCreateModal")
  onSyncedShowCreateModalChange(newVal: boolean) {
    if (!newVal) {
      this.orderDetails = null;
    }
  }
}

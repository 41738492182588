
















































































































































































































import AddCustomFieldModal from './AddCustomFieldModal.vue'
import EditModgroupItem from './EditModgroupItem.vue'
import { Settings } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler, translations } from '@/mixins'
import VueRouter from 'vue-router';
import menuRoles from '@/directives/menuRoles';
import draggable from "vuedraggable";
import { Menu } from "@/services/SOLO";
import { mapGetters } from 'vuex';
import EnableDisableModifierGroupModal from './EnableDisableModifierGroupModal';
@Component({
  components: {
    EditModgroupItem,
    AddCustomFieldModal,
    draggable,
    EnableDisableModifierGroupModal
  },
  directives: {
    menuRoles
  },
  mixins: [eventHandler, translations],
  computed: {
    ...mapGetters({
      getMenus: 'menu/getMenus',
      getLocale: 'app/getLocale',
      userRole: 'account/getUserRole',
    })
  },
})
export default class ModGroup extends Vue {
  getLocale!: any;
  private open: Boolean = false
  private openCustomField: Boolean = false
  private modifierGroupId: string = ''
  private customFields: Array<any> = []
  private selectedFields: Array<any> = []
  private itemId: string = ''
  private customFieldId: string = ''
  private customFieldsValue: string = ''
  @Prop() items!: any
  @Prop() currentPage!: any
  @Prop() perPage!: any

  btnLoading!: Function
  btnRestore!: Function
  confirm!: Function
  $notify: any
  $refs!: {
    itemModal: any;
    file: any;
  }
  translate!: Function;
  userRole!: any;
  isEnableDisableOpen: Boolean = false;
  showGripIconItem: any = null;
  uploadImgItemId: any = 0;
  indexImgItemId: any = 0;
  enableMsgBoxText(enabled: number) {
    return enabled ? 'Proceed enabling this item?' : 'Proceed disabling this item?'
  }

  onHoverCard(ind: any){
    this.showGripIconItem = ind
  }

  uploadImage(itemId: any, index: any) {
    this.uploadImgItemId = itemId;
    this.indexImgItemId = index;
    /* @ts-ignore */
    document.getElementById('upload').click();
  }

  filesUploadChange() {
    let formData = new FormData();
    formData.append('image', this.$refs.file.files[0]);
    formData.append(`_method`, 'PATCH');
    Menu.updateItemModifierGroup(formData, this.$route.params.itemId, this.uploadImgItemId).then((response: any) => {
      this.items[this.indexImgItemId].attributes['image-uri'] = response.data.data.attributes['image-uri'];
      this.$notify({
        title: "UPDATES SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Image have been upload successfully",
        type: "success",
        icon: "fas fa-check",
      });
    })
    .catch((err: any) => {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    });
  }

  sortItems() {
    const promiseArr: any = [];
    let dynamicIndex = ((this.currentPage - 1) * this.perPage) + 1;
    this.items.forEach((item: any) => {
      let form = new FormData();
      form.append(`_method`, 'PATCH');
      form.append(`display-order`, dynamicIndex.toString());
      form.append(`enabled`, item.attributes.enabled.toString());
      Menu.updateItemModifierGroup(form, this.$route.params.itemId, item.id);
      dynamicIndex += 1;
    });
  }

  get deleteMsgBoxText(): String {
    return 'Are you sure you want to delete this item?'
  }

  private refreshCustomFields() {
    this.$refs.itemModal.refreshSelectedCustomFields()
  }

  private showCustomFieldModal(customFields: any, selectedFields: any, itemId: string, customFieldId: string = '', value: string = '') {
    this.customFields = customFields
    this.selectedFields = selectedFields
    this.itemId = itemId
    this.customFieldId = customFieldId
    this.customFieldsValue = value
    this.updateCustomFieldModal(true)
  }

  private updateCustomFieldModal(open: Boolean) {
    this.openCustomField = open
  }

  updateModgroupItem(open: Boolean = true, id: string = '') {
    this.open = open
    this.modifierGroupId = id
  }

  checkScreenWith() {
    if(window.screen.width == 1366) {
      return 3;
    }
      return 2;
  }

  remove(id: string, enabled: number) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {
          Settings.deleteItemModifier(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
          .then((response: any) => {
            this.$emit('modifier:update', id, 'remove')
            this.$notify({
              title: "DATA DELETED",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: 'Modifier successfully deleted',
              type: "success",
              icon: "fas fa-trash",
            })
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          })
        }
      })
  }

  enable(id: string, enabled: number) {

    Settings.enableItemModifier({enabled: enabled ? 1 : 0}, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
    .then((response: any) => {
      this.$emit('modifier:update', id, 'enableDisable', enabled)
      this.$notify({
        title: "DATA UPDATED",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: enabled ? 'Modifier Group Enabled!' : 'Modifier Group Disabled',
        type: "success",
        icon: "fas fa-check",
      })
    })
    .catch((err: any) => {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    })
    // this.confirm(this.$bvModal, this.enableMsgBoxText(enabled))
    //   .then((value: boolean) => {
    //     if(value) {
    //       Settings.enableItemModifier({enabled: enabled ? 1 : 0}, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
    //       .then((response: any) => {
    //         this.$emit('modifier:update')
    //         this.$notify({
    //           title: "DATA SAVED",
    //           verticalAlign: "bottom",
    //           horizontalAlign: "left",
    //           message: enabled ? 'Modifier enabled!' : 'Modifier disabled',
    //           type: "success",
    //           icon: "fas fa-check",
    //         })
    //       })
    //       .catch((err: any) => {
    //         this.$notify({
    //           title: "SYSTEM ERROR!",
    //           verticalAlign: "bottom",
    //           horizontalAlign: "left",
    //           message: "Something went wrong, please try again!",
    //           type: "danger",
    //           icon: "fas fa-bomb",
    //         });
    //       })
    //     }
    //   })

  }

  enableNonAdminModGroup(id: string, enabled: number) {
    let payload = {
      'schedule-disabled-hours': '__enabled__',
    }
    Settings.enableItemModifier(payload, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
    .then((response: any) => {
      this.$emit('modifier:update', id, 'enableNonAdminModGroup', true)
      this.$notify({
        title: "DATA UPDATED",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: 'Modifier Group Enabled!',
        type: "success",
        icon: "fas fa-check",
      })
    })
    .catch((err: any) => {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    });
  }

  enableDisableModal(open: any, itemId: string) {
    this.isEnableDisableOpen = !!open;
    this.itemId = itemId;
    
    if (!open) {
      this.items.forEach((element: any) => {
        if (element.id == itemId) {
          element.attributes['is-enabled-for-employee'] = open;
        }
      });
    }
  }

  closeModal(close: any) {
    this.isEnableDisableOpen = !!close;
  }
}
























































































import Modal from "@/components/Modal.vue";
import { Order as OrderApi } from '@/services/SOLO';
import {translations} from '@/mixins';
import { Option, Select } from 'element-ui';
import {
  ValidationProvider,
} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [translations],
})
export default class NewPageModal extends Vue {
  reasonType: string = '';
  reasons: any = [];
  customReason: any = null;
  $notify: any;

  @Prop() orderId!: any
  @Prop() open!: Boolean
  @Prop() selectedCode!: any
  
  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("cancelByEmployeeModal", newVal);
  }

  mounted() {
    this.reasons = [
      {
        value: "Item not available",
        text: "Item not available"
      },
      {
        value: "Requested by customer",
        text: "Requested by customer"
      },
      {
        value: "custom",
        text: "Custom"
      }
    ];
  }

  close() {
    this.$emit("closeModal", false);
  }

  confirmReason() {
    let reason = '';

    if (this.reasonType != 'custom') {
      reason = this.reasonType;
    } else {
      reason = this.customReason;
    }

    let payload = {
      reason: reason,
      'order-status': this.selectedCode,
    };

    OrderApi.updateStatus(payload, this.orderId)
      .then((response: any) => {
        this.$notify({
          title: "STATUS CHANGED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Status have been successfully changed",
          type: "success",
          icon: "fas fa-check",
        });
        this.reasonType = "";
        this.customReason = null;
        this.$emit("cancelByEmployeeModal", false, this.selectedCode);
      })
      .catch((err: any) => {
        const message = JSON.parse(err?.response?.data?.messages)?.errors?.status[0]
          || JSON.parse(err?.response?.data?.messages)?.message
          || 'Something went wrong, please try again!';
        console.log('err:' , JSON.parse(err?.response?.data?.messages));
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message,
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}

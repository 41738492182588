











































































































































import _ from "lodash";
import {
  Component,
  Watch,
  Prop,
  PropSync,
  Mixins
} from "vue-property-decorator";
import { Form, Select, Option } from "element-ui";
import Translations from "@/mixins/translations";
import {
  Connect as ConnectService,
  Location as LocationService
} from "@/services/SOLO";
import { Location as LocationModel, Item as ItemModel } from "@/models";
import { AxiosError } from "axios";
import { debounce } from "vue-debounce";

@Component<ConnectionModal>({
  components: {
    [Form.name]: Form,
    [Select.name]: Select,
    [Option.name]: Option
  }
})
export default class ConnectionModal extends Mixins(Translations) {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean;
  @PropSync("connection", { type: Object, default: null })
  syncedConnection!: any;
  @Prop({ type: Array, required: true }) readonly aggregators!: any[];
  @Prop({ type: Array, required: true }) readonly menus!: any[];

  form: any = {
    "aggregator-id": null,
    "menu-id": null,
    options: {} as any,
    "excluded-locations": [] as any[],
    "is-production": true
  };

  locations: LocationModel[] = [];
  selectedLocations: any[] = [];
  items: ItemModel[] = [];
  selectedItems: any[] = [];

  async getConnection() {
    let { data } = await ConnectService.getConnection(
      this.syncedConnection.id,
      {
        include: "excluded-locations,excluded-items"
      }
    );
    let locations: any[] = data.data.relationships["excluded-locations"].data;
    let items: any[] = data.data.relationships["excluded-items"].data;

    this.selectedLocations = locations.map(v => v.id.toString());
    this.selectedItems = items.map(v => v.id.toString());
  }

  async onSave() {
    if (this.syncedConnection) {
      await this.updateConnection();
    } else {
      await this.createConnection();
    }

    this.closeModal();
    this.resetForm();
  }

  async createConnection() {
    try {
      const menuId = this.form["menu-id"];
      const aggregatorId = this.form["aggregator-id"];
      const isProduction: boolean = this.form["is-production"];
      const options = this.form.options;

      let { data } = await ConnectService.createConnection(
        menuId,
        aggregatorId,
        options,
        isProduction
      );

      this.$emit("add:connection", data.data);

      this.$notify({
        title: `CREATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Connection was created successfully!`,
        type: "success"
      } as any);
    } catch (e) {
      console.log(e);
      this.$notify({
        title: `UPDATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: (e as any).response.data.error[0].detail,
        type: "danger"
      } as any);
    }
  }

  async updateConnection() {
    const updateData: any = {};

    for (let key in this.form) {
      if (
        key == "options" &&
        JSON.stringify(this.form[key]) ==
          JSON.stringify(this.syncedConnection[key])
      ) {
        continue;
      }

      if (this.form[key] == this.syncedConnection[key]) {
        continue;
      }

      switch (key) {
        case "menu-id":
          updateData["menu-id"] = parseInt(this.form["menu-id"]);
          break;
        default:
          updateData[key] = this.form[key];
          break;
      }
    }

    if (_.isEmpty(updateData)) {
      return;
    }

    updateData["excluded-locations"] = this.selectedLocations.map(v =>
      parseInt(v)
    );

    try {
      let { data } = await ConnectService.updateConnection(
        this.syncedConnection.id,
        updateData
      );

      for (let key in updateData) {
        if (typeof this.syncedConnection.attributes[key] != "undefined") {
          if (key == "options") {
            for (let optionKey in updateData[key]) {
              this.$set(
                this.syncedConnection.attributes.options,
                optionKey,
                updateData.options[optionKey]
              );
            }

            continue;
          }
          this.syncedConnection.attributes[key] = updateData[key];
        }
      }

      this.$notify({
        title: `UPDATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Connection data was updated successfully!`,
        type: "success"
      } as any);
    } catch (e) {
      console.log(e);

      this.$notify({
        title: `UPDATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: (e as any).response.data.error[0].detail,
        type: "danger"
      } as any);
    }
  }

  resetForm() {
    this.form = {
      "aggregator-id": null,
      "menu-id": null,
      options: {} as any,
      "excluded-locations": [] as any[],
      "is-production": true
    };
  }

  async syncMenu() {
    try {
      await ConnectService.syncMenu(this.syncedConnection.id);

      this.$notify({
        title: this.translate("MENU SYNCED!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Menu successfully synced!"),
        type: "success",
        icon: "fas fa-check",
      } as any);
    } catch (e) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      } as any);
    }
  }

  async syncBranches() {
    try {
      await ConnectService.syncBranches(this.syncedConnection.id);

      this.$notify({
        title: this.translate("BRANCHES SYNCED!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Menu successfully synced!"),
        type: "success",
        icon: "fas fa-check",
      } as any);
    } catch (e) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      } as any);
    }
  }

  async getLocations() {
    try {
      let { data } = await LocationService.fetchLocations();
      this.locations = data.data;
    } catch (e) {
      console.log(e);
    }
  }

  get selectedAggregator() {
    if (!this.form["aggregator-id"]) {
      return null;
    }

    return this.aggregators.find(agg => agg.id == this.form["aggregator-id"]);
  }

  get availableOptions(): any[] {
    if (!this.selectedAggregator) {
      return [];
    }

    return this.selectedAggregator.attributes["available-options"] || [];
  }

  @Watch("selectedAggregator")
  onselectedAggregatorChange(newVal: any, oldVal: any) {
    if (newVal && !this.syncedConnection) {
      this.form.options = {};
      this.availableOptions.forEach((option: any, i: any) =>
        this.$set(this.form.options, option.key, null)
      );
    }
  }

  closeModal() {
    this.isOpen = false;
  }

  get isOpen() {
    return this.value;
  }

  set isOpen(value) {
    this.$emit("input", value);
  }

  get disableForm() {
    return false;
  }

  @Watch("syncedConnection")
  onsyncedConnectionChange(newVal?: any) {
    if (newVal) {
      this.form["aggregator-id"] = newVal.attributes[
        "aggregator-id"
      ].toString();
      this.form["menu-id"] = newVal.attributes["menu-id"].toString();
      this.form["is-production"] = newVal.attributes["is-production"];

      for (let key in newVal.attributes.options) {
        this.$set(this.form.options, key, newVal.attributes.options[key]);
      }

      this.getLocations();
      this.getConnection();
    } else {
      this.resetForm();
    }
  }
}

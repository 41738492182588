






































































import {translations} from '@/mixins'
import {mapGetters} from 'vuex'
import {Select, Option} from "element-ui";

import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate"

import {Component, Prop, Vue, Watch, Emit,} from "vue-property-decorator";
import { Integrations } from '@/services/SOLO'

@Component({
components: {
ValidationProvider,
[Select.name]: Select,
[Option.name]: Option,
},
computed: {
...mapGetters({
  getConceptSettings: "account/getConceptSettings",
  getUser: 'account/getUser',
  getUserClient: 'account/getUserClient'

}),

},
mixins: [translations],
})
export default class foodicsPayModal extends Vue {

    $notify: any;
    getConceptSettings!: any
    getUserClient!: any
    getUser!: any
    loading: any = false
    v_name: any = ""
    v_email: any = ""
    v_mobile: any = ""
    v_checkbox: Boolean = false
    open_terms_flag: any = '';
    @Prop() open!: Boolean
    @Prop() name!: any
    @Prop() email!: any
    @Prop() mobile!: any
    formPayload: any = {}
    payloadForm: any = {}


@Watch('v_checkbox', {deep: true, immediate: true})
  onWatchCheckBoc(data: any) {

    if(data) {
          this.open_terms_flag = false; 
    }

  }


get isOpen(): Boolean {
return this.open;
}
set isOpen(newVal: Boolean) {
if (!newVal) {
}
}

constructor() {
super();
}

mounted() {
    this.v_name = this.name;
    this.v_email = this.email;
    this.v_mobile = this.mobile;

}

closeFoodicsPay() {
    this.$emit("modal:close", false);
}


async nextToLogo() {
    let optionPayload: any = {}
    let payload: any = []
    let set: any = []

   if(!this.v_checkbox) {
    this.open_terms_flag = true;
    return;
   }
    this.formPayload.contact_name = this.v_name
    this.formPayload.email = this.v_email
    this.formPayload.mobile = this.v_mobile
    this.formPayload.has_terms_agreed = this.v_checkbox

   for (let key in this.formPayload) {
      set = {
        'key': key,
        'value': this.formPayload[key]
      }
      payload.push(set)
    }
    optionPayload['type'] = 'payment'
    optionPayload['provider'] = 'foodics-online'
    optionPayload['options'] = payload
    this.payloadForm = optionPayload

    this.loading = true;

    await Integrations.store('foodics-online', this.payloadForm)
        .then((response) => {
          this.$notify({
            title: "Foodics Pay",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Foodics Pay has been successfully installed.",
            type: "success",
            icon: "fas fa-check",
          });
          this.loading = false;
          this.$emit("modal:close", false);
        })
        .catch((err) => {
          this.loading = false;
          if(err.response.data.error[0]?.detail) {
            this.$notify({
              title: "Foodics Pay",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data.error[0]?.detail,
              type: "danger",
              icon: "fas fa-bomb",
            });
          }
          
        })

}




}

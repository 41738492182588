











import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
@Component
export default class SearchItem extends Vue {
  text: String = ''
  @Prop() placeHolder!: String
  @Watch('text', { immediate: true, deep: true })
  locationChange() {
    this.$emit('change', this.text)
  }
}









































import { Table, TableColumn } from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import { translations } from '@/mixins';
import {mapGetters} from 'vuex'

const ConceptLogoProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
})

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
    computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [translations],
})
export default class AppConceptLogo extends ConceptLogoProps {  
  translate!: Function
  logoUri: any = ''
  logoObj: any = {}

  filesUploadChange(event: any) {
    this.logoObj = event.target.files[0]
    this.logoUri = URL.createObjectURL(this.logoObj)
    if (this.logoObj) {
        this.$emit('uploadLogoConcept', this.logoObj)
    }
  }

  created() {
    this.logoUri = this.data['logo-uri']
  }

}

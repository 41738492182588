








































































































































import Modal from '@/components/Modal.vue'
import { Category, Settings } from '@/services/SOLO'
import {Select, Option, Menu, Form} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Category as CategoryModel, CustomField, Menus as MenuModel } from "@/models"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import Translations from '../../../Translations.vue'
import { BForm } from 'bootstrap-vue'
import locationFacilitiesService from '@/services/SOLO/location-facilities.service'

interface animationDuration {
  type: typeof Number
  default: number
  description: string
}

interface modifierGroupName {
  [x: string]: string
}

interface modifierGroup {
  name: modifierGroupName
  'display-order': number | null
  label: string
  minimum: string
  maximum: string
  type: string
  code: string
  enabled: number
  'image-uri': string
}


@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',
    }),
  },

  mixins: [translations, eventHandler]
})
export default class CreateCustomFieldModal extends Vue {
  public translate!: Function
  animationDuration: animationDuration = {
    type: Number,
    default: 500,
    description: "Modal transition duration"
  }
  getConceptSettings: any
  open: boolean = false
  update: boolean = false
  parent: any
  $notify: any
  selectedType: string = ''
  numberOfLoad: number = 0
  formDiscovered: boolean = false
  types: Array<Object> = [
    {
      value: '',
      text: 'Select Type'
    },
    {
      value: 'options',
      text: 'options'
    },
    {
      value: 'sizes',
      text: 'sizes'
    },
    {
      value: 'fries',
      text: 'fries'
    },
    {
      value: 'drinks',
      text: 'drinks'
    }
  ]
  arrayLang: Array<string> = ["en-us", "ar-sa"]
  name: modifierGroupName = {}
  enabled: boolean = false
  modifierGroup: modifierGroup = {
      name: {},
      'display-order': null,
      label: '',
      minimum: '',
      maximum: '',
      type: '',
      code: '',
      enabled: 1,
      'image-uri': ''
  }

  waiting!: Function
  restore!: Function

  $refs!: {
    form: any
    formValidator: any
    inputName: any
  }

  constructor() {
    super()
    this.parent = this.$parent?.$parent
    for(let i in this.arrayLang) {
      this.name[this.arrayLang[i]] = ''
    }
  }

  @Watch('update', { immediate: true, deep: true })
  isUpdate(newVal: boolean) {      
    if(newVal) {
      this.modifierGroup = {
        name: {},
        'display-order': this.parent.row.attributes['display-order'],
        label: this.parent.row.attributes.label,
        minimum: this.parent.row.attributes.minimum,
        maximum: this.parent.row.attributes.maximum,
        type: this.parent.row.attributes.type,
        code: this.parent.row.attributes.code,
        enabled: this.parent.row.attributes.enabled,
        'image-uri': this.parent.row.attributes['image-uri']
      }
      for(let i in this.arrayLang) {
        this.getModifierGroup(this.arrayLang[i])
      }
    }
  }

  @Watch('open', {immediate: true, deep: true})
  isOpen(newVal: boolean) {
    if(newVal) {
      this.formDiscovered = true
      setTimeout(() => {
        this.$refs.inputName[0].$children[0].$el.children[0].querySelector('input[type="text"]').focus()
      },100)
    }else {
      this.update = false
      this.numberOfLoad = 0
      this.formReset()
    }
  }

  @Watch('enabled', { immediate: true, deep: true })
  isEnabled(newVal: boolean) {
    this.modifierGroup.enabled = newVal ? 1 : 0
  }

  get isLoaded(): boolean {
    return this.update && this.numberOfLoad != this.arrayLang.length
  }

  get modalTitle(): string {
    return !this.update ? this.translate('Create Global Modifier Groups') : this.translate('Update Global Modifier Group')
  }

  get modalButtonText(): string {
    return !this.update ? this.translate('Save') : this.translate('Update')
  }

  get modifierGroupData(): modifierGroup {
    this.modifierGroup.name = this.name
    return this.modifierGroup
  }

  setDefaultName() {
    for(let i in this.arrayLang) {
      this.name[this.arrayLang[i]] = ''
    }
  }

  saveForm(e: any) {
    if(!this.update)
      this.createForm(e)
    else
      this.updateForm(e)
  }

  createForm(e: any) {
    console.log('this.parent: ', this.parent);
    const defaultText = e.submitter.innerHTML
    this.waiting(e, `<i class="fas fa-spinner fa-spin"></i> ${this.translate('Updating...')}`)
    Settings.createGlobalModifierGroups(this.modifierGroupData)
      .then((response: any) => {
        this.restore(e, defaultText)
        this.open = false
        this.$emit('updateList', {
          data: response.data.data,
          type: 'create',
        });
        e.target.reset()
        this.$notify({
          title: this.translate('DATA SAVED'),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate('Global Modifier Group has been saved!'),
          type: "success",
          icon: "fas fa-check",
        })
      })
      .catch((err: any) => {
        console.log('err: ', err);
        this.restore(e, defaultText)
        this.$notify({
          title: this.translate('SYSTEM ERROR!'),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate('Something went wrong, please try again!'),
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }

  updateForm(e: any) {
    console.log('xxx')
    const defaultText = e.submitter.innerHTML
    this.waiting(e, `<i class="fas fa-spinner fa-spin"></i> ${this.translate('Updating...')}`)
    Settings.updateGlobalModifierGroups(this.modifierGroupData, this.parent.row.id)
      .then((response: any) => {
        // this.parent.modifierGroups.push(response.data.data)
        this.restore(e, defaultText)
        this.open = false
        this.$emit('updateList', {
          data: response.data.data,
          type: 'update',
        });
        e.target.reset()
        this.$notify({
          title: this.translate('DATA SAVED'),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate('Global Modifier Group has been saved!'),
          type: "success",
          icon: "fas fa-check",
        })
      })
      .catch((err: any) => {
        console.log('err: ', err);
        this.restore(e, defaultText)
        this.$notify({
          title: this.translate('SYSTEM ERROR!'),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate('Something went wrong, please try again!'),
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }

  getModifierGroup(lang: string = '') {
    Settings.modifierGroup(this.parent.row.id, lang).then((response: any) => {
      this.numberOfLoad++
      this.name[lang] = response.data.data.attributes.name
    })
  }

  formReset() {
    this.modifierGroup = {
      name: {},
      'display-order': 1,
      label: '',
      minimum: '',
      maximum: '',
      type: '',
      code: '',
      enabled: 1,
      'image-uri': ''
    }

    this.setDefaultName()
  }

  closeMe(e: any) {
    this.open = false
  }
}












































































































































































import { translations } from '@/mixins'
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
@Component({
  mixins: [translations],
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
    })
  }
})
export default class DashboardFigures extends Vue {
  @Prop() title!: String;
  @Prop() subTitle!: String;
  @Prop() revenue!: number;
  @Prop() average!: number;
  @Prop() orders!: number;
  @Prop() revpct!: number;
  @Prop() avgpct!: number;
  @Prop() orderspct!: number;
  @Prop() disableIcon!: Boolean;
  @Prop() dataPerSource!: any;
  @Prop() selectedStats!: string;

  private getConceptSettings!: any;

  mounted() {
    console.log('getConceptSettings: ', this.getConceptSettings);
  }

  private getGradient(num: any) {
    if(num.toFixed(1) >= 0) {
      return 'gradient-green'
    } else if (num.toFixed(1) < 0) {
      return 'gradient-red'
    }
  }

  public toLocale(val: number): string {
    // const decimal: number = this.getConceptSettings.attributes['decimal-places'] || 0;
    const decimal: number = 2;

    if (decimal > 0) {
      let localeString = val ? Number(val.toFixed(decimal)).toLocaleString() : '0.00';
      let tmp = localeString.split('.')[1];

      if (tmp && tmp.length !== decimal && tmp.length < decimal) {
        for (let i = 0; i < decimal - tmp.length; i++) {
          localeString += '0';
        }
      }
      return localeString;
    }
    return val ? Number(val.toFixed(2)).toLocaleString() : '0.00';
  }
}

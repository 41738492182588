import state from './button.state'
import getters from './button.getters'
import mutations from './button.mutations'
import actions from './button.actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}





























// npm packages
import { mapGetters, mapMutations } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';

// our packages
import { Concept } from '@/interfaces/SOLO';
import { Messaging, Concept as ConceptService } from '@/services/SOLO';
import TabCompose from '@/views/Admin/pages/Messaging/components/Notification/TabCompose';
import TabPending from '@/views/Admin/pages/Messaging/components/Notification/TabPending';
import TabHistory from '@/views/Admin/pages/Messaging/components/Notification/TabHistory';
//Translation
import { translations } from '@/mixins'

@Component({
  components: {
    TabCompose,
    TabPending,
    TabHistory,
  },
  mixins: [translations],
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      segments: 'messaging/segments',
    }),
  },
  methods: {
    ...mapMutations({
      setHistory: 'messaging/setHistory',
      setPending: 'messaging/setPending',
      // setDeviceCount: 'messaging/setDeviceCount',
      setSegments: 'messaging/setSegments',
    }),
  },
})
export default class Notification extends Vue {
  public setHistory!: Function;
  public setPending!: Function;
  // public setDeviceCount!: Function;
  public setSegments!: Function;
  public currentConcept: any = {};
  private activeConcept!: Concept;
  tab: number = 1
  translate!: Function

  created() {
    Messaging.fetchSegments()
      .then((response: any) => this.setSegments(response.data.data));
    // Messaging.fetchDevicesCount(this.activeConcept.id)
    //   .then((response: any) => this.setDeviceCount(response.data.meta.pagination.total));
    // TODO: should only be called when tab is selected
    Messaging.fetchPushNotifications()
      .then((response: any) => this.setHistory(response.data.data));
    Messaging.fetchPushNotifications('Pending')
      .then((response: any) => this.setPending(response.data.data));
  }

  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Compose A Message')
    } else if (this.tab === 2) {
      return this.translate('Pending Messages')
    } else {
      return this.translate('Message History')
    }
  }
}

































































































import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import _ from "lodash";

import { translations } from "@/mixins";
import { WhatsApp as WhatsAppService, Applications } from '@/services/SOLO';

interface ApplicationAttributesObject {
  "application-type": string;
  "menu-only": boolean;
  label: string;
  hosts: string;
}

interface ApplicationObject {
  id: string;
  attributes: ApplicationAttributesObject;
}

interface Application {
  id: number;
}

@Component({
  mixins: [translations],
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
})

export default class MyWhatsAppAccount extends Vue {
  translate!: Function;
  applications: Array<Application> = [];
  formData: any = {
    "id": "",
    "label": "",
    "mobile": "",
    "facebook-business-id": "",
    "monthly-conversation": "",
    "application-id": "",
    "api-key": "",
  };
  clonedAppId!: any;
  submittingRequest: boolean = false;
  getConceptSettings!: any;

  mounted() {
    this.getAppItems();
    this.geVonageData();
  }

  async onSaveAccountDetails() {
    try {
      this.submittingRequest = true
      const { id } = this.getConceptSettings
      let payload = {
        'application-id': this.formData['application-id'],
      }
      await WhatsAppService.updateWhatsAppAccount(id, this.formData.id, payload)
      const notifyOptions: any = {
        title: 'Updated Successfully.',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: 'Successfully Updated',
        type: "success",
        icon: "fas fa-check"
      }
      this.clonedAppId = _.cloneDeep(this.formData['application-id']);
      this.$notify(notifyOptions);
    } catch (error) {
      if (error.response) {
      // Error with response from the server
        const { error: err } = error.response.data
        console.log("GET THE ERROR", err);
        const notifyOptions: any = {
          title: 'Error on Saving the Details',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: err[0].detail || 'An Error Encounter',
          type: 'danger',
          icon: 'fas fa-bomb'
        }
        this.$notify(notifyOptions)
      }
    } finally {
      this.submittingRequest = false
    }
  }

  async geVonageData() {
    try {
      const { id } = this.getConceptSettings;
      const { data } = await WhatsAppService.checkAccountIfVerified(id);
      const { attributes } = data.data[0];
      console.log('vonage', attributes);
      this.formData.id = data.data[0].id;
      this.formData.label = attributes.label;
      this.formData.mobile = attributes.mobile;
      this.formData['application-id'] = attributes['application-id'];
      this.formData['monthly-conversation'] = attributes['monthly-conversation'];
      this.formData['facebook-business-id'] = attributes['facebook-business-id'];
      this.formData['api-key'] = attributes['api-key'];
      this.clonedAppId = _.cloneDeep(attributes['application-id']);

    } catch (err) { 
      if (err instanceof Error) {
        console.log(err.message);
      }

      console.log('Unexpected error', err);
    }
  }

  getAppItems() {
    Applications.getAllApplications()
    .then((response: any) => {
      const applications = response.data.data;
      this.applications = applications.filter((app: ApplicationObject) => !app.attributes['menu-only'] && app.attributes['application-type'] === 'social-ordering');
    })
  }

  get appOptions() {
    return this.applications.map((app: any) => {
      return {
        value: app.id, 
        text: app.attributes.label
      }
    })
  }
}

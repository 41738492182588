











































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb"
import {translations} from '@/mixins'
import {
  Form,
  Alert,
  Input,
  Row,
  Col,
  InputNumber,
  FormItem,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
  Table,
  TableColumn,
  Dialog
} from 'element-ui'
import {Integrations} from '@/services/SOLO'

@Component({
  components: {
    RouteBreadCrumb,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
  },
  mixins: [translations]
})
export default class Franchisee extends Vue {
  public translate!: Function
  secretKey: string = ''
  publicKey: string = ''
  businessDetails: string = ''
  companyCr: string = ''
  email: string = ''
  mobile: any = null
  provider: any = {}
  $notify: any

  mounted() {
    let provider = 'foodics-online';
    Integrations.getSingleIntegration(provider)
      .then((response) => {
          this.provider = response.data.data[0]
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async update() {
    let provider = 'foodics-online'
    let formPayload: any = {}
    let updatePayload: any = {}
    let payload: any = []
    let set: any = []

    for (let key in this.provider.attributes.options) {
      set = {
        'key': key,
        'value': typeof (this.provider.attributes.options[key]) === 'object' ? JSON.stringify(this.provider.attributes.options[key]) : this.provider.attributes.options[key]
      }
      payload.push(set)
    }

    updatePayload['provider'] = this.provider.attributes.provider
    updatePayload['type'] = this.provider.attributes.type
    updatePayload['options'] = payload

    await Integrations.storeFO(this.provider.id, updatePayload)
        .then((response) => {
          this.$notify({
            title: "Integration Provider Updated!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Integration provider has been successfully updated.",
            type: "success",
            icon: "fas fa-check",
          })
        })
        .catch((err) => {
          this.$notify({
            title: "Integration Provider Failed!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: err.response.data.error[0].detail,
            type: "danger",
            icon: "fas fa-bomb",
          })
        })
  }
}

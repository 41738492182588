










































import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { eventHandler } from '@/mixins'
import { Logistics } from '@/models'
import { Integrations, Location } from '@/services/SOLO'
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [eventHandler,translations]
})
export default class LogisticsModal extends Vue {
  logistic: string = ''
  @Prop() open!: Boolean
  @Prop() orderId!: string
  @Prop() index!: number
  @Prop() logisticName!: string
  @Prop() logistics!: Array<Logistics>
  public translate!: Function
  $notify: any

  get isOpen(): Boolean {
    return this.open
  }

  set isOpen(newVal: Boolean) {
    this.$emit('modal:update', newVal)
  }

  @Watch('open', {immediate: true, deep: true})
  onOpen(newVal: Boolean) {
    console.log(this.orderId, this.index, this.logisticName);
    console.log("logistics", this.logistics);
    if(newVal) {
      this.logistic = this.logisticName
    }
  }

  submit(e: any, reset: any) {
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Saving ...'
    e.target.disabled = true

    Integrations.assignLogistics(this.orderId, this.logistic)
      .then((response: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        let data = {
          index: this.index,
          data: response.data.data
        }
        this.$emit('logistics:changed', data)
        this.$emit('modal:update', false)
        this.$emit('refresh:order');
        this.$notify({
          title: "LOGISTIC ASSIGNED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Logistic has been assigned",
          type: "success",
          icon: "fas fa-check"
        });
      })
      .catch((err: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        console.log(err.response.data.message)
        this.$notify({
          title: "Something went wrong, please try again!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: err.response.data.message,
          type: "danger",
          icon: "fas fa-bomb"
        });
      })
  }
}

import BaseService from '../base.service'
import store from '@/store'
export default class AuditLogService extends BaseService {
  url!: string
  constructor() {
    super();
    // this.baseURL = this.baseURL + 'menus/';
  }

  all(type = '', id = 0, page = 1, size = 10) {
    this.url = this.baseURL + `${type}/${id}/audits`;
    return super.get(this.url, {
      page: page,
      size: size,
    });
  }
  
}

import store from '@/store'

export default {
  bind: function(el: any, binding: any, vnode: any) {
    if(!store.getters['account/getUser'].attributes.roles.includes('administrator')) {

      let element = el.tagName.toLowerCase()
      let elClass = el.className.toLowerCase()
      if (element === 'button') {
        el.style.display = 'none'
      } else if (element === 'a') {
        let buttons = store.getters['button/getButton']

        if (!buttons.includes('edit_category')) {
          if (elClass.includes('edit-category'))
            el.style.display = 'none'
        }
        if (!buttons.includes('delete_category')) {
          if (elClass.includes('delete-category'))
            el.style.display = 'none'
        }
        if (!buttons.includes('enable_disable_category')) {
          if (elClass.includes('enable-disable-category'))
            el.style.display = 'none'
        }
        if (!buttons.includes('edit_item')) {
          if (elClass.includes('edit-item'))
            el.style.display = 'none'
        }
        if (!buttons.includes('delete_item')) {
          if (elClass.includes('delete-item'))
            el.style.display = 'none'
        }
        if (!buttons.includes('edit_modifier_group')) {
          if (elClass.includes('edit-modifier-group'))
            el.style.display = 'none'
        }
        if (!buttons.includes('enable_disable_item')) {
          if (elClass.includes('enable-disable-item'))
            el.style.display = 'none'
        }
        if (!buttons.includes('delete_modifier_group')) {
          if (elClass.includes('delete-modifier-group'))
            el.style.display = 'none'
        }
        if (!buttons.includes('enable_disable_modifier_group')) {
          if (elClass.includes('enable-disable-modifier-group'))
            el.style.display = 'none'
        }
        if (!buttons.includes('edit_modifier')) {
          if (elClass.includes('edit-modifier'))
            el.style.display = 'none'
        }
        if (!buttons.includes('delete_modifier')) {
          if (elClass.includes('delete-modifier'))
            el.style.display = 'none'
        }
        if (!buttons.includes('include_exclude_modifier')) {
          if (elClass.includes('include-exclude-modifier'))
            el.style.display = 'none'
        }
        if (!buttons.includes('enable_disable_modifier')) {
          if (elClass.includes('enable-disable-modifier'))
            el.style.display = 'none'
        }
      } else if(element === 'span') {
        console.log(el, binding, vnode.context)
      }
    }
  }
};

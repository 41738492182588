


































import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { eventHandler } from '@/mixins'
import { DriverAttributes, Driver } from '@/models'
import { Location } from '@/services/SOLO'
import { translations } from '@/mixins'
import Translations from './Translations.vue'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [eventHandler,translations]
})
export default class DriversModal extends Vue {
  driver: string = ''
  @Prop() index!: number
  @Prop() open!: Boolean
  @Prop() orderId!: string
  @Prop() driverId!: string
  @Prop() drivers!: Array<Driver> 
  public translate!: Function
  get isOpen(): Boolean {    
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
  }

  $notify: any

  @Watch('open', {immediate: true, deep: true})
  onOpen(newVal: Boolean) {
    if(newVal && this.driverId) {
      this.driver = this.driverId.toString()
    }
  }

  submit(e: any, reset: any) {   
    
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Saving ...'
    e.target.disabled = true
    let payload = {
      order: this.orderId,
      employee: this.driver,
      employee_id: this.driver      
    }

    Location.assignDriver(payload, this.driver)
      .then((response: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        let data = {
          index: this.index,
          data: response.data.data
        }
        this.$emit('driver:changed', data)
        this.$emit('modal:update', false)
        this.$notify({
          title: "DRIVER ASSIGNED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Driver has been assigned",
          type: "success",
          icon: "fas fa-check"
        });
      })
      .catch((err: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb"
        });
      })
  }
}














import { Loyalty as LoyaltyModel } from "@/models"
import {Component, Vue} from "vue-property-decorator"
import LoyaltyList from "./LoyaltyList.vue"
import { Loyalty as LoyaltyService } from '@/services/SOLO'
import LoyaltyCollapse from "./LoyaltyCollapse.vue"
import { translations } from '@/mixins';

@Component({
  components: {
    LoyaltyList,
    LoyaltyCollapse,
  },
  mixins: [translations],
})
export default class LoyaltyItem extends Vue {
  loyalties: Array<LoyaltyModel> = []
  loyaltyMeta: any = {}
  targetKeyword: string = ''
  isLoaded: boolean = false
  page: number = 1
  loading: boolean = false
  public translate!: Function

  async mounted() {
    await this.getLoyalties(this.page)
    this.isLoaded = true
  }

  async getLoyalties(page: number) {
    this.loading = true
    await LoyaltyService.all(page)
      .then((response: any) => {
        let filter = response.data.data
        this.loyalties = response.data.data
        this.loyaltyMeta = response.data.meta.pagination

        filter.map((c: any) => {
          let formatted = c.attributes
            formatted['fullname'] = c.attributes['customer-first-name'] +' '+ c.attributes['customer-last-name']

          return formatted
        })

        console.log(response.data.data)
        this.loading = false
      })
  }

  async searchLoyalties(name: string, mobile: string, type: string, from: string, to: string, order: string) {
    this.loading = true
    await LoyaltyService.search(name, mobile, type, from, to, order).then((response: any) => {
      this.loyalties = response.data.data;
      console.log(this.loyalties)
      this.loading = false
      this.loyaltyMeta = response.data.meta.pagination
    });
  }
}


































import Modal from '@/components/Modal.vue'
import { Category, Settings } from '@/services/SOLO'
import {Select, Option, Menu} from 'element-ui'
import { Category as CategoryModel, CustomField, Menus as MenuModel } from "@/models"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'

@Component({
  components: {
    Modal,
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser'
    })
  },
  mixins: [eventHandler],
})
export default class DetachCustomFieldModal extends Vue {
  parent: any
  getUser: any
  $notify: any;
  selectedMenu: string = ''
  selectedCategory: string = ''
  categories: Array<CategoryModel> = []
  open: boolean = false
  @Prop() menus!: Array<MenuModel>
  @Prop() customField!: CustomField
  
  btnLoading!: Function
  btnRestore!: Function
  
  constructor() {
    super()
    this.parent = this.$parent?.$parent          
  }  

  mounted() {
    if(this.menus.length) {
      this.selectedMenu = this.menus[0].id
      this.getCategories()
    }
  }

  closeMe() {
    this.open = false
  }

  getCategories() {        
    Category.all(this.selectedMenu).then((response: any) => {
      this.categories = response.data.data;      
      if(this.categories.length)
        this.selectedCategory = this.categories[0].id
      else
        this.selectedCategory = ''
    });
  }

  detach(e: any) {    
    let payload = {         
      entity: 'category',
      'entity-id': this.selectedCategory
    }    
    
    const defaultText = e.target.innerHTML
    this.btnLoading(e, '<i class="fas fa-spinner fa-spin"></i> Saving changes')
    Settings.detachField(this.customField.id, payload).then((response: any) => {  
      this.btnRestore(e, defaultText);    
      this.closeMe()
      this.$notify({
        title: "UPDATE SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Custom field successfully detached",
        type: "success",
        icon: "fas fa-check",
      })
    }).catch((err: any) => {
      this.btnRestore(e, defaultText)
      this.closeMe()
    })
  }
}

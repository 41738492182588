















































































import { Auth } from '@/services/core'
import { Concept as ConceptApi, Menu } from '@/services/SOLO'
import { ValidationObserver, ValidationProvider, configure, extend } from 'vee-validate'
import { eventHandler, translations } from '@/mixins'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapMutations } from "vuex"

@Component({
  components: {
    ValidationProvider
  },
  computed: {
    ...mapGetters({
      getUserClient: 'account/getUserClient'
    }),
  },
  methods: {
  },
  mixins: [eventHandler, translations],
})
export default class Home extends Vue {
  private getUserClient: any
  translate: any
  public model: any = {
    username: null,
  };
  public isEmailSent: boolean = false;
  public apiError: string | null = null;

  mounted() {
  }

  private get logo(): string {
    if(this.getUserClient) {
      return this.getUserClient.attributes['logo-uri']
    }
    return ``
  }
  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }

  // public onForgotPassword() {
  //   Auth.forgot(this.model.username).then(response => {
  //     this.isEmailSent = true;
  //   });
  // }
    
      public onForgotPassword() {
        Auth.forgot(this.model.username)
          .then(response => {
            this.isEmailSent = true;
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              // Validation failed
              const errors = error.response.data.error;
              if (errors.length > 0) {
                // Display the first error message
                this.apiError = 'Sorry, please try again later';
              }
            } else {
              // Other error occurred
              this.$bvToast.toast('An error occurred. Please try again later.', {
                title: 'Error',
                variant: 'danger',
                solid: true,
              });
            }
          });
      }
}

import BaseService from '../base.service'

export default class SubscriptionMealsService extends BaseService{
  url!: string
  constructor(){
    super();
  }

  index() {
    this.url = this.baseURL + 'meal-subscription/premade-plans'
    return super.get(this.url)
  }

  show(id: number, lang: string) {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + 'meal-subscription/premade-plans/' + id + '?include=setMeals'
    return super.get(this.url, {}, this.headers)
  }

  showWithSetmeals(id: number) {
    this.url = this.baseURL + 'meal-subscription/premade-plans/' + id + '?include=setMeals'
    return super.get(this.url)
  }

  create(payload: Object = {}) {
    this.url = this.baseURL + 'meal-subscription/premade-plans'
    return super.post(this.url, payload)
  }

  update(id: number, payload: Object = {}) {
    this.url = this.baseURL + 'meal-subscription/premade-plans/' + id
    return super.patch(this.url, payload)
  }

  delete(id: number) {
    this.url = this.baseURL + 'meal-subscription/premade-plans/' + id
    return super.remove(this.url, {})
  }

  getSubscriptionSetting(id: number) {
    this.url = this.baseURL + 'meal-subscriptions/settings/' + id
    return super.get(this.url)
  }

  createSubscriptionSetting(payload: {}) {
    this.url = this.baseURL + 'meal-subscriptions/settings'
    return super.post(this.url, payload)
  }

  updateSubscriptionSetting(id: number, payload: {}) {
    this.url = this.baseURL + 'meal-subscriptions/settings/' + id
    return super.patch(this.url, payload)
  }
  getDeliverySlots() {
    this.url = this.baseURL + 'delivery-slots'
    return super.get(this.url)
  }

  uploadToS3(payload: Object = {}) {
    this.url = this.baseURL + 'meal-subscription/premade-plans/upload'
    return super.post(this.url, payload)
  }

  getSubscribers(page: any) {
    let url = this.baseURL + `subscribers?include=customer,premadePlan&page=${page}`;
    // let url = this.baseURL + `subscribers?include=customer,premadePlan&page=${page}`;
    return super.get(url);
  }

  // search(name: string, mobile: string) {
  //   let url = this.baseURL + `customers/subscribers?filter[customer]=${name}&filter[mobile]=${mobile}`;
  //   return super.get(url);
  // }

}

import * as jwt from 'jsonwebtoken';
import BaseService from '../base.service'
import store from '@/store'

let conceptService = class ConceptService extends BaseService {

    constructor() {
        super();
    }

    all(page: 1) {
        let url = this.baseURL + '';

        return super.get(url, {
            page: page
        });
    }

    checkOnboarding() {
        let url = this.baseURL + `onboarding`;
        return super.get(url)

    }

    getTerms() {
        let url = this.baseURL + `user-terms?filter[term-key]=solo-toc-20230205`;
        return super.get(url)
    }

    get(conceptId: any) {
        let url = this.baseURL + `concepts/${conceptId}`;

        return super.get(url)
    }


    updateConcept(payload: any, conceptId: any) {
        let url = this.baseURL + `concepts/${conceptId}`;

        return super.patch(url, payload)
    }

    applyUpdatesToLocations(payload: any = {}) {
        let url = this.baseURL + `concepts/${store.getters['account/activeConcept'].id}/copy-default-delivery-settings-to-locations`;

        return super.post(url, payload);
    }

    applyOpeningHours(payload: any = {}) {
        let url = this.baseURL + `concepts/${store.getters['account/activeConcept'].id}/copy-default-opening-hours-to-locations`;

        return super.post(url, payload);
    }

    find() {
        this.conceptId = store.getters['account/activeConcept'].id
        let url = this.baseURL + 'concepts/' + this.conceptId + '/applications';

        return super.get(url)
    }

    default() {
        this.conceptId = store.getters['account/activeConcept'].id
        let url = this.baseURL + 'concepts/' + this.conceptId;

        return super.get(url)
    }

    settings() {
        this.conceptId = store.getters['account/activeConcept'].id
        let url = this.baseURL + 'concepts/' + this.conceptId + '/settings';

        return super.get(url)
    }

    getTapPublicKey()
    {
        let url = this.baseURL + 'integration-options/tap/web_public_key'
        return super.get(url)
    }

    getCheckoutPublicKey()
    {
        let url = this.baseURL + 'integration-options/checkout/public_key'
        return super.get(url)
    }

    getMetabaseReport(dashboard: 5) {
      // 5 General
      // 6 Customers
      // 7 Locations
      // 8 Orders
      // 11 Products
      const payload = {
        resource: { dashboard },
        params: {
          id: [store.getters['account/activeConcept'].id],
        },
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
      };
      const token = jwt.sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY);

      return process.env.VUE_APP_METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
    }

};

export default conceptService

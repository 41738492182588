





















































































































































































































































































import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { eventHandler } from '@/mixins'
import { DriverAttributes, Driver } from '@/models'
import { Integrations, Location, Order as OrderApi } from '@/services/SOLO'
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import { Logistics } from '@/models'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [eventHandler,translations]
})
export default class DriversModal extends Vue {
  selectDriver: any = ''
  selectLogistic: any = ''
  isDriver: Boolean = true;
  isLogistic: Boolean = false;
  @Prop() index!: number
  @Prop() open!: Boolean
  @Prop() orderId!: string
  @Prop() driverId!: string
  @Prop() drivers!: Array<Driver>
  @Prop() logistics!: Array<Logistics>
  @Prop() driverName!: string
  @Prop() logisticCname!: string
  @Prop() driverCname!: string

  public translate!: Function
  get isOpen(): Boolean {    
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
  }

  $notify: any

  @Watch('open', {immediate: true, deep: true})
  onOpen(newVal: Boolean) {
    console.log("logisticsssewew", this.logistics);
    if(newVal && this.driverId) {
      this.selectDriver = this.driverId.toString()
    } else {
      this.selectDriver = '';
    }
  }

  mounted() {
  }
 updateStatusChange() {
    let payload = {
        'order-status': 'ready',
    };
    OrderApi.updateStatus(payload, this.orderId)
      .then((response: any) => {
        this.$notify({
          title: "STATUS CHANGED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Status have been successfully changed",
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        const message = JSON.parse(err?.response?.data?.messages)?.errors?.status[0]
          || JSON.parse(err?.response?.data?.messages)?.message
          || 'Something went wrong, please try again!';
        // console.log('err:' , JSON.parse(err?.response?.data?.messages));
        // this.description = this.orderStatusCode;
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message,
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
  clickLogistic(e: any) {
    this.isLogistic = e;
    this.isDriver = false;
    console.log("ll", this.isLogistic);
  }
  clickDriver(e: any){
    this.isDriver = e;
    this.isLogistic = false;
    console.log("dr", this.isDriver);
  }
  pickDriver(d: any) {
    console.log("driverID", d);
    this.selectDriver = d;
  }
  pickLogistic(l: any) {
    console.log("logistic", l);
    this.selectLogistic = l;
  }
  closeModal(e: any) {
    this.$emit('modal:update', false)
  }
  submitDriver(e: any) {   
    
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Saving ...'
    e.target.disabled = true
    let payload = {
      order: this.orderId,
      employee: this.selectDriver,
      employee_id: this.selectDriver      
    }

    Location.assignDriver(payload, this.selectDriver)
      .then((response: any) => {
        this.selectDriver = '';
        e.target.innerHTML = defaultText
        e.target.disabled = false
        let data = {
          index: this.index,
          data: response.data.data
        }
        this.updateStatusChange();
        this.$emit('driver:changed', data)
        this.$emit('modal:update', false)
        this.$emit('refresh:order');
        this.$notify({
          title: "DRIVER ASSIGNED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Driver has been assigned",
          type: "success",
          icon: "fas fa-check"
        });
      })
      .catch((err: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb"
        });
      })
  }

  submitLogistic(e: any) {
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Saving ...'
    e.target.disabled = true

    Integrations.assignLogistics(this.orderId, this.selectLogistic)
      .then((response: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        let data = {
          index: this.index,
          data: response.data.data
        }
        this.updateStatusChange();
        this.$emit('logistics:changed', data)
        this.$emit('modal:update', false)
        this.$emit('refresh:order');
        this.$notify({
          title: "LOGISTIC ASSIGNED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Logistic has been assigned",
          type: "success",
          icon: "fas fa-check"
        });
      })
      .catch((err: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        console.log(err.response.data.message)
        this.$notify({
          title: "Something went wrong, please try again!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: err.response.data.message,
          type: "danger",
          icon: "fas fa-bomb"
        });
      })
  }
}

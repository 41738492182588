













































































































































































































































































import {translations} from '@/mixins'
import {Banner as BannerApi} from "@/services/SOLO";
import {
  BanAttributes,
  SlideAttributes,
  Banner,
  Slide,
  BanDesc,
  BanTitle,
  BanLinkLabel,
  BanImgUri,
} from "@/models";
import {Select, Option} from "element-ui";
import {ValidationProvider} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";
import {mapGetters} from 'vuex'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',

    }),
  },
  mixins: [translations],
})
export default class CreateBannerItem extends Vue {
  applyBoth: Boolean = true;
  saving: Boolean = false
  arrayLang: Array<string> = [];
  menuID: any = "";
  advanceSettings: any = false;
  banTitle: BanTitle = {};
  banDesc: BanDesc = {};
  banImgUri: BanImgUri = {};
  menus: Array<Slide> = [];
  linkOptions: Array<any> = [];
  menu_id: number = 0;
  imageType: any = {};
  imageTypes: any = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload IMG",
    },
  ];
  linkTypes: any = [
    {value: "url", text: "URL"},
    {value: "category", text: "Category"},
    {value: "item", text: "Item"},
    {value: "cms-page", text: "CMS-PAGE"},
  ];
  imageValuePrimary: string = '';
  imageValueSecondary: string = '';
  tmpImageValuePrimary: string = '';
  tmpImageValueSecondary: string = '';
  fileValue: any = {};
  $notify: any;
  $refs!: {
    file: any;
  };
  public translate!: Function
  getConceptSettings: any
  @Prop() open!: Boolean;
  bannerID!: any;
  getLocale!: any;
  bannerslider: SlideAttributes = {
    label: "",
    status: "",
    description: {},
    title: {},
    link: "",
    "display-order": "",
    "link-type": "category",
    "link-value": "",
    "link-label": {},
    "image-uri": {},
  };

  @Watch("fileValue", {immediate: true, deep: true})
  checkPrimaryUpload(newVal: any) {
    if (newVal[this.getConceptSettings.attributes['primary-language']]) {
      if(this.applyBoth) {
        if(this.fileValue[this.getConceptSettings.attributes['primary-language']]) {
          console.log('check ckeck pri', this.fileValue[this.getConceptSettings.attributes['primary-language']]);
          this.fileValue[this.getConceptSettings.attributes['secondary-language']] = this.fileValue[this.getConceptSettings.attributes['primary-language']];
        }
      }

    } else {
      if(this.applyBoth) {
        if(this.fileValue[this.getConceptSettings.attributes['secondary-language']]) {
          console.log('check ckeck sec', this.fileValue[this.getConceptSettings.attributes['secondary-language']]);
          this.fileValue[this.getConceptSettings.attributes['primary-language']] = this.fileValue[this.getConceptSettings.attributes['secondary-language']];
        } 

      }
    }
  }



  @Watch("imageValuePrimary", {immediate: true, deep: true})
  checkPrimary(newVal: any) {
    if (newVal) {
      if(this.applyBoth) {
        this.imageValueSecondary = this.imageValuePrimary;
        console.log('check apply primary', this.imageValueSecondary);
      }
      
    }
  }

  @Watch("imageValueSecondary", {immediate: true, deep: true})
  checkSecondary(newVal: any) {
    if (newVal) {
      if(this.applyBoth) {
        this.imageValuePrimary = this.imageValueSecondary;
        console.log('check apply secondary', this.imageValuePrimary);
      }
    }
  }

  constructor() {
    super();
  }

  mounted() {
    this.getMenus()
    this.bannerID = this.$route.params.id
    /* @ts-ignore */
    if(this.getConceptSettings.attributes['primary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    }

    /* @ts-ignore */
    if(this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }

    console.log('arrayLang', this.arrayLang.length);

    for (let i in this.arrayLang) {
      this.imageType[this.arrayLang[i]] = 0;
    }
    this.imageValuePrimary = "";
    this.imageValueSecondary = "";
  }

  created() {
    this.imageTypes = [
      {value: 0, text: this.translate("Image URL")},
      {value: 1, text: this.translate("Upload IMG")},
    ];
    this.linkTypes = [
      {value: "url", text: this.translate("URL")},
      {value: "category", text: this.translate("Category")},
      {value: "item", text: this.translate("Item")},
      {value: "cms-page", text: this.translate("CMS-PAGE")}
    ];
  }

  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("modal:add", newVal);
    if (!newVal) {
    }
  }

  handleClick() {
    // eslint-disable-next-line no-alert
    alert('button click')
  }

  openAdvanceSettings() {
    if(this.advanceSettings == false) {
      this.advanceSettings = true;
    } else {
      this.advanceSettings = false;
    }
  }

  resetForm() {
    this.bannerslider["display-order"] = "";
    this.bannerslider["link-type"] = "";
    this.bannerslider["link-value"] = "";
    this.bannerslider.label = "";

    this.bannerslider.title = {};
    this.bannerslider.description = {};

    this.bannerslider["link-label"] = {};
    this.bannerslider["image-uri"] = {};
  }

  get bannerSliderData(): SlideAttributes {
    return this.bannerslider;
  }

  resetImage(type: number, lang: string) {
    if (type == 0) {
      /* @ts-ignore */
      if (this.getConceptSettings.attributes['primary-language'] == lang) {
        this.imageValuePrimary = this.tmpImageValuePrimary;
      /* @ts-ignore */
      } else if (this.getConceptSettings.attributes['secondary-language']) {
        this.imageValueSecondary = this.tmpImageValueSecondary;
      }
      // this.imageValue[lang] = this.tmpImageValue;
      this.fileValue[lang] = null;
    } 
    if (type == 1) {
      /* @ts-ignore */
      if (this.getConceptSettings.attributes['primary-language'] == lang) {
        this.tmpImageValuePrimary = this.imageValuePrimary;
        this.imageValuePrimary = '';
      /* @ts-ignore */
      } else if (this.getConceptSettings.attributes['secondary-language'] == lang) {
        this.tmpImageValueSecondary = this.imageValueSecondary;
        this.imageValueSecondary = '';
      }
      // this.tmpImageValue = this.imageValue[lang];
      // this.imageValue[lang] = null;
    }
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }

  getMenus() {
    BannerApi.menus().then((response: any) => {
      this.menus = response.data.data;
    });
  }

  onMenuSearch(): void {
    console.log('this.bannerSlider: ', this.bannerslider);
    this.onChangeLinkType();
  }

  onChangeLinkType(): void {
    this.bannerslider['link-value'] = '';
    const { menuID } = this;
    if (this.bannerslider['link-type'] === 'item' && menuID) {
      BannerApi.linkValueSearch(menuID, 'items', 1)
        .then((response) => (this.linkOptions = response.data.data));
    } else if (this.bannerslider['link-type'] === 'category' && menuID) {
      BannerApi.linkValueSearch(menuID, 'categories', 1)
        .then((response) => (this.linkOptions = response.data.data));
    }
  }

  formatData() {
    let formData = new FormData();
    formData.append("link-type", this.bannerslider["link-type"]);
    formData.append("link-value", this.bannerslider["link-value"]);
    formData.append("label", this.bannerslider.label);
    formData.append(
        "display-order",
        this.bannerslider["display-order"].toString()
    );

    let count = 0;
    for (let i in this.arrayLang) {
      formData.append(
          `title[${this.arrayLang[i]}]`,
          this.bannerslider.title[this.arrayLang[i]] ?? ''
      );

      formData.append(
          `description[${this.arrayLang[i]}]`,
          this.bannerslider.description[this.arrayLang[i]] ?? ''
      );

      formData.append(
          `link-label[${this.arrayLang[i]}]`,
          this.bannerslider["link-label"][this.arrayLang[i]] ?? ''
      );

      // formData.append(`image`, this.$refs.file[i].files[0])
      // formData.append(`alt-image`, this.$refs.file[i].files[0])

      if (this.fileValue && this.fileValue[this.arrayLang[i]]) {
        console.log('fileValue', this.fileValue[this.arrayLang[i]]);
        if (count == 0) {
          formData.append("image", this.fileValue[this.arrayLang[i]]);
        } 
        if (count == 1) {
          formData.append("alt-image", this.fileValue[this.arrayLang[i]]);
        }
      } else {
        /* @ts-ignore */
        formData.append(`image-uri[${this.getConceptSettings.attributes['primary-language']}]`, this.imageValuePrimary);
        /* @ts-ignore */
        formData.append(`image-uri[${this.getConceptSettings.attributes['secondary-language']}]`, this.imageValueSecondary);
        // formData.append(`image-uri[${this.arrayLang[i]}]`, this.imageValue[this.arrayLang[i]]);
      }
      count += 1
    }
    return formData;
  }


  // get bannerSliderForm(): FormData {
  //   let formData = new FormData();
  //   formData.append("link-type", this.bannerslider["link-type"]);
  //   formData.append("link-value", this.bannerslider["link-value"]);
  //   formData.append("label", this.bannerslider.label);
  //   formData.append(
  //       "display-order",
  //       this.bannerslider["display-order"].toString()
  //   );

  //   for (let i in this.arrayLang) {
  //     formData.append(
  //         `title[${this.arrayLang[i]}]`,
  //         this.bannerslider.title[this.arrayLang[i]] ?? ''
  //     );

  //     formData.append(
  //         `description[${this.arrayLang[i]}]`,
  //         this.bannerslider.description[this.arrayLang[i]] ?? ''
  //     );

  //     formData.append(
  //         `link-label[${this.arrayLang[i]}]`,
  //         this.bannerslider["link-label"][this.arrayLang[i]] ?? ''
  //     );

  //     formData.append(`image`, this.$refs.file[i].files[0])
  //     formData.append(`alt-image`, this.$refs.file[i].files[0])
  //   }
  //   return formData;
  // }

  cancelCreate() {
    this.resetForm()
    this.$router.go(-1);
  }

  showNotifyStatus(status: Boolean) {
    if (status) {
      this.$notify({
        title: "BANNER SLIDE SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Banner slide created successfully.",
        type: "success",
        icon: "fas fa-check",
      });
    } else {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  async createBannerSlides() {
    this.saving = true
    // let payload = this.imageType ? this.bannerSliderData : this.bannerSliderForm
    await BannerApi.createBannerSlide(this.formatData(), this.bannerID)
        .then((response) => {
          this.saving = false
          this.showNotifyStatus(true)
          this.resetForm()
          this.$router.back()
        })
        .catch((err) => {
          this.showNotifyStatus(false)
        });
  }
}

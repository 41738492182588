




















import { Vue, Component } from "vue-property-decorator";
import { translations } from "@/mixins";

@Component({
  mixins: [translations],
})

export default class ContitionsModal extends Vue {
  translate!: Function;
  conditions: Array<String> = [
    `The phone number should not be currently in use on WhatsApp, either on the personal or business app. If it is already active with another Business Solution Provider (BSP), there should be a process to migrate the phone number.`,
    `The phone number should be capable of accepting voice calls or SMS.`,
    `It should have international reach and be able to receive voice calls or SMS from the United States of America. This is necessary to complete the two-factor authentication (2FA) process with Facebook.`,
    `Shortcodes are not allowed for the phone number.`,
    `Toll-free numbers are not accepted.`,
    `For Contact Centre phones, the IVR (Interactive Voice Response) system must have the ability to be disabled or intercepted in order to complete the 2FA.`
  ];
}


















































































































































































































































import { Table, TableColumn, Button, Form, FormItem, Select, Option, Checkbox } from 'element-ui'
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Employee } from '@/services/SOLO'
import { translations, notificationAlerts } from '@/mixins'
import Translations from './Translations.vue'
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

interface CustomerDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string
}

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    ValidationProvider,
  },
mixins: [translations, notificationAlerts],
})
export default class CustomerUpdateModal extends Vue {
  @Prop() openCreate: boolean = false;
  @Prop() employee?: Object;
  public translate!: Function;
  id: any;
  isOpen: boolean = false
  $notify: any;
  modalForm: any =  {
    'employee-id': null,
    username: null,
    'first-name': null,
    'last-name': null,
    password: null,
    email: null,
    mobile: null,
    roles: [],
    locations: [],
  };
  roles: any = [];
  locations: any = [];
  private req: Function = (rule: any, value: any, callback: any) => {
    console.log('validator: ', value);
    if (!value) {
      callback(new Error('This field is required'));
    } else {
      callback();
    }
  };
  private reqValidator = [
    {
      validator: this.req,
      trigger: 'blur',
    }
  ];
  rules = {
    ['employee-id']: this.reqValidator,
    username: this.reqValidator,
    ['first-name']: this.reqValidator,
    ['last-name']: this.reqValidator,
    password: this.reqValidator,
    email: this.reqValidator,
    mobile: this.reqValidator,
    roles: this.reqValidator,
    locations: this.reqValidator,
  };
  successNotification!: Function;
  systemErrorNotification!: Function;
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };
  includeFranchisee: boolean = false;

  @Watch('modalForm.roles', { deep: true })
  onRolesChanged(newVal: any) {
    console.log('onRolesChanged', newVal);
    if (newVal.includes('franchisee') && !this.includeFranchisee) { // perform only if first selection of franchisee role
      this.locations = [];
      this.getLocations(1, true);
      this.includeFranchisee = true;
    } else if (!newVal.includes('franchisee') && this.includeFranchisee) { // perform only if first unselection of franchisee role
      this.locations = [];
      this.getLocations(1, false);
      this.includeFranchisee = false;
    }
  }

  mounted() {
    Employee.getRoles().then((response) => {
      let roles = response.data.data;
      this.roles = roles.map((r: any) => {
        return {
          key: r.attributes.label,
          value: r.attributes.name,
        };
      });
      console.log('roles: ', this.roles);
    });

    this.getLocations(1, false);
  }
  getLocations(page: 1, franchisee: boolean) {
    let lang = 'en-us';
    Employee.getLocations2(lang, page, franchisee).then((response) => {
      let locations = response.data.data;
      console.log("test locations", locations);
      locations = locations.map((l: any) => {
        return {
          key: l.id,
          value: l.attributes.name,
        };
      });

      this.locations.push(...locations);

      if (
          response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
      ) {
        this.getLocations(response.data.meta.pagination.current_page + 1, franchisee);
      }
    });
  }

  @Watch('openCreate', { deep: true })
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }

  handleClose() {
    this.clearForm();
    this.$emit('handleClose', false);
  }

  clearForm() {
    this.modalForm['employee-id'] = null;
    this.modalForm.username = null;
    this.modalForm['first-name'] = null;
    this.modalForm['last-name'] = null;
    this.modalForm.password = null;
    this.modalForm.email = null;
    this.modalForm.mobile = null;
    this.modalForm.roles = [];
    this.modalForm.locations = [];
  }

  onCreateEmployee(e: HTMLFormElement, invalid: boolean) {
    if (!invalid) {
      console.log('this.modalForm: ', this.modalForm);
      const payload: any = { ...this.modalForm };
      console.log('payload: ', payload);
      Employee.create(payload)
        .then(() => {
          this.clearForm();
          this.$emit('handleClose', false);
          this.$emit('updateList', true);
          this.successNotification('EMPLOYEE CREATED!', 'Employee successfully created!');
          
        })
        .catch((err: any) => {
          if(err.response.data.error?.password) {
            this.systemErrorNotification('CREATE ERROR!', err.response.data.error.password[0]);
          } else {
            if(err.response.data.error[0]?.detail) {
                this.systemErrorNotification('CREATE ERROR!', err.response.data.error[0].detail);
            } else if (err.response.data.error?.username[0]) {
                this.systemErrorNotification('CREATE ERROR!', err.response.data.error.username[0]);
            } 
          }

        });
    }
  }
}

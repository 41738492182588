











































import Modal from "@/components/Modal.vue";
import {Banner as BannerApi} from "@/services/SOLO";
import {BanAttributes, Banner, Slide} from "@/models";
import {translations} from '@/mixins'
import {
  ValidationProvider,
} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
  },
  mixins: [translations],
})
export default class BannerSetModal extends Vue {
  banner: BanAttributes = {
    label: '',
  }
  $notify: any;
  $refs!: {
    file: any;
  }
  @Prop() open!: Boolean
  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("modal:add", newVal);
    if (!newVal) {
    }
  }

  constructor() {
    super();
  }

  get bannerData(): BanAttributes {
    return this.banner;
  }

  close() {
    this.$emit("modal:close", false);
  }

  createBannerSet(e: any, reset: any) {
    let defaultText = e.target.innerHTML;
    e.target.innerHTML =
        '<i class="fas fa-spinner fa-spin"></i> Creating Banner Set';
    e.target.disabled = true;
    BannerApi.createBannerLabelForm(this.bannerData)
        .then((response) => {
          this.$notify({
            title: "BANNER SET CREATED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Banner Set has been created successfully",
            type: "success",
            icon: "fas fa-check",
          });
          this.banner.label = "";
          console.log(response.data.data);
          e.target.innerHTML = defaultText;
          e.target.disabled = false;
          this.$emit("banner:created");
          this.$emit("modal:add", false);
          reset();
        })
        .catch((err) => {
          e.target.innerHTML = defaultText;
          e.target.disabled = false;
          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Something went wrong, please try again!",
            type: "danger",
            icon: "fas fa-bomb",
          });
          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Something went wrong, please try again!",
            type: "danger",
            icon: "fas fa-bomb",
          });
        });
  }
}

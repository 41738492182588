import state from './menu.state';
import getters from './menu.getters';
import mutations from './menu.mutations';
import actions from './menu.actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
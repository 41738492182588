import BaseService from '../base.service'
export default class MessagingService extends BaseService {
  url!: string
  constructor() {
    super();
  }

  fetchPushNotifications(status: string = '') {
    this.url = `${this.baseURL}push-notifications${!!status ? `?filter[status]=${status}` : ''}`;
    return super.get(this.url);
  }

  fetchDevicesCount(conceptId: string | number) {
    this.url = `${this.baseURL}devices?concept_id=${conceptId}`;
    return super.get(this.url);
  }

  fetchSegments() {
    this.url = `${this.baseURL}segments/all`;
    return super.get(this.url);
  }

  sendPushNotification(params: {}) {
    // this.headers['Accept-Language'] = lang
    // this.headers['Content-Type'] = 'multipart/form-data'

    this.url = `${this.baseURL}push-notifications`;
    return super.post(this.url, params);
  }

  uploadToS3(payload: {}) {
    this.url = this.baseURL + 'push-notifications/upload'
    return super.post(this.url, payload)
  }

  menus() {
    this.url = this.baseURL1 + 'menus';
    return super.get(this.url);
  }

  menuItem(menuID: number) {
    this.url = this.baseURL1 + `menus/${menuID}/items`;
    return super.get(this.url);
  }

  getCoupons(){
    this.url = this.baseURL1 + 'coupons';
    return super.get(this.url);
  }

  getDigitalCoupons(){
    this.url = this.baseURL1 + 'admin/digital-coupons';
    return super.get(this.url);
  }

  deletePushNotification(id: number) {
    this.url = `${this.baseURL}push-notifications/${id}`;
    return super.remove(this.url, {});
  }


  // sendStarRating(params: {}) {
  //   // {
  //   //   "type": 1,
  //   //   "name": {
  //   //       "en-us": "Condition of Meal",
  //   //       "ar-sa": "حالة الوجبة"
  //   //   }
  //   // }
    // let url = `${this.baseURL}topics`;
  //   return super.post(url, params);

}

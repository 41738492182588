























































import moment from "moment"
import { mapGetters, mapMutations } from 'vuex'
import {Select, Option} from 'element-ui'
import { translations } from '@/mixins'
import PaymentDescription from './components/PaymentDescription.vue'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { Subscription as SubscriptionApi } from '@/services/SOLO'
import { mixins } from 'vue-class-component';
import billingCycleModal from './components/billingCycleModal'
import LangSwitcher from '@/components/LangSwitcher';
import Translations from "../../Translations.vue";

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    PaymentDescription,
    billingCycleModal,
    LangSwitcher,
    Translations,
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("D/MM/YYYY");
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser',
      getLocale: 'app/getLocale',
      getSubscription: 'account/getSubscription',
      getAppSubscription: 'app/getAppSubscription',
      getAppQueryParams: 'app/getAppQueryParams',
      getUserClient: 'account/getUserClient',
    })
  },
  methods: {
    ...mapMutations({
      setSubscription: 'account/setSubscription',
      setAppSubscription: 'app/setSubscription',
      logoutUser: 'account/logoutUser'
    })
  },
  mixins: [translations],
})
export default class Subscription extends Vue {
  private getUserClient: any
  private getUser: any
  private getLocale: any
  private getSubscription: any
  private plan: any = 0
  private subscription: any = null
  private translateNumber!: Function
  private logoutUser!: Function
  private translate!: Function
  private getAppSubscription: any
  private getAppQueryParams: any
  private showBillingModal: Boolean = false
  billCycle: any = ''
  branchCount: any = ''
  private plans: Array<any> = ['Free Plan','Standard Plan', 'Premium Plan']
  flagOpenModal: any = false
  get account() {
    return this.getUser.attributes
  }

  private setSubscription!: Function
  private setAppSubscription!: Function

  mounted() {
    this.checkSubscription()
    this.branchCount = this.getSubscription.attributes['branch-quantity'];
    console.log("branch quantity", this.getSubscription.attributes['branch-quantity']);
    this.billCycle = this.getSubscription.attributes['billing-cycle'];
    this.plan = this.$route.params.planId;
    console.log("planID in subs", this.plan);

    if(!this.getAppSubscription && !this.getSubscription) {
      this.setAppSubscription(this.$route.params.planId)
      this.plan = this.getAppSubscription

    } else {
      this.setAppSubscription(this.getSubscription.attributes['plan-id'])
      this.plan = this.getAppSubscription
    }
  }


  modalCloseState(open: Boolean = false) {
    this.showBillingModal = open
  }
  private get logo(): string {
    if(this.getUserClient) {
      return this.getUserClient.attributes['logo-uri']
    }
    return ``
  }

  private get isCurrentPlan() {
    if(this.getSubscription) {
      return this.getAppSubscription === this.getSubscription.attributes['plan-id']
    }
    return false
  }

  private changePlan(planId: number, billingCycle: boolean) {
    console.log("change plan ID", planId)
    console.log("bill cycle for month check", billingCycle);
    this.billCycle = billingCycle;
    this.setAppSubscription(planId)
    this.plan = this.getAppSubscription
    console.log("change plan/billCycle", this.plan, this.billCycle);
  }

  private logout() {
    this.logoutUser()
    this.$router.push({name: 'login'})
  }

  private initChangePlan() {
    this.showBillingModal = true;
    this.flagOpenModal = true;


    // if(this.getSubscription) {
    //    if(this.getSubscription.hasOwnProperty('attributes')) {
    //     this.plan = this.getSubscription.attributes['plan-id'];
    //   }
    // } else {
    // }
    // this.billCycle = this.getSubscription.attributes['billing-cycle'] == 'year' ? true : false;
  }

  private checkSubscription() {
    if(this.getSubscription) {
      this.plan = this.getSubscription.attributes['plan-id']
    } else {
      this.getSubscriptionFn()
    }
  }

  private getPlan() {
    SubscriptionApi.getPlan(this.plan).then(response => {
      console.log(response.data.data)
    })
  }

  private formatSubscription(num: number) {
    let d = 0
    if(!num) {
      if(!this.getSubscription) {
        return this.translateNumber(0);
      } else {
        return this.getSubscription.attributes['included-branches']
      }
    } else if(num === 1) {
      if(!this.getSubscription) {
        return this.translate('SAR') + ` ${this.translateNumber(d.toFixed(2))}`
      } else {
        return this.getSubscription.attributes['latest-invoice-amount-paid']
      }
    } else if(num === 2) {
      if(!this.getSubscription) {
        return this.translate('SAR') + ` ${this.translateNumber(d.toFixed(2))}`
      } else {
        return this.getSubscription.attributes['next-invoice-amount']
      }
    } else {
      if(!this.getSubscription) {
        return this.translate('N/A')
      } else {
        return moment
          .utc(this.getSubscription.attributes['next-invoice-at'], "YYYY-MM-D hh:mm:ss")
          .locale("en-us")
          .local()
          .format("D/MM/YYYY")
      }
    }
  }

  private modalState(show: Boolean) {
    this.showBillingModal = show
  }

  private getSubscriptionFn() {
    SubscriptionApi.getSubscription().then(response => {
      console.log('test')
      this.setSubscription(response.data.data)
    }).catch(err => {
      console.log(err.response)
    })
  }
}

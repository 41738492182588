import BaseService from '../base.service'

let subscriptionService = class SubscriptionService extends BaseService {

  constructor() {
    super();
  }  

  getSubscription() {
    let url = this.baseURL + `clients/subscriptions?include=plan`
    return super.get(url)
  }

  getPlan(id: number) {
    let url = this.baseURL + `plans/${id}?include=features`
    return super.get(url)
  }

  getUpcomingInvoice(params: any = {}) {
    let url = this.baseURL + `clients/subscriptions/invoices/upcoming?${this.getQueryString(params)}`
    return super.get(url)
  }

  getPaymentIntent() {
    let url = this.baseURL + `clients/subscriptions/payment-intents`
    return super.get(url)
  }

  subscribe(payload: Object = {}) {
    let url = this.baseURL + `clients/subscriptions`
    return super.post(url, payload)
  }

  createPaymentMethod(payload: Object = {}) {
    let url = this.baseURL + `clients/subscriptions/payment-methods`
    return super.post(url, payload)
  }
  
  getPayment(id: string) {
    let url = this.baseURL + `clients/subscriptions/payments/${id}`

    return super.get(url)
  }

  getPaymentMethod() {
    let url = this.baseURL + `clients/subscriptions/payment-methods`
    return super.get(url)
  }
};

export default subscriptionService
















































import { translations } from '@/mixins'
import { Concept as ConceptApi, Subscription } from '@/services/SOLO'
// import Stripe from 'stripe';
import { ValidationObserver, configure, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import {loadStripe} from '@stripe/stripe-js';
import { mapGetters, mapMutations } from 'vuex';

@Component({  
  components: {
    ValidationObserver,
  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
    })
  },
  methods: {
    ...mapMutations({
      setConceptSettings: 'account/setConceptSettings',
      setSubscription: 'account/setSubscription'
    })
  },
  mixins: [translations],
})
export default class PaymentDescription extends Vue {

  private activeConcept: any
  private branchCount: number = 1
  private cardHolder: string = ''  
  private card: Object = {
    number: '',
    exp: '',
    code: ''
  }
    
  private paymentIntent: any
  private cardElement: any = null
  private stripe: any = null
  private paymentMethod: any = null    
  
  $notify: any;
  $refs!: {
    cardExpiry: any
    cardCvc: any
    cardNumber: any
  }
  
  private setConceptSettings!: Function
  private setSubscription!: Function

  mounted() {
    this.initStripe()   
    this.getPaymentIntent()         
  }

  private async initStripe() {
    let pubKey: any = process.env.VUE_APP_STRIPE_KEY    
    this.stripe = await loadStripe(pubKey);
    const elements = this.stripe.elements();
    this.cardElement = elements.create('card', {hidePostalCode: true});
    this.cardElement.mount('#card-element');
  }

  private async subscribe(e: any) {    
    
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
    e.target.disabled = true

    const {setupIntent, error} = await this.setupCard();
    if (error) {   
      
      e.target.innerHTML = defaultText
      e.target.disabled = false

      return this.$notify({
        title: "INFO",
        verticalAlign: "top",
        horizontalAlign: "right",
        message: error.message,
        type: "danger",
        icon: "fas fa-spinner fa-spin",
      });         
    } else {
      this.paymentMethod = setupIntent.payment_method;        
    }

    let payload: any = {
     'payment-method': this.paymentMethod
    }
        

    Subscription.createPaymentMethod(payload).then((response: any) => {            
      this.setSubscription(response.data.data)
      this.getPaymentIntent()
      this.$notify({
        title: "INFO",
        verticalAlign: "top",
        horizontalAlign: "right",
        message: `Payment method was updated successfully.`,
        type: "success",
        icon: "fas fa-spinner fa-spin",
      });       
      this.cardElement.clear()
      e.target.innerHTML = defaultText
      e.target.disabled = false
    }).catch(err => {
      e.target.innerHTML = defaultText
      e.target.disabled = false
    })

  }

  private async setupCard() {    
    return await this.stripe.confirmCardSetup(this.paymentIntent.attributes['client-secret'], {
        payment_method: {
          card: this.cardElement,
          billing_details: {name: this.cardHolder}
        }
      }
    )
  }

  
  private getPaymentIntent() {
    Subscription.getPaymentIntent().then((response: any) => {      
      this.paymentIntent = response.data.data
      console.log(this.paymentIntent)
    })
  }
}














































































import moment from "moment";
import { Feedback as FeedbackModel} from "@/models";
import { Table, TableColumn, Button } from "element-ui";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'
import FeedbackRatings from './FeedbackRatings'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    FeedbackRatings,
  },
mixins: [translations],
})
export default class FeedbackList extends Vue {
  public translate!: Function
  @Prop() list!: Array<FeedbackModel>;
  @Prop() loading: boolean = false;
  @Prop() concept: any
  @Prop() location: any
  color: string = "white";
  currentPage: number = 1;
  feedback: FeedbackModel = {
    id: "",
    attributes: {
      name: "",
      email: "",

    },
  };
  openEdit: boolean = false;
  ratings: any = [];

  @Watch("loading", { deep: true })
  onLoadingChanged(newVal: any) {
    this.loading = newVal;
  }

  showRating(value: any) {
    this.openEdit = value;
  }

  checklocation(loc: any) {
    if(this.location.id == loc) {
      return this.location.attributes.name;
    }
  }

  handleClose(value: any) {
    this.openEdit = value;
  }

  onEdit(feedback: any) {
    let ids = feedback.relationships.ratings.data.map((item: any) => {return item.id});
    /* @ts-ignore */
    this.ratings = this.list.included.filter((item: any) => {return ids.includes(item.id)});
    this.openEdit = true;
  }
 
  formatDate(date: string) {
    return moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .local()
      .format("YYYY/MM/DD hh:mm A");
  }

  getHandIcon(sentiment: string) {
    if (! sentiment) {
      return;
    }

    let img = null;
    switch (sentiment.toLowerCase()) {
      case 'positive':
        img = require(`@/assets/images/icons/thumbsup2.png`);
        break;
      case 'negative':
        img = require(`@/assets/images/icons/thumbsdown1.png`);
        break;
      default:
        img = require(`@/assets/images/icons/greyhand.png`);
    }

    return img;
  }
}

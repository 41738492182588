import BaseService from '../base.service'
import store from '@/store'

export default class SettingsOrderStatusesService extends BaseService{
  url!: string
  constructor(){
    super();        
  }
  
  all(lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    const concept = store.getters['account/activeConcept'].key
    this.url = `${this.baseURL}concepts/${concept}/statuses`
    return super.get(this.url)
  }

  update(id: string, payload: any = {}, lang: string = 'en-us') {    
    this.headers['Accept-Language'] = lang
    const concept = store.getters['account/activeConcept'].key
    this.url = `${this.baseURL}concepts/${concept}/statuses/${id}`
    payload['_method'] = 'patch'
    return this.post(this.url, payload)
  }
}
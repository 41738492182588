import { TimeEvent } from '@/models';
import BaseService from '../base.service'

export default class MenuService extends BaseService{
    [x: string]: any;
    url: string = ''
    constructor(){
        super();
    }

    all(page = 1){
        let url = this.baseURL + 'menus';

        return super.get(url, {
            page: page
        });
    }

    exludeModifierItem(payload: Object = {}, menuId: string, categoryId: string, itemId: string, modifierGroupId: string) {
        this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups/${modifierGroupId}/modifiers/excludes`
        return super.post(this.url, payload)
    }

    includeModifierItem(payload: Object = {}, menuId: string, categoryId: string, itemId: string, modifierGroupId: string, modifierId: string) {
        this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups/${modifierGroupId}/modifiers/excludes/${modifierId}`
        return super.remove(this.url, payload)
    }

    // inactiveModifiers(locationId: string|number, modId: string|number) {
    //     this.baseURL = `locations/${locationId}/inactive-modifiers?filter[id]=${modId}`;
    //     // https://api.solo.skylinedynamics.com/locations/location_id/inactive-modifiers?filter%5Bid%5D=id
    // }

    removeModifierItem(modifierItemId: string) {
        this.url = this.baseURL + `modifiers/${modifierItemId}`
        return super.remove(this.url, {})
    }

    storeModifierItem(payload: FormData, modifierGroupId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.headers['Content-Type'] = 'multipart/form-data'
        this.url = this.baseURL + `modifier-groups/${modifierGroupId}/modifiers`
        return super.post(this.url, payload)
    }

    updateModifierItem(payload: any, modifierGroupId: string, modifierGroupItemId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `modifier-groups/${modifierGroupId}/modifiers/${modifierGroupItemId}`
        return super.post(this.url, payload)
    }

    updateComboItem(payload: any, menuId: any, comboId: any, componentId: any, itemId: any, lang: string = 'en-us') {
        // console.log("return combo item", payload); return false;
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/${menuId}/combo-meals/${comboId}/combo-components/${componentId}/items/${itemId}`
        return super.post(this.url, payload)
    }

    getModifierItemDetails(modifierId: string, modifierItemId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang;
        this.url = this.baseURL + `modifier-groups/${modifierId}/modifiers/${modifierItemId}?filter[enabled]=all&modifiers=all&include=locations`
        return super.get(this.url, {}, this.headers)
    }

    getModifierItem(menuId: string, categoryId: string, itemId: string, modifierId: string, lang: string = 'en-us', page: any = 1) {
      this.headers['Accept-Language'] = lang;
      this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups/${modifierId}/modifiers?filter[enabled]=all&modifiers=all&page=${page}`
      return super.get(this.url, {}, this.headers);
    }

    getItemModifierGroupDetails(menuId: string, categoryId: string, itemId: string, modifierGroupId: string) {
        this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups/${modifierGroupId}`
        return super.get(this.url)
    }

  getItemModifierGroups(menuId: string, categoryId: string, itemId: string, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups?include=modifier-groups`

    return super.get(this.url, {}, this.headers);
  }

    getItemModifierGroup(menuId: string, categoryId: string, itemId: string, modifierGroupId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups/${modifierGroupId}?include=locations,modifiers`

        return super.get(this.url, {}, this.headers);
    }

    updateItemModifierGroup(payload: Object = {},itemId: string, modifierGroupId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.headers['Content-Type'] = 'multipart/form-data'

        this.url = this.baseURL + `menus/0/categories/0/items/${itemId}/modifier-groups/${modifierGroupId}`

        return super.post(this.url, payload)
    }

    removeComboComponent(componentId: string, componentItemId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/1/combo-meals/1/combo-components/${componentId}/items/${componentItemId}`

        return super.remove(this.url, {})
    }

    updateComboComponent(payload: Object = {}, itemId: string, componentId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/1/combo-meals/${itemId}/components/${componentId}`

        return super.post(this.url, payload)
    }

    createComboComponent(payload: Object = {}, itemId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/1/combo-meals/${itemId}/components`

        return super.post(this.url, payload)
    }

    getComboComponentItems(componentId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/1/combo-meals/1/combo-components/${componentId}/items`

        return super.get(this.url)
    }


    linkComponentItems(payload: Object = {}, componentId: string | number, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `menus/1/combo-meals/1/combo-components/${componentId}/items`
        console.log(payload, componentId, url)

        return super.post(url, payload)
    }


    searchComboItems(menuId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/${menuId}/items?filter[is-combo]=0`

        return super.get(this.url)
    }

    getComboComponents(componentId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/1/combo-meals/1/combo-components/${componentId}?include=combo,category,menu`
        return super.get(this.url)
    }

    getComboItem(menuId: string, itemId: string, componentId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `menus/${menuId}/combo-meals/${itemId}/components/${componentId}`

        return super.get(url)
    }

    deleteComboItem(menuId: string, itemId: string, componentId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `menus/${menuId}/combo-meals/${itemId}/components/${componentId}`

        return super.remove(url, {})
    }

    getComboItems(menuId: string, itemId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `menus/${menuId}/combo-meals/${itemId}/components?include=comboItems`

        return super.get(url)
    }

    find(menuId: string | number, categoryId: string | number, lang: string = 'en-us'){
        this.headers['Accept-Language'] = lang
        // let url = this.baseURL + `menus/${menuId}/categories/${categoryId}?include=locations,apps`
      let url = this.baseURL + `menus/${menuId}/categories/${categoryId}`

        return super.get(url, {
          include: 'locations,apps'
        }, this.headers);
    }

    getTimedEvents(menuId: string | number, categoryId: string, itemId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/timed-events`

        return this.get(this.url)
    }

    getCategoryTimedEvents(categoryId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `categories/${categoryId}/timed-events`

        return this.get(this.url)
    }

    storeTimedEvents(event: TimeEvent, payload: Object = {},menuId: string | number, categoryId: string, itemId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        if(!event.saved) {
            this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/timed-events`
            return this.post(this.url, payload)
        }else {
            this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/timed-events/${event.id}`
            return this.patch(this.url, payload)
        }

    }

    storeCategoryTimedEvents(event: TimeEvent, payload: Object = {},categoryId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        if(!event.saved) {
            this.url = this.baseURL + `categories/${categoryId}/timed-events`
            return this.post(this.url, payload)
        }else {
            this.url = this.baseURL + `categories/${categoryId}/timed-events/${event.id}`
            return this.patch(this.url, payload)
        }

    }

    deleteTimedEvents(eventId: string | number, menuId: string | number, categoryId: string, itemId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/timed-events/${eventId}`

        return super.remove(this.url, {})
    }

    deleteCategoryTimedEvents(eventId: string | number, categoryId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `categories/${categoryId}/timed-events/${eventId}`

        return super.remove(this.url, {})
    }

    items(menuId: string | number, categoryId: string | number, itemId: string | number, lang: string = 'en-us'){
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}?include=locations,apps,excluded-connections,out-of-stock-locations`

        return super.get(url, {}, this.headers)
    }

    findItemsByLocation(menuId: number, categoryId: number, locationId: number) {
        // let url = this.baseURL + `menus/${menuId}/categories/${categoryId}/revel-items?filter[location-id]=${locationId}`
        let url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items?filter[location-id]=${locationId}`
        return super.get(url)
    }

    update(payload = {}, menuId: string | number, categoryId: string | number, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `menus/${menuId}/categories/${categoryId}`

        return super.post(url, payload);
    }

    updateItem(payload = {}, menuId: string | number, categoryId: string | number, itemId: string | number, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}`

        return super.patch(url, payload);
    }

    updateFormdata(payload = {}, menuId: string | number, categoryId: string | number, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.headers['Content-Type'] = 'multipart/form-data'
        let url = this.baseURL + `menus/${menuId}/categories/${categoryId}`

        return super.post(url, payload);
    }

    updateItemFormdata(payload = {}, menuId: string | number, categoryId: string | number, itemId: string | number, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.headers['Mime-Type'] = 'multipart/form-data'
        this.headers['Content-Type'] = 'false'
        let url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}`

        return super.post(url, payload);
    }

    upsells(menuId: string | number){

      let url = this.baseURL + 'menus/'+ menuId +'/upsells'

      return super.get(url)
    }

    deleteMenu(menuId: string) {
      let url = this.baseURL + `menus/${menuId}`;
      return super.remove(url, {});
    }
    
    hardReset(menuId: string) {
      let url = this.baseURL + `menu-reset/${menuId}`;
      return super.remove(url, {});
    }

    create(payload: any) {
      const url = `${this.baseURL}menus`;
      return super.post(url, payload);
    }
}

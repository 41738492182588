



















































import moment from "moment";
import { Loyalty as LoyaltyModel } from "@/models";
import { Table, TableColumn, Button } from "element-ui";
import { Component, Prop, Vue, Watch, Mixins } from "vue-property-decorator";
import Translations from '@/mixins/translations';
import { mapGetters } from "vuex";

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },

  computed: {
    ...mapGetters({
      getConcept: 'account/activeConcept',
    })
  },
})
export default class LoyaltyList extends Mixins(Translations) {
  @Prop() list!: Array<LoyaltyModel>;
  @Prop() loading: boolean = false;
  color: string = "white";
  currentPage: number = 1;
  getConcept: any;

  @Watch("loading", { deep: true })
  onLoadingChanged(newVal: any) {
    this.loading = newVal;
  }

  formatDate(date: string) {
    return moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .local()
      .format("MMM D YYYY hh:mm A");
  }
}

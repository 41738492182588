export default {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if(!event.target.classList.contains('filter-concept') &&
        !event.target.classList.contains('navbar-text') &&
        !event.target.classList.contains('navbar-text') &&
        !event.target.classList.contains('nav-item') &&
        !event.target.classList.contains('nav-link'))
        vnode.context.$root.conceptIsVisible = false
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
};

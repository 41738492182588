













































































import {
  Alert,
  Button,
  Checkbox,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Upload
} from "element-ui"
import VueHorizontalCalendar from 'vue-horizontal-calendar'
import moment from "moment"
import {Component, Vue} from "vue-property-decorator"
import subscriptionMealEvent from "@/events/subscriptionMealEvent"
import {Item} from '@/services/SOLO'

interface Meal {
  'main-dish': {
    id?: any
  },
  drinks: {
    id?: any
  },
  snacks: {
    id?: any
  }
}

interface SetDay {
  day?: any,
  meals?: any | {
    breakfast?: Meal,
    lunch?: Meal,
    dinner?: Meal
  }
}

interface SetMeals {
  week?: any,
  days?: any | SetDay
}

@Component({
  components: {
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Switch.name]: Switch,
    [Input.name]: Input,
    [Button.name]: Button,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    VueHorizontalCalendar
  },
  computed: {
    getMinimumDate() {
      return moment().format('YYYY-MM-DD')
    },
    getThisMondayDate() {
      let today = new Date()
      let today_weekCode = today.getDay() == 0 ? 7 : today.getDay()
      let monday_timestamp = today.getTime() - (today_weekCode - 1) * 1000 * 3600 * 24
      let monday = new Date(monday_timestamp)
      return monday.getFullYear() + "/" + (monday.getMonth() + 1) + "/" + monday.getDate()
    }
  },

  filters: {
    fieldTitle(str: any) {
      return str.split('-').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    smallTitle(str: any) {
      return str.split(' ').map((item: any) => {
        return item.charAt(0).toLowerCase() + item.substring(1);
      }).join(' ');
    }
  }
})
export default class WeekPicker extends Vue {
  selectedMenuItem: any = ''
  loading: boolean = false
  $refs: any
  countWeek: any = ''
  actualWeekCount: any = ''
  currentWeek: any = ''
  currentDate: any = {
    day: '',
    date: '',
    year: '',
    month: '',
    timestamp: '',
    dateFormat: ''
  }
  meals: any = []
  getMinDate: any = moment().format('YYYY-MM-DD')
  getMaxDate: any = moment().format('YYYY-MM-DD')
  subscriptionForm: any = []
  currentInclusions: any = []
  mainDishArr: any = []
  daysArr: any = []
  snacksArr: any = []
  drinksArr: any = []
  form: any = []
  formSetMeals: any = []
  currentSelectedDay: any = {
    day: '',
    meals: {}
  }
  currentPrice: any = 0
  calculatedPrice: any = 0
  isRender: boolean = false
  itemPrice: any = []
  imageHolder: any = ''
  isDataLoaded: boolean = false
  isNewPlan: boolean = false

  mounted() {
    this.calculatedPrice = parseFloat(this.currentPrice).toFixed(2)
    this.actualWeekCount = 1
    this.currentWeek = 1
  }

  created() {
    subscriptionMealEvent.$on('change', (data: any) => {
      this.formSetMeals = []
      Object.entries(data).map((v: any) => {
        this.subscriptionForm[v[0]] = v[1]
      })

      this.meals = this.subscriptionForm.meals
      this.maxDate(Number(this.subscriptionForm.number_of_weeks))
      this.countWeek = this.subscriptionForm.number_of_weeks ?? 1
      this.actualWeekCount = 1
      this.currentInclusions = this.subscriptionForm.inclusions
      this.formSetMeals = this.subscriptionForm.setmeals
      this.calculatedPrice = parseFloat(this.subscriptionForm.price).toFixed(2)
      this.isNewPlan = this.subscriptionForm.is_new_plan
      // rendering menu items
      this.renderMenuItems(this.formSetMeals)

    })
  }

  maxDate(week: any) {
    if (week === 1) {
      this.getMaxDate = moment(this.getMinDate).add(6, 'days').format('YYYY-MM-DD')
    } else if (week > 1) {
      let actualDays = (Number(week) * 7) - 1
      this.getMaxDate = moment(this.getMinDate).add(actualDays, 'days').format('YYYY-MM-DD')
    }
  }

  renderMenuItems(data: any) {
    let aWrapper:any = {}
    let bWrapper:any = []

    data.map((s: any) => {
      s.days.map((e: any) => {
        if (this.currentSelectedDay.day === e.day) {
          this.currentSelectedDay.meals = e.meals

          Object.values(e.meals).map((a:any) => {
            aWrapper = Object.values(a).map((c:any) => {
              if (c.id !== null && c.id !== '') {
                return c.id
              }
            })
            bWrapper.push(...new Set(aWrapper))
          })
        }
      })
    })
    let finarArr = bWrapper.filter((x:any) => {
      return x !== undefined
    })
    finarArr = finarArr.map((i:any) => Number(i))
    Item.fetchItemByGroup(finarArr).then((response: any) => {
      this.mainDishArr = response.data.data
    })
  }

  onSelectedDay(date: any) {
    date.day = moment(date.dateFormat, 'YYYY/MM/DD').locale('en').format('ddd')
    let meal: any = {}
    this.currentDate = date;
    this.currentSelectedDay.day = this.currentDate.day

      if (this.formSetMeals.length > 0) {

        this.formSetMeals.map((s: any) => {
          s.days.map((e: any) => {
            if (this.currentSelectedDay.day === e.day) {
              this.meals.map((m: any) => {
                return meal[m] = {
                  'main-dish': {
                    id: ''
                  },
                  snacks: {
                    id: ''
                  },
                  drinks: {
                    id: ''
                  }
                }
              })
              this.currentSelectedDay.meals = meal
            }
          })
        })
      }
  }

  decWeekCount() {
    if (this.actualWeekCount <= this.countWeek && this.actualWeekCount !== 1) this.actualWeekCount--
    this.currentWeek = this.actualWeekCount
  }

  incWeekCount() {
    if (this.countWeek > this.actualWeekCount) this.actualWeekCount++
    this.currentWeek = this.actualWeekCount
  }

  filterItem(query: any) {
    if (query.length >= 3) {
      this.mainDishArr = []
      Item.fetchItemByName(query).then((response: any) => {
        this.mainDishArr = response.data.data
      })
    }
  }

  storeMeal(event: any) {
    Object.values(this.subscriptionForm.setmeals).map((m: any) => {
      m.days.map((d: any, i: any) => {
        if (d.day === this.currentSelectedDay.day) {
          d.meals = this.currentSelectedDay.meals
        }
      })
    })
    this.getItemPrice(event)
    this.$emit('dataPlan', this.subscriptionForm)
  }

  async getItemPrice(id: any) {
    await Item.fetchItemById(id).then((response: any) => {
      this.itemPrice.push(response.data.data[0].attributes.price)
      this.calculatedPrice = WeekPicker.getArraySum(this.itemPrice)
      this.subscriptionForm.price = Number(this.calculatedPrice)
      this.subscriptionForm.plan_image = this.imageHolder
    })
  }

  private static getArraySum(a: any) {
    let total: any = 0;
    for (let x in a) {
      total += a[x];
    }
    return parseFloat(total).toFixed(2);
  }

}











































import { Table, TableColumn, Button, Form, FormItem, Select, Option, Checkbox } from 'element-ui'
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Coupon } from '@/services/SOLO'
import { translations, notificationAlerts } from '@/mixins'
import Translations from './Translations.vue'
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    ValidationProvider,
  },
mixins: [translations, notificationAlerts],
})
export default class CustomerUpdateModal extends Vue {
  @Prop() openCreate: boolean = false;
  @Prop() employee?: Object;
  public translate!: Function;
  id: any;
  isOpen: boolean = false;
  modalForm: any =  {
    "name": '',
  };
  roles: any = [];
  locations: any = [];
  private req: Function = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback(new Error('This field is required'));
    } else {
      callback();
    }
  };
  private reqValidator = [
    {
      validator: this.req,
      trigger: 'blur',
    },
  ];
  rules = {
    ['group-name']: this.reqValidator,
  };
  successNotification!: Function;
  systemErrorNotification!: Function;
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  mounted() {
  }
  @Watch('openCreate', { deep: true })
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }

  handleClose() {
    this.clearForm();
    this.$emit('handleClose', false);
  }

  clearForm() {
    this.modalForm.name = null;
  }

  onCreateCoupon(e: HTMLFormElement, invalid: boolean) {
    if (!invalid) {
      Coupon.createCouponsGroup(this.modalForm)
        .then(() => {
          this.clearForm();
          // this.$emit('updateList', true);
          this.$emit('handleClose', false);
          this.successNotification('COUPON GROUP CREATED!', 'Coupon successfully created!');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err: any) => {
          console.log('response: ', err.response);
          let error = '';
          for (const prop in err.response.data.error) {
            err.response.data.error[prop].forEach((e: any) => {
              error += e + '\n';
            })
          }
          console.log('error: ', error);
          this.systemErrorNotification('CREATE ERROR!', error);
        });
    }
  }
}















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import * as Ably from 'ably';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Table, TableColumn, Select, Button, Option} from 'element-ui'
import DriversModal from './components/DriversModal.vue'
import ExportAllOrderNoti from './components/exportOrderAllNoti.vue'

import LogisticsModal from './components/LogisticsModal.vue'
import AssignModal from './components/assignModal.vue'
import ButtonAssign from './components/ButtonAssign.vue'
import AcceptButton from './components/AcceptButton.vue'

import OrderStatus from './components/OrderStatus.vue'
import OrderReceipt from './components/OrderReceipt.vue'
import buttonAssignLogistics from './components/buttonAssignLogistics.vue'
import buttonAssignDriver from './components/buttonAssignDriver.vue'
import {
  Order as OrderApi,
  Integrations,
  Order,
  Location as LocationApi,
  Employee,
  Connect as ConnectService
} from "@/services/SOLO";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {ValidationObserver, configure} from 'vee-validate'
import {Component, Prop, Vue, Watch, Emit, Ref} from "vue-property-decorator";
import {Driver, DriverAttributes, Location, Logistics} from '@/models'
import moment from "moment";
import "moment/locale/pt-br";
import {eventHandler} from '@/mixins'
import {mapGetters} from 'vuex';
import sound from '../../../../../public/sound/default_notif.wav'
import {BButton} from 'bootstrap-vue';
import {translations} from '@/mixins'
import Translations from './components/Translations.vue'
import Card from '@/components/Cards/Card.vue';
import { truncate } from '@/util/truncate';
import CountdownTimer from './components/CountdownTimer.vue';

interface Filter {
  order: string,
  from: string,
  to: string,
  customer: string,
  email: string,
  mobile: string,
  location: any,
  status: string,
  statusGroup?: string,
  today: Boolean,
  type: string,
  'exclude-statuses'?: string,
  source: string,
  'payment-type': string,
}

@Component({
  components: {
    RouteBreadCrumb,
    buttonAssignDriver,
    DriversModal,
    AssignModal,
    ButtonAssign,
    AcceptButton,
    ExportAllOrderNoti,
    buttonAssignLogistics,
    LogisticsModal,
    OrderStatus,
    OrderReceipt,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    flatPicker,
    Card,
    CountdownTimer,

  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      userRole: 'account/getUserRole',
      user: 'account/getUser',
      getLocale: 'app/getLocale',

    })
  },
  filters: {
    datetime(value: any, format: string = "YYYY-MM-DD HH:mm") {
      return moment.utc(value).locale("en-us").fromNow();
    },
    datetime1(value: any, format: string = "YYYY-MM-DD HH:mm") {
      return moment.utc(value).locale("ar-sa").fromNow();
    },
    circleColor(finishedTime: any, promisedTime: any) {
      let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
      let end = moment.utc(promisedTime);
      let diff = moment.duration(start.diff(end)).asMinutes();
      if (diff > 2) {
        // console.log('text-danger');
        return 'text-danger'
      } else if (diff > 0) {
        // console.log('text-warning');
        return 'text-warning';
      } else {
        // console.log('text-success');
        return 'text-success';
      }
    },
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY HH:mm");
    }
  },
  mixins: [eventHandler, translations]
})
export default class Orders extends Vue {
  toggleView: Boolean = false;
  activeConcept: any
  userRole: any
  user: any
  canPlayNotificationSound: Boolean = false;
  driverOpen: Boolean = false
  assignOpen: Boolean = false
  exportOrderNotiOpen: Boolean = false
  logisticsOpen: Boolean = false
  page: number = 1;
  size: number = 7;
  source: string = "Source"
  posTooltip: string = ""
  paymentMethod: string = "Payment"
  type: string = "Type"
  orders: any = [];
  maxOrders: any = [];
  filteredOrders: any = [];
  driverData: Object = {}
  logisticData: Object = {}
  logistics: Array<Logistics> = []
  statuses: Array<Object> = []
  storeLocations: Array<Object> = []
  isLoading: Boolean = false
  loaded: Boolean = false
  visible: Boolean = false
  filter: Filter = {
    order: '',
    from: '',
    to: '',
    customer: '',
    email: '',
    mobile: '',
    location: [],
    status: '',
    statusGroup: 'all',
    today: true,
    type: '',
    source: '',
    'payment-type': '',
  }
  erpDate: string = ''
  flag: Boolean = true;
  rows: number = 0
  perPage: number = 50
  currentPage: number = 1
  eventTab: any = ''
  confirm!: Function
  broadcasted!: Function
  $notify: any
  getLocale: any
  notif!: Function
  public translate!: Function
  $refs!: {
    sound: HTMLAudioElement,
    playNewOrderAudio: any
  }
  isVisible: boolean = false;
  locationIds: string = '';
  employeeLocations: any = [];
  aggregators: any[] = [];
  isPlay: boolean = false;
  isMobile: boolean = false;
  isCanPlayNotificationSoundInit: boolean = false;
  webNotif: any;
  ablyUnsubscribe: any;
  screenSize: number = 0;
  trunc: Function = truncate;
  audio: any;
  notificationType: string = '';
  driverName: string = ''
  orderIncludes: Array<Object> = [];
  sources: Array<Object> = [
    {
      value: 'iOs',
      text: "iOS",
    },
    {
      value: 'Android',
      text: "Android",
    },
    {
      value: 'Web',
      text: "Web",
    },
    {
      value: 'Kiosk',
      text: "Kiosk",
    },
        {
      value: 'Call Center',
      text: "Call Center",
    },
  ];
  paymentMethods: Array<Object> = [
    {
      value: '',
      text: "All",
    },
    {
      value: 'cash',
      text: "Cash",
    },
    {
      value: 'card',
      text: "Card",
    },
    {
      value: 'payment-decline',
      text: "Payment Decline",
    },
  ];

  private locations: Array<Object> = [];
  tab: number = 1;
  orderType: string = 'open';
  countAbly: number = 0;

  @Watch("canPlayNotificationSound", { immediate: true, deep: true })
  onCanPlaySoundChanged(val: any) {
    if (!this.isCanPlayNotificationSoundInit) {
      this.isCanPlayNotificationSoundInit = true;
    } else {
      window.localStorage.setItem('sound', val);
    }
    if (val) {
      console.log('val: ', val);
      // if (!this.isPlay) {
      //   let audio = document.getElementsByTagName("audio")[0];
      //   audio.play().then(() => {
      //   }).catch((e) => {
      //   });
      //   this.isPlay = true;
      // }
    }
  }

  private get isMobileDevice(): Boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  private get isTabletDevice(): Boolean {
    return /Android|webOS|iPad|iPod|BlackBerry|Opera Mini/i.test(navigator.userAgent);
  }

  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Active')
    } else {
      return this.translate('Close')
    }
  }

  mounted() {
    // this.getEmployeeLocations();

    this.erpDate = new Date().toJSON().slice(0,10).replace(/-/g,'-');
    console.log('erpDate', this.erpDate);

    this.getLocations(1);

    this.audio = document.getElementsByTagName("audio")[0];

    if (!('Notification' in window)) {
      console.error('This browser does not support desktop notification');
    } else if (Notification.permission === 'default' || Notification.permission === 'denied') {
      Notification.requestPermission();
    }

    window.addEventListener('resize', this.screensizeHandler);

    if (this.isMobileDevice) {
      this.isMobile = true;

      const h = this.$createElement
      // More complex structure
      const titleVNode = h('div', { domProps: { innerHTML: '<div style="direction: rtl !important">انتباه!<div>' } })
      const msgVNode = h('div', { domProps: { innerHTML: '<p style="direction: rtl !important; text-align: center !important">هل تريد تشغيل صوت عند تلقي طلب جديد؟<p>' } })
      const yesVNode = h('p', { domProps: { innerHTML: '<p style="direction: rtl !important; height: 6px !important">نعم!<p>' } })
      const noVNode = h('p', { domProps: { innerHTML: '<p style="direction: rtl !important; height: 6px !important">لا!<p>' } })

      if (this.getLocale === 'ar_SA') {
        this.confirm(
          this.$bvModal,
          msgVNode,
          {
            title: titleVNode,
            okTitle: yesVNode,
            cancelTitle: noVNode,
          },
        ).then(async (value: boolean) => {
          this.canPlayNotificationSound = value;
          this.initAudio();
        });
      } else {
        this.confirm(
          this.$bvModal,
          'Do you want to play a sound when an order is received?',
          {
            title: 'Attention!',
            okTitle: 'YES',
            cancelTitle: 'NO',
          },
        ).then(async (value: boolean) => {
          this.canPlayNotificationSound = value;
          this.initAudio();
        });
      }
    } else {
      if (window.localStorage.getItem('sound') === 'true') {
        let hasClickedOnce = false;
        document.onclick = () => {
          console.log('trigger click event');
          if (!hasClickedOnce) {
            hasClickedOnce = true;
            this.initAudio();
          }
        };

        this.canPlayNotificationSound = true;
      }
    }

    this.getStoreLocations()
      .then(() => {
        this.getLogisticPartners();
        this.pushedNotification();
        return this.getOrderStatuses();
      })
      .then(() => {
        this.getOrders(this.orderType);
      });

    // this.getAggregators();
  }

  get eventDropDownText(): string {
    if (this.eventTab === 1) {
      return this.translate('Orders')
    } else {
      return this.translate('Events')
    }                   
  }

  viewCashier(evnt: Boolean) {
    this.toggleView = evnt;
  }

  viewAdmin(evnt: Boolean) {
    this.toggleView = evnt;
  }

  cancelOrder(orderId: any) {
    let payload = {
        'order-status': 'cancelled-by-employee',
    };
    OrderApi.updateStatus(payload, orderId)
      .then((response: any) => {
        this.$notify({
          title: "STATUS CHANGED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Status have been successfully changed",
          type: "success",
          icon: "fas fa-check",
        });
        location.reload();
      })
      .catch((err: any) => {
        const message = JSON.parse(err?.response?.data?.messages)?.errors?.status[0]
          || JSON.parse(err?.response?.data?.messages)?.message
          || 'Something went wrong, please try again!';
        // console.log('err:' , JSON.parse(err?.response?.data?.messages));
        // this.description = this.orderStatusCode;
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message,
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }

 updateStatusChange(orderId: any) {
    let payload = {
        'order-status': 'ready',
    };
    OrderApi.updateStatus(payload, orderId)
      .then((response: any) => {
        this.$notify({
          title: "STATUS CHANGED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Status have been successfully changed",
          type: "success",
          icon: "fas fa-check",
        });
        location.reload();
      })
      .catch((err: any) => {
        const message = JSON.parse(err?.response?.data?.messages)?.errors?.status[0]
          || JSON.parse(err?.response?.data?.messages)?.message
          || 'Something went wrong, please try again!';
        // console.log('err:' , JSON.parse(err?.response?.data?.messages));
        // this.description = this.orderStatusCode;
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message,
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }

  initAudio(): void {
    this.audio.muted = true;
    this.audio.play();
    setTimeout(() => {
      this.audio.pause();
      this.audio.currentTime = 0;
    }, 200);
  }

  testtest(){
    alert("asdasd");
  }

  destroyed() {
    if (this.webNotif) {
      this.webNotif.close();
      this.webNotif = null;
    }
    if (this.ablyUnsubscribe) {
      this.ablyUnsubscribe.unsubscribe();
      this.ablyUnsubscribe = null;
    }
    window.removeEventListener('resize', this.screensizeHandler);
  }

  screensizeHandler() {
    this.screenSize = screen.width;

    if (this.isMobileDevice) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.$forceUpdate();
  }

  disabled(check: any) {
    if(check === true) {
      return 'pointer-events: none';
    }
    return 'pointer-events: auto';
  }


  getLocations(page: any) {
    let lang = 'en-us'
     LocationApi.fetchLocations(lang, page)
        .then((response: any) => {
          if (response?.data?.data && Array.isArray(response?.data?.data)) {
            this.locations = [...this.locations, ...response?.data?.data];
          }

          if (
              response.data.meta.pagination.current_page <
              response.data.meta.pagination.total_pages
          ) {
            this.getLocations(response.data.meta.pagination.current_page + 1);
          }
          console.log("total loc", this.locations);
          this.$forceUpdate();
        })
        .catch((err: any) => {
          console.log(err);
        });
  }

  // async getAggregators() {
  //   try {
  //     let { data } = await ConnectService.getlAggregators();
  //     this.aggregators = data.data;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  getEmployeeLocations() {
    if (!this.employeeLocations.length) {
      return Employee.find(this.user.id)
        .then((response) => {
          if (response.data.included) {
            this.employeeLocations = response.data.included;

            console.log("employee location", this.employeeLocations);
          }
        });
    }
    return Promise.resolve();
  }

  getFirstName(customerName: string) {
    return customerName ? customerName: 'N/A';
  }

  closeOrder() {
    this.flag = true;
    this.visible = true;
  }

  openOrder() {
    this.flag = true;
  }

  circleTooltip(finishedTime: any, promisedTime: any) {
    let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
    let end = moment.utc(promisedTime);
    let diff = moment.duration(start.diff(end)).asMinutes();
    if (diff > 2) {
      return 'Past the promised time'
    } else if (diff > 0) {
      return 'Slightly past the promised time';
    } else {
      if (!finishedTime) {
        return 'Not finished';
      } else {
        return 'Finished on time';
      }
    }
  }

  posColor(code: number, posResponse: any) {
    if (code && posResponse) {
      this.posTooltip = "Order successfully sent to POS";
      return 'success'

    } else if (!code && posResponse) {
      this.posTooltip = "Order failed in POS";
      return 'danger'
    } else {
      this.posTooltip = "";
      return 'secondary'
    }
  }

  tooltip(code: number, posResponse: any) {
    if (code && posResponse) {
      return 'Order successfully sent to POS'
    } else if (!code && posResponse) {
      return 'Order failed in POS'
    } else {
      return ''
    }
  }

  paginate(page: number) {
    this.page = page
    this.getOrders(this.orderType)
  }

  resetForm(reset: any) {
    this.filter.order = ''
    this.filter.from = ''
    this.filter.to = ''
    this.filter.customer = ''
    this.filter.email = ''
    this.filter.mobile = ''
    this.filter.location = []
    this.filter.status = ''
    this.filter.today = true
    this.filter.type = ''
    this.filter.source = ''
    this.filter['payment-type'] = ''
    this.erpDate = new Date().toJSON().slice(0,10).replace(/-/g,'-');
  }

  filterLiveOrder(order: any) {
    const filter = { ...this.filter };
    let tmpFilterLocation: any = [];

    if (!filter.location.length && this.userRole === 'supervisor') {
      filter.location = this.storeLocations.map((s: any) => s.id).join();
    } else {
      tmpFilterLocation = [...filter.location];
      filter.location = filter.location.join();
    }

    const exclude = 'initiated,waiting-for-payment';
    if (filter.statusGroup === 'unpaid') {
      const statuses = this.statuses
        .filter((status: any) => !exclude.includes(status.attributes.code))
        .map((status: any) => status.attributes.code)
        .join();
      delete filter.statusGroup;
      filter['exclude-statuses'] = statuses;
    } else if (filter.statusGroup === 'paid') {
      const statuses = this.statuses
        .filter((status: any) => exclude.includes(status.attributes.code))
        .map((status: any) => status.attributes.code)
        .join();
      delete filter.statusGroup;
      filter['exclude-statuses'] = statuses;
    } else if (filter.statusGroup === 'all') {
      delete filter['exclude-statuses'];
      delete filter.statusGroup;
    }

    let toBeAdded = true;

    if (filter.customer && (filter.customer != order['customer-id'] || filter.customer != order['customer-name'])) {
      console.log('toBeAdded', 'customer', filter.customer, order['customer-id']);
      toBeAdded = false;
    }
    // if (filter.email && (filter.email != order['customer-email'])) {
    //   toBeAdded = false;
    // }
    if (filter.location && (!filter.location.includes(order['location-id']))) {
      console.log('toBeAdded', 'location', filter.location, order['location-id']);
      toBeAdded = false;
    }
    if (filter.mobile && (filter.mobile != order['mobile'])) {
      console.log('toBeAdded', 'mobile', filter.mobile, order['mobile']);
      toBeAdded = false;
    }
    if (filter.order) {
      console.log('toBeAdded', 'order', filter.order);
      toBeAdded = false;
    }
    if (filter.source.length && (!filter.source.includes(order['source']))) {
      console.log('toBeAdded', 'source', filter.source, order['source']);
      toBeAdded = false;
    }
    if (filter.status.length && (!filter.status.includes(order['status']))) {
      console.log('toBeAdded', 'status', filter.status, order['status']);
      toBeAdded = false;
    }
    let liveOrderType = '';
    if (order['current-status']['type'] == 'open') {
      liveOrderType = order['current-status']['type'];
    } else {
      liveOrderType = 'closed';
    }
    if (!this.orderType.includes(liveOrderType)) {
      console.log('toBeAdded', 'orderType', this.orderType, liveOrderType);
      toBeAdded = false;
    }
    if (!filter.today) {
      console.log('toBeAdded', 'today', filter.today);
      let from = new Date(this.filter.from);
      let to = new Date(this.filter.to);
      let orderUpdatedAt = new Date(order['updated-at']);

      if (from.getTime() < orderUpdatedAt.getTime() && to.getTime() > orderUpdatedAt.getTime()) {
        toBeAdded = true;
      } else {
        toBeAdded = false;
      }

      if (!this.filter.from || !this.filter.to) {
        toBeAdded = true;
      }
    }
    if (filter.type.length && (!filter.type.includes(order['type']))) {
      console.log('toBeAdded', 'type', filter.type, order['type']);
      toBeAdded = false;
    }
    if (filter['payment-type'].length && (!filter['payment-type'].includes(order['payment-method']))) {
      console.log('toBeAdded', 'payment-type', filter['payment-type'], order['payment-method']);
      toBeAdded = false;
    }
    if (this.tab == 3) {
      console.log('toBeAdded', 'delayed orders are not consider as live orders');
      toBeAdded = false;
    }

    console.log('toBeAdded', toBeAdded);

    return toBeAdded;
  }

  pushedNotification() {
    this.ablyUnsubscribe = this.broadcasted(this.activeConcept.id);
    this.ablyUnsubscribe.subscribe((payload: any) => {
      this.countAbly += 1;
      const isAdmin = this.userRole.includes('administrator');
      const { order } = payload?.data;
      console.log('broadcasting orders: ', payload);

      if (! this.filterLiveOrder(order)) {
        return;
      }

      // if (this.countAbly == 1) {    // for concepts that are not auto-accept
        // reset count of Ably response
        this.countAbly = 0;

        switch (payload.name) {
          case 'Solo\\Order':
            this.notificationType = 'solo-order';
            const exclude = 'initiated,waiting-for-payment';

            const paymentMethod = 'cash,card-on-pickup,card-on-delivery';
            if (!exclude.includes(payload?.data?.order?.status) || paymentMethod.includes(payload?.data?.order?.['payment-method'])) {
              if (payload?.data?.order?.['payment-method'] !== 'cash') {
                if (payload?.data?.order?.['payment-method'] === 'card-on-pickup' || payload?.data?.order?.['payment-method'] === 'card-on-delivery') {
                  this.getEmployeeLocations()
                    .then(() => {
                      const found = this.employeeLocations
                        .find((loc: any) => Number(loc.id) === Number(payload?.data?.order?.['location-id']));
                      if (isAdmin || found) {
                        this.findOrder(order);
                      }
                    });
                } else {
                  this.getEmployeeLocations()
                    .then(() => {
                      const found = this.employeeLocations
                        .find((loc: any) => Number(loc.id) === Number(payload?.data?.order?.['location-id']));
                      if (isAdmin || found) {
                        this.findOrder(order);
                      }
                    });
                }
              } else {
              this.getEmployeeLocations()
                .then(() => {
                  const found = this.employeeLocations
                    .find((loc: any) => Number(loc.id) === Number(payload?.data?.order?.['location-id']));
                  if (isAdmin || found) {
                    this.findOrder(order);
                  }
                });
              }
            }
            break;
          case 'Solo\\Order\\Status':
            this.notificationType = 'solo-order-status';
            this.getEmployeeLocations()
              .then(() => {
                const found = this.employeeLocations
                  .find((loc: any) => Number(loc.id) === Number(payload?.data?.order?.['location-id']));
                if (isAdmin || found) {
                  this.findOrder(order);
                }
              });
          break;
        }
    });
  }

  DriverModalOpenState(open: Boolean = true) {
    this.driverOpen = open
  }

  ExportOrderAllNotiModalOpenState(open: Boolean = true) {
    this.exportOrderNotiOpen = open
  }

  LogisticsModalOpenState(open: Boolean = true) {
    this.logisticsOpen = open
  }

  AssignModalOpenState(open: Boolean = true) {
    this.assignOpen = open
  }

  showDriver(data: Object) {
    this.driverData = data
    this.DriverModalOpenState(true)
  }


  showAssign(data: Object, data1: Object, dName: any) {
    this.driverData = data
    this.logisticData = data1
    this.driverName = dName;
    console.log("drivers", this.driverData);
    this.AssignModalOpenState(true);
  }

  showLogistics(data: Object) {
    this.logisticData = data
    this.LogisticsModalOpenState(true)
  }



  get orderItems() {
    if (this.orderIncludes.length) {
      return this.orderIncludes.filter((include: any) => {
        return include.type === "order-item";
      });
    }

    return [];
  }

  get modifiers() {
    if (this.orderIncludes.length) {
      return this.orderIncludes.filter((include: any) => {
        return include.type === "modifier";
      });
    }
    return [];
  }

  get ingredients() {
    if (this.orderIncludes.length) {
      return this.orderIncludes.filter((include: any) => {
        return include.type === "item-ingredient";
      });
    }

    return [];
  }

  getItemModifier(data: Array<any>, modifiers: Array<any>) {
    return data
      .map((d: any) => {
        let filtered: any = modifiers.filter((modifier: any) => {
          return modifier.id === d.id;
        });
        if (filtered.length) {
          console.log('getItemModifier: ', d, filtered[0]);
          return filtered[0];
        }
        return null;
      });
  }

  changeOrderDisplay(orderType: string, tab: any) {
    this.getOrders(orderType);
    this.tab = tab;
  }

  getOrders(orderType: string) {
    this.orderType = orderType;
    const filter = { ...this.filter };
    let tmpFilterLocation: any = [];
    this.orders = []
    this.isLoading = true
    this.loaded = false

    if (!filter.location.length && this.userRole === 'supervisor') {
      filter.location = this.storeLocations.map((s: any) => s.id).join();
    } else {
      tmpFilterLocation = [...filter.location];
      filter.location = filter.location.join();
    }

    const exclude = 'initiated,waiting-for-payment';
    if (filter.statusGroup === 'unpaid') {
      const statuses = this.statuses
        .filter((status: any) => !exclude.includes(status.attributes.code))
        .map((status: any) => status.attributes.code)
        .join();
      delete filter.statusGroup;
      filter['exclude-statuses'] = statuses;
    } else if (filter.statusGroup === 'paid') {
      const statuses = this.statuses
        .filter((status: any) => exclude.includes(status.attributes.code))
        .map((status: any) => status.attributes.code)
        .join();
      delete filter.statusGroup;
      filter['exclude-statuses'] = statuses;
    } else if (filter.statusGroup === 'all') {
      delete filter['exclude-statuses'];
      delete filter.statusGroup;
    }

    /* @ts-ignore */
    let copyFilter = _.cloneDeep(filter);
    copyFilter['status'] = filter['status'].concat(this.orderType);

    OrderApi.all(this.page, this.size, copyFilter).then((response) => {

      this.filter.location = [...tmpFilterLocation];
      this.isLoading = false;
      this.loaded = true;

      this.rows = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.currentPage = response.data.meta.pagination.current_page
      this.orders = response.data.data;

      if (this.tab == 3) {
        this.orders.forEach((order: any) => {
          let end = (order.attributes['finished-at'] ? moment.utc(order.attributes['finished-at']).local() : moment().utc().local());
          let start = moment.utc(order.attributes['promised-time']);
          
          let duration = moment.duration(start.diff(end));

          if (duration.asMinutes() <= 3) {
            order['is_delayed'] = true;
          }
        });

        this.orders = this.orders.filter((order: any) => {
          return order['is_delayed'];
        });
      }

      this.orderIncludes = response.data.included;

      if (this.isMobile) {
        const promiseArray: any = [];
        this.orders.forEach((order: any) => {
          promiseArray.push(OrderApi.findItems(order?.id));
        });
        Promise.all(promiseArray)
          .then((responses: any) => {
            this.orders.map((data: any, key: any) => {
              let items = responses?.[key].data?.data;
              const included = responses?.[key].data?.included;
              items = items.map((item: any) => {
                if (item?.relationships?.modifiers?.data) {
                  item.relationships.modifiers.data = item?.relationships?.modifiers?.data
                    .map((mod: any) => included.find((inc: any) => inc.id === mod.id));
                }
                return item;
              });
              data.attributes['show-receipt'] = true;
              data.attributes['order-items'] = items;
              this.$forceUpdate();
            });
          });
      }

      this.getOrderStatuses()
    });

    this.screenSize = screen.width;

    this.isVisible = false;
  }

  getOrderDetails(data: Array<any>, orderItems: Array<any>) {
    return data
      .map((d: any) => {
        let filtered: any = orderItems.filter((orderItem: any) => {
          return orderItem.id === d.id;
        });
        if (filtered.length) {
          console.log('getOrderItem: ', d, filtered[0]);
          return filtered[0];
        }
        return null;
      });
  }

  getStoreLocations() {
    return LocationApi.fetchLocations()
      .then((response: any) => (this.storeLocations = response.data.data));
  }

  playNotificationSound() {
    let audio = document.getElementsByTagName("audio")[0];
    audio.muted = false;
    if (this.canPlayNotificationSound) {
      audio.play().then(() => {
      }).catch((e) => {
      });
    }
  }

  async findOrder(order: any) {
    if (!this.filter.type.length || this.filter.type.includes(order['type'])) {
      if (this.notificationType == 'solo-order') {
        await OrderApi.find(order.id)
          .then((response: any) => {
            let res = response.data.data;
            res['live'] = true;
            this.updateOrders(res);
          });
      } else if (this.notificationType == 'solo-order-status') {
        order['live'] = true;
        const data = {
          id: order.id,
          attributes: order
        }
        this.updateOrders(data);
      }
    }
  }

  async refreshStatus(data: any) {
    await OrderApi.getOrderStatusSync(data)
      .then((response: any) => {
        let item = response.data.data
        console.log(item)
        this.orders.some((v: any, i: any) => {
          if (item.id === v.id) {
            this.orders[i].attributes['current-status'].description = item.attributes.code
            return true
          }
        })
      })
  }

  notify(title: string, message: string, order: any) {
    if (!this.isMobile) {
      this.webNotif = new Notification(title, {
        body: message,
      });
      this.webNotif.onclick = () => {
        this.webNotif = null;
        this.$router.push({ name: 'order-details', params: { id: order.id } });
      };
    } else {
      let config: any = { body: message };
      navigator.serviceWorker.register('/sw.js');
      navigator.serviceWorker.ready.then(function (registration) {
        config.data = order.id;
        registration.showNotification(title, config);
      });
    }
  }

  showNotification(title: string, message: string, order: any) {
    if (('Notification' in window)) {
      if (Notification.permission === 'granted') {
        this.notify(title, message, order);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            this.notify(title, message, order);
          }
        });
      }
    }
  }

  async updateOrder(data: any) {
    const { id, attributes } = data;
    const sr = this.translate('SAR');
    // const old = !!this.orders.find((ord: any) => Number(ord.id) === Number(id));
    const title = 'New order';
    const message = `New order #${id} for ${sr} ${attributes.total} at ${attributes['location-name']}.`;
    const findOrderIdx = this.orders.findIndex((ord: any) => Number(ord.id) === Number(id));

    //console.log('old: ', old);
    // Play sound and notification
    if (this.notificationType == 'solo-order') {
      if (this.userRole.includes('restaurant') && data.attributes['status'] == 'waiting-for-payment') {}  // skip sound and notification for this condition
      else {
        this.playNotificationSound();
        this.showNotification(title, message, data);
      }
    } else if (this.notificationType == 'solo-order-status') {
      if (data.attributes['payment-method'] == 'card' && data.attributes['status'] == 'posted') {
        this.playNotificationSound();
        this.showNotification(title, message, data);
      }
    }

    if (findOrderIdx > -1) {
      this.orders[findOrderIdx] = data;
      this.$forceUpdate();
    } else {
      if (this.isMobile) {
        OrderApi.findItems(id)
          .then((response) => {
            let items = response.data?.data;
            const included = response.data?.included;
            items = items.map((item: any) => {
              if (item?.relationships?.modifiers?.data) {
                item.relationships.modifiers.data = item?.relationships?.modifiers?.data
                  .map((mod: any) => included.find((inc: any) => inc.id === mod.id));
              }
              return item;
            });
            data.attributes['show-receipt'] = true;
            data.attributes['order-items'] = items;
            // this.orders.unshift(data);
            if (this.notificationType == 'solo-order') {
              if (this.userRole.includes('restaurant') && data.attributes['status'] == 'waiting-for-payment') {}  // don't update orders list for this condition
              else {
                this.orders.unshift(data);
              }
            } else if (this.notificationType == 'solo-order-status') {
              if (data.attributes['payment-method'] == 'card' && data.attributes['status'] == 'posted') {
                this.orders.unshift(data);
              }
            }
          });
      } else {
        if (this.notificationType == 'solo-order') {
          if (this.userRole.includes('restaurant') && data.attributes['status'] == 'waiting-for-payment') {}  // don't update orders list for this condition
          else {
            this.orders.unshift(data);
          }
        } else if (this.notificationType == 'solo-order-status') {
          if (data.attributes['payment-method'] == 'card' && data.attributes['status'] == 'posted') {
            this.orders.unshift(data);
          }
        }
      }
    }
  }

  updateOrders(data: any) {
    const isAdmin = this.userRole.includes('administrator');
    // GET employee locations
    // if already exist do not call API for performance
    this.getEmployeeLocations()
      .then(() => {
        const found = this.employeeLocations
          .find((loc: any) => Number(loc.id) === Number(data.attributes['location-id']));
        if (isAdmin || found) {
          this.updateOrder(data);
        }
      });
  }

  updateOrderIndex(data: any) {
    this.orders[data.index] = data.data;
  }

  iconSrc(src: string) {
    return `/img/order icons/${src.toLowerCase()}.png`;
  }

  getLogisticPartners() {
    Integrations.logisticsPartners('food-logistics')
      .then(response => (this.logistics = response.data.data));
  }

  getOrderStatuses() {
    return OrderApi.statusList()
      .then((response: any) => (this.statuses = response.data.data));
  }

  getActiveOrderStatuses() {
    return OrderApi.activeStatusList()
      .then((response: any) => (this.statuses = response.data.data));
  }

  exportOrder(orderId: any) {

    if (true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be downloaded in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportOrder(orderId)
        .then(response => {
          window.open(response.data.data.attributes['csv-uri'], '_blank');
        })
    }
  }

  exportAllOrder() {
        const filter = { ...this.filter };
        delete filter.statusGroup;

    if (true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be exported in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportAllOrder(filter)
        .then(response => {
          // window.open(response.data.data.attributes['csv-uri'], '_blank');
          this.ExportOrderAllNotiModalOpenState();
        })
    }
  }

  exportERP() {
    if (true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be exported in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportERP(this.erpDate)
        .then(response => {
          window.open(response.data.url, '_blank');
        })
        .catch(err => {
          this.$notify({
            title: "Export Error!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Something went wrong.",
            type: "danger",
            icon: "fas fa-bomb",
          });
        });
    }
  }

  NewExportOrderAllNoti(open: any) {
    this.exportOrderNotiOpen = open
  }

  closeExportOrderAllNotiSet(close: any) {
    this.exportOrderNotiOpen = close
  }

  filterActiveDrivers(drivers: any) {
    // console.log('filterActiveDrivers: ', drivers);
    // console.log('filterActiveDrivers - filtered: ', drivers && drivers.filter((driver: any) => (driver.attributes.status === 'active')));
    return drivers && drivers.filter((driver: any) => (driver.attributes.status === 'active'));
  }

  onStatusGroupClick(type: string) {
    this.filter.statusGroup = type;
    this.getOrders(this.orderType);
  }

  truncateCode(code: string) {
    if (code.length <= 8) {
      return code;
    }

    return code.substring(0,4) + '...' + code.substring(code.length-4,code.length);
  }
}

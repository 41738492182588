








































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import {Integrations} from '@/services/SOLO'
import {translations} from '@/mixins'
import {mapGetters, mapMutations} from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      getMoneyHash: 'page/getMoneyHash'
    })
  },
  mixins: [translations]
})
export default class MoneyHash extends Vue {
  provider: any = {}
  private getMoneyHash!: any

  mounted() {
    this.provider = this.getMoneyHash;
  }
}

import { Component, Vue } from "vue-property-decorator";

@Component
export default class NotificationAlerts extends Vue {
  $notify: any;
  translate!: Function;

  public systemErrorNotification(title: string = 'SYSTEM ERROR!', message: string = 'Something went wrong, please try again!') {
    this.$notify({
      title: this.translate(title),
      verticalAlign: "bottom",
      horizontalAlign: "left",
      message: this.translate(message),
      type: "danger",
      icon: "fas fa-bomb",
    });
  }

  public successNotification(title: string = 'RECORDS SAVED!', message: string = 'Record have been saved successfully') {
    this.$notify({
      title: this.translate(title),
      verticalAlign: "bottom",
      horizontalAlign: "left",
      message: this.translate(message),
      type: "success",
      icon: "fas fa-check",
    });
  }
}

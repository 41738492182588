











































































































































































































































































































































































































import { Select, Option } from "element-ui";
import Modal from "@/components/Modal.vue";
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import { Banner as BannerApi } from "@/services/SOLO";
import {
  BanAttributes,
  SlideAttributes,
  Banner,
  Slide as SlideModel,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";

interface objKey {
  [x: string]: string;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [eventHandler,translations],
})
export default class Slide extends Vue {
  bannerslides: Array<SlideModel> = [];
  mID: any = "";
  arrayLang: Array<string> = ["en-us", "ar-sa"];
  bbanDesc: objKey = {};
  bbanLinkLabel: objKey = {};
  bbanImgUri: objKey = {};
  bbanTitle: objKey = {};
  imageType: number = 0;
  $notify: any;
  categoryMenus: Array<any> = [];
  menus: Array<Slide> = [];
  numOfLoadedData: number = 0;
  file: any;
  waiting!: Function;
  restore!: Function;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ];

  linkTypes: Array<Object> = [
    {
      value: "item",
      text: "Item",
    },
    {
      value: "category",
      text: "Category"
    },
    {
      value: "url",
      text: "URL",
    },
    {
      value: "cms-page",
      text: "CMS-PAGE",
    },
  ];
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file1: any;
    file2: any;
  };
  confirm!: Function;
  public translate!: Function

  @Watch("file", { immediate: true, deep: true })
  onFileChange(val: any) {

  }

  mounted() {
    this.initializeForms();
    this.getMenus();
  }

  initializeForms() {
    for (let i in this.arrayLang) this.findSlide(this.arrayLang[i]);
  }

  getMenus() {
    BannerApi.menus().then((response: any) => {
      this.menus = response.data.data;

    });
  }
 

  getCategorySearch(menuID: number) {
    BannerApi.categorySearch(menuID).then((response: any) => {
      this.categoryMenus = response.data.data;
    });
  }

  findSlide(lang: string = "en-us") {
    BannerApi.findSlide(
      this.$route.params.bannerID,
      this.$route.params.slidesID,
      lang
    ).then((response: any) => {
      let data = { ...response.data.data, ...{ lang: lang } };
      this.bannerslides.push(data);
      this.numOfLoadedData++;
    });
  }

  updateSlide(e: HTMLFormElement, isvalid: boolean) {
    if (!isvalid) {
      if (!this.imageType) {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        BannerApi.update(
          this.setData(),
          this.$route.params.bannerID,
          this.$route.params.slidesID
        )
          .then((response: any) => {
            this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            this.$router.push({ name: "sliding-banner" });
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data.error.detail,
              type: "danger",
              icon: "fas fa-bomb",
            });
            
          });
      } else {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        BannerApi.updateFormdata(
          this.formData(),
          this.$route.params.bannerID,
          this.$route.params.slidesID
        )
          .then((response: any) => {
            this.imageType = 0;
            this.bannerslides[0].attributes["image-uri"] =
              response.data.data.attributes["image-uri"];
            this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            this.$router.push({ name: "sliding-banner" });
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      }
    }
  }

  get deleteMsgBoxText(): String {
    return "Are you sure you want to delete this slide?";
  }

  removeSlide(categoryId: string) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText).then(
      (value: boolean) => {
        if(value) {
          BannerApi.removeslide(
            this.$route.params.bannerID,
            this.$route.params.slidesID
          ).then((response) => {
          this.$emit("banner:created");
          this.$router.push({ name: "sliding-banner" });
          })
          .catch((err) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
        }
      }
    );
  }

  cancel() {
    this.$router.push({ name: "sliding-banner" });
  }

  formData() {
    let formData = new FormData();
    formData.append(
      "display-order",
      this.bannerslides[0].attributes["display-order"]
    );
    formData.append("link-type", this.bannerslides[0].attributes["link-type"]);
    formData.append(
      "link-value",
      this.bannerslides[0].attributes["link-value"]
    );
    formData.append("label", this.bannerslides[0].attributes.label);

    for (let i in this.bannerslides) {
      formData.append(
        `title[${this.bannerslides[i].lang}]`,
        this.bannerslides[i].attributes.title
          ? this.bannerslides[i].attributes.title
          : ""
      );
      formData.append(
        `description[${this.bannerslides[i].lang}]`,
        this.bannerslides[i].attributes.description
          ? this.bannerslides[i].attributes.description
          : ""
      );
    }

    formData.append("image", this.$refs.file1.files[0]);
    formData.append("alt-image", this.$refs.file2.files[0]);
    return formData;
  }

  setData() {
    for (let i in this.bannerslides) {
      this.bbanTitle[this.bannerslides[i].lang] = this.bannerslides[
        i
      ].attributes.title;
      this.bbanDesc[this.bannerslides[i].lang] = this.bannerslides[
        i
      ].attributes.description;
      this.bbanLinkLabel[this.bannerslides[i].lang] = this.bannerslides[
        i
      ].attributes["link-label"];
      this.bbanImgUri[this.bannerslides[i].lang] = this.bannerslides[
        i
      ].attributes["image-uri"];
    }

    return {
      "display-order": this.bannerslides[0].attributes["display-order"],
      "link-type": this.bannerslides[0].attributes["link-type"],
      "link-value": this.bannerslides[0].attributes["link-value"],
      label: this.bannerslides[0].attributes.label,
      link: this.bannerslides[0].attributes.link,
      title: this.bbanTitle,
      description: this.bbanDesc,
      "link-label": this.bbanLinkLabel,
      "image-uri": this.bbanImgUri,
    };
  }

  filesChange(e: any, errors: any) {
    console.log(errors);
    if (typeof e.target.files[0] != undefined) {
      this.file = e.target.files[0];
      
    }
  }
}

export default {
  setHistory(state: { history: Array<any> }, data: Array<any>) {
    state.history = data;
  },

  setPending(state: { pending: Array<any> }, data: Array<any>) {
    state.pending = data;
  },

  setDeviceCount(state: { deviceCount: number }, data: number) {
    state.deviceCount = data;
  },

  setSegments(state: { segments: Array<any> }, data: Array<any>) {
    state.segments = data;
  },
}































































































































































































import {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Upload
} from 'element-ui'
import WeekPicker from "@/views/Admin/pages/SubscriptionMeal/components/WeekPicker.vue"
import {Component, Vue} from "vue-property-decorator"
import subscriptionMealEvent from "@/events/subscriptionMealEvent"
import {SubscriptionMeals} from '@/services/SOLO'

interface PremadePlanLangName {
  [x: string]: string
}
interface PremadePlanLangDesc {
  [x: string]: string
}

interface Meal {
  'main-dish': {
    id?: any
  },
  drinks: {
    id?: any
  },
  snacks: {
    id?: any
  }
}

interface SetDay {
  day?: any,
  meals?: any | {
    breakfast?: Meal,
    lunch?: Meal,
    dinner?: Meal
  }
}

interface SetMeals {
  week?: any,
  days?: any | SetDay
}

interface PremadePlanForm {
  plan_name: PremadePlanLangName,
  plan_description: PremadePlanLangDesc,
  plan_image?: any,
  number_of_weeks?: any,
  number_of_people?: any,
  meals?: any,
  inclusions?: any,
  days_per_week?: any,
  setmeals?: any | SetMeals
}

@Component({
  components: {
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Switch.name]: Switch,
    [Input.name]: Input,
    [Button.name]: Button,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    WeekPicker
  },
  filters: {
    fieldTitle(str: any) {
      return str.split(' ').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    smallTitle(str: any) {
      return str.split(' ').map((item: any) => {
        return item.charAt(0).toLowerCase() + item.substring(1);
      }).join(' ');
    }
  }
})
export default class EditPremadePlan extends Vue {
  arrayLang: Array<string> = ['en-us', 'ar-sa']
  saving: boolean = false
  checked: boolean = true
  enabled: boolean = true
  weekData: any = {}
  planSummary: any = {
    days: 7,
    weeks: 1,
    meals: 15,
    total: 0
  }
  isFileActive: boolean = false
  uploadedFileName: any = ''
  weekCounter: number = 0
  weekArr: any = [
    {
      week: '',
      days: []
    }
  ]
  weekDay: any = []
  emptyMeals: any = {
    breakfast: <Meal> {
      'main-dish': {
        id: ''
      },
      snacks: {
        id: ''
      },
      drinks: {
        id: ''
      }
    },
    lunch: <Meal> {
      'main-dish': {
        id: ''
      },
      snacks: {
        id: ''
      },
      drinks: {
        id: ''
      }
    },
    dinner: <Meal> {
      'main-dish': {
        id: ''
      },
      snacks: {
        id: ''
      },
      drinks: {
        id: ''
      }
    }
  }
  form: PremadePlanForm = {
    plan_name: <PremadePlanLangName> {},
    plan_description: <PremadePlanLangDesc> {},
    plan_image: '',
    number_of_weeks: 1,
    number_of_people: 1,
    meals: [],
    inclusions: [],
    days_per_week: [],
    setmeals: []
  }
  fileList: any = ''
  currentPlanId: number | any = ''
  premadePlanTitle: PremadePlanLangName = {}
  premadePlanDesc: PremadePlanLangDesc = {}
  constructor() {
    super()
    for (let i in this.arrayLang) {
      this.premadePlanTitle[this.arrayLang[i]] = ''
      this.premadePlanDesc[this.arrayLang[i]] = ''
    }
  }

  mounted() {
    this.currentPlanId = this.$route.params.id
    this.loadLang(this.arrayLang)
    for (let step = 0; step < this.form.number_of_weeks; step++) {
      this.weekArr = {
        week: 1,
        days: []
      }

      this.form.days_per_week.map((d: any) => {
        this.weekDay = <SetDay>{
          day: d,
          meals: this.emptyMeals
        }
        this.weekArr.days.push(this.weekDay)
      })
      this.form.setmeals.push(this.weekArr)
    }
  }

  loadLang(language: string[]) {
    for (let lang in language ) {
      this.getPremadePlanOnly(this.currentPlanId, this.arrayLang[lang])
    }
  }

  handleChange(file: any) {
    this.isFileActive = true
    this.form.plan_image = file.raw
    this.uploadedFileName = file.name
  }

  weekPayload(data: any, type: any) {

    if (type === 'number_of_weeks') {
      this.form.setmeals.splice(0, this.form.setmeals.length) // clearing array
      let removeFirstEl = this.form.setmeals.shift()

      for (let step = 0; step < data; step++) {
        this.weekArr = {
          week: step + 1,
          days: []
        }

        this.form.days_per_week.map((d: any) => {
          this.weekDay = <SetDay>{
            day: d,
            meals: this.emptyMeals
          }
          this.weekArr.days.push(this.weekDay)
        })

        this.form.setmeals.push(this.weekArr)
      }

    }
    if (type === 'days_per_week') {
      this.form.setmeals.map((v: any) => {
        v.days = []
        data.map((d: any) => {
          this.weekDay = <SetDay>{
            day: d,
            meals: this.emptyMeals
          }
          v.days.push(this.weekDay)
        })

      })

    }

    this.weekData = {
      [type]: data
    }
    subscriptionMealEvent.$emit('change', this.weekData)
    this.planSummary.days = (this.form.days_per_week.length * this.form.number_of_weeks)
    this.planSummary.weeks = this.form.number_of_weeks
    this.planSummary.meals = ((this.form.meals.length * this.form.number_of_people) * this.form.days_per_week.length) * this.planSummary.weeks
  }

  updatePremadePlan() {
    console.log(this.form)
  }

  async getPremadePlanOnly(id: any, lang: string) {
    await SubscriptionMeals.show(id, lang).then((response: any) => {
      this.weekData = {}
      let payload = response.data.data.attributes

      this.premadePlanTitle[lang] = payload['plan-name']
      this.premadePlanDesc[lang] = payload['plan-description']
      this.form.plan_image = payload['plan-image']
      this.form.meals = payload['meals']
      this.form.inclusions = payload['inclusions']
      this.form.number_of_weeks = payload['number-of-weeks']
      this.form.number_of_people = payload['number-of-people']
      this.form.days_per_week = payload['days-per-week']

      this.planSummary.days = (this.form.days_per_week.length * this.form.number_of_weeks)
      this.planSummary.weeks = this.form.number_of_weeks
      this.planSummary.meals = ((this.form.meals.length * this.form.number_of_people) * this.form.days_per_week.length) * this.planSummary.weeks

      // static 1 week only, rectify when accepting more than 1 week
      let reconstructArr = []
      reconstructArr.push(response.data.included[0].attributes)
      let mealPayload = {
        'setmeals': reconstructArr,
        'is_new_plan': false
      }
      this.weekData = {...payload, ...mealPayload}
      subscriptionMealEvent.$emit('change', this.weekData)
    })
  }

  // async getPremadePlanWithSetMeals(id: any) {
  //   await SubscriptionMeals.showWithSetmeals(id).then((response: any) => {
  //     this.weekData = response.data.included
  //     // static 1 week only, rectify when accepting more than 1 week
  //     // subscriptionMealEvent.$emit('change', response.data.included[0].attributes.days)
  //
  //     // subscriptionMealEvent.$emit('change', this.weekData)
  //     subscriptionMealEvent.$emit('change', {
  //       'test': 'test'
  //     })
  //   })
  // }

}










































































































































































































































































































































































































// npm package
import {Component, Vue, Watch} from 'vue-property-decorator';

import { eventHandler, translations } from '@/mixins';
import Translations from './components/Translations.vue';
import { Autocomplete, Button, Form, Option, Select, Table, TableColumn } from 'element-ui';
import flatPicker from 'vue-flatpickr-component';
import { Customer, Smiles } from '@/services/SOLO';
import { AxiosError } from 'axios';
import { debounce } from "vue-debounce";
import * as _ from 'lodash';

@Component({
  components: {
    Translations,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Autocomplete.name]: Autocomplete,
    flatPicker,
    [Form.name]: Form,
  },
  mixins: [translations, eventHandler],
})
export default class Promotions extends Vue {
  public tab: number = 1
  public translate!: Function;
  public loading: boolean = false;
  public color: string = 'white';
  public pin: string = '';
  public customers = [];
  public orderId = [];
  // $notify: any;
  private errorObj = {
    title: 'System Error',
    verticalAlign: 'bottom',
    horizontalAlign: 'left',
    type: 'error',
    icon: 'fas fa-bomb',
  }

  page = 1
  downloadUrl = ''
  queryUrl = ''
  filter: any = {
    source: null,
    rangeType: null,
    dateRange: null,
  }
  queryParam: any = {
    dateRange: null,
    source: null,
  }
  sequenceLogs: any = {
    data: [],
    meta: {
      current_page: 1,
      total: 0,
    },
    links: {
      prev: null,
      next: 1,
    },
  }
  limitPerPage = 10;
  oldQuery: string = '';
  log = null;
  rows: number = 0;
  perPage: number = 50;
  currentPage: number = 1;
  currentDate = new Date();

  customerId = null;
  customerTab: number = 1;
  customer: any = null;
  customerOrders = null;
  customerCampaigns = null;
  activeSequenceCampaigns = [];
  searchedCustomers = [];
  $notify: any;

  @Watch('filter.dateRange', { immediate: true, deep: true })
  onDateRangeChange(val: any) {
    console.log('onDateRangeChange: ', val);
    if (val) {
      this.filterByRange(val);
    } else {
      this.queryParam.dateRange = null;
    }
    this.search();
  }

  @Watch('filter.source', { immediate: true, deep: true })
  onSourceChange(val: any) {
    console.log('onSourceChange: ', val);
    if (val) {
      this.source(val);
    } else {
      this.queryParam.source = null;
    }
    this.search();
  }

  created() {
    this.getLogs();
  }

  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Validate Pin')
    } else if (this.tab === 2) {
      return this.translate('Audit Logs')
    } else {
      return this.translate('Customers')
    }
  }
  onSearchPin() {
    if (this.pin) {
      this.loading = true;
      Smiles.searchByPin(this.pin)
        .then((response) => {
          this.customers = response?.data?.data
          this.loading = false;
        })
        .catch((error: AxiosError) => {
          this.$notify({
            ...this.errorObj,
            message: error?.response?.data?.message || 'Something went wrong!',
          });
          this.customers = [];
        });
    }
  }
  onValidate(customerId: any) {
    const payload = {
      customerId,
      orderId: this.orderId[customerId],
    }

    if (!this.pin) {
      this.$notify({
        ...this.errorObj,
        message: 'Sequence pin is required!',
      });
    }

    Smiles.validateOrder(payload)
      .then((response) => {
        if (response?.data?.success) {
          this.pin = '';
          this.customers = [];

          this.$notify({
            title: 'Success',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: response?.data?.success,
            type: 'success',
          });
        }
      })
      .catch((err) => console.log('err: ', err));
  }
  async getLogs() {
    const { data } = await Smiles.logs(this.queryUrl);
    this.sequenceLogs = data;
    this.rows = this.sequenceLogs.meta.total;
    this.page = this.sequenceLogs.meta.per_page;
    this.currentPage = this.sequenceLogs.meta.current_page;
  }
  buildUrl() {
    this.downloadUrl = `download=true`;
    this.queryUrl = `page=${this.currentPage}&limit=${this.limitPerPage}`;
    if (this.queryParam.dateRange) {
      this.queryUrl += this.queryParam.dateRange;
      this.downloadUrl += this.queryParam.dateRange;
    }
    if (this.queryParam.source) {
      this.queryUrl += this.queryParam.source;
      this.downloadUrl += this.queryParam.source;
    }
  }
  source(source: any) {
    this.queryParam.source = `&source=${source.join(",")}`
    // this.getLogs();
  }
  filterByRange(dateRange: any) {
    const date = dateRange.split(' to ');
    console.log('filterByRange: ', date);
    this.queryParam.dateRange = `&dateRange[from]=${date[0]}&dateRange[to]=${date[1]}`;
    // this.search();
  }
  search() {
    this.page = 1;
    this.buildUrl();
    this.getLogs();
  }
  // onFetch(queryString: string, cb: Function) {
  //   console.log('queryString: ', queryString);
  //   const filterType = ['customer', 'email', 'mobile'];
  //   const promiseArray: any = [];

  //   if (!queryString || (queryString && queryString.length < 3) || this.oldQuery === queryString) {
  //     return;
  //   }
  //   this.oldQuery = queryString;

  //   debounce(() => {
  //     filterType.forEach(filter => {
  //       promiseArray.push(Customer.filterByType(queryString, filter));
  //     });
  //     Promise.all(promiseArray)
  //       .then((response) => {
  //         console.log('onFetch: ', response);
  //         let customerArr: any = [];
  //         response.forEach((each: any) => {
  //           if (each.data.data.length) {
  //             customerArr = [...each.data.data];
  //           }
  //         })
  //         cb(customerArr.map((customer: any) => {
  //           return {
  //             value: `${customer.attributes['first-name']} ${customer.attributes['last-name']} - ${customer.attributes['email']} - ${customer.attributes['mobile'] == null ? 'No phone number' : customer.attributes['mobile']}`,
  //             id: customer.id,
  //           };
  //         }));
  //       });
  //   }, 700)();
  // }
  onFetch2(queryString: string, cb: Function) {
    console.log('queryString: ', queryString);
    const filters: any = [];

    if (!queryString || (queryString && queryString.length < 3)) {
      return;
    }

    if (this.oldQuery == queryString) {
      cb(this.searchedCustomers);
      return;
    }
    this.oldQuery = queryString;
    console.log('oldQuery',this.oldQuery);

    if (!isNaN(parseInt(this.oldQuery))) {
      filters['mobile'] = this.oldQuery;
      filters['customer-id'] = this.oldQuery;
    } else if (this.oldQuery.includes('@')) {
      filters['email'] = this.oldQuery;
    } else {
      filters['customer'] = this.oldQuery;
    }

    debounce(() => {
      Smiles.searchByCustomers(filters)
        .then((response) => {
          this.searchedCustomers = response?.data?.data.map((customer: any) => {
            return {
              value: `${customer.attributes['first-name']} ${customer.attributes['last-name']} - ${customer.attributes['email']} - ${customer.attributes['mobile'] == null ? 'No phone number' : customer.attributes['mobile']}`,
              id: customer.id,
            };
          });
          cb(this.searchedCustomers);
        })
        .catch((error: AxiosError) => {
          this.$notify({
            ...this.errorObj,
            message: error?.response?.data?.message || 'Something went wrong!',
          });
          return [];
        });
    }, 700)();
  }
  handleSelect(customer: any) {
    Smiles.getCustomerDetails(customer.id)
      .then((response) => {
        this.customer = response?.data?.data;
        this.customer.id = response?.data?.data.customer.id;
        this.customerOrders = _.groupBy(this.customer.orders, 'sequence');
        this.customerCampaigns = _.groupBy(this.customer.campaigns, 'sequence');
        this.activeSequenceCampaigns = _.filter(this.customer.campaigns, (campaign: any) => campaign.sequence == this.customer['active-sequence']);
      });
  }
  paginate(page: number) {
    this.currentPage = page;
    this.buildUrl();
    this.getLogs();
  }
  onClearDateRange() {
    this.filter.dateRange = null;
  }
  onRenewQRCodeAndPin() {
    Smiles.renewQRCode({
      customerId: this.customer.id,
      renew: true,
    })
      .then(() => this.handleSelect(this.customer));
  }
  downloadLogs() {
    this.$notify({
      title: "PLEASE WAIT!",
      horizontalAlign: "right",
      message: 'Please wait, the file will be downloaded in few seconds.',
      type: "success",
      icon: "fas fa-spinner fa-spin",
    });
    Smiles.download(this.downloadUrl)
      .then((response) => {
        console.log(response);
        window.open(response.data.data['csv_uri'], '_blank');
      });
  }
}

























































































import Translations from './global-component/Translations'
import {translations} from '@/mixins'
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import { mapGetters, mapMutations } from 'vuex'
import { Concept } from '@/interfaces/SOLO'
import { Sidebar } from '@/interfaces/Landing'
import { Concept as ConceptApi, Menu, Subscription } from '@/services/SOLO'
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { Menus } from '@/models'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'

@Component({
  components: {
    CollapseTransition,
    BaseNav,
    Modal,
    RouteBreadCrumb,
    Translations
  },
  computed: {
   ...mapGetters({
      getConcepts: 'account/getConcepts',
      activeConcept: 'account/activeConcept',
      getMenus: 'menu/getMenus',
      getUser: 'account/getUser',
      getLocale: 'app/getLocale',
      getLocales: 'app/getLocales',
      isLoggedIn: 'account/isLoggedIn',
      getConceptSettings: 'account/getConceptSettings',
      getUserRole: 'account/getUserRole',
      subscription: 'account/getSubscription',
      paymentId: 'account/getSubscriptionPaymentId',
      getClient: 'account/getUserClient'
    })
  },
  methods: {
    ...mapMutations({
      setUserConcept: 'account/setUserConcept',
      setMenus: 'menu/setMenus',
      logoutUser: 'account/logoutUser',
      setLocale: 'app/setLocale',
      setConceptSettings: 'account/setConceptSettings',
      setSubscription: 'account/setSubscription',
    })
  },
  mixins: [translations]
})
export default class DashboardNavbar extends Vue {
  activeNotifications: Boolean = false
  showMenu: Boolean = false
  searchModalVisible: Boolean = false
  searchQuery: string = ''
  isDisplay: Boolean = false;
  getUser: any
  getConcepts!: Array<Concept>
  temp_concepts: any
  getMenus!: Array<Menus>
  conceptIsVisible: boolean = false
  activeConcept!: Concept
  filterText: string = ''
  menuRoutes: Array<any> = ['menus', 'menu', 'updatecategory', 'items', 'itemlist', 'itemUpdate', 'itemModifier']
  languages: any = {
    'ar-sa': 'ar_SA',
    'en-us': 'en_US',
    'fr': 'fr_FR'
  }

  @Prop() type!: string
  setUserConcept!: Function
  setMenus!: Function
  logoutUser!: Function
  setLocale!: Function
  setConceptSettings!: Function
  setSubscription!: Function
  getLocale!: Function
  getLocales!: any
  isLoggedIn: any
  getConceptSettings: any
  getUserRole: any
  subscription: any
  paymentId: any
  $sidebar!: Sidebar
  $root!: any
  private getUserClient!: any;
  parent!: any
  translate!: Function
  dynamicHeight: any = 0
  availableLanguages: any = []
  concptLanguages: any = []
  arrayLang: Array<string> = []
  constructor() {
    super()
  }

  @Watch("getClient", {deep: true, immediate: true})
  onChangeGetUserClient(data: any) {

    if(data) {

      const api_theme = this.getUserClient.attributes.theme;
      const themeParse = JSON.parse(api_theme);
   
      let primary = document.documentElement;
      let header = document.documentElement;
      let secondary = document.documentElement;

      primary.style.setProperty('--primary-color', themeParse.primary_color)  
      header.style.setProperty('--header-color', themeParse.header_bg_color)  
      secondary.style.setProperty('--secondary-color', themeParse.secondary_color) 
    }
  }

  mounted () {
    this.parent = this.$parent?.$parent
    this.showLogout();
    this.showBreadcrum();
    this.setHeight();
    // this.concptLanguages = this.getConceptSettings.attributes.languages.sort().reverse();
    this.availableLanguages = ['en-us', 'ar-sa', 'fr'];
    // this.$root.$on('DashboardNavbar', (data: any) => {
    //   this.switchCheck(data);
    // });
    // this.setLocale(this.languages[this.getConceptSettings.attributes['primary-language']]);
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    if (this.getConceptSettings.attributes['secondary-language']) {
      if (!this.arrayLang.includes(this.getConceptSettings.attributes['secondary-language'])) {
        this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
      }
    }

    this.concptLanguages = this.arrayLang.concat(this.availableLanguages.filter((item: any) => this.arrayLang.indexOf(item) < 0));
  }

  setHeight() {
    const height = 55

    let min = 0, max = this.filtered.length > 9 ? 9 : this.filtered.length
    while (min < max) {
      this.dynamicHeight += height
      min++;
    }
  }


  showBreadcrum() {
    if (!this.isMobile()) {
      this.isDisplay = true;
    } else {
      this.isDisplay = false;
    }
  }

  showLogout() {
    if (!this.isMobile()) {
      this.isDisplay = true;
    } else {
      this.isDisplay = false;
    }
  }

  // @Watch('conceptLanguages', { deep: true, immediate: true })
  // selectDefault(newVal: any) {
  //   if(this.languages[this.conceptLanguages[0]] === 'fr_FR') {
  //    this.switchLang(this.languages[this.conceptLanguages[0]])
  //   }
  // }

  // get conceptLanguages(): Array<any> {
  //   return this.getConceptSettings.attributes.languages.sort().reverse()
  // }

  get filtered(): Array<Concept> {

    this.temp_concepts = this.getConcepts.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1)

    if(this.filterText === '')
      return this.temp_concepts
    else
      return this.temp_concepts.filter((concept: Concept) => {
        return concept.label.toLowerCase().includes(this.filterText.toLowerCase())
      })
  }

  // switchCheck(data: any) {

  //   console.log("checkData", data);
    
  //   if(data[0] === 'ar-sa') {
  //     this.setLocale('ar_SA');
  //   } else if (data[0] === 'en-us') {
  //     this.setLocale('en_US');
  //   } else {
  //     this.setLocale('fr_FR');
  //   }

  //   this.concptLanguages = data;

  //   console.log("Concept Languages", this.concptLanguages);
  //   console.log("locales", this.getLocales);
  //   console.log("single locale", this.getLocale);

  //   // window.location.reload()

  // }


  switchLang(locale: string) {
    // alert(locale); return;
    this.setLocale(locale)
    // window.location.reload()
  }

  showConcepts (allow: boolean) {
    this.$root.conceptIsVisible = allow
  }

  setDefaultConcept () {
    if(this.getConcepts.length && !this.activeConcept) {
      this.setUserConcept(this.getConcepts[0])
      this.getSubscription()
    }
  }

  setConcept (concept: Concept) {
    this.setUserConcept(concept)
    this.showConcepts(false)
    this.getSubscription()
  }

  private getSubscription() {
    Subscription.getSubscription().then(response => {
      this.getApps(response.data.data)
    }).catch(err => {
      this.getApps(null)
    })
  }

  private getAccountConcept(data: any) {
    ConceptApi.get(this.activeConcept.id).then(response => {
      this.setSubscription(data)
      this.setConceptSettings(response.data.data)
      // this.switchLang(this.languages[response.data.data.attributes.languages.sort().reverse()[0]])
      window.location.reload()
    })
  }

  getApps (data: any) {
    if(this.activeConcept) {
      this.$root.isLoading = true
      Menu.all().then((response: any) => {
        this.setMenus(response.data.data)
        this.$root.isLoading = false
        // if(this.menuRoutes.includes(this.$route.name) && this.$route.path != this.parent.$refs.menus[0].link.path) {
        //   this.$router.push({ path: this.parent.$refs.menus[0].link.path })
        // }
        this.getAccountConcept(data)
      }).catch((err: any) => {
        console.log(err)
      })
    }
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  toggleNotificationDropDown() {
    this.activeNotifications = !this.activeNotifications;
  }
  closeDropDown() {
    this.activeNotifications = false;
  }
  toggleSidebar() {
    this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
  }
  hideSidebar() {
    this.$sidebar.displaySidebar(false);
  }

  isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
     // this.logoutUser()
    setTimeout(() => {
      location.reload()
      localStorage.clear()
    }, 2000)
    // this.$router.go(0)
  }
}

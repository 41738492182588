import AuthService from './auth.service'
import FoodicsAuthService from './foodics.auth.service';

let Auth = new AuthService();
let FoodicsAuth = new FoodicsAuthService();

export {
  Auth,
  FoodicsAuth
}

import BaseService from '../base.service'

export default class GMBService extends BaseService {
  [x: string]: any;
  url!: string

  constructor() {
    super();
  }

  createToken(data: any = null) {
    this.url = this.baseURL + 'partoo/connection-token';
    return super.get(this.url);
  }

  // getConnectionToken() {
  //   this.url = this.baseURL + 'partoo/connection-token';
  //   return super.get(this.url);
  // }

  getCategories(filter: any) {
    this.url = this.baseURL + 'partoo/categories';
    return super.get(this.url, filter);
  }

  syncLocations(payload: any) {
    this.url = this.baseURL + 'partoo/sync-locations';
    return super.post(this.url, payload);
  }
}















































































































































































































































































































































































































































































































































































































































































































































import moment from "moment";
import Translations from './components/translations'
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import DoughnutChart from '@/components/Charts/DoughnutChart';
import * as chartConfigs from '@/components/Charts/config';
import DashboardFigures from "./components/DashboardFigures.vue";
import DashboardFiguresNew from "./components/DashboardFiguresNew.vue";
import { Dashboard as DashboardApi, Concept as ConceptApi, Applications, Menu } from "@/services/SOLO";
import {Concept} from "@/interfaces/SOLO";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { translations, eventHandler } from '@/mixins';
import {mapGetters, mapMutations} from "vuex";
import setupModal from '@/views/Admin/modal-component/setupModal'
import menuSetup from '@/views/Admin/modal-component/menuSetup'
import foodicsPay from '@/views/Admin/modal-component/foodicsPay'
import addressModal from '@/views/Admin/modal-component/webAddressModal'
import logoModal from '@/views/Admin/modal-component/logoModal'
import deliveryCharge from '@/views/Admin/modal-component/deliveryCharge'
import termsCondition from '@/views/Admin/modal-component/termsCondition'

import menuSyncing from '@/views/Admin/modal-component/menuSyncing'
/* @ts-ignore */
import {} from '@types/googlemaps';
import { Userpilot } from 'userpilot';

//Initialize Userpilot
Userpilot.initialize('NX-ff263cfb');

@Component({
  components: {
    BarChart,
    LineChart,
    DoughnutChart,
    DashboardFigures,
    DashboardFiguresNew,
    Translations,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
     setupModal,
     addressModal,
     logoModal,
     deliveryCharge,
     termsCondition,
     menuSetup,
     foodicsPay,
     menuSyncing
  },
    computed: {
    ...mapGetters({
      activeConcept: "account/activeConcept",
      getUser: 'account/getUser',
      getUserClient: 'account/getUserClient',
      getConceptSettings: 'account/getConceptSettings',
      getMenus: "menu/getMenus",
      getIsFoodicsPay: "account/getIsFoodicsPay"


    }),
  },
  methods: {
    ...mapMutations({
      setMenus: 'menu/setMenus',
      setFoodicsPay: 'account/setFoodicsPay'
    }),
  },
  mixins: [eventHandler, translations],
  filters: {
    datetime(value: any) {
      return moment.utc(value).locale('en-us').local().fromNow();
    }
  }
})
export default class Dashboard extends Vue {
  employeeDetails: any = {}
  setFoodicsPay!: Function
  getIsFoodicsPay!: Function
  ablyUnsubscribe: any;
  broadcasted!: Function
  doneSetup: Boolean = false
  defaultWebAddress: any = 'sample'
  isSetupSetOpen: Boolean = false
  isWebAdressSetOpen: Boolean = false
  isLogoSetOpen: Boolean = false
  isMenuSetup: Boolean = false
  isDeliveryCharge: Boolean = false
  isTermsCondition: Boolean = false
  isFoodicsPay: Boolean = false
  isMenuSyncing: Boolean = false
  activeConcept!: Concept;
  setMenus!: any
  getUser!: any
  getUserClient!: any
  getConceptSettings!: any
  onBoard: Boolean = false
  flag_sync: Boolean = false
  applications: any = []
  public summary: Array<any> = [];
  public orders: Array<any> = [];
  public locations: any = [];
  public products: any = [];
  deliveryCharges: any = [];
  menuGroups: any = [];
  wizard: Boolean = false;
  integrationId: any = ""
  ablyWatch: any = ''
  public statuses: any = null;
  public statusesClosed: any = null;
  public registrationBySource: any = [];
  private loadChart: Boolean = false
  public redBarChart: any = null;
  public blueBarChart: any = null;
  private bigLineChart: any = {
    allData: [
      [0, 20, 10, 30, 15, 40, 20, 60, 60],
      [0, 20, 5, 25, 10, 30, 15, 40, 40]
    ],
    activeIndex: 0,
    chartData: {
      datasets: [],
      labels: []
    },
    extraOptions: {
      scales: {
        yAxes: [
          {
            id: "A",
            type: "linear",
            position: "left"
          },
          {
            id: "B",
            type: "linear",
            position: "right",
          }
        ],
        xAxes: [{
          ticks: {
            beginAtZero: true,
            autoSkip: true,
          }
        }]
      },
      legend: {
        display: true,
        labels: {
          fontColor: '#000000'
        }
      }
    },
  };
  public chartDoughnutData: any = null;
  public dataPerSource: any = {
    today: [],
    yesterday: [],
    prev: [],
  };
  public displayStatuses: any = [
    {
      code: 'posted',
      color: '#ccc',
    },
    {
      code: 'accepted',
      color: '#11CDEF',
    },
    {
      code: 'ready',
      color: '#5E72E4',
    },
    {
      code: 'dispatched',
      color: '#39CD8B',
    },
    {
      code: 'delivery-in-progress',
      color: '#F5365C',
    },
    {
      code: 'initiated',
      color: '#ccc',
    },
    {
      code: 'waiting-for-payment',
      color: '#11CDEF',
    },
    {
      code: 'in-kitchen',
      color: '#5E72E4',
    },
    {
      code: 'arrived-at-customer',
      color: '#39CD8B',
    },
    {
      code: 'finished',
      color: '#F5365C',
    },
    {
      code: 'cancelled-by-customer',
      color: '#ccc',
    },
    {
      code: 'cancelled-by-employee',
      color: '#11CDEF',
    },
    {
      code: 'order-failed',
      color: '#5E72E4',
    },
  ];
  public selectedStats: string = 'source';

  private translate!: Function


  @Watch('activeConcept.id', {deep: true, immediate: true})
  onChangeCheckConcept(data: any) {
    if(data) {
          this.getOnboarding();
    }
  }

  @Watch('onBoard', {deep: true, immediate: true})
  onChangeConceptID(data: any) {

    if(data) {
        this.isSetupSetOpen = true; 
    }

  }

  @Watch('ablyWatch', {deep: true, immediate: true})
  onWatchFlagSync(data: any) {

    if(data) {
      if(data.name == 'Solo\\Application') {
          this.flag_sync = true;
          this.getApps();
          this.defaultWebAddress = data.data['sub_domain'];
          console.log("check application sync", data);
          console.log("check defaultWebAddress", this.defaultWebAddress);

        }    
      }

  }


  mounted() {
    this.getSales();
    this.getLatestOrders();
    this.getDailySales();
    this.getTopPerformingLocations();
    this.getTopPerformingProducts();
    this.getOrderStatuses();
    this.getRegistrationBySource();
    this.getDeliveryAreas();
    this.getIntegrationID();
    this.checkApplicationOnBoarding();
    this.getEmployeeDetails();
    console.log("check set isFoodicsPay", this.getIsFoodicsPay);
    if(this.getIsFoodicsPay) {
      this.isFoodicsPay = true;
    }
    this.getTerms();
  }

  userPilot() {
    console.log("pasok sa banga");
    Userpilot.identify(
                this.getUser.id,
                {
                    name: this.employeeDetails.name,
                    email: this.employeeDetails.email,
                    created_at: moment(this.employeeDetails.created_at).format('YYYY-MM-DD'), // ISO8601 Date,
                    company:  // optional 
                    {
                        id: this.employeeDetails.company.id, // Company Unique ID
                        name: this.employeeDetails.company.name,
                        created_at: moment(this.employeeDetails.company.created_at).format('YYYY-MM-DD')// ISO8601 Date
                    },
                    country: this.employeeDetails.country,
                    last_order_at: moment(this.employeeDetails.last_order_at).format('YYYY-MM-DD'),
                    mobile: this.employeeDetails.mobile,
                    business_code: this.employeeDetails.company.id,
                }
            )
  }

  async getEmployeeDetails() {
    await Applications.getEmployeeDetails(this.getUser.id)
        .then((response: any) => {
          this.employeeDetails = response.data;
          if(this.getClientUserLabel === 'Foodics Online') {
            this.userPilot(); // for foodics only
          }
        })
  }


  async checkApplicationOnBoarding() {
    this.ablyUnsubscribe = this.broadcasted(this.activeConcept.id);
    await this.ablyUnsubscribe.subscribe((payload: any) => {

        this.ablyWatch = payload;

    })
  }

  getApps () {
    Menu.all().then((response: any) => {
      this.setMenus(response.data.data)
    }).catch((err: any) => {
      console.log(err)
    })
  }


  calculateDataPerSource(when: string, sale: any) {
    if (!this.dataPerSource[when].find((i: any) => i[this.selectedStats] === sale[this.selectedStats])) {
      this.dataPerSource[when].push({
        [this.selectedStats]: sale[this.selectedStats],
        t_revenue: 0,
        t_avg_check: 0,
        t_orders: 0,
        data: [],
      });
    }
    this.dataPerSource[when] = this.dataPerSource[when].map((i: any) => {
      if (i[this.selectedStats] === sale[this.selectedStats]) {
        i.t_revenue += sale.total_sales;
        i.t_orders += sale.number;
      }
      return i;
    });
    this.dataPerSource[when].map((item: any) => {
      item.t_avg_check = item.t_revenue / item.t_orders;
      return item;
    });
  }

  private get todaysRevenue(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return !sale.ival;
      })
      .map((sale: any) => {
        this.calculateDataPerSource('today', sale);

        return sale.total_sales;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  async getDeliveryCharge() {
    await Applications.getDeliveryCharge()
        .then((response: any) => {
          this.deliveryCharges = response.data.data
          console.log("delivery charges", this.deliveryCharges);
        })
  }

  async getIntegrationID() {
    await Applications.getIntegrationID()
        .then((response: any) => {
          let integration = response.data.data;
          this.integrationId = integration[0].id;
          console.log("integration ID", this.integrationId);
        })
  }

  async saveDeliveryCharge(dChargeId: any) {
    var payload = {
      type: 'pos',
      provider: 'foodics-f5',
      "options": [
           {
               "key": 'delivery-charge-id',
               "value": dChargeId
          }
      ]
    }
    await Applications.saveDeliveryCharge(payload, this.integrationId)
        .then((response: any) => {
          this.integrationId = response.data.data
              console.log("dChargeID", dChargeId);

        })
  }

  private get todaysRevenuePCT(): any {
    let pct = (this.todaysRevenue / this.yesterdaysRevenue - 1) * 100;
    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0
    }
    return pct
  }

  private get todaysOrder(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return !sale.ival;
      })
      .map((sale: any) => {

        return sale.number;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get todaysOrderPCT(): any {
    let pct = (this.todaysOrder / this.yesterdaysOrder - 1) * 100;

    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0;
    }

    return pct;
  }

  private get todaysAverage(): any {
    let ave: any = this.todaysRevenue / this.todaysOrder;
    if (!ave) {
      return 0;
    }
    return ave;
  }

  private get todaysAveragePCT(): any {
    let pct = (this.todaysAverage / this.yesterdaysAverage - 1) * 100;
    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0;
    }
    return pct;
  }

  private get yesterdaysRevenue(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return sale.ival === 1;
      })
      .map((sale: any) => {
        this.calculateDataPerSource('yesterday', sale);
        return sale.total_sales;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get yesterdaysRevenuePCT(): any {
    let pct = (this.yesterdaysRevenue / this.otherDayRevenue - 1) * 100;
    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0
    }
    return pct
  }

  private get yesterdaysOrder(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return sale.ival === 1;
      })
      .map((sale: any) => {
        return sale.number;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get yesterdaysOrderPCT(): any {
    let pct = (this.yesterdaysOrder / this.otherDaysOrder - 1) * 100;

    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0;
    }

    return pct;
  }

  private get yesterdaysAverage(): any {
    let ave: any = this.yesterdaysRevenue / this.yesterdaysOrder;
    if (!ave) {
      return 0;
    }
    return ave;
  }

  private get yesterdaysAveragePCT(): any {
    let pct = (this.yesterdaysAverage / this.otherDayAverage - 1) * 100;
    if(!pct || pct == NaN || pct == Infinity) {
      return 0;
    }
    return pct;
  }

  private get otherDayRevenue(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return sale.ival > 1;
      })
      .map((sale: any) => {
        this.calculateDataPerSource('prev', sale);
        return sale.total_sales;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get otherDaysOrder(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return sale.ival > 1;
      })
      .map((sale: any) => {
        return sale.number;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get otherDayAverage(): any {
    let ave: any = this.otherDayRevenue / this.otherDaysOrder;
    if (!ave) {
      return 0;
    }
    return ave;
  }

  private get getActiveOrders(): any {
    let count = 0;
    if (
      this.statuses
      && this.statuses.data
      && Array.isArray(this.statuses.data)
    ) {
      this.statuses.data.forEach((d: any) => {
        count += d.ordersCount;
        return d;
      });
    }

    return count;
  }

  private getSales(): void {
    DashboardApi.dailySummary().then((response: any) => {
      this.summary = response.data;
    });
  }

  private getDailySales(): void {
    DashboardApi.dailySales().then((response: any) => {
      let dailySales: Array<any> = response.data
      this.bigLineChart.chartData.datasets = [
        {
          label: this.translate('Daily Sales Value'),
          yAxisID: 'A',
          type: 'bar',
          data: dailySales.map((order: any) => { return order.total_sales }).slice(Math.max(dailySales.length - 20, 0)),
          // data: dailySales.map((order: any) => { return order.total_sales }),
          borderColor: '#1ED760',
          fill: false,
          backgroundColor: '#1ED760',
        },
        {
          label: this.translate('Number of Sales'),
          yAxisID: 'B',
          type: 'line',
          data: dailySales.map((order: any) => { return order.number }).slice(Math.max(dailySales.length - 20, 0)),
          // data: dailySales.map((order: any) => { return order.number }),
          borderColor: '#5E72E4',
          fill: false,
          backgroundColor: '#5E72E4',
        }
      ]
      this.bigLineChart.chartData.labels = dailySales.map((order: any) => { return order.date }).slice(Math.max(dailySales.length - 20, 0))
      // this.bigLineChart.chartData.labels = dailySales.map((order: any) => { return order.date })
      this.loadChart = true
    })
  }

  private getLatestOrders(): void {
    DashboardApi.latestOrders().then((response: any) => {
      this.orders = response.data.data
    })
  }

  private getTopPerformingLocations(): void {
    const payload = {
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    };
    DashboardApi.getTopPerformingLocations(payload).then((response: any) => {
      this.locations = response.data;
      console.log("check locations count", this.locations);
      this.redBarChart = {
        chartData: {
          labels: [],
          datasets: [{
            label: 'Sales',
            data: [],
          }],
        },
        extraOptions: chartConfigs.redChartOptions,
      };
      this.locations.data.forEach((location: any) => {
        this.redBarChart.chartData.labels.push(location.attributes.name);
        this.redBarChart.chartData.datasets[0].data.push(location.attributes.ordersCount);
      });
      this.$forceUpdate();
    })
  }

  private getTopPerformingProducts(): void {
    const payload = {
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    };
    DashboardApi.getTopPerformingProducts(payload).then((response: any) => {
      this.products = response.data;
      this.blueBarChart = {
        chartData: {
          labels: [],
          datasets: [{
            label: 'Sales',
            data: [],
            backgroundColor: '#18A0FB',
          }],
        },
        extraOptions: chartConfigs.blueChartOptions,
      };
      this.products.data.forEach((product: any) => {
        this.blueBarChart.chartData.labels.push(product.attributes.name);
        this.blueBarChart.chartData.datasets[0].data.push(product.attributes['items-sold']);
      });
      this.$forceUpdate();
    })
  }

    private async getOnboarding() {
      await ConceptApi.checkOnboarding().then((response: any) => {
        this.onBoard = response.data.onboarding;
        this.wizard = response.data.wizard;
        this.menuGroups = response.data.groups;
        // this.onBoard = true;
        console.log("onBoard check check", this.onBoard);
      })
    }

    async getTerms() {
      await ConceptApi.getTerms().then((response: any) => {
        if(response.data.data.length == 0) {
          if(this.getClientUserLabel !== 'Foodics Online') {
            this.isTermsCondition = true;
          }
        }
        console.log("check terms data", response.data.data.length);
      })
    }

  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }

    closeSetupModal(e: any) {
      this.isSetupSetOpen = e;
      this.doneSetup = true;
      if(this.wizard) { 
        this.isMenuSetup = true;
      } else {
        this.isWebAdressSetOpen = true;
      }
    }
    closeMenuSetup(e: any, flag: any) {
      this.isMenuSetup = false;
      if(flag != 'skip') {
        this.isMenuSyncing = true;
      } else {
        this.isWebAdressSetOpen = true;
      }
      this.checkApplicationOnBoarding();
    }
    closeMenuSyncing(e: any) {
      this.isMenuSyncing = e;
      this.isWebAdressSetOpen = true;
    }
    closeFoodicsPay(e: any) {
      this.isFoodicsPay = e;
      this.setFoodicsPay(false);
      this.isLogoSetOpen = true;
      this.isMenuSetup = false;
    }
    closeAddressModal(e: any) {
      this.isWebAdressSetOpen = e;
      if(this.getClientUserLabel === 'Foodics Online') {
        this.openDeliveryCharge(true);
          return;
       } else {
        if(this.getConceptSettings.attributes.country == 'SA') {
          this.isFoodicsPay = true;
          this.setFoodicsPay(true);
        } else {
          this.isLogoSetOpen = true;
        }
          return;
       }
    }
    closeTerms(e: any) {
      this.isTermsCondition = e;
    }
    closeDeliveryCharge(e: any) {
      this.isDeliveryCharge = e;
      if(this.getConceptSettings.attributes.country == 'SA') {
          this.isFoodicsPay = true;
          this.setFoodicsPay(true);
        } else {
          this.isLogoSetOpen = true;
        }
      }
    closeLogoModal(e: any) {
      this.isLogoSetOpen = e;
    }

    openLogoModalCharge(deliveryCharge: any) {
    this.saveDeliveryCharge(deliveryCharge);
    console.log("from delivery", deliveryCharge);
    }
    openDeliveryCharge(e: any) {
      this.isDeliveryCharge = e;
      this.getDeliveryCharge();
    }

  private capitalize (s: any): string {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  private getOrderStatuses(): void {
    DashboardApi.getOrderByStatuses('open').then((response: any) => {
      this.statuses = response.data;
      this.chartDoughnutData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      };
      this.statuses.data = this.statuses.data
        .filter((d: any) => !!this.displayStatuses.find((ds: any) => ds.code === 'waiting-for-payment' || ds.code === 'cancelled-by-customer' || ds.code === 'cancelled-by-employee'))
        .map((d: any) => {
          const status = this.displayStatuses.find((stat: any) => stat.code === 'waiting-for-payment' || stat.code === 'cancelled-by-customer' || stat.code === 'cancelled-by-employee');
          if (status) {
            this.chartDoughnutData.labels.push(d.status.code);
            this.chartDoughnutData.datasets[0].backgroundColor.push(status.color);
            this.chartDoughnutData.datasets[0].data.push(d.ordersCount);
            d.status.color = status.color;
            d.status.codeFormatted = this.capitalize(d.status.code.replaceAll('-', ' '));
          }
          return d;
        });
    })
    DashboardApi.getOrderByStatuses('closed').then((response: any) => {
      this.statusesClosed = response.data;
    });
  }

  private getRegistrationBySource(): void {
    DashboardApi.getRegistrationBySource().then((response: any) => {
      this.registrationBySource = response.data.data;
    });
  }

  private static findMedian(coords: Array<any>): any {
    coords.sort((a: any, b: any) => { return a - b; });

    const middle = Math.floor(coords.length / 2);

    if (coords.length % 2 === 0) {
      console.log("middle nums when array length is even: " + coords[middle - 1]  + " " + coords[middle]);
      return coords[middle - 1];
    } else {
      console.log("middle num when array length is odd: " + coords[middle]);
      return coords[middle];
    }
  }

  private static getBounds(mapData: any): any {
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0, LtLgLen = mapData.length; i < LtLgLen; i++) {
      bounds.extend(mapData[i]);
    }
    return bounds;
  }

  private getDeliveryAreas() {
    let map: any
    let heatmap: any;

    DashboardApi.deliveryAreas().then((response: any) => {
      const deliveryAreas = response.data;
      const lats: any = [];
      const longs: any = [];
      const mapData: any = [];
      const elem: any = document.getElementById('map');

      deliveryAreas.forEach(function (item: any) {
        lats.push(Number(item.lat));
        longs.push(Number(item.long));
        mapData.push(new google.maps.LatLng(item.lat, item.long));
      });
      console.log('lats - longs: ', lats, longs)

      map = new google.maps.Map(elem, {
        zoom: 5,
        center: { lat: 23.8859, lng: 45.0792 },
        mapTypeControl: false,
        streetViewControl: false,
      });

      heatmap = new google.maps.visualization.HeatmapLayer({
        data: mapData,
        radius: 20,
        map: map,
      });

      console.log('mapData: ', mapData);

      if (mapData && mapData.length) {
        if (this.$store.getters['account/activeConcept'].id === 15) {
          // This is for KUDU only because we have an I.T branch in PHILIPPINES
          // setting the bounds will show the entire map
          // this is a workaround.
          map.setCenter(new google.maps.LatLng(Dashboard.findMedian(lats), Dashboard.findMedian(longs)));
        } else {
          map.fitBounds (Dashboard.getBounds(mapData));
          map.setZoom(7);
        }
      }

      // Set user location hide for now
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(function(position) {
      //     map.setCenter({
      //       lat: position.coords.latitude,
      //       lng: position.coords.longitude
      //     });
      //   }, () => {
      //     alert('Cannot find location.');
      //   });
      // }
    });
  }

  public get totalUserCount(): number {
    let total = 0;
    if (this.registrationBySource) {
      this.registrationBySource.forEach((source: any) => (total += source.usersCount));
    }
    return total;
  }

  public onSelectStats(statType: string): void {
    this.selectedStats = statType;
    this.dataPerSource = {
      today: [],
      yesterday: [],
      prev: [],
    };
  }
}

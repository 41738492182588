


































































































































import { Order as OrderApi, Integrations, Location } from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import {translations} from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    Translations,
  },
  mixins: [translations]
})
export default class buttonAssignDriver extends Vue {
  isShowDetails: boolean = false
  showReceiptButton: boolean = false
  @Prop() receipts!: Array<Object>;
  @Prop() total!: string
  @Prop() screenSize!: number

  @Watch('screenSize', {deep: true})
    onShow(screenSize: number) {
      this.updateScreen(screenSize);
  }
  mounted() {
    this.updateScreen(this.screenSize);
  }

  updateScreen(screenSize: number) {
    if (screenSize <= 575) {
      this.isShowDetails = false;
      this.showReceiptButton = true;
    } else {
      this.isShowDetails = true;
      this.showReceiptButton = false;
    }
  }

  showOrderDetails() {
    this.isShowDetails = ! this.isShowDetails;
  }
}

import store from '@/store'

export default function auth({ to, from, next, router }) {

  if (from.path === '/' && !store.getters['account/isLoggedIn']) {
    // return router.push({ name: 'login' })
    next('/login')
  } else if (from.path && to.path === '/' && store.getters["account/isLoggedIn"]) {
    router.push('/dashboard')
  } else if (from.path === '/' && store.getters['account/isLoggedIn']) {
    next()
  } else if (from.path === '/login' && store.getters['account/isLoggedIn']) {
    next()
  }
  next()
}

import state from './page.state';
import getters from './page.getters';
import mutations from './page.mutations';
import actions from './page.actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

import BaseService from '../base.service'
import store from '@/store'
export default class SliderService extends BaseService{

    constructor(){
        super();
        this.baseURL += 'cms/sliders'
    }


    /**
     *
     * @param page
     * @returns {AxiosPromise<any>}
     */
    all(page = 1){
        return super.get(this.baseURL, {
            page: page
        });
    }

    getSlides(sliderId: string) {
        return super.get(this.baseURL + '/' + sliderId + '/slides');
    }
}

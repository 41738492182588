






















import {translations} from '@/mixins'
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";
import { gsap } from "gsap/all";
@Component({
  components: {
  },
  mixins: [translations],
})
export default class BannerSetModal extends Vue {
  number: any = 99
  tweenedNumber: any = 0
  $notify: any;
  $refs!: {
    file: any;
  }
  @Prop() open!: Boolean
  @Prop() flag_sync!: Boolean
  @Prop() wizard!: Boolean

  @Watch("open", {deep: true, immediate: true})
  onOpen(data: any) {
    if(data) {
      gsap.to(this.$data, { duration: 45, tweenedNumber: this.number  });
    }
  }

    get animatedNumber () {
        return this.tweenedNumber.toFixed(0);
    }


  get isOpen(): Boolean {
    return this.open;
  }
  set isOpen(newVal: Boolean) {
    if (!newVal) {
    }
  }

  constructor() {
    super();
  }


closeModalSetup(e: any) {
       setTimeout(() => {
            this.$emit("modal:close", e);
        }, 2000);
  }


}














































































































































import moment from 'moment'
import SubscriberUpdateModal from './SubscriberUpdateModal'
import SubscriberAddress from './SubscriberAddress'
import SubscriberOrders from './SubscriberOrders'
import {Table, TableColumn, Button, Form, FormItem, Select, Option} from 'element-ui'
import {Component, Prop, Watch, Vue} from "vue-property-decorator"
import { Customer } from '@/services/SOLO'
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import { CustomerAddress as Address } from "@/models"
import { CustomerOrders as Orders } from "@/models"
interface SubscriberDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string,
  'subscribe-to'?: string,
}

@Component({
  components: {
    SubscriberUpdateModal,
    SubscriberAddress,
    SubscriberOrders,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  filters: {
      standardDateFormat(value: any)  {
        if (! value) return '---';
        return moment(value).format('DD/MM/YYYY');
      }
  },
mixins: [translations],
})
export default class SubscriberSingle extends Vue {
  openEdit: boolean = false
  id: any
  tab: number = 1
  singleSubscriber: SubscriberDetails = {}
  customerAddress: any = {}
  customerOrders: any = {}
  currentSubPlan: any = ''
  public translate!: Function


  mounted() {
    let subscriberId = this.$route.params.id
    this.currentSubPlan = this.$route.params.data
    this.singeSubscriber(subscriberId)
    this.getSubscriberAddress(subscriberId)
    this.getSubscriberOrders(subscriberId)
  }

  handleEdit() {
    this.openEdit = true
  }

  handleClose(value: any) {
    this.openEdit = value
  }

  async singeSubscriber(id: any) {
    await Customer.find(id)
        .then((response: any) => {
          this.singleSubscriber = response.data.data.attributes
        })
  }

  async getSubscriberOrders(id: any) {
    await Customer.getOrders(id).then((response: any) => {
      this.customerOrders = response.data.data
    })
  }

  async getSubscriberAddress(id: any) {
    await Customer.getAddresses(id).then((response: any) => {
      this.customerAddress = response.data.data
    })
  }

  get dropDownText(): string {
     if (this.tab === 1) {
       return this.translate('Addresses')
     } else {
       return this.translate('Orders')
     }
  }
}

import {Auth} from '@/services/core'

export default {
    setLocale(state: { locale: any; }, data: any){
        state.locale = data
    },
    setSubscription(state: { subscription: any }, data: any) {
        state.subscription = data
    },
    setQueryParams(state: { queryParams: any }, data: any) {
        state.queryParams = data
    },
    setAppSubscription(state: { subscription: any; queryParams: null }, data: any) {

        let planId: any = data.params.planId ? data.params.planId : 2
        let billingCycle: any = data.query['billing-cycle']

        if([2,3].includes(Number(planId))) {
          state.subscription = Number(planId)
        } else {
          state.subscription = 2
        }
        state.queryParams = billingCycle
    }
}






















import { translations } from '@/mixins';
import Translations from './components/Translations.vue';
import { Component, Vue } from "vue-property-decorator";
import { mixins } from 'vue-class-component';

import CouponsHeader from './components/CouponsHeader'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'

@Component({
  components: {
    Translations,
    CouponsHeader,
    RouteBreadCrumb,
  },
  mixins: [translations],
})
export default class Coupons extends Vue {
  created() {
  }
}





















import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"

@Component
export default class PremadePlanIndex extends Vue {}

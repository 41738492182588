









































import { CustomField, Menus as MenuModel } from "@/models"
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui"
import CreateModifierGroupModal from './CreateModifierGroupModal.vue'
import ModifierItemModal from './ModifierItemModal.vue'
import { Settings } from '@/services/SOLO'
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator"
import { mapGetters } from 'vuex';
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    CreateModifierGroupModal,
    ModifierItemModal,
    Translations,
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser',
      getMenus: 'menu/getMenus'
    })
  },
  mixins: [translations],
})
export default class CustomFieldTable extends Vue {
  translate!: Function;
  row: any
  @Prop() modifierGroups!: Array<any>
  @Prop() active!: Boolean

  $refs!: {
    modifierGroupModal: CreateModifierGroupModal
    modifierItemModal: ModifierItemModal
  }
  initCreate() {
    this.$refs.modifierGroupModal.open = true
    this.$refs.modifierGroupModal.update = false
  }

  initUpdate(row: any) {
    this.row = row
    this.$refs.modifierGroupModal.open = true
    this.$refs.modifierGroupModal.update = true
  }

  viewItems(row: any) {
    this.row = row
    this.$refs.modifierItemModal.open = true
  }

  getList(data: any) {
    this.$emit('updateList', data);
  }
}

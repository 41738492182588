import { Concept } from '@/interfaces/SOLO'
import { Language } from '@/interfaces/SOLO'
import { Integration } from '@/interfaces/SOLO'

import * as jwt from 'jsonwebtoken';

export default {
    getUser(state: { user: any }) {
        return state.user;
    },
    getIsFoodicsPay(state: { isFoodicsPay: any}) {
      return state.isFoodicsPay;
    },
    getIntegration(state:  { integration: any } ) {
        return state.integration
    },
    getUserClient(state: { client: any; }) {
      return state.client
    },
    getSubscriptionPaymentId(state: { paymentId: any; }) {
      return state.paymentId
    },
    getSubscription(state: { subscription: any; } ) {
      return state.subscription
    },
    getUserRole(state: { userRole:string }) {
        return state.userRole
    },
    isLoggedIn(state: { user: null }) {
        return state.user !== null
    },
    getConceptSettings(state: { user: any; conceptSettings: any; }) {
      if(state.user) {
        return state.conceptSettings
      }
      return null
    },
    getConcepts(state: { user: { attributes: { concepts: Concept } } }) {
      if(state.user) {
        return state.user.attributes.concepts
      }        
      return []
    },
    // getLanguages(state: { user: any; conceptSettings: any; }) {
    //   if(state.user) {
    //     return state.conceptSettings
    //   }        
    //   return []
    // },
    activeConcept(state: { concept: Concept }) {
        if(state.concept.hasOwnProperty('id'))
        {
            return state.concept
        }
        return null
    },
    activeLanguage(state: { language: Language }) {

          return state.language

    },
    getMetabaseReportURL(state: { concept: Concept, dashboard: number, userRole: any, user: any }) {
      if (state.concept.hasOwnProperty('id')) {
        let dash = 0;
        let id = 0;
        if (state.userRole.includes('franchisee')) {
          dash = 20;
          id = parseInt(state.user.id);
        } else {
          dash = state.dashboard;
          id = state.concept.id;
        }
        const payload:any = {
          resource: { dashboard: dash },
          // params: {
          //   id: [id],
          // },
          exp: Math.round(Date.now() / 1000) + (10 * 60), // 10 minute expiration
        };
        payload['params'] = {};
        if (state.userRole.includes('franchisee')) {
          payload['params']['employee_id'] = id;
        } else {
          payload['params']['id'] = id;
        }
        console.log('jwt', payload, process.env.VUE_APP_METABASE_SECRET_KEY);
        const token = jwt.sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY);

        return process.env.VUE_APP_METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
      }
      return null;
    }
}

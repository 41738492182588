import BaseService from '../base.service'

let upsellService = class UpsellService extends BaseService {

  constructor() {
    super();
  }

  loadItems(menuId: string, query: string = 'eid') {
    const url = `${this.baseURL}menus/${menuId}/items?filter[name]=${query}`;
    return super.get(url);
  }

  loadUpsells(menuId: string) {
    const url = `${this.baseURL}menus/${menuId}/upsells?include=item.category`;
    
    return super.get(url);
  }

  addUpsell(menuId: string, itemId: string) {
    const url = `${this.baseURL}menus/${menuId}/upsells`;
    return super.post(url, { 'item-id': itemId });
  }

  removeUpsell(menuId: string, upsellId: string) {
    const url = `${this.baseURL}menus/${menuId}/upsells/${upsellId}`;
    return super.remove(url, {});
  }





  all(page = 1){
    let url = this.baseURL + 'employees';

    return super.get(url, {
      page: page
    });
  }

  find(employeeId: string) {
    let url = this.baseURL + `employees/${employeeId}?include=locations`;

    return super.get(url)
  }

  getDrivers(page: number = 1) {
    // let url = this.baseURL + `employees?filter[role]=driver&include=bearing,order&size=999560&page=${page}`
    let url = this.baseURL + `employees?filter[role]=driver&size=50&page=${page}`

    return super.get(url);
  }

  getRoles() {
    let url = this.baseURL + `roles`;

    return super.get(url);
  }

  getLocations(pageSize: number = 50) {
    let url = this.baseURL + `locations?filter[enabled]=all&limit=${pageSize}`;

    return super.get(url);
  }

  create(payload: any) {
    let url = this.baseURL + 'employees';

    return super.post(url, payload);
  }

  update(employeeId: string, params: any) {

    let url = this.baseURL + 'employees/' + employeeId;

    return super.patch(url, params);
  }

  remove(employeeId: string) {
    let url = this.baseURL + 'employees/' + employeeId;

    return super.remove(url, {});
  }
}

export default upsellService












import Modal from '@/components/Modal.vue'
import Modifiers from './Modifiers.vue'
import ModifierItemForm from './ModifierItemForm'
import { Category, Settings } from '@/services/SOLO'
import {Select, Option, Menu, Form} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Category as CategoryModel, CustomField, Menus as MenuModel } from "@/models"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import { BForm } from 'bootstrap-vue'

interface itemName {
  [x: string]: string
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ModifierItemForm,
    Modifiers
  },
  mixins: [eventHandler]
})
export default class CreateCustomFieldModal extends Vue {
  parent: any
  name: itemName = {}
  open: boolean = false
  arrayLang: Array<string> = ["en-us", "ar-sa"]
  modifiers: Array<Object> = []

  constructor() {
    super()
    this.parent = this.$parent?.$parent
    for(let i in this.arrayLang) {
      this.name[this.arrayLang[i]] = ''
    }
  }

  get modalTitle(): string {
    if(this.open) {
      return this.parent.row.attributes.name
    }
    return ''
  }

  saveForm(e: HTMLFormElement) {

  }

  @Watch('open', { immediate: true, deep: true})
  isOpen(newVal: boolean) {
    if(newVal) {
      this.getModifiers()
    }
  }

  initUpdateForm(data: any) {
    console.log(data)
  }

  getModifiers(lang: string = '') {
    Settings.modifiers(this.parent.row.id)
      .then((response: any) => {
        this.modifiers = response.data.data
        console.log(response.data.data)
      })
  }

  closeMe(e: any) {
    this.open = false
  }
}

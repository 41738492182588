












































































import { Table, TableColumn, Button } from "element-ui"
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  mixins: [translations],
})
export default class PageList extends Vue {
  public translate!: Function
  @Prop() list!: any
  @Prop() listMeta!: any
  @Prop() loading: boolean = false
  color: string = "white"
  isLoaded: boolean = false
  total: number = 0
  currentPage: number = 1
  perPage: number = 0

  @Watch("listMeta", { deep: true })
  onListMetaChanged(newVal: any) {
    this.isLoaded = true;
    this.total = newVal.total;
    this.currentPage = newVal.current_page;
    this.perPage = newVal.per_page;
  }

  openEditPage(open: any, id: any) {
    this.$emit("openEditPage", open, id)
  }
  openDeletePage(id: any) {
    this.$emit("deletePage", id)
  }

  pageChange(data: any) {
    this.$emit("getPages", data);
  }
}




































import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { mapGetters } from "vuex";
import { Select, Option } from "element-ui";
import { notificationAlerts, translations } from "@/mixins";
import StoreTimes from "../components/StoreTimes.vue";
import Translations from "../components/Translations.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CriteriaFields from "./CriteriaFields";
import Promotions from "../Promotions.vue";
import { Promotion } from "@/services/SOLO";

@Component({
  components: {
    CriteriaFields
  },
  mixins: [notificationAlerts, translations]
})
@Component({
  components: {
    CriteriaFields
  },
  mixins: [notificationAlerts, translations]
})
export default class SegmentsUpdate extends Vue {
  isLoading: boolean = false;
  isDeleteLoading: boolean = false;
  segment: any = {};
  successNotification!: Function;

  created() {
    const id = this.$route.params.id;
    const crit: any = [];
    Promotion.getSegment(id).then(res => {

      console.log('getSegment: ', res.data.data.criteria);
      res.data.data.criteria.forEach((criterion: any) => {
        console.log('criterion: ', criterion.value);
        crit.push({
          id: uuidv4(),
          provider: `\\${criterion.handler}`,
          condition: criterion.choice,
          value: Array.isArray(criterion.value) ? [...criterion.value] : criterion.value,
        });
      });
      console.log('crit: ', crit);
      this.segment = {
        name: res.data.data.name,
        condition: res.data.data.condition,
        criteria: crit,
      };
    });
  }
  submitForm(e: any) {
    const id = this.$route.params.id;
    e.preventDefault();
    const criteria = this.segment.criteria.map((criterion: any) => {
      return {
        provider: criterion.provider,
        condition: criterion.condition,
        value: criterion.value,
      };
    });
    const body = { ...this.segment };
    body.criteria = criteria;
    this.isLoading = true;
    Promotion.updateSegment(id, body)
      .then(() => {
        this.isLoading = false;
        this.successNotification('SEGMENT UPDATED!', 'segment successfully updated!');
        this.$router.push({ name: 'SegmentsList' });
      });
  }
  deleteSegment() {
    const id = this.$route.params.id;
    let proceed = confirm("Are you sure you want to delete this segment?");
    if (proceed) {
      this.isDeleteLoading = true;
      Promotion.deleteSegment(id)
        .then(() => {
          this.isDeleteLoading = false;
          this.successNotification('SEGMENT DELETED!', 'segment successfully deleted!');
          this.$router.push({ name: 'SegmentsList' });
        });
    }
  }
}




















































import {Table, TableColumn, Button, Form, FormItem, Select, Option} from 'element-ui'
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import { Customer } from '@/services/SOLO'
import { translations } from '@/mixins'
import Translations from './Translations.vue'
interface CustomerDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string
}

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },
mixins: [translations],
})
export default class SubscriberUpdateModal extends Vue {
  @Prop() openEdit: boolean = false
  @Prop() customer?: Object
  public translate!: Function
  id: any
  isOpen: boolean = false
  modalForm: any =  {
    'first-name': '',
    'last-name': '',
    email: '',
    mobile: '',
    pincode: 0,
    status: '',
    'account-type': ''
  }
  selectedStatus: any = ''
  selectedType: any = ''

  mounted() {
    this.id = this.$route.params.id
  }
  @Watch('openEdit', {deep: true})
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }
  @Watch('customer', {deep: true})
  onCustomerChanged(newVal: any) {
    this.modalForm = newVal
    this.selectedStatus = this.modalForm['status']
    this.selectedType = this.modalForm['account-type']
  }

  handleClose() {
    this.$emit('handleClose', false)
  }

  updateCustomerDetails() {
    this.modalForm['status'] = this.selectedStatus
    this.modalForm['account-type'] = this.selectedType

    Customer.update(this.id, this.modalForm)
        .then((response: any) => {
          this.$emit('handleClose', false)
        })
  }

}
































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'
import { OrderStatusAttributes, OrderStatus as OrderStatusModel } from "@/models";
import { Order as OrderApi } from '@/services/SOLO'
import {mapGetters} from 'vuex';

@Component({
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
mixins: [translations],
})
export default class OrderNotification extends Vue {
  orderstatuses: Array <OrderStatusModel> = [];
  order: Array <OrderStatusAttributes> = [];
  getConceptSettings: any
  public translate!: Function
  $notify: any;
  mounted() {
    this.getOrderStatuses();
  }

  getOrderStatuses() {
    OrderApi.orderstatuses().then((response: any) => {
      this.orderstatuses = response.data.data;
 
      console.log(this.orderstatuses);
   
    });
  }



  updateOrderStatuses(orderstatusesID: number | string, index: any) {
    let payload = {
      "delivery-notification-message-alt": this.orderstatuses[index].attributes["delivery-notification-message-alt"],
      "delivery-notification-message": this.orderstatuses[index].attributes['delivery-notification-message'],
      "pickup-notification-message-alt": this.orderstatuses[index].attributes['pickup-notification-message-alt'],
      "pickup-notification-message": this.orderstatuses[index].attributes['pickup-notification-message'],
      "is-notification-enabled": this.orderstatuses[index].attributes['is-notification-enabled']
    };

    OrderApi.updateorderstatuses(payload, orderstatusesID).then((response: any) => {
      this.orderstatuses = response.data.data;
      console.log(this.orderstatuses);
      this.getOrderStatuses();
      this.$notify({
        title: "ORDER NOTIFICATION SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Changes have been saved successfully",
        type: "success",
        icon: "fas fa-check",
      });
  
    })
    .catch((err: any) => {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: err.response.data.error.detail,
        type: "danger",
        icon: "fas fa-bomb",
      });
    });
     
  }


}

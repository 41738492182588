import BaseService from '../base.service'
export default class SmilesService extends BaseService {

  constructor() {
    super();
  }

  searchByPin(pin: string|number) {
    const url = `${this.baseURL}kudu-smiles-search-sequence-pin?sequencePin=${pin}`;
    return super.get(url);
  }

  validateOrder(payload: any) {
    const url = `${this.baseURL}kudu-smiles-validate-call-center-order`;
    return super.post(url, payload);
  }

  download(query: string) {
    const url = `${this.baseURL}kudu-smiles-reports/download?${query}`;
    return super.get(url);
  }

  logs(query: string) {
    const url = `${this.baseURL}kudu-smiles-reports?${query}`;
    return super.get(url);
  }

  getCustomerDetails(customerId: string|number) {
    const url = `${this.baseURL}kudu-smiles-customer/${customerId}`;
    return super.get(url);
  }
  renewQRCode(payload: any) {
    const url = `${this.baseURL}kudu-smiles-qr-code`;
    return super.post(url, payload);
  }

  searchByCustomers(term: any) {
    let filters = ''
    filters += Object.keys(term)
                .filter(k => { return k != 'today' })
                .map(k => { return `filter[${encodeURIComponent(k)}]` + '=' + encodeURIComponent(term[k])})
                .join('&')
    const url = `${this.baseURL}customers?${filters}`;
    return super.get(url);
  }
}

import BaseService from '../base.service'
import store from '@/store'

export default class SettingsQueueBusterService extends BaseService {
  url!: string

  constructor() {
      super();
  }

  download(orderType: string = '', qrCodeCount: number = 1, branchId: any, branchName: string = '', sequenceFrom: number = 1, sequenceTo: number = 1, printLogo: any, printBranchName: any, printTableLabel: any, tableLabel: any, applicationId: any) {
    this.url = this.baseURL + `queue-buster`;  
    return super.post(this.url, {
      orderType: orderType,
      qrCodeCount: qrCodeCount,
      branchId: branchId,
      branchName: branchName,
      sequenceFrom: sequenceFrom,
      sequenceTo: sequenceTo,
      printLogo: printLogo,
      printBranchName: printBranchName,
      printTableLabel: printTableLabel,
      tableLabel: tableLabel,
      applicationId: applicationId,
    });
  }
}

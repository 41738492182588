export default {
    getLocale(state: { locale: any; }){
        return state.locale
    },
    getLocales(state: { locales: any }) {
        return state.locales
    },
    getAppSubscription(state: { subscription: any }) {
        return state.subscription
    },
    getAppQueryParams(state: { queryParams: any }) {
        return state.queryParams
    }
}

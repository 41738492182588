import BaseService from '../base.service';
import { TimeEvent } from '@/models';

export default class CmsService extends BaseService{
    
    appendURI: string = '/cms/pages';
    url: string = ''

    constructor(){
        super();
    }

    /**
     *
     * @param page
     * @returns {AxiosPromise<any>}
     */
    all(params: any, page: any = 1){
        let url = super.prepareUrl(this.baseURL + this.appendURI, params);
        return super.get(this.baseURL, {
            page: page
        });
    }

    getPage(pageId: number) {
        return super.get(this.baseURL + this.appendURI + '/' + pageId);
    }

    storeTimedEvents(event: TimeEvent, payload: Object = {},slideId: string | number, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        if(!event.saved) {
            this.url = this.baseURL + `cms/slides/${slideId}/timed-events`
            return this.post(this.url, payload)
        }else {
            this.url = this.baseURL + `cms/slides/${slideId}/timed-events/${event.id}`
            return this.patch(this.url, payload)
        }

    }

    getTimedEvents(slideId: string | number, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `cms/slides/${slideId}/timed-events`

        return this.get(this.url)
    }

    deleteTimedEvents(eventId: string | number, slideId: string | number, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.url = this.baseURL + `cms/slides/${slideId}/timed-events/${eventId}`

        return super.remove(this.url, {})
    }
}

import BaseService from '../base.service'

let employeeService = class EmployeeService extends BaseService {

  constructor() {
    super();
  }

  all(page = 1){
    let url = this.baseURL + 'employees';

    return super.get(url, {
      page: page
    });
  }

  find(employeeId: string) {
    let url = this.baseURL + `employees/${employeeId}?include=locations`;

    return super.get(url)
  }

  getDrivers(page: number = 1) {
    // let url = this.baseURL + `employees?filter[role]=driver&include=bearing,order&size=999560&page=${page}`
    let url = this.baseURL + `employees?filter[role]=driver&include=bearing&size=50&page=${page}`

    return super.get(url);
  }

  getRoles() {
    let url = this.baseURL + `roles`;

    return super.get(url);
  }

  getLocations(pageSize: number = 300) {
    let url = this.baseURL + `locations?filter[enabled]=all&limit=${pageSize}`;

    return super.get(url);
  }

  getLocations2(lang: string = 'en-us', page: number = 1, franchisee: boolean = false) {
    this.headers['Accept-Language'] = lang
    let url = this.baseURL + `locations?filter[enabled]=all&limit=100&page=${page}`;

    if (franchisee) {
      url += '&filter[has-franchiser]=0';
    }

    return super.get(url)
  }

  create(payload: any) {
    let url = this.baseURL + 'employees';

    return super.post(url, payload);
  }

  update(employeeId: string, params: any) {

    let url = this.baseURL + 'employees/' + employeeId;

    return super.patch(url, params);
  }

  remove(employeeId: string) {
    let url = this.baseURL + 'employees/' + employeeId;

    return super.remove(url, {});
  }

  search(queryString: string = '') {
    let url = this.baseURL + `employees?filter[search_string]=${queryString}`;

    return super.get(url);
  }
}

export default employeeService

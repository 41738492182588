




























































































































































































































































































import moment from "moment";
import { eventHandler, translations } from "@/mixins";
import Translations from "../components/Translations.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Promotion } from "@/services/SOLO";
import PromotionCollapse from "../components/PromotionCollapse.vue";

@Component({
  components: {
    Translations,
    PromotionCollapse
  },
  mixins: [eventHandler, translations]
})
export default class PromotionsList extends Vue {
  hasList: boolean = true;
  promotions: Array<any> = [];
  currentPage: number = 1;
  perPage: number = 0;
  totalPage: number = 0;
  total: number = 0;
  page: any = 1;
  promotionMeta: any = ""
  filter: any = {
    name: '',
  }
  translate!: Function;
  confirm!: Function
  $notify: any;

  mounted() {
    this.listOfPromotion(this.page);
  }

  @Watch("promotionMeta", { deep: true })
    onFeedbackMetaChanged(newVal: any) {
      this.total = newVal.total;
      this.currentPage = newVal.current_page;
      this.perPage = newVal.per_page;
  }

  applyFilter(data: any) {
    this.filter.name = data.name;
    this.listOfPromotion(1);
  }

  reset() {
    this.filter.name = '';
    this.listOfPromotion(1);
  }

  listOfPromotion(page: any) {
    Promotion.fetchPromotions(page, { name: this.filter.name }).then(response => {
      this.promotions = response.data.data;
      console.log("promotionsResponse: ", response.data.meta.pagination);
      this.promotionMeta = response.data.meta.pagination

    });
  }

  onAddPromotion() {
    this.$router.push({
      name: "PromotionsCreate"
    });
  }

  onEditPromotion(promotion: any) {
    this.$router.push({
      path: `/promotions/edit/${promotion.id}`
    });
  }

  onDeletePromotion(promotion: any) {
    let message = this.translate("Are you sure you want to delete this promotion ") + promotion.attributes.name

    this.confirm(this.$bvModal, `${message}?`).then(
      (value: boolean) => {
        if(value) {
          Promotion.deletePromotion(promotion.id).then(() => {
            this.listOfPromotion(this.page)

            this.$notify({
              title: 'Deleting Success',
              verticalAlign: 'bottom',
              horizontalAlign: 'left',
              message: `Promotion Deleted Successfully`,
              type: "success",
              icon: "fas fa-check"
            });
          }).catch((err) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
        }
      }
    );
  }

  pageChangeActive(data: any) {
    this.page = data;
    console.log("page", data);
    this.listOfPromotion(data);
  }

  formatDate(date: Date) {
    console.log(moment.locale());
    return moment
      .utc(date)
      .locale("en-us")
      .local()
      .format("ll");
  }
}

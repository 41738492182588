





























































































































































































import Modal from "@/components/Modal.vue";
import { Category as CategoryApi } from '@/services/SOLO'
import { CatAttributes, CatDesc, Category, CatName, ItemAttributes } from "@/models";
import { Select, Option } from "element-ui";
import {
  translations
} from '@/mixins'
import Translations from './Translations.vue'
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [translations],
})
export default class MenuItemCreateModal extends Vue {
  arrayLang: Array<string> = ["en-us", "ar-sa"];
  catName: CatName = {};
  catDesc: CatDesc = {};
  imageType: number = 0;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ]
  catItem: ItemAttributes = {
    name: {},
    description: {},
    code: '',        
    price: '',
    "display-order": 1,    
    calories: '',
    'person-count-per-serving': 1,
    'calorie-count': '',
    'image-uri': '',
    enabled: 1,
    'disable-for-pickup': 0,
    'disable-for-delivery': 0
  }
  $notify: any
  $refs!: {
    file: any
  }

  @Prop() open!: Boolean;

 

  get isDisabledForPickup(): Boolean {
    return this.catItem['disable-for-pickup'] === 1 ? true : false;
  }

  set isDisabledForPickup(newVal: Boolean) {
    this.catItem['disable-for-pickup'] = newVal ? 1 : 0
  }

  get isDisabledForDelivery(): Boolean {
    return this.catItem['disable-for-delivery'] === 1 ? true : false;
  }

  set isDisabledForDelivery(newVal: Boolean) {
    this.catItem['disable-for-delivery'] = newVal ? 1 : 0
  }
  
  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("modal:update", newVal);
    if(!newVal) {      
        this.catItem.code = ''
        this.catItem["display-order"] = 1
        this.catItem.enabled = 1
        this.catItem['image-uri'] = ''                
        this.catItem.price = ''        
        this.catItem.calories = ''
        this.catItem['person-count-per-serving'] = 1
        this.catItem['calorie-count'] = ''
        this.catItem['disable-for-pickup'] = 0
        this.catItem['disable-for-delivery'] = 0        
        for (let i in this.arrayLang) {
          this.catName[this.arrayLang[i]] = ''
          this.catDesc[this.arrayLang[i]] = ''
        }
        this.catItem.name = this.catName;
        this.catItem.description = this.catDesc;
        this.imageType = 0
      
    }
  }

  constructor() {
    super();
    for (let i in this.arrayLang) {
      this.catName[this.arrayLang[i]] = "";
      this.catDesc[this.arrayLang[i]] = "";
    }
    this.catItem.name = this.catName;
    this.catItem.description = this.catDesc;
  }

  get categoryItemData(): CatAttributes {
    return this.catItem
  }

  get categoryItemForm(): FormData {
    let formData = new FormData()
    for(let i in this.arrayLang) {
      formData.append(`name[${this.arrayLang[i]}]`, this.catItem.name[this.arrayLang[i]])
      formData.append(`description[${this.arrayLang[i]}]`, this.catItem.description[this.arrayLang[i]])
    }          
    formData.append('code', this.catItem.code)
    formData.append('price', this.catItem.price)
    formData.append('display-order', this.catItem['display-order'].toString())
    formData.append('calories', this.catItem.calories)
    formData.append('person-count-per-serving', this.catItem['person-count-per-serving'].toString())
    formData.append('calorie-count', this.catItem['calorie-count'])
    formData.append('enabled', this.catItem.enabled.toString()) 
    formData.append('disable-for-pickup', this.catItem['disable-for-pickup'].toString())     
    formData.append('disable-for-delivery', this.catItem['disable-for-delivery'].toString())    
    formData.append('image', this.$refs.file.files[0])
    return formData
  }  

  checkSize(text: any) {
    if(text === 'The image field size must be less than 2000KB') {
      return 'The image file size must be less than 2MB'
    }
  }

  createCategoryItem(e: any, reset: any) {    
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Creating Category'
    e.target.disabled = true    
    
    const payload = !this.imageType ? this.categoryItemData : this.categoryItemForm
    const content = !this.imageType ? 'application/json' : 'multipart/form-data'
    
    CategoryApi.createItem(payload, this.$route.params.id, this.$route.params.catId, content)
      .then(response => {
        this.$notify({
          title: "CATEGORY ITEM SAVED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Category item has been saved successfully",
          type: "success",
          icon: "fas fa-check",
        });
        e.target.innerHTML = defaultText
        e.target.disabled = false

        // reset headers
        CategoryApi.headers['Content-Type'] = 'application/json'
        this.$emit('item:created')
        this.$emit('modal:update', false);
        reset()
      })
      .catch(err => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}

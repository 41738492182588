

























































import moment from 'moment'
import { Customers } from "@/models";
import { Table, TableColumn, Button } from "element-ui"
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { eventHandler, notificationAlerts, translations } from '@/mixins'
import EmployeeUpdateModal from './EmployeeUpdateModal'
import { Employee } from '@/services/SOLO'

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    EmployeeUpdateModal,
  },
mixins: [eventHandler, translations, notificationAlerts],
})
export default class EmployeeList extends Vue {
  @Prop() list!: Array<any>
  @Prop() loading: boolean = false
  public translate!: Function
  color: string = 'white'
  currentPage: number = 1
  customer: Customers = {
    id: 0,
    attributes: {
      code: '',
      'first-name': '',
      'last-name': '',
      email: '',
      mobile: '',
      status: '',
      'account-type': '',
      provider: 0,
      'last-order-id': 0,
      'last-order-type': '',
      'last-order-location': 0,
      'last-order-location-name': '',
      pincode: 0,
    }
  }
  singleEmployee: any = {}
  openEdit: boolean = false
  successNotification!: Function;
  confirm!: Function;
  included: any;

  @Watch('loading', {deep: true})
  onLoadingChanged(newVal: any) {
    this.loading = newVal
  }
  standardDateFormat(value: any)  {
    if (! value) return '---';
    return moment(value).format('DD/MM/YYYY');
  }

  handleClose(value: any) {
    this.openEdit = value;
  }
  onEdit(employee: any) {
    Employee.find(employee.id).then((response) => {
      console.log('response: onEdit: ', response);
      const employee = response.data.data;
      employee.attributes.locations = response.data.included;
      this.singleEmployee = employee;
      this.included = response.data.included;
    });
    this.openEdit = true;
  }
  get deleteMsgBoxText(): String {
    return "Are you sure you want to delete this Employee?";
  }

  onDelete(id: string) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {
          Employee.remove(id).then((response) => {
            console.log('response: remove: ', response);
            this.updateList();
            this.successNotification('EMPLOYEE DELETED!', 'Employee successfully deleted!');
          });
        }
      });
  }
  updateList() {
    this.$emit('updateList', true);
  }
}

import BaseService from '../base.service'

export default class ConnectService extends BaseService {
  url!: string

  constructor() {
    super();
  }

  getConnections() {
    let url = this.baseURL + 'connections';

    return super.get(url);
  }

  getlAggregators() {
    let url = this.baseURL + 'aggregators';

    return super.get(url);
  }

  getConnection(id: number, params: any = {}) {
    this.url = this.baseURL + `connections/${id}`;

    return super.get(this.url, params)
  }

  createConnection(menuId: number, aggregatorId: number, options: any = {}, isProduction: boolean = true) {
    this.url = this.baseURL + 'connections';

    return super.post(this.url, {
      'menu-id': menuId,
      'aggregator-id': aggregatorId,
      'options': options,
      'is-production': isProduction,
    });
  }

  updateConnection(connectionId: number, data: any) {
    this.url = this.baseURL + `connections/${connectionId}`;

    return super.put(this.url, data);
  }

  deleteConnection(connectionId: number) {
    this.url = this.baseURL + `connections/${connectionId}`;

    return super.remove(this.url, {});
  }

  getOrdersByConnection(connectionId: number, params: any = {}) {
    this.url = this.baseURL + `connections/${connectionId}/orders`;

    return super.get(this.url, params);
  }

  getAuditLogs(connectionId: number | null = null, params: any = {}) {
    if (connectionId) {
      this.url = this.baseURL + `connections/${connectionId}/sync-audit-logs`;
    } else {
      this.url = this.baseURL + 'connect-sync-logs';
    }

    return super.get(this.url, params);
  }

  syncMenu(connectionId: number) {
    this.url = this.baseURL + `connections/${connectionId}/sync-menu`;

    return super.post(this.url, {});
  }

  syncBranches(connectionId: number) {
    this.url = this.baseURL + `connections/${connectionId}/sync-branches`;

    return super.post(this.url, {});
  }

  searchByCustomers(term: any) {
    let filters = ''
    filters += Object.keys(term)
      .filter(k => { return k != 'today' })
      .map(k => { return `filter[${encodeURIComponent(k)}]` + '=' + encodeURIComponent(term[k]) })
      .join('&')
    const url = `${this.baseURL}customers?${filters}`;
    return super.get(url);
  }

  getCustomerDetails(id: number) {
    this.url = this.baseURL + `customers/${id}`;
    return super.get(this.url);
  }

  createCustomer(data: any) {
    this.url = this.baseURL + 'customers';
    return super.post(this.url, data);
  }
}

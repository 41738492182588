import BaseService from '../base.service'
export default class PromotionService extends BaseService {

  constructor() {
    super();
  }

  fetchPromotions(page: number = 1, filter: any = {}) {
    let url = `${this.baseURL}promotions?page=${page}`;
    if (filter.name) {
      url += `&filter[name]=${filter.name}`;
    }
    return super.get(url);
  }

  savePromotion(payload: any) {
    const url = `${this.baseURL}promotions`;

    return super.post(url, payload);
  }

  updatePromotion(id: string, payload: any) {
    const url = `${this.baseURL}promotions/${id}`;

    return super.put(url, payload);
  }

  deletePromotion(promotionId: string) {
    let url = this.baseURL + 'promotions/' + promotionId;

    return super.remove(url, {});
  }

  getPromotionQualifyingItems(id: string) {
    const url = `${this.baseURL}promotions/${id}/qualifying-items`;

    return super.get(url);
  }

  getPromotion(id: string) {
    const url = `${this.baseURL}promotions/${id}?include=item.category`;

    return super.get(url);
  }

  fetchSegments(page: number = 1) {
    const url = `${this.baseURL}segments?page=${page}`;
    return super.get(url);
  }
  fetchSegmentsCriterias() {
    const url = `${this.baseURL}segments/criteria`;
    return super.get(url);
  }
  saveSegment(payload: any) {
    let url = this.baseURL + 'segments';

    return super.post(url, payload);
  }
  updateSegment(segmentId: string, params: any) {

    let url = this.baseURL + 'segments/' + segmentId;

    return super.put(url, params);
  }
  deleteSegment(segmentId: string) {
    let url = this.baseURL + 'segments/' + segmentId;

    return super.remove(url, {});
  }
  getSegment(segmentId: string) {
    let url = this.baseURL + `segments/${segmentId}.json`;

    return super.get(url)
  }

  getSegmentCustomers(segmentId: string, page: number) {
    let url = this.baseURL + `segments/${segmentId}/customers`;

    return super.get(url, { page })
  }

  getPOSDiscounts() {
    let url = this.baseURL + `pos/promotions`;

    return super.get(url)
  }

  refreshPOSDiscounts() {
    let url = this.baseURL + `pos/promotions?refresh=1`;

    return super.get(url)
  }
}

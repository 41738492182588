










































































import { Component, PropSync, Mixins, Watch, Prop } from "vue-property-decorator";
import { Table, TableColumn, Button } from "element-ui";
import LoyaltyCollapse from "./LoyaltyCollapse.vue";
import { Loyalty as LoyaltyService } from "@/services/SOLO";
import TierSingleModal from "./TierSingleModal.vue";
import { translations, notificationAlerts } from '@/mixins'
import Translations from "@/mixins/translations";
import { mapGetters } from 'vuex';

@Component<LoyaltyTierList>({
  components: {
    LoyaltyCollapse,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    TierSingleModal
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
    })
  },
  mixins: [translations, notificationAlerts],
})
export default class LoyaltyTierList extends Mixins(Translations) {
  // @PropSync("showCreateModal", {
  //   type: Boolean,
  //   required: true,
  //   default: false
  // })
  @Prop() showCreateModal!: boolean;
  systemErrorNotification!: Function;
  successNotification!: Function;
  syncedShowCreateModal: boolean = false;

  loading: boolean = false;
  tiers: any[] = [];
  exporting: any = {};

  showUpdateModal: boolean = false;
  selectedTier: any = null;

  get computedModalPropActivatorValue(): boolean {
    return this.showCreateModal;
  }

  mounted() {
    this.getTiers();
  }

  async getTiers() {
    try {
      this.loading = true
      const tiers = await LoyaltyService.getTiers({
        limit: "none",
        with_customer_count: true,
        name_translation: true
      });
      this.tiers = tiers.data.data;
    } catch (error) {
      console.error('Error in getting Error', error)
    } finally {
      this.loading = false;
    }
  }

  handleRowClick(row: any) {
    this.selectedTier = row;
    this.syncedShowCreateModal = true;
  }

  updateTier(tier: any) {
    this.selectedTier = tier;
  }

  addTier(tier: any) {
    this.tiers.push(tier);
  }

  async exportCustomers(row: any) {
    try {
      this.$set(this.exporting, row.id, true);
      let { data } = await LoyaltyService.exportTierCustomers(row.id);
      if (data.data && data.data.attributes) {
        window.open(data.data.attributes['csv-uri'], '_blank');
        this.successNotification('Export customers successfully');
      } else {
        this.systemErrorNotification(`Can't export customers.`, data.message);
      }
    } catch (error) {
      this.systemErrorNotification(`Error in exporting customer`);
    } finally {
      this.$set(this.exporting, row.id, false);
    }
  }

  onInputChange(val: any) {
    this.$emit('update-modal-activator', val)
  }

  @Watch("computedModalPropActivatorValue")
  onChangeComputedModalPropActivatorValue(newVal: boolean) {
    this.syncedShowCreateModal = newVal
  }

  @Watch("syncedShowCreateModal")
  onSyncedShowCreateModalChange(newVal: boolean) {
    if (!newVal) {
      this.selectedTier = null;
    }
  }
}































// npm package
import { mapGetters } from 'vuex';
import { Component, Watch, Vue } from 'vue-property-decorator';

// our package
import { translations } from '@/mixins';
import Translations from '@/views/Admin/pages/GMB/Translations.vue';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import { GMB, Integrations } from "@/services/SOLO";

@Component({
  components: {
    RouteBreadCrumb,
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [translations],
})
export default class Pages extends Vue {
  public getLocale!: string;
  private translate!: Function;
  isDesktopDisplay: boolean = true;
  isMobileDisplay: boolean = false;
  partooPage: any;
  userId: string = '';
  page: string = '';
  loaded: boolean = false;

  @Watch("$route.params.page", {deep: true, immediate: true})
  onChangePage(data: any) {

    if (data) {
      switch (data) {
        case 'manage-locations':
          this.page = 'businesses';
          break;
        case 'listings':
          this.page = 'presenceManagement';
          break;
        case 'partner-connect':
          this.page = 'partnerConnections';
          break;
        case 'google-posts':
          this.page = 'googlePosts';
          break;
        case 'analytics':
          this.page = 'analytics';
          break;
        case 'review-analytics':
          this.page = 'reviewAnalytics';
          break;
        case 'review-management':
          this.page = 'reviewManagement';
          break;
      }
      if (this.loaded) {
        this.navigate();
        console.log('loaded', this.loaded, data, this.page);
      } else {
        this.authorizeGMB();
        console.log('not loaded', this.loaded, data, this.page);
      }
    }
  }

  mounted() {
    console.log('route.mounted', this.$route.params.page);
    this.loaded = true;
  }

  authorizeGMB() {
    GMB.createToken()
      .then((response: any) => {
        /* @ts-ignore */
        this.partooPage = Partoo.init('gmb_iframe', {});
        var userToken = response.data.data;
        console.log(userToken);
        this.partooPage.login(userToken); // authentication is mandatory access add page
        this.navigate();
      }).catch((err: any) => {
        console.log(err);
      });
  }

  navigate() {
    this.partooPage.navigate(this.page);
  }

  showReportsMobile() {
    if (!this.isMobile()) {
      this.isDesktopDisplay = true;
      this.isMobileDisplay = false;
    } else {
      this.isDesktopDisplay = false;
      this.isMobileDisplay = true;
    }
  }

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  beforeDestroy() {
    this.partooPage.destroy();
  }
}

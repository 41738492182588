import BaseService from '../base.service'

let customFieldsService = class CustomFieldsService extends BaseService {
    url!: string
    constructor() {
        super()
    }

    all() {
        let url = this.baseURL + `custom-fields`
        return super.get(url)
    }

    postData(customFieldId: string, payload: object) {
        let url = this.baseURL + `custom-fields/${customFieldId}/data`
        return super.post(url, payload)
    }

    deleteData(customFieldId: string, payload: object) {
      let url = this.baseURL + `custom-fields/${customFieldId}/data`
      return super.remove(url, payload)
  }
};

export default customFieldsService




















































import { translations, eventHandler } from "@/mixins";
import Translations from "./Translations.vue";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import { Location } from "@/services/SOLO";

@Component({
  components: {
    Translations,
  },
  mixins: [translations, eventHandler],
})
export default class TemporaryDeliveryModal extends Vue {
  @Prop() location!: any;
  public open: boolean = false;
  distance: any = null;

  $notify: any;
  translate!: Function;

  @Watch('open', { immediate: true, deep: true })
  onOpen(value: Boolean) {
    if(!value) {
      this.open = false;
    }
  }

  close() {
    this.open = false;
  }

  create() {
    if (this.distance <= 0) {
        return;
    }

    let points = this.getDeliveryZonePoints(this.location.attributes, this.distance);
    console.log(points);

    let payload = {
      coordinates: points,
      label: `Temporary ${this.distance}km`
    };

    Location.createArea(payload, this.location.id)
    .then((response: any) => {
      this.close();
      this.distance = null;
      this.$emit('prepareMapForAddingNewDeliveryArea', this.location);
    }).catch(err => {
      console.log(err.response)
      console.log(err.response.data.error[0].detail)
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: err.response.data.error.detail,
        type: "danger",
        icon: "fas fa-bomb",
      });   
    });
  }

  getDeliveryZonePoints(location: any, radius: any) {
      let points = [];
      let spherical = google.maps.geometry.spherical;
      let point = new google.maps.LatLng(location.lat, location.long);
      let angles = [0, 45, 90, 135, 180, 225, -90, -45];
      for (let a of angles) {
          let p = spherical.computeOffset(point, radius * 1000, a);
          points.push([p.lat(), p.lng()]);
      }
      return points;
  }
}

import BaseService from '../base.service'

export default class CmsService extends BaseService{
    
    appendURI: string = '/payments/providers/checkout/authorize';

    constructor(){
        super();
    }

    submitToken(params: object = {})
    {
        let url = this.baseURL + this.appendURI

        return super.post(url, params);
    }    
    
}

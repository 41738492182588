












































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { Select, Option } from "element-ui";
import Multiselect from 'vue-multiselect';
import _ from "lodash";

import { translations } from "@/mixins";
import {
  WhatsApp as WhatsAppService,
  Location
} from "@/services/SOLO";
import WhatsAppModal from './WhatsAppModal';

interface MessageData {
  type?: string,
  id?: string,
  attributes?: object,
}
@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Multiselect,
    WhatsAppModal
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getLocale: 'app/getLocale'
    }),
  },
  mixins: [translations],
})
export default class DashboardCustomizeMessage extends Vue {
  @Prop() customizeId!: Number;
  @Prop() action!: String;
  @Prop() messageData!: any;
  @Prop() orderStatuses!: any;
  @Prop() vonageData!: any;
  getLocale: any;
  selectedButtons: any = []; 
  clonedMessageData: any = {}; 
  modal: any = false;
  isLoading: boolean = false;
  isFetchingLocations: boolean = false;
  selectedBranch: any;
  getConceptSettings: any;
  translate!: Function;
  getSimulationMessages: any = [
    {
      id: 1,
      message: 'Hello.',
    },
    {
      id: 2,
      message: 'Dine In.',
    },
    {
      id: 3,
      message: 'Branch 1.',
    },
  ];
  orderStatusMessages: any = [
    {
      code: 'accepted',
      title: {
        'ar-sa': 'حالة الطلب: تم استلامه',
        'en-us': 'Order Received Status'
      },
    },
  ];
  errorMessages: any = [];
  locations: Array<Object> = [];

  created() {
    this.selectedButtons = this.messageData?.attributes?.buttons;
    this.clonedMessageData = _.cloneDeep(this.messageData);
    if (this.messageData?.attributes['button-model'] === 'location') {
      this.getLocation();
    }
    
  }

  mounted() {
    this.scrollToTop()
  }
  
  get locale() {
    if (this.getLocale === 'en_US') {
      return 'en-us';
    }
    return 'ar-sa';
  }

  get optionButtons() {
    return this.messageData?.attributes['button-model'] === 'location' ? this.filteredLocations : this.messageData?.attributes?.['default-buttons'];
  }

  get messageTitle() {
    return this.messageData?.attributes?.title[this.locale]
  }

  get messageBody() {
    return this.messageData?.attributes?.body[this.locale]
  }

  get hasButton() {
    return this.messageData?.attributes?.buttons.length > 0 ? true : false;
  }

  get optionLabel() {
    if (this.messageData?.attributes['button-model'] === 'order_type') {
      return this.translate('Available for Ordering Options');
    } else if (this.messageData?.attributes['button-model'] === 'location') {
      return this.translate('Available for Branches');
    }
  }

  get optionPlaceholder() {
    if (this.messageData?.attributes['button-model'] === 'order_type') {
      return this.translate('Select Ordering Options');
    } else if (this.messageData?.attributes['button-model'] === 'location') {
      return this.translate('Select Branch');
    }
  }

  get simulationMessage() {
    if (this.messageData?.attributes['button-model'] === 'order_type') {
      return this.translate(this.getSimulationMessages[0].message);
    } else if (this.messageData?.attributes['button-model'] === 'location') {
      return this.translate(this.getSimulationMessages[1].message);
    } else if (this.messageData?.attributes['button-model'] === 'address') {
      return this.translate(this.getSimulationMessages[2].message);
    }
    return [];
  }

  get isAllSelected() {
    return this.optionButtons.length === this.selectedButtons.length;
  }

  get statusMessages() {
    return this.orderStatusMessages.map((message: any) => {
      const msgOrder = this.orderStatuses.find((msg: any) => msg.attributes.code === message.code);
      return {
        ...message,
        'pickup-description': msgOrder?.attributes['pickup-description'],
      }
    })
  }

  get errorMessageNotif() {
    let message = '';

    if (this.errorMessages.length > 0) {
      let msgArray: any = [];
      this.errorMessages.forEach((msg: any) => {
        msgArray.push(`<li>${msg}</li>`)
      })
      return `${msgArray.toString().replaceAll(',','')}`;
    }

    return message;
  }

  get filteredLocations() {
    let activeLocation = this.locations.filter((location: any) => location.attributes.status === 'active' );
    return activeLocation.map((location: any) => {
      const loc = this.messageData.attributes['default-buttons'].find((btn: any) => btn.value == location.id);
      return {
        label: loc.label,
        value: Number(location.id)
      }
    })
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  validateForm() {
    if (this.messageData?.attributes?.body['en-us'] === '') {
      this.errorMessages.push(this.translate('English translation'));
    }
    if (this.messageData?.attributes?.body['ar-sa'] === '') {
      this.errorMessages.push(this.translate('Arabic translation'));
    }
    if (this.hasButton && this.selectedButtons.length === 0) {
      this.errorMessages.push(this.translate('Options'));
    }
  }

  async updateMessage() {
    this.errorMessages = [];
    if (this.messageData.hasOwnProperty('is-order-status') && this.messageData['is-order-status']) {
      this.isLoading = true;
      try {
        const { id } = this.getConceptSettings
        let payload = {
          'is-sending-order-status': this.vonageData.attributes['is-sending-order-status'],
        }
        await WhatsAppService.updateWhatsAppAccount(id, this.vonageData.id, payload)
        const notifyOptions: any = {
          title: 'Updated Successfully.',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Successfully Updated',
          type: "success",
          icon: "fas fa-check"
        }
        this.$notify(notifyOptions);
        this.$emit('toggleCustomizeMessage', { id: this.customizeId, action: this.action })
      } catch (error) { 
        if (error.response) {
          const { error: err } = error.response.data
          console.log("GET THE ERROR", err);
          const notifyOptions: any = {
            title: 'Error on Saving the Details',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: err[0].detail || 'An Error Encounter',
            type: 'danger',
            icon: 'fas fa-bomb'
          }
          this.$notify(notifyOptions)
        }
      }
    } else {
      this.validateForm();

      if (this.errorMessages.length > 0) {
        const notifyOptions: any = {
          title: 'Required fields',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: this.errorMessageNotif,
          type: "warning",
          icon: "fas fa-info-circle"
        }
        return this.$notify(notifyOptions);
      }
      try {
        this.isLoading = true;
        const { id } = this.getConceptSettings;

        console.log(this.messageData);
        let payload: Object = {
          body : this.messageData.attributes.body,
          buttons: this.hasButton ? this.selectedButtons : null,
          'is-active': this.messageData.attributes['is-active'],
        }
        await WhatsAppService.updateMessage(id, this.messageData.id, payload);
        const notifyOptions: any = {
          title: 'Update Successfully.',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Successfully Updated',
          type: "success",
          icon: "fas fa-check"
        }
        this.$notify(notifyOptions);
        this.$emit('toggleCustomizeMessage', { id: this.customizeId, action: this.action })
      } catch (error) { 
        if (error.response) {
          const { error: err } = error.response.data
          console.log("GET THE ERROR", err);
          const notifyOptions: any = {
            title: 'Error on Saving the Details',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: err[0].detail || 'An Error Encounter',
            type: 'danger',
            icon: 'fas fa-bomb'
          }
          this.$notify(notifyOptions)
        }
      }
    }
    
    this.isLoading = false;
  }

  cancelEdit() {
    let payload: Object = {
      body : this.messageData.attributes.body,
      buttons: this.hasButton ? this.selectedButtons : null,
      'is-active': this.messageData.attributes['is-active'],
    }
    let cloned: Object = {
      body : this.clonedMessageData.attributes.body,
      buttons: this.hasButton ? this.clonedMessageData.attributes.buttons : null,
      'is-active': this.clonedMessageData.attributes['is-active'],
    }

    if (JSON.stringify(payload) === JSON.stringify(cloned)) {
      this.$emit('toggleCustomizeMessage', { id: this.customizeId, action: this.action });
    } else {
      this.$bvModal.show('modal')
    }
  }

  cancel() {
    this.$emit('toggleCustomizeMessage', { id: this.customizeId, action: this.action });
  }

  handleSelectAll(val: any) {
    this.selectedButtons = this.isAllSelected ? [] : val;
  }

  async getLocation() {
    this.isFetchingLocations = true;
    let lang = this.getLocale;
    await Location.fetchLocations(lang, 1)
    .then((response: any) => {
      if (response?.data?.data && Array.isArray(response?.data?.data)) {
        this.locations = [...this.locations, ...response?.data?.data];
        this.isFetchingLocations = false;
      }
    });
  }
}

































import { translations } from '@/mixins'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate";
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
@Component({
  components: {
    ValidationObserver
  },
  mixins: [translations]
})
export default class ChangeAreaNameModal extends Vue {
  private tempAreaName: String = ''
  @Prop() open!: Boolean
  @Prop() areaName!: String
  
  @Watch('areaName', {immediate: true, deep: true})
  onChangeAreaName() {
    this.tempAreaName = this.areaName
  }

  public translate!: Function

  get isOpen(): Boolean {    
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
  }

  submit() {
    this.$emit('submit', this.tempAreaName)
  }
}






































































































import { Button } from "element-ui";
// import {Integration as Integrated} from '@/models';
// import IntegratedItems from './components/integrations/IntegratedItems'
// import IntegrationAvailable from './components/integrations/IntegrationAvailable'
// import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { mapGetters } from "vuex";
import { Connect as ConnectService } from "@/services/SOLO";
import Translations from "@/mixins/translations";
import ConnectionModal from "./ConnectionModal.vue";

import { Component, Mixins, Watch, PropSync } from "vue-property-decorator";
// import {Integrations} from '@/services/SOLO'

@Component<Connections>({
  components: {
    ConnectionModal
    // IntegrationAvailable,
    // RouteBreadCrumb,
  }
})
export default class Connections extends Mixins(Translations) {
  @PropSync("showCreateModal", {
    type: Boolean,
    required: true,
    default: false
  })
  syncedShowCreateModal!: boolean;

  aggregators: any[] = [];
  connections: any[] = [];
  isLoading: Boolean = false

  selectedConnection: any = null;

  mounted() {
    console.log(this.menus);
    this.getAggregators();
    this.getConnections();
  }

  viewConnection(connection: any) {
    // this.selectedConnection = connection;
    // this.syncedShowCreateModal = true;
    this.$router.push(`/connect/connections/${connection.id}`);
  }

  async getAggregators() {
    try {
      let { data } = await ConnectService.getlAggregators();
      this.aggregators = data.data;
    } catch (e) {
      console.log(e);
    }
  }

  async getConnections() {
    try {
      this.isLoading = true
      let { data } = await ConnectService.getConnections();
      this.connections = data.data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false
    }
  }

  addConnection(connection: any) {
    this.connections.push(connection);
  }

  updateConnection(connection: any) {
    this.selectedConnection = connection;
  }

  get menus() {
    return this.$store.getters["menu/getMenus"];
  }

  @Watch("syncedShowCreateModal")
  onSyncedShowCreateModalChange(newVal: boolean) {
    if (!newVal) {
      this.selectedConnection = null;
    }
  }
}

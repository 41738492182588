import BaseService from '../base.service'
import store from '@/store'
let customerService = class CustomerService extends BaseService {

  constructor() {
    super();
  }

  store(params: {}) {
    let url = this.baseURL + 'customers';
    console.log(url)
    return super.post(url, params)
  }

  all(page = 1) {
    let url = this.baseURL + 'customers';

    return super.get(url, {
      page: page
    });
  }

  getCustomerLogs(cusID: any) {
    let log = this.baseURL1 + `logs?filter[customer-id]=${cusID}`;

    return super.get(log);
  }

  getOrderLogs(orderID: any) {
    let log = this.baseURL1 + `logs?filter[order-id]=${orderID}`;

    return super.get(log);
  }

  getDeviceLogs(deviceID: any) {
    let log = this.baseURL1 + `logs?filter[device-id]=${deviceID}`;

    return super.get(log);
  }

  search(name: string, email: string, mobile: string, date: string) {
    let url = this.baseURL + `customers?filter[customer]=${name}&filter[email]=${email}&filter[mobile]=${mobile}&filter[date]=${date}`;
    return super.get(url);
  }

  searchInKiosk(name: string) {
    let url = this.baseURL + `customers?filter[customer]=${name}`;
    return super.get(url);
  }

  // let url = this.baseURL + `locations?filter[delivery]=all&filter[enabled]=all&include=delivery-areas&limit=100&page=${page}`;


  searchLocInKiosk(name: string, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang

    let url = this.baseURL + `locations?filter[name]=${name}&filter[delivery]=all&filter[enabled]=all&include=delivery-areas&limit=100`;
    return super.get(url);
  }

  getCustomerName(cID: any) {
    let url = this.baseURL + `customers?filter[customer]=${cID}`;
    return super.get(url);
  }

  filterByType(query: string, type: string) {
    let url = this.baseURL + `customers?filter[${type}]=${query}`;
    return super.get(url);
  }

  forgotPassword(params: {}) {
    let url = this.baseURL + 'forgotPassword'
    return super.post(url, params);

  }

  find(customerId: string) {
    let url = this.baseURL + 'customers/' + customerId;

    return super.get(url)
  }

  update(customerId: string, params: any) {

    let url = this.baseURL + 'customers/' + customerId;

    return super.patch(url, params);
  }

  getDefault() {
    let url = this.baseURL + 'concepts/' + store.getters['concept/getConcept'].attributes.key;

    return super.get(url)
  }

  getOrders(customerId: string, sort = 'desc', limit = 999) {
    let url = this.baseURL + 'customers/' + customerId + '/orders?sort=' + sort + '&size=' + limit;

    return super.get(url)
  }

  getAddresses(customerId: string) {
    let url = this.baseURL + 'customers/' + customerId + '/addresses';

    return super.get(url)
  }

  createAddress(customerId: string, params: {}) {
    let url = this.baseURL + 'customers/' + customerId + '/addresses';

    return super.post(url, params);
  }

  deleteAddress(customerId: string, addressId: string) {
    let url = this.baseURL + 'customers/' + customerId + '/addresses/' + addressId;

    return super.remove(url, {});
  }

  verifyCode(customerId: string, params: {}) {
    let url = this.baseURL + 'customers/' + customerId + '/verify';

    return super.post(url, params);
  }

  authenticateOTP(params: {}) {
    const url = this.baseURL + 'login/guest';

    return super.post(url, params);
  }

  resendCode(params: {}) {
    let url = this.baseURL + 'resendsms';

    return super.post(url, params);
  }

  tapVerification(params: object = {})
  {
      let url = this.baseURL + 'payments/providers/tap/verify'

      return super.post(url, params);
  }

  getBlacklists(page: number) {
    let url = this.baseURL + 'blacklists';
    return super.get(url, {
      page: page
    });
  }

  showBlacklist(id: any) {
    let url = this.baseURL + 'blacklists/' + id;
    return super.get(url);
  }

  createBlacklist(params: any) {

    const url = this.baseURL + 'blacklists/';

    return super.post(url, params);
  }

  updateBlacklist(id: string, params: any) {

    const url = this.baseURL + 'blacklists/' + id;

    return super.patch(url, params);
  }

  deleteBlacklist(id: string|number) {
    const url = this.baseURL + 'blacklists/' + id;

    return super.remove(url, {});
  }

  exportAllCustomer() {

    let url = this.baseURL + `exports`

    return super.post(url, {
      type: 'customers'
    });
  }

  getCustomerLoyalty(customerId: string) {
    const url = `${this.baseURL}customers/${customerId}/loyalty`

    return super.get(url);
  }

  getCustomerLoyaltyPoints(customerId: string|number) {
    let url = `${this.baseURL}loyalty/customers/${customerId}/transactions`;

    return super.get(url, {});
  }

};

export default customerService


import GetTextPlugin from 'vue-gettext'
import translations from '../locales/translations.json'

const Translations = {
    install(Vue) {
        Vue.use(GetTextPlugin, {
            translations: translations
        })
    }
}

export default Translations

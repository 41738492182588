































































































































































import { Table, TableColumn, Button, Form, FormItem, Select, Option, Checkbox } from 'element-ui'
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Customer, Employee } from '@/services/SOLO'
import { translations, notificationAlerts } from '@/mixins'
import Translations from './Translations.vue';
import { mapGetters } from "vuex";

import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import _ from "lodash";

interface CustomerDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string
}

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getUser: "account/getUser",
    }),
  },
mixins: [translations, notificationAlerts],
})
export default class CustomerUpdateModal extends Vue {
  @Prop() openEdit: boolean = false
  @Prop() employee?: Object
  @Prop() included?: Object
  public translate!: Function
  getUser!: any
  id: any
  isOpen: boolean = false
  modalForm: any =  {
    'emp_id': '',
    'first-name': '',
    'last-name': '',
    email: '',
    mobile: '',
    pincode: 0,
    status: '',
    'account-type': '',
    roles: [],
    locations: [],
    password: '',
  }
  selectedStatus: any = ''
  selectedType: any = ''
  roles: any = [];
  locations: any = [];
  selectedLocations: any = [];
  isPassword: boolean = false;
  password: string = '';
  successNotification!: Function;
  systemErrorNotification!: Function;
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };
  includeFranchisee: boolean = false;

  @Watch('modalForm.roles', { deep: true })
  onRolesChanged(newVal: any) {
    if (newVal.includes('franchisee') && !this.includeFranchisee) { // perform only if first selection of franchisee role
      this.locations = [];
      this.getLocations(1, true);
      let included = _.cloneDeep(this.included);
      included = included.map((l: any) => {
        return {
          key: l.id,
          value: l.attributes.name,
        };
      });
      this.locations.push(...included);
      this.includeFranchisee = true;
    } else if (!newVal.includes('franchisee') && this.includeFranchisee) { // perform only if first unselection of franchisee role
      this.locations = [];
      this.getLocations(1, false);
      this.includeFranchisee = false;
    }
  }

  mounted() {
    this.getRoles()
    this.getLocations(1, false)
  }
  @Watch('openEdit', { deep: true })
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }
  @Watch('employee', { deep: true })
  onCustomerChanged(newVal: any) {
    console.log('newVal: ', newVal);
    this.modalForm = {
      'emp_id' : newVal.attributes.id,
      'first-name': newVal.attributes['first-name'],
      'last-name': newVal.attributes['last-name'],
      email: newVal.attributes.email,
      mobile: newVal.attributes.mobile,
      pincode: newVal.attributes.pincode,
      status: newVal.attributes.status,
      'account-type': newVal.attributes['account-type'],
      roles: newVal.attributes.roles,
      locations: newVal.attributes
        && newVal.attributes.locations
        && newVal.attributes.locations.map((l:any) => l.id) || [],
    };
    console.log('this.modalForm: ', this.modalForm);
    this.id = newVal.id;
  }

  handleClose() {
    this.$emit('handleClose', false)
  }

  

  getRoles() {
    Employee.getRoles().then((response) => {
      let roles = response.data.data;
      console.log("employee roles", roles)
      this.roles = roles.map((r: any) => {
        return {
          key: r.attributes.label,
          value: r.attributes.name,
        };
      });
    });
  }

  // getLocations() {
  //   Employee.getLocations().then((response) => {
  //     let locations = response.data.data;
  //     this.locations = locations.map((l: any) => {
  //       return {
  //         key: l.id,
  //         value: l.attributes.name,
  //       };
  //     });
  //     console.log('this.locations: ', this.locations);
  //   });
  // }

  getLocations(page: 1, franchisee: boolean) {
    let lang = 'en-us';
    Employee.getLocations2(lang, page, franchisee).then((response) => {
      let locations = response.data.data;
      console.log("test locations", locations);
      locations = locations.map((l: any) => {
        return {
          key: l.id,
          value: l.attributes.name,
        };
      });

      this.locations.push(...locations);

      if (
          response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
      ) {
        this.getLocations(response.data.meta.pagination.current_page + 1, franchisee);
      }
    });
  }

  updateEmployeeDetails() {
    const payload = {
      ['first-name']: this.modalForm['first-name'],
      ['last-name']: this.modalForm['last-name'],
      email: this.modalForm.email,
      mobile: this.modalForm.mobile,
      roles: this.modalForm.roles.toString(),
      locations: this.modalForm.locations.map((l: any) => l).join(),
      password: '',
    };
    if (this.isPassword) {
      payload.password = this.modalForm.password;
    } else {
      delete payload.password;
    }

    console.log('update: ', payload);
    Employee.update(this.id, payload)
        .then((response: any) => {
          this.$emit('handleClose', false);
          this.$emit('updateList', true);
          this.successNotification('EMPLOYEE UPDATED!', 'Employee successfully updated!');
        })
        .catch((err: any) => {
          if(err.response.data == 'Unauthorized') {
            this.systemErrorNotification('CREATE ERROR!', err.response.data);
            return;
          }
          if(err.response.data.error?.password) {
            this.systemErrorNotification('CREATE ERROR!', err.response.data.error.password[0]);
          } else {
            if(err.response.data.error[0]?.detail) {
                this.systemErrorNotification('CREATE ERROR!', err.response.data.error[0].detail);
            } else if (err.response.data.error?.username[0]) {
                this.systemErrorNotification('CREATE ERROR!', err.response.data.error.username[0]);
            } 
          }
        });
  }

}

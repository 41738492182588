



































































































import { RegisterUser } from '@/interfaces/Landing'
import { Auth, FoodicsAuth } from '@/services/core'
import { Concept as ConceptApi, Menu } from '@/services/SOLO'
import { ValidationObserver, ValidationProvider, configure, extend } from 'vee-validate'
import { mapGetters, mapMutations } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Component, Prop, Vue, Watch, Ref, Mixins } from 'vue-property-decorator'
import LangSwitcher from '@/components/LangSwitcher';
import Translations from "../../Translations.vue";


extend('password', {
  params: ['target'],
  validate(value, target: any) {
    return value === target.target;
  },
  message: 'Password confirmation does not match'
});

@Component({
  components: {
    ValidationProvider,
    LangSwitcher,
    Translations,
  },
  computed: {
    ...mapGetters({
      getUserClient: 'account/getUserClient',
      activeConcept: 'account/activeConcept',
    })
  },
  methods: {
    ...mapMutations({
      setUser: 'account/setUser',
      setUserRole: 'account/setUserRole',
      setAppSubscription: 'app/setAppSubscription',
      setSubscription: 'account/setSubscription',
      setUserConcept: 'account/setUserConcept',
      setConceptSettings: 'account/setConceptSettings',
      setMenus: 'menu/setMenus',
      logoutUser: 'account/logoutUser',
      setLocale: 'app/setLocale',
    })
  },
  mixins: [eventHandler, translations],
})
export default class Home extends Vue {
  saving: any = false
  private getUserClient: any
  private activeConcept: any
  private model: RegisterUser = {
    'first-name': '',
    'last-name': '',
    rememberMe: false,
    'email': '',
    password: '',
    'password_confirmation': '',
    'business-name': '',
    'mobile': '',
    'country-code': 'sa',
  }
  private countries: { [key: string]: any } = {};
  private countryName: { [key: string]: any } = {};
  private countryRegMobile: { [key: string]: any } = {};
  private setAppSubscription!: Function
  private setMenus!: Function


  mounted() {
    this.setAppSubscription(this.$route)
    this.logoutUser()
    this.fetchCountries();
  }

  async fetchCountries() {
    try {
      const countryData = await Auth.fetchCountriesData();
      const countryCodes = Object.keys(countryData);
      const excludedCountryCodes = countryCodes.filter(code => code !== 'ph');
      const countryLabels = excludedCountryCodes.map(code => countryData[code].label);
      const countryMobile = excludedCountryCodes.map(code => countryData[code].mobile_format);

      this.countries = excludedCountryCodes;
      this.countryName = countryLabels;
      this.countryRegMobile = countryMobile;

      console.log('Countries Code: ', this.countries);
      console.log('Countries: ', this.countryName);
      console.log('Countries: ', this.countryRegMobile);
    } catch (error) {
      console.error(error);
    }
  }


  private get hasFoodicsRegister(): Boolean {
    if (this.getUserClient) {
      if (this.getUserClient.attributes.auth.split(',').includes('foodics')) {
        return true
      }
      return false
    }
    return false
  }

  get regexMobile() {
    const selectedCountryCode = this.model['country-code'];
    if (selectedCountryCode in this.countryRegMobile) {
      const regexPattern = this.countryRegMobile[selectedCountryCode];
      console.log('Regex: ', new RegExp(regexPattern))
      return new RegExp(regexPattern);
    }
  }

  private setUser!: Function
  private setUserRole!: Function
  private setSubscription!: Function
  private setConceptSettings!: Function
  private setUserConcept!: Function
  private logoutUser!: Function

  errEMail: string = ''
  errConceptName: string = ''
  errMobileNumber: string = ''

  @Watch("model.email", { immediate: true, deep: true })
  onmodelEmailChanged(val: any) {
    this.errEMail = ''
  }

  @Watch("model.mobile", { immediate: true, deep: true })
  onChangeMobileNumber(val: any) {
    this.errMobileNumber = ''
  }

  private get logo(): string {
    if (this.getUserClient) {
      return this.getUserClient.attributes['logo-uri']
    }
    return ``
  }

  private registerUser() {
    this.saving = true;
    Auth.register(this.model).then(response => {
      if (response.data.data.attributes.roles[0] === 'administrator' && Number(this.$route.params.planId) != 1) {
        this.setUser(response.data.data)
        this.setUserRole(response.data.data.attributes.roles)
        this.setUserConcept(response.data.data.attributes.concepts[0])
      } else {
        this.setUser(response.data.data)
        this.setUserRole(response.data.data.attributes.roles)
        this.setUserConcept(response.data.data.attributes.concepts[0])
        this.conceptUpdate(response.data.data.attributes.concepts[0].id)

        this.getAccountConcept(response.data.data, response.data.data.attributes.roles, response.data.data.attributes.concepts[0])
          .then(res => {
            this.setConceptSettings(res.data.data)
            this.getApps()
          })
      }
      this.saving = false;
      this.$router.push({ name: 'customer-subscription', params: { planId: this.$route.params.planId }, query: { 'billing-cycle': this.$route.query['billing-cycle'] } });
    }).catch(err => {
      console.log(err.response)
      if (err.response.data.messages['email']) {
        this.errEMail = err.response.data.messages['email'][0]
      }
      if (err.response.data.messages['business-name']) {
        this.errConceptName = err.response.data.messages['business-name'][0]
      }
      if (err.response.data.messages['mobile']) {
        this.errMobileNumber = err.response.data.messages['mobile'][0]
      }
      this.saving = false;
    })
  }

  private async conceptUpdate(conceptId: any) {
    let payload = { "is-free-package": true }
    return await ConceptApi.updateConcept(payload, conceptId)
  }

  private async getAccountConcept(data: any, roles: any, subscription: any) {
    return await ConceptApi.get(this.activeConcept.id)
  }

  getApps() {
    Menu.all().then((response: any) => {
      this.setMenus(response.data.data)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  private redirectToMarketing() {
    window.location.replace('http://www.getsolo.io')
  }

  private signInWithFoodics() {
    FoodicsAuth.redirect();
  }
}

import axios, { AxiosInstance, AxiosResponse } from 'axios';

let foodicsAuthService = class FoodicsAuthService {
  appUrl: string = process.env.VUE_APP_URL
  authUrl: string = process.env.VUE_APP_FOODICS_AUTH_URL
  tokenUrl: string = process.env.VUE_APP_FOODICS_TOKEN_URL
  redirectUri: string = process.env.VUE_APP_FOODICS_REDIRECT
  clientId: string = process.env.VUE_APP_FOODICS_CLIENT_ID
  clientSecret: string = process.env.VUE_APP_FOODICS_CLIENT_SECRET
  scopes: Array<string> = [
    'general.read',
    'customers.list',
    'customers.write',
    'orders.limited.read',
    'orders.limited.create',
    'orders.write',
    'ingredients.read users.read',
  ]
  translationURL: string = ''
  headers: any
  $http: AxiosInstance

  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
    }

    this.$http = axios.create({
      timeout: 1600000
    });

    this.$http.interceptors.request.use((config: any) => {
      return config
    })

    this.$http.interceptors.response.use((response: any) => {
      return response
    })
  }


  getHeaders(additionalHeaders = {}, multipart = false) {
    let defaultHeaders = this.headers;

    // if (store.getters['account/isLoggedIn']) {
    //   defaultHeaders['Solo-Concept'] = store.getters['account/activeConcept'].key
    //   defaultHeaders['Authorization'] = 'Bearer ' + store.getters['account/getUser'].attributes.token
    // }

    return {
      ...defaultHeaders,
      ...additionalHeaders
    }
  }

  prepareUrl(url: string, params: any) {
    for (let index in params) {
      let identifier = ':' + index;
      url = url.replace(identifier, params[index]);
    }
    return url;
  }

  getQueryString(params: { [x: string]: string | number | boolean; }) {
    return (
      Object
        .keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
    )
  }

  redirect() {
    window.location.href = this.getAuthUrl();
  }

  getAuthUrl() {
    return `${this.authUrl}?${this.getQueryString(this.getCodeFields())}`
  }

  getToken(code:string) {
    return this.$http.post(`${this.appUrl}/oauth/token`, this.getTokenFields(code))
    // return this.$http.post(`${this.tokenUrl}/`, this.getTokenFields(code))
  }

  getCodeFields() {
    return {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      redirect_uri: this.redirectUri,
      scope: this.scopes.join(','),
      response_type: 'code',
      state: this.generateState()
    }
  }

  getTokenFields(code: string) {
    return {
      code,
      grant_type: 'authorization_code',
      redirect_uri: this.redirectUri,
      client_id: this.clientId,
      client_secret: this.clientSecret,
    }
  }

  generateState() {
    return Math.random().toString(36).substring(2);
  }
};

export default foodicsAuthService

import BaseService from '../base.service'

let couponService = class CouponService extends BaseService {

  constructor() {
    super();
  }

  all(page = 1, onlyActive = false){
    const url = this.baseURL + 'coupons';

    let filter = {
      page: page,
    } as any;

    if (onlyActive) {
      filter['status'] = "active"
    }

    return super.get(url, filter);
  }

  allCouponsGroup(page = 1, name?: string) {
    const url = this.baseURL + "coupon-groups";

    let filter = {
      page: page,
      include: 'coupon',
    } as any;

    if (name) {
      filter["filter[name]"] = name;
    }

    return super.get(url, filter);
  }

  allDigitalCoupons(page = 1) {
    const url = this.baseURL + 'admin/digital-coupons';

    return super.get(url, {
      page: page,
      include: 'promotion',
    });
  }

  allDigitalCouponsFuture(page: string|number, lang: string = 'en-us', name?: string) {
    this.headers['Accept-Language'] = lang;
    let url = this.baseURL + `admin/digital-coupons?include=promotion&page=${page}&filter[status]=upcoming`;
    if (name) {
      url += `&filter[name]=${name}`;
    }
    return super.get(url, {}, this.headers);
  }

  allDigitalCouponsExpired(page: string|number, lang: string = 'en-us', name?: string) {
    this.headers['Accept-Language'] = lang;
    let url = this.baseURL + `admin/digital-coupons?include=promotion&page=${page}&filter[status]=expired`;
    if (name) {
      url += `&filter[name]=${name}`;
    }
    return super.get(url, {}, this.headers);
  }

  allDigitalCouponsActive(page: string|number, lang: string = 'en-us', name?: string) {
    this.headers['Accept-Language'] = lang;
    let url = this.baseURL + `admin/digital-coupons?include=promotion&page=${page}&filter[status]=active`;
    if (name) {
      url += `&filter[name]=${name}`;
    }
    return super.get(url, {}, this.headers);
  }

  allCouponGroupsActive(couponGroupId: string, lang: string = 'en-us', code?: string, page = 1, search?: string) {
    this.headers['Accept-Language'] = lang;
    let url = this.baseURL + `coupon-groups/${couponGroupId}/coupons?&filter[status]=active&include=promotion&page=${page}`;
    
    if (code) {
      url += `&filter[code]=${code}`;
    }
    return super.get(url, {}, this.headers);
  }

  allCouponGroupsFuture(couponGroupId: string, lang: string = 'en-us', code?: string, page = 1) {
    this.headers['Accept-Language'] = lang;
    let url = this.baseURL + `coupon-groups/${couponGroupId}/coupons?&filter[status]=future&include=promotion&page=${page}`;

    if (code) {
      url += `&filter[code]=${code}`;
    }

    return super.get(url, {}, this.headers);
  }

  allCouponGroupsExpired(couponGroupId: string, lang: string = 'en-us', code?: string, page = 1) {
    this.headers['Accept-Language'] = lang;
    let url = this.baseURL + `coupon-groups/${couponGroupId}/coupons?&filter[status]=expired&include=promotion&page=${page}`;

    if (code) {
      url += `&filter[code]=${code}`;
    }
    
    return super.get(url, {}, this.headers);
  }

  createCouponsGroup(payload: any) {
    const url = this.baseURL + 'coupon-groups';

    return super.post(url, payload);
  }

  deleteCouponsGroup(id: string) {
    const url = `${this.baseURL}coupon-groups/${id}`;

    return super.remove(url, {});
  }

  createCoupon(payload: any, groupId: any) {
    const url = this.baseURL + `coupon-groups/${groupId}/coupons`;

    return super.post(url, payload);
  }

  createDigitalCoupon(payload: any) {
    const url = this.baseURL + `admin/digital-coupons`;

    return super.post(url, payload);
  }

  uploadImage(payload: any) {
    this.headers['Content-Type'] = 'multipart/form-data';
    const url = this.baseURL + `images`;

    return super.post(url, payload);
  }

  updateDigitalCouponQuantity(id: number, count: number) {
    const url = this.baseURL + `digital-coupons/${id}/codes`;

    return super.post(url, {count: count});
  }

  getCoupon(groupId: any, couponId: any, lang: string = 'en-us') {
    const url = this.baseURL + `coupon-groups/${groupId}/coupons/${couponId}`;
    this.headers['Accept-Language'] = lang;

    return super.get(url, {
      include: 'promotion,customer',
    }, this.headers);
  }

  deleteCoupon(groupId: any, couponId: any) {
    const url = this.baseURL + `coupons/${couponId}`;

    return super.remove(url, {});
  }

  getDigitalCoupon(couponId: any, lang: string = 'en-us') {
    const url = this.baseURL + `admin/digital-coupons/${couponId}`;
    this.headers['Accept-Language'] = lang;

    return super.get(url, {
      include: 'promotion,customer',
    }, this.headers);
  }

  deleteDigitalCoupon(couponId: any) {
    const url = this.baseURL + `admin/digital-coupons/${couponId}`;

    return super.remove(url, {});
  }

  updateCoupon(payload: any, groupId: any, couponId: any) {
    // const url = this.baseURL + `coupon-groups/${groupId}/coupons/${couponId}`;
    const url = this.baseURL + `coupons/${couponId}`;

    return super.patch(url, payload);
  }

  updateDigitalCoupon(payload: any, couponId: any) {
    const url = this.baseURL + `admin/digital-coupons/${couponId}`;

    return super.put(url, payload);
  }

  getCouponsbyCouponGroupId(couponGroupId: string, page = 1) {
    const url = this.baseURL + `coupon-groups/${couponGroupId}/coupons`;

    return super.get(url, {
      page: page,
      include: 'promotion'
    });
  }

  bulkDeleteCoupons (couponGroupId: any, couponStatus: any) {
    const url = `${this.baseURL}coupon-groups/${couponGroupId}/coupons?&filter[status]=${couponStatus}`
    
    return super.remove(url, {});
  }

  bulkExportCoupons (couponGroupId: any, couponStatus: any) {
    const url = this.baseURL + `coupon-groups/${couponGroupId}/coupons/export?status=${couponStatus}`;
    
    return super.post(url, {});
  }

  bulkExportDigitalCoupons () {
    const url = this.baseURL + `admin/digital-coupons/export`;
    
    return super.post(url, {});
  }

  exportDigitalCoupons(couponIds: any) {
    const url = this.baseURL + `admin/digital-coupons/export`;

    return super.post(url, couponIds);
  }

  exportCoupons(couponIds: any, couponGroupId: any) {
    const url = this.baseURL + `coupon-groups/${couponGroupId}/coupons/export`;

    return super.post(url, couponIds);
  }

  deleteDigitalCoupons(couponIds: any) {
    const url = this.baseURL + `admin/digital-coupons`;

    return super.remove(url, couponIds);
  }

  deleteCoupons(couponIds: any, couponGroupId: any) {
    const url = this.baseURL + `coupon-groups/${couponGroupId}/coupons`;

    return super.remove(url, couponIds);
  }
}

export default couponService
import BaseService from "../base.service";

export default class WhatsAppService extends BaseService {
  
  constructor() {
    super();
  }

  createAccount(payload: any, conceptId: any) {
    const url = `${this.baseURL}concepts/${conceptId}/vonages`;

    return super.post(url, payload);
  }

  checkAccountIfVerified (conceptId: any) {
    const url = `${this.baseURL}concepts/${conceptId}/vonages`;

    return super.get(url);
  }

  updateWhatsAppAccount (conceptId: any, vonageId: any, payload: any) {
    const url = `${this.baseURL}concepts/${conceptId}/vonages/${vonageId}`;
    
    return super.patch(url, payload);
  }

  getMessages (conceptId: any) {
    const url = `${this.baseURL}concepts/${conceptId}/whatsapp-messages`;

    return super.get(url);
  }

  getMessage (conceptId : any, whatsappMessageId: any) {
    const url = `${this.baseURL}concepts/${conceptId}/whatsapp-messages/${whatsappMessageId}`;

    return super.get(url);
  }

  updateMessage (conceptId : any, whatsappMessageId: any, payload: any) {
    const url = `${this.baseURL}concepts/${conceptId}/whatsapp-messages/${whatsappMessageId}`;

    return super.patch(url, payload)
  }

  getOrderStatus (conceptId: any) {
    const url = `${this.baseURL}concepts/${conceptId}/statuses`;

    return super.get(url);
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DashboardPlugin from '@/plugins/dashboard-plugin'
import vSelect from 'vue-select'
import VueTheMask from 'vue-the-mask'
import VoerroTagsInput from '@voerro/vue-tagsinput';
import VueIntercom from 'vue-intercom';
import { mapGetters, mapMutations } from 'vuex';
import Vidle from 'v-idle'

Vue.use(Vidle)
Vue.use(VueIntercom, { appId: 'ebqs8drg' });
Vue.component('v-select', vSelect)
Vue.component('tags-input', VoerroTagsInput);
Vue.use(DashboardPlugin)
Vue.use(VueTheMask)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const updateBackground = () => {
    if (store.getters['account/isLoggedIn']) {
      // document.body.classList.remove('bg-default');
      document.body.style.cssText+=`background-image: none !important`
    } 
  };

  window.onpopstate = (event: any) => {
    console.log('window.onpopstate');
    updateBackground();
  };
  updateBackground();

  next();
});

new Vue({
  data() {
    return {
      isLoading: false,
      conceptIsVisible: false
    }
  },
  router,
  store,
  computed:  {

    ...mapGetters({
      getUserClient: 'account/getUserClient'
    }),

    secondaryColor() : any
		{

      if(this.getUserClient !== null) {
        
        const api_theme = this.getUserClient.attributes.theme;

        const themeParse = JSON.parse(api_theme);
    
        return themeParse.secondary_color;
      
     }

      const themeParse = JSON.parse("{\"header_bg_color\":\"#26314f\",\"primary_color\":\"#ff6b00\",\"secondary_color\":\"#18a0fb\"}");
  
      return themeParse.secondary_color;
      
    },
    primaryColor() : any
		{
      if(this.getUserClient !== null) {
        
        const api_theme = this.getUserClient.attributes.theme;

        const themeParse = JSON.parse(api_theme);
    
        return themeParse.primary_color;
      
     }

      const themeParse = JSON.parse("{\"header_bg_color\":\"#26314f\",\"primary_color\":\"#ff6b00\",\"secondary_color\":\"#18a0fb\"}");
      
      return themeParse.primary_color;
      
    },
    HeaderColor() : any
		{

      if(this.getUserClient !== null) {
        
            const api_theme = this.getUserClient.attributes.theme;
    
            const themeParse = JSON.parse(api_theme);
        
            return themeParse.header_bg_color;
        
      }

      const themeParse = JSON.parse("{\"header_bg_color\":\"#26314f\",\"primary_color\":\"#ff6b00\",\"secondary_color\":\"#18a0fb\"}");
  
      return themeParse.header_bg_color;
      
    },

  },
  mounted()  {
    let primary = document.documentElement;
    let header = document.documentElement;
    let secondary = document.documentElement;

    primary.style.setProperty('--primary-color', this.primaryColor)  
    header.style.setProperty('--header-color', this.HeaderColor)  
    secondary.style.setProperty('--secondary-color', this.secondaryColor) 


    const api_background = this.getUserClient.attributes.extras;
    
    const backgroundParse = JSON.parse(api_background);

    document.body.style.cssText+=`background-image:url(${backgroundParse.background})`
   
  },

  render: h => h(App)
}).$mount('#app')






























































































import {eventHandler, translations} from '@/mixins'
import { Table, TableColumn, Button } from "element-ui"
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Applications} from "@/services/SOLO"
import { mapGetters } from 'vuex'

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [eventHandler, translations]
})

export default class Content extends Vue {
  public translate!: Function
  loading: boolean = false
  color: string = "white"
  list: any
  search: string = ''
  updatable: any
  getLocale!: any

  mounted() {
    this.getTranslations()
  }

  isArabic(): boolean {
    return this.getCurrentLocale(this.getLocale) === 'ar-sa'
  }

  getCurrentLocale(currentLocale: string): string {
    const locale: any = {
      'en_US' : 'en-us',
      'ar_SA' : 'ar-sa',
      'fr_FR' : 'fr-fr'
    }
    return locale[currentLocale]
  }

  async getTranslations() {
    this.loading = true
    await Applications.getTranslations(this.$route.params.id, this.search)
      .then((response: any) => {
        this.list = response.data.data

        /* @ts-ignore */
        let newList = {};
        this.list.forEach((item: any) => {
          /* @ts-ignore */
          if (!newList[item.attributes.key]) {
            /* @ts-ignore */
            newList[item.attributes.key] = {}
          } 
          
          /* @ts-ignore */
          newList[item.attributes.key][item.attributes.language] = item
          
        })

        /* @ts-ignore */
        let formatList = [], i = 0
        Object.entries(newList).map((v: any) => {
          formatList[i] = {
            editable: false,
            attributes: {},
            id: i
          }
          
          Object.keys(v[1]).forEach(item => {
            /* @ts-ignore */
            formatList[i]['attributes']['key'] = v[0]
            /* @ts-ignore */
            formatList[i]['attributes'][item] = v[1][item]['attributes']['value']
          })

          i+=1
        })
        
        /* @ts-ignore */
        this.list = formatList
        this.loading = false
      })
  }

  toggleRow(row: any, data: boolean) {
    this.updatable = {}

    this.list.forEach((item: any) => {
      if (item.id == row.id) {
        // collect updated row
        if (!data) {
          // this.updatable.push(item.attributes)
          this.updatable['translations'] = [{
            'language': 'en-us',
            'key': item.attributes.key,
            'value': item.attributes['en-us']
          },{
            'language': 'ar-sa',
            'key': item.attributes.key,
            'value': item.attributes['ar-sa']
          }]
        }
        item.editable = data
      }
    });

    if (Object.keys(this.updatable).length) {
      /* @ts-ignore */
      this.updateTranslation()
    }
  }

  async updateTranslation() {
    try {
      await Applications.updateTranslation(this.$route.params.id, this.updatable)
    } catch (error) {
      console.error('Error in updating translation', error)
    }
  }
}

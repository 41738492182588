























































































import Modal from "@/components/Modal.vue";
import {Category as CategoryApi} from "@/services/SOLO";
import {translations} from '@/mixins';
import { Option, Select } from 'element-ui';
import {
  ValidationProvider,
} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [translations],
})
export default class NewPageModal extends Vue {
  durationType: string = '';
  durations: any = [];
  numOfHours: any = null;
  $notify: any;

  @Prop() itemId!: any
  @Prop() open!: Boolean
  
  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("disableCategoryItemModal", newVal);
  }

  mounted() {
    this.durations = [
      {
        value: "1",
        text: "1 Hour"
      },
      {
        value: "4",
        text: "4 Hours"
      },
      {
        value: "__next_business_day__",
        text: "Next Business Date"
      },
      {
        value: "custom",
        text: "Custom"
      }
    ];
  }

  close() {
    this.$emit("closeCategoryItemModal", false);
  }

  get menuId(): string {
    return this.$route.params.id;
  }

  confirmDuration() {
    console.log('numOfHours', this.durationType, this.numOfHours);

    let hours = '';

    if (this.durationType != 'custom') {
      hours = this.durationType;
    } else {
      hours = this.numOfHours;
    }

    let payload = {
      'schedule-disabled-hours': hours,
    };

    CategoryApi.enableCategoryItem(payload, this.$route.params.id, this.$route.params.catId, this.itemId)
      .then((response) => {
        this.$notify({
          title: "DURATION SET!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Duration has been set successfully",
          type: "success",
          icon: "fas fa-check",
        });
        this.durationType = "";
        this.numOfHours = null;
        this.$emit("disableCategoryItemModal", false, this.itemId);
      })
      .catch((err) => {
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}
